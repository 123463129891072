/* eslint-disable react/jsx-props-no-spreading */
import { VFC } from 'react';
import Container from 'global/components/Container/Container';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import { Divider } from '@chakra-ui/react';
import Button from 'global/components/button/Button';
import { FormProvider, useForm } from 'react-hook-form';
import AuthCodeFormContainer from 'global/components/AuthCodeFormContainer/AuthCodeFormContainer';
import UndraggableLink from 'global/components/UndraggableLink/UndraggableLink';
import styles from './PhoneNumberAuthenticationPage.module.css';

type Props = {
  submitted: (phoneNumberAuthCode: number) => void;
};

type FormData = {
  phoneNumberAuthCode: number;
};

const PhoneNumberAuthenticationPage: VFC<Props> = ({ submitted }) => {
  const methods = useForm<FormData>({ mode: 'onBlur' });

  const onSubmit = (data: FormData) => {
    submitted(data.phoneNumberAuthCode);
  };

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <div className={styles.body}>
          <Container marginTop="xxs">
            <div className={styles.textSmall}>
              FitStatsに登録している電話番号に認証コードが送信されました。
              <br />
              送付した6桁の認証コードを入力してください。
            </div>
          </Container>
          <FormProvider {...methods}>
            <Container marginTop="xl">
              <AuthCodeFormContainer name="phoneNumberAuthCode" />
              <p className={styles.errorMessage}>
                {methods.formState.errors.phoneNumberAuthCode?.message}
              </p>
            </Container>
          </FormProvider>
          <Container marginTop="xxl">
            <p className={styles.textSmall}>
              認証コードの有効期限が切れた場合は、
              <br />
              <UndraggableLink className={styles.highlightText} to="/sign_in">
                前の画面
              </UndraggableLink>
              から再度ログイン情報を入力してください。
            </p>
          </Container>
        </div>
      </Container>
      <div className={styles.bottomContainer}>
        <BottomContainer>
          <Divider orientation="horizontal" />
          <Container
            marginTop="xxl"
            marginBottom="m"
            marginLeft="s"
            marginRight="s"
          >
            <Button
              text="ログイン"
              type="primary"
              size="large"
              disabled={
                !methods.formState.isDirty || !methods.formState.isValid
              }
              onClick={methods.handleSubmit(onSubmit)}
            />
          </Container>
        </BottomContainer>
      </div>
    </>
  );
};

export default PhoneNumberAuthenticationPage;
