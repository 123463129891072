import { VFC } from 'react';
import { useHistory } from 'react-router';
import Loading from 'global/components/Loading/Loading';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ScrollToTopWhenMounted from 'global/components/ScrollToTopWhenMounted/ScrollToTopWhenMounted';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import ProgramTopPage from '../pages/ProgramTopPage';
import useProgramTop from '../hooks/use-program-top';
import ProgramTutorialDialog from '../component/ProgramTutorialDialog';
import ProgramId from '../data/common/program-id';

const ProgramTopContainer: VFC = () => {
  const { fetchResult } = useProgramTop();
  const history = useHistory();
  useNavigationBarItemsSetting('FitStatsプログラム');

  if (fetchResult.isSuccess()) {
    return (
      <ScrollToTopWhenMounted>
        <ProgramTutorialDialog />
        <ProgramTopPage
          notJoinedState={fetchResult.value.notJoinedState}
          joinedState={fetchResult.value.joinedState}
          onTapCarouselItem={(id: ProgramId) =>
            history.push(`/program/${id.value}`)
          }
        />
      </ScrollToTopWhenMounted>
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default ProgramTopContainer;
