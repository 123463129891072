import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { VFC } from 'react';
import NotifyEmailCompletePage from '../pages/NotifyEmailCompletePage';

const NotifyEmailCompleteContainer: VFC = () => {
  useNavigationBarItemsSetting('メールアドレスの確認');

  return <NotifyEmailCompletePage />;
};

export default NotifyEmailCompleteContainer;
