import { VFC } from 'react';
import styles from './DetailIntro.module.css';

type Props = {
  offerTitle: string;
  companyName: string;
  termDescription: string;
};

const DetailIntro: VFC<Props> = ({
  offerTitle,
  companyName,
  termDescription,
}) => (
  <>
    <div className={styles.offerTitle}>{offerTitle}</div>
    <div className={styles.offerMessage}>{companyName}</div>
    <div className={styles.offerExpirationDate}>{termDescription}</div>
  </>
);

export default DetailIntro;
