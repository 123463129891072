import { DateTime } from 'luxon';

class YearMonthDay {
  year: number;
  month: number;
  day: number;

  constructor(datetime: DateTime) {
    this.year = datetime.year;
    this.month = datetime.month;
    this.day = datetime.day;
  }

  static fromString = (
    dateString: string,
    separator = '-',
  ): YearMonthDay | undefined => {
    const dateComponents = dateString.split(separator);
    if (dateComponents.length !== 3) {
      return undefined;
    }

    const year = Number(dateComponents[0]);
    const month = Number(dateComponents[1]);
    const day = Number(dateComponents[2]);

    if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
      return undefined;
    }

    const dateTime = DateTime.local(year, month, day);
    if (dateTime.isValid) {
      return new YearMonthDay(dateTime);
    }

    return undefined;
  };

  addDays = (days: number): YearMonthDay => {
    const dateTime = DateTime.fromFormat(
      `${this.year}-${this.month}-${this.day}`,
      'y-M-d',
    );

    return new YearMonthDay(dateTime.plus({ days }));
  };

  diffOfDays = (targetDate: YearMonthDay): number => {
    const targetDateTime = DateTime.fromFormat(
      `${targetDate.year}-${targetDate.month}-${targetDate.day}`,
      'y-M-d',
    );
    const dateTime = DateTime.fromFormat(
      `${this.year}-${this.month}-${this.day}`,
      'y-M-d',
    );
    const diff = targetDateTime.diff(dateTime, 'days');

    return diff.days;
  };

  diffOfYears = (targetDate: YearMonthDay): number => {
    const targetDateTime = DateTime.fromFormat(
      `${targetDate.year}-${targetDate.month}-${targetDate.day}`,
      'y-M-d',
    );
    const dateTime = DateTime.fromFormat(
      `${this.year}-${this.month}-${this.day}`,
      'y-M-d',
    );
    const diff = targetDateTime.diff(dateTime, 'years');

    return Math.trunc(diff.years);
  };

  separatedByHyphen = (): string => this.separatedYMDBy('-');
  separatedYMMDDByHyphen = (): string => this.separatedYMMDDBy('-');

  separatedBySlash = (): string => this.separatedYMDBy('/');

  private separatedYMDBy = (separator: string): string =>
    `${
      this.year
    }${separator}${this.month.toString()}${separator}${this.day.toString()}`;

  private separatedYMMDDBy = (separator: string): string =>
    `${this.year}${separator}${this.month
      .toString()
      .padStart(2, '0')}${separator}${this.day.toString().padStart(2, '0')}`;
}

export const isYearMonthDay = (data: unknown): data is YearMonthDay => {
  if (data == null) {
    return false;
  }
  const record = data as Record<string, unknown>;

  return (
    typeof record.year === 'number' &&
    typeof record.month === 'number' &&
    typeof record.day === 'number'
  );
};

export default YearMonthDay;
