import { useQuery } from 'react-query';
import Result from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import { isErrorDTO } from '../../../data/dto/error-dto';
import NoticeDetailDto from '../data/dto/notice-detail-dto';
import NoticeDetailRepository, {
  NoticeDetailRepositoryImpl,
} from '../data/repositories/notice-detail-repository';
import NoticeDetailViewData from '../view-data/notice-detail-view-data';

type ReturnType = {
  fetchResult: Result<NoticeDetailViewData, Error>;
};

const convertDtoToViewData = (dto: NoticeDetailDto): NoticeDetailViewData => ({
  ...dto,
  registeredAt: dto.registeredAt
    .setZone('Asia/Tokyo')
    .toFormat('yyyy/M/d HH:mm'),
});

const useNoticeDetail = (
  id: string,
  repository: NoticeDetailRepository = new NoticeDetailRepositoryImpl(),
): ReturnType => {
  const queryResult = useQuery<NoticeDetailViewData, Error>(
    [`/notice-detail/${id}`],
    async () => {
      const dto = await repository.fetch(id).catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertDtoToViewData(dto);
    },
  );

  const fetchResult = createResult(queryResult);

  return {
    fetchResult,
  };
};

export default useNoticeDetail;
