import queryClient from 'global/query-client';

type ReturnType = {
  clearCaches: () => void;
};

const useClearCaches = (): ReturnType => {
  // メモリにキャッシュしているデータを削除する.
  // 現状React Queryのキャッシュのみだが、
  // その他に削除すべきものがあればこちらに追加する.
  const clearCaches = () => {
    queryClient.clear();
  };

  return { clearCaches };
};

export default useClearCaches;
