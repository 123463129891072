import { useRef, VFC } from 'react';
import { useHistory } from 'react-router';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import TopContainer from 'global/components/TopContainer/TopContainer';
import ErrorDTO from 'data/dto/error-dto';
import ProgressBar from 'features/sign-up/components/ProgressBar/ProgressBar';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import PasswordPage from '../pages/PasswordPage';
import usePassword from '../hooks/use-password';
import { PasswordRepositoryImpl } from '../data/repositories/password-repository';

type DialogInfo = {
  description: string;
  primaryButtonAction: () => void;
};

const PasswordContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, onClose } = useDialog();
  const dialogInfoRef = useRef<DialogInfo | undefined>();
  const primaryButtonActionRef = useRef<() => void>(() => onClose());

  useNavigationBarItemsSetting('パスワードの登録', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const isAlreadyExistsError = (error: ErrorDTO | undefined) =>
    error && error.error.status === 'ALREADY_EXISTS';

  const { submitted } = usePassword(
    new PasswordRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        localStorage.setItem('deviceId', saveResult.value);

        history.push('/sign_up/profile/basic');
      }

      if (saveResult.isFailure()) {
        const description = saveResult.error
          ? saveResult.error.error.message
          : 'パスワードの登録に失敗しました。';

        if (isAlreadyExistsError(saveResult.error)) {
          primaryButtonActionRef.current = () => {
            onClose();
            history.push('/sign_in');
          };
        }

        dialogInfoRef.current = {
          description,
          primaryButtonAction: primaryButtonActionRef.current,
        };

        requestShowing();
      }
    },
  );

  return (
    <>
      {dialogInfoRef.current && (
        <Dialog
          title="エラー"
          description={dialogInfoRef.current.description}
          primaryButton={{
            text: 'OK',
            onClick: dialogInfoRef.current.primaryButtonAction,
          }}
          isOpen={isOpen}
          onClose={() => undefined}
          showsCloseButton={false}
        />
      )}

      {/* PasswordPageは初回登録以外でも呼ばれる可能性があるためここで初回登録用のプログレスバーを設置 */}
      <TopContainer>
        <ProgressBar max={8} value={7} />
      </TopContainer>
      {/* これ以上style長くなりそうならcssファイルの切り出し検討する */}
      <div style={{ paddingTop: 60, paddingBottom: 150 }}>
        <PasswordPage submitted={submitted} />
      </div>
    </>
  );
};

export default PasswordContainer;
