import { Flex } from '@chakra-ui/react';
import { useState, VFC } from 'react';
import Container from '../Container/Container';
import styles from './NoticeContainer.module.css';

export type Props = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  children: any;
  closeButtonCustomAction?: () => void;
};

const NoticeContainer: VFC<Props> = ({ children, closeButtonCustomAction }) => {
  const [isOpen, setIsOpen] = useState(true);

  const closeButtonTapped = () => {
    if (closeButtonCustomAction) {
      closeButtonCustomAction();
    }
    setIsOpen(false);
  };

  return (
    <div
      className={[
        styles.noticeContainer,
        isOpen ? styles.showContainer : styles.closeContainer,
      ].join(' ')}
    >
      <Flex alignItems="center" justifyContent="flex-start">
        <Container marginRight="xxs">
          <div className={styles.noticeIcon} />
        </Container>
        {children}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button
          type="button"
          className={styles.closeBtn}
          onClick={closeButtonTapped}
        />
      </Flex>
    </div>
  );
};

export default NoticeContainer;
