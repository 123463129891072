import { FC, useContext } from 'react';
import GlobalNavigationDisplayableContext from 'global/contexts/GlobalNavigationDisplayableContext';
import { GLOBAL_NAVIGATION_HEIGHT_PX } from 'global/constants';
import styles from './BottomContainer.module.css';

type Props = {
  background?: string;
};

const BottomContainer: FC<Props> = ({ children, background }) => {
  const shouldDisplayGlobalNavigation = useContext(
    GlobalNavigationDisplayableContext,
  );

  return (
    <div
      style={{
        background: background || '#fff',
        bottom: shouldDisplayGlobalNavigation
          ? `${GLOBAL_NAVIGATION_HEIGHT_PX}px`
          : 0,
      }}
      className={styles.bottomContainer}
    >
      {children}
    </div>
  );
};

export default BottomContainer;
