import { isErrorDTO } from 'data/dto/error-dto';
import { DataConsentRepositoryImpl } from 'features/my-data/data/repositories/data-consent-repository';
import { TutorialLocationState } from 'features/tutorial/hooks/use-tutorial-navigation';
import CoarchMark from 'global/components/CoarchMark/CoarchMark';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import Loading from 'global/components/Loading/Loading';
import TutorialFetchErrorDialog from 'global/components/TutorialFetchErrorDialog/TutorialFetchErrorDialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { useState, VFC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ClientCompanyRepositoryImpl } from '../data/repositories/client-company-repository';
import useClientCompany from '../hooks/use-client-company';
import ClientCompanyPage from '../pages/ClientCompanyPage';

const TutorialClientCompanyContainer: VFC = () => {
  useNavigationBarItemsSetting('データ公開する企業の選択', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const { isOpen, dialogProps, requestShowing, onClose } = useDialog();
  const history = useHistory<TutorialLocationState>();
  const { state } = useLocation<TutorialLocationState | undefined>();
  const [currentShowsCloseButton, setShowsCloseButton] = useState(true);
  const [currentOnCloseAction, setOnCloseAction] = useState(() => onClose);

  const {
    fetchResult,
    pageLoaded,
    storageData,
    closeButtonTapped,
    didConsentChanged,
    saveButtonTapped,
  } = useClientCompany(
    new ClientCompanyRepositoryImpl(),
    new DataConsentRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        requestShowing({
          title: '初回登録は全て完了しました',
          description:
            'これからオファーをたくさん受け取って、FitStatsポイントを貯めていきましょう。',
          imageSrc: '/offer/offer_achive.svg',
          primaryButton: {
            text: 'FitStatsをはじめる',
            onClick: () => history.push('/top'),
          },
        });
        setShowsCloseButton(false);
        setOnCloseAction(() => ''); // チュートリアル中なので、閉じない
      }
      if (saveResult.isFailure()) {
        requestShowing({
          title: 'エラー',
          description: isErrorDTO(saveResult.error)
            ? saveResult.error.error.message
            : '公開するデータの更新に失敗しました。',
          primaryButton: { text: 'OK' },
        });
      }
    },
  );

  const PopOverValue = {
    children: (
      <div>
        あなたのデータを公開している企業が多いほど、より多くのオファーが届きます。
        <br />
        <br />
        早速、データを公開する企業を選んでみましょう。
      </div>
    ),
    clickOption: {
      clickWithCoarchMarkClosed: true,
    },
  };

  if (fetchResult.isSuccess()) {
    return (
      <>
        <Dialog
          title={dialogProps?.title}
          description={dialogProps?.description}
          imageSrc={dialogProps?.imageSrc}
          primaryButton={dialogProps?.primaryButton ?? { text: 'OK' }}
          isOpen={isOpen}
          showsCloseButton={currentShowsCloseButton}
          onClose={currentOnCloseAction}
        />
        <ClientCompanyPage
          clientConmapnyData={fetchResult.value}
          pageLoaded={pageLoaded}
          storageData={storageData}
          closeButtonTapped={closeButtonTapped}
          didConsentChanged={didConsentChanged}
          saveButtonTapped={saveButtonTapped}
          locationState={state}
        />
        {!state?.hideCoarchMark && (
          <CoarchMark
            elementId="tutorial-mydata-client-company"
            popOverOption={PopOverValue}
          />
        )}
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <TutorialFetchErrorDialog />;
  }

  return <Loading />;
};

export default TutorialClientCompanyContainer;
