import { useQuery } from 'react-query';
import Result, { Failure, Success } from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { isErrorDTO } from 'data/dto/error-dto';
import ProfileRepository, {
  ProfileRepositoryImpl,
} from 'features/profile/data/repositories/profile-repository';
import queryClient from 'global/query-client';
import { useRef, useEffect } from 'react';
import { TutorialLocationState } from 'features/tutorial/hooks/use-tutorial-navigation';
import ProfileDto, {
  ProfilePage,
  ProfileSection,
} from 'features/profile/data/dto/profile-dto';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import PersonalDataRepository, {
  PersonalDataRepositoryImpl,
} from '../data/repositories/personal-data-repository';
import DataConsentRepository, {
  DataConsentRepositoryImpl,
  ConsentCategory,
  ConsentItem,
} from '../../data/repositories/data-consent-repository';
import PersonalDataViewData, {
  ConsentElement,
  StorageViewData,
} from '../view-data/personal-page-view-data';
import buildPersonalDataViewData from './utils/build-personal-page-view-data';
import updateConsentState from './utils/update-consent-state';
import buildConsentProperties from './utils/build-consent-items';

const IS_NOTICE_CLOSED_DATA_KEY = '/personal_data_notice_closed';
const PERSONAL_DATA_CACHE_KEY = '/personal_data';

export type ReturnType = {
  fetchResult: Result<PersonalDataViewData, Error>;
  storageData: StorageViewData;
  isConsentStateChanged: boolean;
  closeButtonTapped: () => void;
  didConsentChanged: (item: ConsentElement) => void;
  saveButtonTapped: () => void;
  locationState?: TutorialLocationState | undefined;
};

const cachedPersonalData = (): PersonalDataViewData | undefined =>
  queryClient.getQueryData(PERSONAL_DATA_CACHE_KEY);

const sortProfileData = (
  locationState: TutorialLocationState | undefined,
  profileDto: ProfileDto,
): ProfilePage | undefined => {
  if (locationState?.isTutorial) {
    let filteredProfileDto = profileDto.pages.filter((page) =>
      ['profile_basic_info', 'profile_detail'].includes(page.key),
    );

    // ライフスタッツ、カラダの項目は、チュートリアルの時点で公開設定を行う
    const myDataDto = profileDto.pages.find((page) => page.key === 'mydata');
    if (myDataDto) {
      const filteredSections = myDataDto.sections.filter((section) =>
        ['ライフスタッツ', 'カラダ'].includes(section.title),
      );
      myDataDto.sections = filteredSections;
      filteredProfileDto = filteredProfileDto.concat(myDataDto);
    }

    const result: ProfileSection[] = [];
    filteredProfileDto.map((d) =>
      d.sections.map((s) =>
        result.push({
          title: s.title,
          formItems: s.formItems,
        }),
      ),
    );

    return {
      key: 'profile_for_tutorial',
      sections: result,
    };
  }

  return profileDto.pages.find((page) => page.key === 'mydata');
};

const usePersonalData = (
  saveResult: (result: Result<void, Error>) => void,
  userProfileDataRepository: PersonalDataRepository = new PersonalDataRepositoryImpl(),
  profileDataRepository: ProfileRepository = new ProfileRepositoryImpl(),
  consentRepository: DataConsentRepository = new DataConsentRepositoryImpl(),
  locationState?: TutorialLocationState | undefined,
): ReturnType => {
  const savedConsentItems = useRef<ConsentItem[]>();

  useEffect(() => {
    const clearQuery = () => {
      queryClient.removeQueries({
        queryKey: PERSONAL_DATA_CACHE_KEY,
      });
    };

    return clearQuery;
  }, []);

  const queryResult = useQuery<PersonalDataViewData, Error>(
    PERSONAL_DATA_CACHE_KEY,
    async () => {
      try {
        const userProfileDto = await userProfileDataRepository.fetch();
        const profileDto = await profileDataRepository.fetch();
        const consentDto = await consentRepository.fetch(
          ConsentCategory.Property,
        );

        const targetProfileData = sortProfileData(locationState, profileDto);

        if (targetProfileData) {
          const personalDataViewData = buildPersonalDataViewData(
            targetProfileData,
            userProfileDto,
            consentDto,
          );

          savedConsentItems.current =
            buildConsentProperties(personalDataViewData);

          return personalDataViewData;
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      } catch (error) {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      }
    },
  );

  const fetchResult = createResult(queryResult);

  const closeButtonTapped = () => {
    const formattedValue = JSON.stringify(true);

    localStorage.setItem(IS_NOTICE_CLOSED_DATA_KEY, formattedValue);
  };

  const isCloseButtonTapped = (): boolean => {
    const data: string | null = localStorage.getItem(IS_NOTICE_CLOSED_DATA_KEY);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedData = data === null ? undefined : JSON.parse(data);

    return typeof parsedData === 'boolean' ? parsedData : false;
  };

  const didConsentChanged = (consentElement: ConsentElement): void => {
    const cachedData = cachedPersonalData();
    if (cachedData == null) {
      return;
    }

    const newPersonalData = updateConsentState(cachedData, consentElement);
    queryClient.setQueryData(PERSONAL_DATA_CACHE_KEY, newPersonalData);
  };

  const saveButtonTapped = () => {
    const cachedData = cachedPersonalData();
    if (cachedData == null) {
      return;
    }

    const consentItems = buildConsentProperties(cachedData);
    consentRepository
      .save(ConsentCategory.Property, consentItems)
      .then(() => {
        savedConsentItems.current = consentItems;
        saveResult(new Success(undefined));
      })
      .catch((error) => {
        saveResult(new Failure(error));
      });
  };

  const isConsentStateChanged =
    fetchResult.isSuccess() && savedConsentItems.current != null
      ? JSON.stringify(savedConsentItems.current) !==
        JSON.stringify(buildConsentProperties(fetchResult.value))
      : false;

  return {
    fetchResult,
    storageData: {
      isCloseButtonTapped: isCloseButtonTapped(),
    },
    isConsentStateChanged,
    closeButtonTapped,
    didConsentChanged,
    saveButtonTapped,
  };
};

export default usePersonalData;
