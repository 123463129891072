import { VFC } from 'react';
import Loading from 'global/components/Loading/Loading';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import ProfileDetailPage from '../pages/ProfileDetailPage';
import useProfileDetail from '../hooks/use-profile-detail';

const ProfileDetailContainer: VFC = () => {
  const { fetchResult, nextButtonTapped } = useProfileDetail();
  useNavigationBarItemsSetting('プロフィールの登録 2/2', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  if (fetchResult.isSuccess()) {
    return (
      <ProfileDetailPage
        result={fetchResult.value}
        nextButtonTapped={nextButtonTapped}
      />
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default ProfileDetailContainer;
