import { useQuery } from 'react-query';
import AuthenticationRepository from 'features/authentication/data/repositories/authentication-repository';
import { isErrorDTO } from 'data/dto/error-dto';
import Result from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';

const useAuthentication = (
  authorizationCode: string,
  onSuccess: () => void,
): Result<void, Error> => {
  const queryResult = useQuery<void, Error>(
    ['/finc_users/authenticate', authorizationCode],
    async () => {
      const repository = new AuthenticationRepository();
      const _ = await repository
        .authenticate(authorizationCode)
        .then(onSuccess)
        .catch((error) => {
          if (isErrorDTO(error)) {
            throw Error(error.error.message);
          }
          throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
        });
    },
  );

  return createResult(queryResult);
};

export default useAuthentication;
