import { VFC } from 'react';
import useDialog from 'global/components/dialog/use-dialog';
import Loading from 'global/components/Loading/Loading';
import Dialog from 'global/components/dialog/Dialog';
import TermsOfUseAndPrivacyPolicyPage from 'global/pages/terms-of-use-and-privacy-policy/TermsOfUseAndPrivacyPolicyPage';
import { useHistory } from 'react-router';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import useTermsOfUse from '../hooks/use-terms-of-use';
import { TermsOfUseRepositoryImpl } from '../data/repositories/terms-of-use-repository';

export type PrivacyPolicyLocationState = {
  privacyPolicy: boolean;
};

const TermsOfUseContainer: VFC = () => {
  const history = useHistory<PrivacyPolicyLocationState>();
  const { privacyPolicy } = history.location.state;
  const { isOpen, requestShowing, onClose } = useDialog();
  useNavigationBarItemsSetting('利用規約', {
    isCloseButtonVisible: true,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const { fetchResult, submitErrorMessage, submitted } = useTermsOfUse(
    new TermsOfUseRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        if (privacyPolicy) {
          history.push('/terms_and_privacy_policy/privacy_policy');
        } else {
          history.push('/top');
        }
      }
      if (saveResult.isFailure()) {
        requestShowing();
      }
    },
  );

  if (fetchResult.isSuccess()) {
    return (
      <>
        <Dialog
          title="エラー"
          description={submitErrorMessage}
          primaryButton={{ text: 'OK' }}
          isOpen={isOpen}
          onClose={onClose}
        />

        <TermsOfUseAndPrivacyPolicyPage
          name="利用規約"
          content={fetchResult.value.content}
          submitted={submitted}
        />
      </>
    );
  }

  if (fetchResult.isFailure())
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;

  return <Loading />;
};
export default TermsOfUseContainer;
