import ApiClient from 'data/api-client/api-client';
import AuthenticationDTO from 'features/authentication/data/dto/authentication-dto';
import RequestConfig, { URL } from 'data/request-configs/request-config';

const createAuthenticationRequestConfig = (
  authorizationCode: string,
): RequestConfig => ({
  endpoint: { baseUrl: URL.FIT_STATS, path: '/finc_users/authenticate' },
  method: 'post',
  parameters: { code: authorizationCode },
});

class AuthenticationRepository {
  authenticate = async (
    authorizationCode: string,
  ): Promise<AuthenticationDTO> =>
    new ApiClient().connect(
      createAuthenticationRequestConfig(authorizationCode),
    );
}

export default AuthenticationRepository;
