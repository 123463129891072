import { DateTime } from 'luxon';

export const LAST_PAGE_LOAD_AT_DATA_KEY = '/last_page_load_at';

const lastPageLoadAt = (): DateTime | undefined => {
  const data: string | null = localStorage.getItem(LAST_PAGE_LOAD_AT_DATA_KEY);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const parsedData = data === null ? undefined : JSON.parse(data);

  const convertedData = parsedData ? DateTime.fromISO(parsedData) : undefined;

  return convertedData;
};

export default lastPageLoadAt;
