import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import Loading from 'global/components/Loading/Loading';
import { useState, VFC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { isErrorDTO } from 'data/dto/error-dto';
import { TutorialLocationState } from 'features/tutorial/hooks/use-tutorial-navigation';
import { ProfileRepositoryImpl } from 'features/profile/data/repositories/profile-repository';
import { DataConsentRepositoryImpl } from 'features/my-data/data/repositories/data-consent-repository';
import CoarchMark from 'global/components/CoarchMark/CoarchMark';
import TutorialFetchErrorDialog from 'global/components/TutorialFetchErrorDialog/TutorialFetchErrorDialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import usePersonalDataProfile from '../hooks/use-personal-data-profile';
import PersonalDataPage from '../pages/PersonalDataPage';
import { PersonalDataRepositoryImpl } from '../data/repositories/personal-data-repository';

const TutorialPersonalDataContainer: VFC = () => {
  useNavigationBarItemsSetting('公開するデータの選択', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const { isOpen, dialogProps, requestShowing, onClose } = useDialog();
  const history = useHistory<TutorialLocationState>();
  const { state } = useLocation<TutorialLocationState | undefined>();
  const [currentShowsCloseButton, setShowsCloseButton] = useState(true);
  const [currentOnCloseAction, setOnCloseAction] = useState(() => onClose);

  const {
    fetchResult,
    storageData,
    closeButtonTapped,
    didConsentChanged,
    saveButtonTapped,
  } = usePersonalDataProfile(
    (result) => {
      if (result.isSuccess()) {
        requestShowing({
          title: 'あなたのデータを公開する企業を選びましょう',
          description:
            'あなたのデータを公開する企業は、ご自身で選択することができます。\n\n※設定した内容は、いつでも変更することができます。',
          imageSrc: '/tutorial/tutorial_10@3x.png',
          imageSize: 'large',
          primaryButton: {
            text: 'データを公開する企業を選ぶ',
            onClick: () =>
              history.push('/mydata/client_company', { isTutorial: true }),
          },
        });
        setShowsCloseButton(false);
        setOnCloseAction(() => ''); // チュートリアル中なので、閉じない
      } else if (result.isFailure()) {
        requestShowing({
          title: 'エラー',
          description: isErrorDTO(result.error)
            ? result.error.error.message
            : '公開するデータの更新に失敗しました。',
          primaryButton: { text: 'OK' },
        });
      }
    },
    new PersonalDataRepositoryImpl(),
    new ProfileRepositoryImpl(),
    new DataConsentRepositoryImpl(),
    state,
  );

  const PopOverValue = {
    children: (
      <div>
        公開するデータが多いほど、より多くのオファーが届きます。
        <br />
        <br />
        早速、公開するデータを選んでみましょう。
      </div>
    ),
    clickOption: {
      clickWithCoarchMarkClosed: true,
    },
  };

  if (fetchResult.isSuccess()) {
    return (
      <>
        <Dialog
          title={dialogProps?.title}
          description={dialogProps?.description}
          imageSrc={dialogProps?.imageSrc}
          imageSize={dialogProps?.imageSize}
          primaryButton={dialogProps?.primaryButton ?? { text: 'OK' }}
          isOpen={isOpen}
          showsCloseButton={currentShowsCloseButton}
          onClose={currentOnCloseAction}
        />
        <PersonalDataPage
          personalData={fetchResult.value}
          storageData={storageData}
          closeButtonTapped={closeButtonTapped}
          didConsentChanged={didConsentChanged}
          saveButtonTapped={saveButtonTapped}
          locationState={state}
        />
        <CoarchMark
          elementId="tutorial-mydata-personal"
          popOverOption={PopOverValue}
        />
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <TutorialFetchErrorDialog />;
  }

  return <Loading />;
};

export default TutorialPersonalDataContainer;
