import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';

const createWithdrawalSettingsRequestConfig = (
  reasons: string[],
  opinionAndRequest: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/withdrawal',
  },
  method: 'post',
  parameters: {
    withdrawal_reasons: reasons,
    withdrawal_comments: opinionAndRequest,
  },
});

interface WithdrawalSettingsRepository {
  withdraw: (reasons: string[], opinionAndRequest: string) => Promise<void>;
}

export class WithdrawalSettingsRepositoryImpl
  implements WithdrawalSettingsRepository
{
  withdraw = async (
    reasons: string[],
    opinionAndRequest: string,
  ): Promise<void> =>
    new ApiClient().connect(
      createWithdrawalSettingsRequestConfig(reasons, opinionAndRequest),
    );
}

export default WithdrawalSettingsRepository;
