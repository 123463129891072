import ClientCompanyRepository, {
  ClientCompanyRepositoryImpl,
} from 'features/my-data/client-company/data/repositories/client-company-repository';
import NoticeTopRepository, {
  NoticeTopRepositoryImpl,
} from 'features/notice/data/repositories/notice-top-repository';
import OfferListDto from 'features/offer/data/dto/offer-list-dto';
import OfferRepository, {
  OfferRepositoryImpl,
} from 'features/offer/data/repositories/offer-repository';
import ProgramActionsNotAchievedCountRepository, {
  ProgramActionsNotAchievedCountRepositoryImpl,
} from 'global/data/repositories/program-actions-not-achieved-count-repository';
import { useCallback, useState } from 'react';
import NoticeTopDto from 'features/notice/data/dto/notice-top-dto';
import useNumberOfNewClientCompanies from './use-number-of-new-client-companies';

type ReturnType = {
  fetchBatchItems: () => void;
  numberOfNotifications: number;
  numberOfUnreadOffers: number;
  numberOfUnachievedPrograms: number;
  numberOfNewCompanies: number;
};

const fetchNumberOfNotifications = (
  fetcher: Promise<NoticeTopDto>,
): Promise<number> =>
  new Promise((resolve) => {
    void fetcher.then((dto) => {
      const numberOfItems = dto.notifications.filter((n) => !n.isRead).length;
      resolve(numberOfItems);
    });
  });

const fetchNumberOfUnreadOffers = (
  fetcher: Promise<OfferListDto>,
): Promise<number> =>
  new Promise((resolve) => {
    void fetcher.then((dto) => {
      const numberOfItems = dto.offers.filter(
        (offer) => !offer.isOfferOpened,
      ).length;
      resolve(numberOfItems);
    });
  });

const useGlobalMenuBatchItems = (
  offerRepository: OfferRepository = new OfferRepositoryImpl(),
  programActionsCountRepository: ProgramActionsNotAchievedCountRepository = new ProgramActionsNotAchievedCountRepositoryImpl(),
  clientCompanyRepository: ClientCompanyRepository = new ClientCompanyRepositoryImpl(),
  noticeTopRepository: NoticeTopRepository = new NoticeTopRepositoryImpl(),
): ReturnType => {
  // お知らせ数
  const [numberOfNotifications, setNumberOfNotifications] = useState(0);
  // 未読のオファー数
  const [numberOfUnreadOffers, setNumberOfUnreadOffers] = useState(0);
  // 未達成のプログラム数
  const [numberOfUnachievedPrograms, setNumberOfUnachievedPrograms] =
    useState(0);
  // 新着企業数
  const { fetchNumberOfNewClientCompanies, numberOfNewCompanies } =
    useNumberOfNewClientCompanies(clientCompanyRepository);

  const fetchBatchItems = useCallback(() => {
    void fetchNumberOfNotifications(noticeTopRepository.fetch(1, 100)).then(
      (n) => setNumberOfNotifications(n),
    );
    void fetchNumberOfUnreadOffers(offerRepository.fetchList()).then((n) =>
      setNumberOfUnreadOffers(n),
    );
    void programActionsCountRepository
      .fetch()
      .then((dto) =>
        setNumberOfUnachievedPrograms(dto.notAchievedActionsCount),
      );
    fetchNumberOfNewClientCompanies();

    // NOTE: useGlobalMenuBatchItemsの引数のofferRepository, programActionsCountRepositoryを依存配列に追加する必要があるが、
    // レンダリングごとに別のオブジェクトとなり意図した挙動にならない.
    // 望ましいやり方ではないが、影響がないと断言できるため disable exhaustive-deps している
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    fetchBatchItems,
    numberOfNotifications,
    numberOfUnreadOffers,
    numberOfUnachievedPrograms,
    numberOfNewCompanies,
  };
};

export default useGlobalMenuBatchItems;
