import { useEffect, useState, VFC } from 'react';
import { Prompt } from 'react-router';
import PopOver from '../PopOver/PopOver';
import styles from './CoarchMark.module.css';

type popOverClickOption = {
  clickWithCoarchMarkClosed?: boolean;
  clickCustomAction?: () => void;
};

type popOverValue = {
  children: JSX.Element;
  buttonText?: string;
  clickOption?: popOverClickOption;
};

export type Props = {
  elementId: string;
  popOverOption: popOverValue;
};

const CoarchMark: VFC<Props> = ({ elementId, popOverOption }) => {
  const [currentPopOverEnabled, setPopOverEnabled] = useState(false);
  const [currentPopOverPosition, setPopOverPosition] = useState(0);

  const handleClick = () => {
    // ターゲットidを検索し、css classを付与（ターゲットを画面最前面に表示する）
    const target = document.getElementById(elementId) as HTMLElement;
    target.classList.add(styles.tutorialTarget);

    // body全体をoverlayする
    const body = document.getElementById('tutorial-body') as HTMLElement;
    body.classList.add(styles.tutorialBody);

    // ターゲットの位置を検索し、popoverをセットする
    const clientRect = target.getBoundingClientRect();
    const popover = document.getElementById('tutorial-popover') as HTMLElement;
    popover.classList.add(styles.tutorialPopOver);

    // popoverを指定位置に表示する
    setPopOverEnabled(true);
    setPopOverPosition(clientRect.bottom); // FIXME: 表示位置上下で選べるようにする。
  };

  const setPopOverClosed = () => {
    // popoverを非表示にする
    setPopOverEnabled(false);
  };

  const setCoarchMarkClosed = () => {
    // body全体のoverlayを解除
    const body = document.getElementById('tutorial-body') as HTMLElement;
    body.classList.remove(styles.tutorialBody);

    // ターゲットidの画面最前面表示を解除
    const target = document.getElementById(elementId) as HTMLElement;
    target.classList.remove(styles.tutorialTarget);
  };

  const onPopOverClick = () => {
    if (popOverOption.clickOption?.clickCustomAction) {
      popOverOption.clickOption.clickCustomAction();
    }

    if (popOverOption.clickOption?.clickWithCoarchMarkClosed) {
      setCoarchMarkClosed();
    }

    setPopOverClosed();
  };

  // ターゲットの要素の読み込みが完了するまでpopoverの位置を確定できないので、画像の読み込み完了後に処理を始める。
  // 背景: useEffectだけだと遅れて読み込まれた画像等でターゲットの表示位置が変わりpopoverが追従できないことがある。
  useEffect(() => {
    const imageTags: HTMLCollection = document.getElementsByTagName('img');

    let imageCount = 0;
    for (let i = 0; i < imageTags.length; i += 1) {
      if (imageTags[i].getAttribute('src')) {
        imageCount += 1;
      }
    }

    if (imageCount === 0) {
      handleClick();
    }

    let imageLoadCount = 0;
    for (let i = 0; i < imageTags.length; i += 1) {
      // eslint-disable-next-line no-loop-func
      imageTags[i].addEventListener('load', () => {
        imageLoadCount += 1;

        if (imageLoadCount >= imageCount) {
          handleClick();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id="tutorial-popover"
      style={{
        top: currentPopOverPosition + 24,
        width: '90%',
      }}
    >
      <PopOver
        enabled={currentPopOverEnabled}
        onClick={onPopOverClick}
        buttonText={popOverOption.buttonText}
      >
        {popOverOption.children}
      </PopOver>
      <Prompt
        when
        message={() => {
          setCoarchMarkClosed();

          // see: https://v5.reactrouter.com/core/api/Prompt/message-func
          return true;
        }}
      />
    </div>
  );
};

export default CoarchMark;
