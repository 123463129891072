import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import UserTermsAgreementStatusesDto from '../dto/user-terms-agreement-statuses-dto';

const createUserTermsAgreementStatusesRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/statuses_of_terms_agreement/',
  },
  method: 'get',
  parameters: {},
});

interface UserTermsAgreementStatusesRepository {
  fetch: () => Promise<UserTermsAgreementStatusesDto>;
}

export class UserTermsAgreementStatusesRepositoryImpl
  implements UserTermsAgreementStatusesRepository
{
  fetch = async (): Promise<UserTermsAgreementStatusesDto> =>
    new ApiClient().connect(createUserTermsAgreementStatusesRequestConfig());
}

export default UserTermsAgreementStatusesRepository;
