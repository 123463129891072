import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import { useRef } from 'react';
import NewPasswordRepository, {
  NewPasswordRepositoryImpl,
} from '../data/repositories/new-password-repository';

type ReturnType = {
  submitErrorMessage?: string;
  submitted: (newPassword: string, transactionId: string) => void;
};

const useNewPassword = (
  saveResult: (result: Result<string, string>) => void,
  repository: NewPasswordRepository = new NewPasswordRepositoryImpl(),
): ReturnType => {
  const submitErrorMessage = useRef<string | undefined>(undefined);

  const submitted = (newPassword: string, transactionId: string): void => {
    const _ = repository
      .send(newPassword, transactionId)
      .then(() => {
        saveResult(new Success(transactionId));
      })
      .catch((e) => {
        const message = isErrorDTO(e)
          ? e.error.message
          : '認証コードの送信に失敗しました';
        submitErrorMessage.current = message;
        saveResult(new Failure(message));
      });
  };

  return {
    submitErrorMessage: submitErrorMessage.current,
    submitted,
  };
};

export default useNewPassword;
