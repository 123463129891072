import Badge from 'global/components/Badge/Badge';
import Container from 'global/components/Container/Container';
import { VFC } from 'react';
import UndraggableLink from 'global/components/UndraggableLink/UndraggableLink';
import styles from './MyDataTopPage.module.css';
import FitstatsPoint from '../components/fitstats-point/FitstatsPoint';

type Props = {
  hasNewClientCompany: boolean;
  elneLinkButtonTapped: () => void;
};

const MyDataTopPage: VFC<Props> = ({
  hasNewClientCompany,
  elneLinkButtonTapped,
}) => (
  <div className={styles.container}>
    <div className={styles.pointSection}>
      <FitstatsPoint showHistoryLink />
    </div>
    <Container marginTop="s">
      <div className={styles.sectionTitle}>マイデータを編集する</div>
      <Container marginTop="s">
        <UndraggableLink
          to="/mydata/personal/edit"
          className={styles.dataSettingLink}
        >
          <div className={styles.dataSettingItem}>
            <Container marginLeft="s">
              <img
                src="/mydata/mydateicon_edit@3x.png"
                className={styles.dataSettingIcon}
                alt=""
              />
            </Container>
            <Container marginLeft="s" marginRight="s">
              <div className={styles.dataSettingTitle}>データの編集</div>
            </Container>
          </div>
        </UndraggableLink>
      </Container>
      <Container marginTop="s">
        <UndraggableLink
          to="/mydata/personal"
          className={styles.dataSettingLink}
        >
          <div className={styles.dataSettingItem}>
            <Container marginLeft="s">
              <img
                src="/mydata/mydateicon_select@3x.png"
                className={styles.dataSettingIcon}
                alt=""
              />
            </Container>
            <Container marginLeft="s" marginRight="s">
              <div className={styles.dataSettingTitle}>
                公開するデータの選択
              </div>
            </Container>
          </div>
        </UndraggableLink>
      </Container>

      <Container marginTop="s">
        <UndraggableLink
          to="/mydata/client_company"
          className={styles.dataSettingLink}
        >
          <div className={styles.dataSettingItem}>
            <Container marginLeft="s">
              <img
                src="/mydata/mydateicon_disclosure@3x.png"
                className={styles.dataSettingIcon}
                alt=""
              />
            </Container>
            <Container marginLeft="s" marginRight="s">
              <div className={styles.dataSettingTitle}>
                データ公開する企業の選択
              </div>
              {hasNewClientCompany && (
                <>
                  <div className={styles.dataSettingItemBadge}>
                    <Badge type={{ kind: 'unread' }} />
                  </div>
                  <div className={styles.dataSettingItemSupplement}>
                    新規追加された企業があります
                  </div>
                </>
              )}
            </Container>
          </div>
        </UndraggableLink>
      </Container>
    </Container>

    <Container marginTop="s">
      <div className={styles.sectionTitle}>ポイントを使う</div>

      <Container marginTop="s">
        <UndraggableLink to="/finc_coupon" className={styles.dataSettingLink}>
          <div className={styles.pointBanner}>
            <img
              src="/mydata/finc_coupon_banner.png"
              alt="finc_coupon_banner"
            />
          </div>
        </UndraggableLink>
      </Container>
      <Container marginTop="s">
        <button
          type="button"
          className={styles.transparentArea}
          onClick={() => elneLinkButtonTapped()}
        >
          <div className={styles.pointBanner}>
            <img src="/mydata/elne_point_banner.png" alt="elne_point_banner" />
          </div>
        </button>
      </Container>
      <Container marginTop="s">
        <UndraggableLink to="/honto_coupon" className={styles.dataSettingLink}>
          <div className={styles.pointBanner}>
            <img
              src="/mydata/honto_coupon_banner.png"
              alt="honto_coupon_banner"
            />
          </div>
        </UndraggableLink>
      </Container>
    </Container>
  </div>
);

export default MyDataTopPage;
