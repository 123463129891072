import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import EmailAuthenticationDto from '../dto/email-authentication-dto';

const createEmailAuthenticationRequestConfig = (
  emailAuthCode: number,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/email/auth',
  },
  method: 'post',
  parameters: { email_auth_code: emailAuthCode },
});

interface EmailAuthenticationRepository {
  auth: (emailAuthCode: number) => Promise<EmailAuthenticationDto>;
}

export class EmailAuthenticationRepositoryImpl
  implements EmailAuthenticationRepository
{
  auth = async (emailAuthCode: number): Promise<EmailAuthenticationDto> =>
    new ApiClient().connect(
      createEmailAuthenticationRequestConfig(emailAuthCode),
    );
}

export default EmailAuthenticationRepository;
