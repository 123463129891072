import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import EmailResettingsAuthenticationRepository, {
  EmailResettingsAuthenticationRepositoryImpl,
} from '../data/repositories/email-resettings-authentication-repository';

type ReturnType = {
  submitted: (emailAuthCode: number) => void;
};

const useEmailResettingsAuthentication = (
  saveResult: (result: Result<void, string>) => void,
  repository: EmailResettingsAuthenticationRepository = new EmailResettingsAuthenticationRepositoryImpl(),
): ReturnType => {
  const submitted = (emailAuthCode: number): void => {
    const _ = repository
      .auth(emailAuthCode)
      .then(() => {
        saveResult(new Success(undefined));
      })
      .catch((e) => {
        saveResult(
          new Failure(
            isErrorDTO(e)
              ? e.error.message
              : 'メールアドレスの認証に失敗しました',
          ),
        );
      });
  };

  return {
    submitted,
  };
};

export default useEmailResettingsAuthentication;
