import { Divider } from '@chakra-ui/react';
import Button from 'global/components/button/Button';
import Container from 'global/components/Container/Container';
import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import { useCallback, useEffect, useRef, VFC } from 'react';
import { useHistory } from 'react-router';
import TopContainer from 'global/components/TopContainer/TopContainer';
import ProgressBar from 'features/sign-up/components/ProgressBar/ProgressBar';
import UserInputItem from '../../../profile/user-input-item';
import {
  ProfileMultiplePageViewData,
  ProfileFormItemViewData,
} from '../../../profile/view-data/profile-view-data';
import styles from './ProfileConfirmPage.module.css';

type Props = {
  result: ProfileMultiplePageViewData;
  submitted: () => void;
};

const ProfileConfirmPage: VFC<Props> = ({ result, submitted }) => {
  const pageTopRef = useRef<HTMLDivElement>(null);
  const scrollToTop = useCallback(() => {
    pageTopRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'start',
    });
  }, []);

  // ページが描画されたら先頭までスクロール
  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  const valueStr = (
    formItem: ProfileFormItemViewData,
    inputItems: UserInputItem[],
  ): string | undefined => {
    const inputValue = inputItems.find(
      (inputItem) => inputItem.name === formItem.inputElement.name,
    )?.value;

    const resultValue = Array.isArray(inputValue)
      ? inputValue.join(', ')
      : inputValue;

    return resultValue;
  };

  const history = useHistory();

  const backButtonTapAction = () => {
    history.push('/sign_up/profile/basic');
  };

  const yearValue = result.userInputItems.find((item) => item.name === 'year');
  const monthValue = result.userInputItems.find(
    (item) => item.name === 'month',
  );
  const dayValue = result.userInputItems.find((item) => item.name === 'day');
  if (yearValue && monthValue && dayValue) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const birthday = `${yearValue.value}-${monthValue.value}-${dayValue.value}`;
    const birthdayValue = result.userInputItems.find(
      (item) => item.name === 'birthday',
    );
    if (birthdayValue) {
      birthdayValue.value = birthday;
    }
  }

  return (
    <>
      <TopContainer>
        <ProgressBar max={11} value={11} />
      </TopContainer>

      <div className={styles.confirmPageWrapper} ref={pageTopRef}>
        {result.profileViewData.map((page) =>
          page.sections.map((section) => (
            <Container marginTop="s" marginBottom="s">
              <div className={styles.sectionTitle}>{section.title}</div>
              {section.formItems.map((formItem) => (
                <>
                  <Container marginTop={10} marginBottom={6}>
                    <FormContainer>
                      <FormTitle text={formItem.title} />
                      <div
                        className={[
                          styles.inputItem,
                          valueStr(formItem, result.userInputItems) ||
                            styles.inputNoItem,
                        ].join(' ')}
                      >
                        {valueStr(formItem, result.userInputItems) || '未回答'}
                      </div>
                    </FormContainer>
                  </Container>
                </>
              ))}
            </Container>
          )),
        )}
        <Container marginTop={40} marginBottom={40}>
          <div className={styles.confirmDescription}>
            上記のプロフィールで登録しますか？
            <br />
            登録された情報は「マイデータ」画面から
            <br />
            いつでも修正することができます。
            <br />
            <br />
            「上記内容で登録する」を押すと、
            <br />
            新規登録が完了となります。
          </div>
        </Container>

        <Divider orientation="horizontal" />
        <Container marginTop={32} marginBottom={32}>
          <Button
            text="修正する"
            type="default"
            size="large"
            onClick={backButtonTapAction}
          />
        </Container>
        <Container marginTop={32} marginBottom={32}>
          <Button
            text="上記内容で登録する"
            type="primary"
            size="large"
            onClick={submitted}
          />
        </Container>
      </div>
    </>
  );
};

export default ProfileConfirmPage;
