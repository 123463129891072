import { useHistory } from 'react-router';
import AcountSettingsRootViewData, {
  AcountSettingsMenuType,
} from '../view-data/acount-settings-root-view-data';

type ReturnType = {
  viewData: AcountSettingsRootViewData;
  onMenuClick: (type: AcountSettingsMenuType) => void;
};

// NOTE: クライアントでのみ必要な導線onlyのデータなので直接書き込む
const createMenuListViewData = (): AcountSettingsRootViewData => ({
  categories: [
    {
      name: 'アカウント',
      menus: [
        {
          type: 'emaliResetting',
          name: 'メールアドレスの再設定',
        },
        {
          type: 'passwordResetting',
          name: 'パスワードの再設定',
        },
        {
          type: 'phoneNumberResetting',
          name: '電話番号の再設定',
        },
      ],
    },
    {
      name: '通知',
      menus: [
        {
          type: 'pushNotificationSetting',
          name: 'プッシュ通知設定',
        },
      ],
    },
  ],
});

const useAcountSettingsRoot = (): ReturnType => {
  const history = useHistory();

  const onMenuClick = (type: AcountSettingsMenuType): void => {
    switch (type) {
      case 'emaliResetting':
        history.push('/email_resettings');
        break;
      case 'passwordResetting':
        history.push('/password_resettings');
        break;
      case 'phoneNumberResetting':
        history.push('/phone_number_settings/reset');
        break;
      case 'pushNotificationSetting':
        history.push('/push_notification_settings');
        break;
      default:
        break;
    }
  };

  return {
    viewData: createMenuListViewData(),
    onMenuClick,
  };
};

export default useAcountSettingsRoot;
