import { VFC } from 'react';
import { useHistory } from 'react-router';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import InputCodePage from '../pages/InputCodePage';
import useInputCode from '../hooks/use-input-code';

type InputCodeLocationState = {
  transactionId: string;
  email: string;
};

const InputCodeContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, dialogProps, onClose } = useDialog();
  useNavigationBarItemsSetting('パスワードの再設定');
  const { submitted } = useInputCode((saveResult) => {
    if (saveResult.isSuccess()) {
      history.push('/forget_password/new_password', {
        transactionId: saveResult.value,
      });
    }
    if (saveResult.isFailure()) {
      requestShowing({
        title: 'エラー',
        description: saveResult.error,
        primaryButton: { text: 'OK' },
      });
    }
  });

  const inputCodeLocationState =
    useHistory<InputCodeLocationState | undefined>();

  // transactionId と emailがない場合不正な繊維なのでtopに戻る
  if (
    !inputCodeLocationState.location.state?.email ||
    !inputCodeLocationState.location.state?.transactionId
  ) {
    history.replace('/top');

    return <></>;
  }

  return (
    <>
      <Dialog
        title={dialogProps?.title}
        description={dialogProps?.description}
        primaryButton={dialogProps?.primaryButton ?? { text: '閉じる' }}
        isOpen={isOpen}
        onClose={onClose}
      />

      <InputCodePage
        transactionId={inputCodeLocationState.location.state?.transactionId}
        email={inputCodeLocationState.location.state?.email}
        submitted={submitted}
      />
    </>
  );
};

export default InputCodeContainer;
