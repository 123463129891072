import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import SignInDto from 'features/sign-in/email-password/data/dto/sign-in-dto';

const createSignInRequestConfig = (
  email: string,
  password: string,
  fincUserId: string,
  deviceId: string | null,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/sign_in',
  },
  method: 'post',
  parameters: {
    email,
    password,
    finc_user_id: fincUserId,
    device_id: deviceId,
  },
});

interface SignInRepository {
  signIn: (
    email: string,
    password: string,
    fincUserId: string,
    deviceId: string | null,
  ) => Promise<SignInDto>;
}

export class SignInRepositoryImpl implements SignInRepository {
  signIn = async (
    email: string,
    password: string,
    fincUserId: string,
    deviceId: string | null,
  ): Promise<SignInDto> =>
    new ApiClient().connect(
      createSignInRequestConfig(email, password, fincUserId, deviceId),
    );
}

export default SignInRepository;
