import { Flex } from '@chakra-ui/react';
import { VFC } from 'react';
import { CategoryScoreViewData } from '../view-data/top-scores-view-data';
import ScoreTrend from './ScoreTrend';
import styles from './TopCategoryLifeScores.module.css';

type Props = {
  categoryScores: CategoryScoreViewData[];
};

const TopCategoryLifeScores: VFC<Props> = ({ categoryScores }) => (
  <>
    <div className={styles.categoryScoresWrapper}>
      {categoryScores.map((categoryScore) => (
        <div className={styles.categoryScoreWrapper}>
          <Flex alignItems="center" justifyContent="space-between">
            <img
              className={styles.categoryScoreImage}
              src={`/questionnaire/questionnaire_category_${categoryScore.categoryId}.svg`}
              alt="category"
            />
            <div className={styles.categoryScoreValueWrapper}>
              <div>{categoryScore.categoryName}</div>
              <div className={styles.categoryScoreValue}>
                {typeof categoryScore.score === 'number'
                  ? categoryScore.score
                  : '---'}
              </div>
            </div>
            <div
              className={styles.categoryScoreRankWrapper}
              style={{ color: categoryScore.rank.colorCode }}
            >
              {categoryScore.rank.name}
              <div className={styles.categoryScoreTrendWrapper}>
                <ScoreTrend
                  differenceFromPrevious={categoryScore.differenceFromPrevious}
                />
              </div>
            </div>
          </Flex>
        </div>
      ))}
    </div>
  </>
);
export default TopCategoryLifeScores;
