import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import WithdrawalSettingsContainer from './containers/index';
import WithdrawalCompletedContainer from './containers/show';

const WithdrawalSettingsRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`}>
      <WithdrawalSettingsContainer />
    </Route>
    <Route path={`${match.path}/completed`}>
      <WithdrawalCompletedContainer />
    </Route>
  </Switch>
);

export default WithdrawalSettingsRouter;
