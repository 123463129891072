import { Divider } from '@chakra-ui/react';
import { VFC } from 'react';
import PushNotificationSettingsListCell from '../component/PushNotificationSettingsListCell';
import PushNotificationSettingsViewData from '../view-data/push-notification-settings-view-data';

type Props = {
  viewData: PushNotificationSettingsViewData;
  onClick: (code: string, accepted: boolean) => void;
};

const PushNotificationSettingsPage: VFC<Props> = ({ viewData, onClick }) => (
  <>
    {viewData.categories.map((e) => (
      <div key={e.code}>
        <PushNotificationSettingsListCell viewData={e} onClick={onClick} />
        <Divider orientation="horizontal" />
      </div>
    ))}
  </>
);

export default PushNotificationSettingsPage;
