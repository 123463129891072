import { useRef, VFC } from 'react';
import Container from 'global/components/Container/Container';
import Button from 'global/components/button/Button';
import styles from './NotifyEmailPage.module.css';

type Props = {
  submitted: (phoneNumber: string, birthday: string) => void;
  phoneNumber: string;
  birthday: string;
};

const NotifyEmailPage: VFC<Props> = ({ submitted, phoneNumber, birthday }) => {
  const processing = useRef(false);
  const onSubmit = () => {
    // TODO: 今後のことを考えると専用のコンポーネントにするのが望ましい
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;

    // 処理中フラグを上げる
    processing.current = true;

    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 3000);

    submitted(phoneNumber, birthday);
  };

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <p className={styles.topMessage}>登録済みのメールアドレスの確認</p>
      </Container>
      <Container marginLeft="s" marginRight="s">
        <p className={styles.message}>
          あなたの電話番号にご登録のメールアドレスを通知します
        </p>
      </Container>

      <Container marginTop="l" marginBottom="s" marginLeft="s" marginRight="s">
        <Button
          text="通知する"
          type="primary"
          size="large"
          onClick={onSubmit}
        />
      </Container>
    </>
  );
};

export default NotifyEmailPage;
