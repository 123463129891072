import { VFC } from 'react';
import Result from 'global/utilities/result';
import Container from 'global/components/Container/Container';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import {
  HELP_DETAIL_OF_LIFESTATS,
  OpenHelpPage,
} from 'global/utilities/open-help-page';
import { Flex } from '@chakra-ui/react';
import Loading from 'global/components/Loading/Loading';
import TopScoresViewData from '../view-data/top-scores-view-data';
import TopLifeScoreChart from './TopLifeScoreChart';
import TopLifeScoreComment from './TopLifeScoreComment';
import TopCategoryLifeScores from './TopCategoryLifeScores';
import styles from './TopLifeScore.module.css';

type Props = {
  scoresFetchResult: Result<TopScoresViewData, Error>;
};

const TopLifeScore: VFC<Props> = ({ scoresFetchResult }) => {
  const { isOpen, requestShowing, onClose } = useDialog();

  return (
    <>
      <Dialog
        title="「ライフスタッツ」とは"
        description={
          'ライフスタッツとは、セルフチェックの回答、FiNCのライフログ、プログラム達成などの結果をもとに算出した、あなたの「健康に関するスコア」です。\n\n食事/運動/睡眠/カラダ/メンタルの5項目それぞれのライフスタッツ（スコア）の合計が総合スタッツとなります。\n\n詳細についてはヘルプページよりご確認ください。'
        }
        primaryButton={{
          text: 'ヘルプを見る',
          onClick: () => OpenHelpPage(HELP_DETAIL_OF_LIFESTATS),
        }}
        isOpen={isOpen}
        onClose={onClose}
      />
      <div className={styles.sectionTitleWrapper}>
        <div className={styles.sectionTitle}>ライフスタッツ</div>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button
          type="button"
          className={styles.helpButton}
          onClick={() => requestShowing()}
        />
      </div>
      {scoresFetchResult.isSuccess() && (
        <div>
          <Container marginTop={16} marginBottom={10}>
            <Flex alignItems="center" justifyContent="space-between">
              <TopLifeScoreChart
                chartData={scoresFetchResult.value.totalScore}
              />
              <TopCategoryLifeScores
                categoryScores={scoresFetchResult.value.categoryScores}
              />
            </Flex>
          </Container>
          <Container marginTop={10} marginBottom={10}>
            <TopLifeScoreComment
              scoreComments={scoresFetchResult.value.scoreComments}
            />
          </Container>
        </div>
      )}
      {scoresFetchResult.isFailure() && (
        <p>{scoresFetchResult.error.message}</p>
      )}
      {scoresFetchResult.isInProgress() && <Loading />}
    </>
  );
};

export default TopLifeScore;
