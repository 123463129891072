import { VFC } from 'react';
import Loading from 'global/components/Loading/Loading';
import TermsOfUseAndPrivacyPolicyPage from 'global/pages/terms-of-use-and-privacy-policy/TermsOfUseAndPrivacyPolicyPage';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import useTermsOfUse from 'features/terms-and-privacy-policy/terms-of-use/hooks/use-terms-of-use';
import { TermsOfUseRepositoryImpl } from 'features/terms-and-privacy-policy/terms-of-use/data/repositories/terms-of-use-repository';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';

const TermsOfUseContainer: VFC = () => {
  useNavigationBarItemsSetting('利用規約');

  const { fetchResult } = useTermsOfUse(
    new TermsOfUseRepositoryImpl(),
    () => '',
  );

  if (fetchResult.isSuccess()) {
    return (
      <TermsOfUseAndPrivacyPolicyPage
        name="利用規約"
        content={fetchResult.value.content}
        submitted={undefined}
      />
    );
  }

  if (fetchResult.isFailure())
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;

  return <Loading />;
};
export default TermsOfUseContainer;
