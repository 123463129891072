import { VFC } from 'react';
import { useHistory } from 'react-router-dom';
import QuestionSection from 'global/components/QuestionSection/QuestionSection';
import Container from 'global/components/Container/Container';
import styles from './QuestionsList.module.css';
import {
  QuestionViewData,
  PathViewData,
} from '../view-data/questions-list-view-data';

type Props = { question: QuestionViewData };

const QuestionsList: VFC<Props> = ({ question }) => {
  const history = useHistory();

  const moveToQuestionaire = (path: PathViewData): void => {
    if (path.type === 'personal_info') {
      history.push('/mydata/personal/edit', { anchor: path.ref });
    } else if (path.type === 'questionnaire') {
      history.push(`/questionnaires/categories/${path.ref}`);
    }
  };

  return (
    <div>
      <div className={styles.questionTitle}>{question.name}</div>
      {question.questions.map((q) => (
        <Container marginTop={12} marginBottom={12}>
          <QuestionSection
            key={q.id}
            imgSrc={`/campaign/campaign_${q.iconName}.svg`}
            title={q.name}
            answeredQuestionsCount={q.answeredQuestionsCount}
            totalQuestionsCount={q.totalQuestionsCount}
            isAchieve={
              q.answeredQuestionsCount === q.totalQuestionsCount || q.isAchieve
            }
            buttonTappedAction={() => moveToQuestionaire(q.path)}
            buttonLabel={`全問回答で${q.point}pt`}
          />
        </Container>
      ))}
    </div>
  );
};
export default QuestionsList;
