import ApiClient from 'data/api-client/api-client';
import UserLifelogRecordsDTO from 'features/top/data/dto/user-lifelog-records-dto';
import RequestConfig, { URL } from 'data/request-configs/request-config';

const createSyncUserLifelogRecordsRequestConfig = (): RequestConfig => ({
  endpoint: { baseUrl: URL.FIT_STATS, path: '/users/me/lifelog_records/sync' },
  method: 'put',
  parameters: {},
});

class UserLifelogRecordsRepository {
  sync = async (): Promise<UserLifelogRecordsDTO> =>
    new ApiClient().connect(createSyncUserLifelogRecordsRequestConfig());
}

export default UserLifelogRecordsRepository;
