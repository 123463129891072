import { FC } from 'react';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';

export type Props = {
  iconSrc?: string;
};

const ErrorContainer: FC<Props> = ({ children, iconSrc }) => (
  <EmptyStateContainer
    iconSrc={iconSrc || '/global/empty_icon.svg'}
    title="データの取得に失敗しました"
  >
    {/* titleとchildrenでメッセージが重複する場合、titleだけ表示する */}
    {children !== 'データの取得に失敗しました' && children}
  </EmptyStateContainer>
);

export default ErrorContainer;
