import queryClient from 'global/query-client';
import Result, { Failure, Success } from 'global/utilities/result';
import UserInputItem from '../../../profile/user-input-item';
import { USER_PROFILE_BASIC_CACHE_KEY } from './constants';

type ReturnType = {
  fetchResult: Result<string, Error>;
};

const getProfileDescriptionResult = (
  userInputItems: UserInputItem[] | undefined,
): Result<string, Error> => {
  const lastName = userInputItems?.find(
    (item) => item.name === 'last_name_kanji',
  )?.value;

  let result: Result<string, Error>;

  if (lastName && typeof lastName === 'string') {
    result = new Success(lastName);
  } else {
    result = new Failure(new Error('データの取得に失敗しました'));
  }

  return result;
};

const useProfileDescription = (
  userInputItems: UserInputItem[] | undefined = queryClient.getQueryData(
    USER_PROFILE_BASIC_CACHE_KEY,
  ),
): ReturnType => {
  const fetchResult = getProfileDescriptionResult(userInputItems);

  return {
    fetchResult,
  };
};

export default useProfileDescription;
