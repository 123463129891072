import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import queryClient from 'global/query-client';
import OfferRepository, {
  OfferRepositoryImpl,
} from 'features/offer/data/repositories/offer-repository';
import { isErrorDTO } from 'data/dto/error-dto';
import Result from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import OfferTopViewData, {
  UserOfferTypeViewData,
  UserOfferViewData,
} from '../view-data/offer-top-view-data';
import OfferListDto, {
  OfferTypeSym,
  UserOfferDto,
} from '../data/dto/offer-list-dto';

const NUM_OF_ITEM_READS_PER_TIME = 4;

const OFFER_TOP_VIEW_QUERY_KEY = '/offer/top';

const cachedOfferTopVewData = (): OfferTopViewData | undefined =>
  queryClient.getQueryData([OFFER_TOP_VIEW_QUERY_KEY]);

type ReturnType = {
  fetchResult: Result<OfferTopViewData, Error>;
  isNeedAddLoadShownListMore: boolean;
  onAddLoadShownListItem: (cursor: number) => void;
  topShownOffers: UserOfferViewData[];
};

const convertOfferType = (
  type: OfferTypeSym,
): UserOfferTypeViewData | undefined => {
  switch (type) {
    case 'announcement_offer':
      return {
        kind: 'announcement',
        text: 'オファー',
      };
    case 'questionnaire_offer':
      return {
        kind: 'questionnaire',
        text: 'アンケート',
      };
    default:
      return undefined;
  }
};

const convertUserOfferViewData = (
  dto: UserOfferDto,
  offerType: UserOfferTypeViewData,
): UserOfferViewData => {
  const convertedScheduledDeliveryEnd = dto.scheduledDeliveryEndDatetime
    .setZone('Asia/Tokyo')
    .toFormat('yyyy/M/d HH:mm');

  return {
    ...dto,
    offerType,
    companyName: dto.companyName,
    iconImageUrl: dto.thumbnailImageAccessKey,
    message: dto.title,
    isOfferOpened: dto.isOfferOpened,
    rewardDescription: `+${dto.awardPoint} pt`,
    termDescription: `${convertedScheduledDeliveryEnd} まで`,
  };
};

export const convertDtoToOfferTopViewData = (
  dto: OfferListDto,
): OfferTopViewData => {
  const result: UserOfferViewData[] = [];

  dto.offers.forEach((e) => {
    const offerType = convertOfferType(e.offerTypeSym);
    if (offerType) {
      result.push(convertUserOfferViewData(e, offerType));
    }
  });

  return {
    offers: result,
  };
};

const useOfferTop = (
  repository: OfferRepository = new OfferRepositoryImpl(),
): ReturnType => {
  useEffect(() => {
    const clearQuery = () => {
      queryClient.removeQueries({
        queryKey: OFFER_TOP_VIEW_QUERY_KEY,
      });
    };

    return clearQuery;
  }, []);

  const [topShownOffers, setTopShownOffers] = useState<UserOfferViewData[]>([]);
  const [isNeedAddLoadShownListMore, setIsNeedAddLoadShownListMore] =
    useState(true);

  const queryResult = useQuery<OfferTopViewData, Error>(
    [OFFER_TOP_VIEW_QUERY_KEY],
    async () => {
      const dto = await repository.fetchList().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      const viewData = convertDtoToOfferTopViewData(dto);

      const sliceIndex = Math.min(
        viewData.offers.length,
        NUM_OF_ITEM_READS_PER_TIME,
      );
      setTopShownOffers(viewData.offers.slice(0, sliceIndex));

      return viewData;
    },
  );

  const fetchResult = createResult(queryResult);

  const onAddLoadShownListItem = (): void => {
    const cachedTop = cachedOfferTopVewData();
    if (!cachedTop) {
      return;
    }

    const nextReadLastIndex =
      topShownOffers.length + NUM_OF_ITEM_READS_PER_TIME;
    if (cachedTop.offers.length < nextReadLastIndex) {
      setIsNeedAddLoadShownListMore(false);
      const remainList = cachedTop.offers.slice(
        topShownOffers.length,
        cachedTop.offers.length,
      );
      if (remainList.length > 0) {
        setTopShownOffers([...topShownOffers, ...remainList]);
      }

      return;
    }

    setTopShownOffers([
      ...topShownOffers,
      ...cachedTop.offers.slice(
        topShownOffers.length,
        topShownOffers.length + NUM_OF_ITEM_READS_PER_TIME,
      ),
    ]);
  };

  return {
    fetchResult,
    isNeedAddLoadShownListMore,
    onAddLoadShownListItem: (_) => onAddLoadShownListItem(),
    topShownOffers,
  };
};

export default useOfferTop;
