import { VFC } from 'react';
import EmptyStateContainer from 'global/components/EmptyStateContainer/EmptyStateContainer';
import {
  NoticeListViewData,
  NoticeType,
} from '../view-data/notice-top-view-data';
import NoticeList from '../component/NoticeList';
import styles from './NoticeTopPage.module.css';

type Props = {
  notices: NoticeListViewData[];
  isNeedLoadMore: boolean;
  onLoadItem: (cursor: number) => void;
  onTapNotice: (id: string, type: NoticeType, isRead: boolean) => void;
};

const NoticeTopPage: VFC<Props> = ({
  notices,
  isNeedLoadMore,
  onLoadItem,
  onTapNotice,
}) => (
  <>
    {notices.length === 0 && (
      <EmptyStateContainer title="配信中のお知らせはありません">
        プログラムやオファーの配信、新しい企業の追加があるときなどにこちらにお知らせが表示されます。
      </EmptyStateContainer>
    )}
    <div className={styles.container}>
      <NoticeList
        notices={notices}
        isNeedLoadMore={isNeedLoadMore}
        onLoadItem={onLoadItem}
        onTapNotice={onTapNotice}
      />
    </div>
  </>
);

export default NoticeTopPage;
