import queryClient from 'global/query-client';
import Result, { Failure, InProgress, Success } from 'global/utilities/result';
import UserInputItem from '../user-input-item';
import ProfileViewData, {
  ProfilePageKey,
  ProfileSinglePageViewData,
} from '../view-data/profile-view-data';

const getUserInputItems = (
  key: ProfilePageKey,
): UserInputItem[] | undefined => {
  switch (key) {
    case 'profile_basic_info':
      return queryClient.getQueryData('/user_profile_basic');
    case 'profile_detail':
      return queryClient.getQueryData('/user_profile_detail');
    default:
      return undefined;
  }
};

const getProfileViewData = (
  fetchResult: Result<ProfileViewData, Error>,
  key: ProfilePageKey,
): Result<ProfileSinglePageViewData, Error> => {
  let result: Result<ProfileSinglePageViewData, Error>;

  if (fetchResult.isSuccess()) {
    const profilePageViewData = fetchResult.value.pages.find(
      (page) => page.key === key,
    );

    const userInputItems = getUserInputItems(key);

    if (profilePageViewData) {
      const viewData: ProfileSinglePageViewData = {
        profilePageViewData,
        userInputItems,
      };

      result = new Success(viewData);
    } else {
      result = new Failure(new Error('データの取得に失敗しました'));
    }
  } else if (fetchResult.isFailure()) {
    result = new Failure(fetchResult.error);
  } else {
    result = new InProgress();
  }

  return result;
};

export default getProfileViewData;
