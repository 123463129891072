import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import TermsOfUseDto from '../dto/terms-of-use-dto';

const createTermsOfUseRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/terms_of_use',
  },
  method: 'get',
  parameters: {},
});

interface TermsOfUseRepository {
  fetch: () => Promise<TermsOfUseDto>;
}

export class TermsOfUseRepositoryImpl implements TermsOfUseRepository {
  fetch = async (): Promise<TermsOfUseDto> =>
    new ApiClient().connect(createTermsOfUseRequestConfig());
}

export default TermsOfUseRepository;
