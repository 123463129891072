import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import ClientCompanyDto from '../dto/client-company-dto';

const createClientCompanyRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/client_companies',
  },
  method: 'get',
  parameters: {},
});

interface ClientCompanyRepository {
  fetch: () => Promise<ClientCompanyDto>;
}

export class ClientCompanyRepositoryImpl implements ClientCompanyRepository {
  fetch = async (): Promise<ClientCompanyDto> =>
    new ApiClient().connect(createClientCompanyRequestConfig());
}

export default ClientCompanyRepositoryImpl;
