import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import QuestionnairesTopContainer from 'features/questionnaire/containers/index';
import QuestionnaireDetailContainer from 'features/questionnaire/containers/show';

const QuestionnaireRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`}>
      <QuestionnairesTopContainer />
    </Route>
    <Route path={`${match.path}/categories/:categoryId`}>
      <QuestionnaireDetailContainer />
    </Route>
  </Switch>
);

export default QuestionnaireRouter;
