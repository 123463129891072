import { VFC } from 'react';
import Loading from 'global/components/Loading/Loading';
import { useParams } from 'react-router-dom';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import { OfferRepositoryImpl } from '../data/repositories/offer-repository';
import AnnouncementOfferDetailPage from '../pages/AnnouncementOfferDetailPage';
import useAnnouncementOfferDetail from '../hooks/use-announcement-offer-detail';

const AnnouncementOfferDetailContainer: VFC = () => {
  const { isOpen, requestShowing, onClose } = useDialog();
  const { offerId } = useParams<{ offerId: string }>();
  useNavigationBarItemsSetting('オファー内容');

  const {
    fetchResult,
    linkButtonTapped,
    linkButtonTappedAction,
    offerOpenEventFired,
    offerOpenEventFiredAction,
  } = useAnnouncementOfferDetail(
    offerId,
    new OfferRepositoryImpl(),
    (offerOpenEventFiredSaveResult) => {
      if (
        offerOpenEventFiredSaveResult.isSuccess() ||
        offerOpenEventFiredSaveResult.isFailure()
      ) {
        requestShowing();
      }
    },
    (linkButtonTappedSaveResult) => {
      if (linkButtonTappedSaveResult.isFailure()) {
        requestShowing();
      }
    },
  );

  if (fetchResult.isSuccess()) {
    return (
      <>
        <AnnouncementOfferDetailPage
          offerDetailViewData={fetchResult.value}
          linkButtonTapped={linkButtonTapped}
          offerOpenEventFired={offerOpenEventFired}
        />
        {linkButtonTappedAction && (
          <Dialog
            title={linkButtonTappedAction?.title}
            description={linkButtonTappedAction?.description}
            primaryButton={{ text: 'OK' }}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
        {offerOpenEventFiredAction && (
          <Dialog
            title={offerOpenEventFiredAction?.title}
            description={offerOpenEventFiredAction?.description}
            imageSrc={offerOpenEventFiredAction?.imageSrc}
            primaryButton={
              offerOpenEventFiredAction?.primaryButton
                ? offerOpenEventFiredAction?.primaryButton
                : { text: 'OK' }
            }
            isOpen={isOpen}
            onClose={offerOpenEventFiredAction?.onClose || onClose}
          />
        )}
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default AnnouncementOfferDetailContainer;
