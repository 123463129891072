import { QuestionAnswer } from 'features/questionnaire/data/repositories/questionnaire-repository';
import QuestionnaireDetailViewData from 'features/questionnaire/view-data/questionnaire-detail-view-data';

const buildQuestionAnswers = (
  viewData: QuestionnaireDetailViewData,
): QuestionAnswer[] =>
  viewData.pages.flatMap((page) =>
    page.section.questions.map((question) => ({
      questionId: question.id,
      answerId: question.userAnsweredOptionId,
    })),
  );

export default buildQuestionAnswers;
