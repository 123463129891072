import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import TopScoresDto from '../dto/top-scores-dto';

const createTopScoresRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/top/scores/',
  },
  method: 'get',
  parameters: {},
});

interface TopScoresRepository {
  fetch: () => Promise<TopScoresDto>;
}

export class TopScoresRepositoryImpl implements TopScoresRepository {
  fetch = async (): Promise<TopScoresDto> =>
    new ApiClient().connect(createTopScoresRequestConfig());
}

export default TopScoresRepository;
