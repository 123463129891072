import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import PhoneNumberRegistrationDto from '../dto/phone-number-registration-dto';

const createPhoneNumberRegistrationRequestConfig = (
  phoneNumber: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/phone_number',
  },
  method: 'put',
  parameters: { phone_number: phoneNumber },
});

interface PhoneNumberRegistrationRepository {
  save: (phoneNumber: string) => Promise<PhoneNumberRegistrationDto>;
}

export class PhoneNumberRegistrationRepositoryImpl
  implements PhoneNumberRegistrationRepository
{
  save = async (phoneNumber: string): Promise<PhoneNumberRegistrationDto> =>
    new ApiClient().connect(
      createPhoneNumberRegistrationRequestConfig(phoneNumber),
    );
}

export default PhoneNumberRegistrationRepository;
