import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import AcountSettingsRootContainer from 'features/settings/root/acount-settings-root/containers/index';

const AcountSettingsRootRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`}>
      <AcountSettingsRootContainer />
    </Route>
  </Switch>
);

export default AcountSettingsRootRouter;
