import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';

const sendAuthCodeRequestConfig = (
  code: string,
  transactionId: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/password/reset/verify_code',
  },
  method: 'post',
  parameters: {
    email_auth_code: code,
    transaction_id: transactionId,
  },
});

interface InputCodeRepository {
  send: (email: string, transactionId: string) => Promise<void>;
}

export class InputCodeRepositoryImpl implements InputCodeRepository {
  send = async (email: string, transactionId: string): Promise<void> =>
    new ApiClient().connect(sendAuthCodeRequestConfig(email, transactionId));
}

export default InputCodeRepository;
