import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import CampaignContainer from 'features/campaign/containers/index';

const CampaignRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`}>
      <CampaignContainer />
    </Route>
  </Switch>
);

export default CampaignRouter;
