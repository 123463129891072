import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import PrivacyPolicyDto from '../dto/privacy-policy-dto';

const createPrivacyPolicyRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/privacy_policy',
  },
  method: 'get',
  parameters: {},
});

interface PrivacyPolicyRepository {
  fetch: () => Promise<PrivacyPolicyDto>;
}

export class PrivacyPolicyRepositoryImpl implements PrivacyPolicyRepository {
  fetch = async (): Promise<PrivacyPolicyDto> =>
    new ApiClient().connect(createPrivacyPolicyRequestConfig());
}

export default PrivacyPolicyRepository;
