import { useQuery } from 'react-query';
import queryClient from 'global/query-client';
import { isErrorDTO } from 'data/dto/error-dto';
import Result from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import PushNotificationSettingsRepository, {
  PushNotificationSettingsRepositoryImpl,
} from '../data/repositories/push-notification-settings-repository';
import PushNotificationSettingsViewData from '../view-data/push-notification-settings-view-data';
import PushNotificationSettingsDto, {
  PushNotificationChangeSettingsDto,
} from '../data/dto/push-notification-settings-dto';

const PUSH_NOTIFICATION_SETTINGS_QUERY_KEY = '/push-notification-settings';

const cachedViewData = (): PushNotificationSettingsViewData | undefined =>
  queryClient.getQueryData([PUSH_NOTIFICATION_SETTINGS_QUERY_KEY]);

type ReturnType = {
  fetchResult: Result<PushNotificationSettingsViewData, Error>;
  onClick: (code: string, accepted: boolean) => void;
};

const convertDtoToViewData = (
  dto: PushNotificationSettingsDto,
): PushNotificationSettingsViewData => dto;

const usePushNotificationSettings = (
  onError: (errorMessage: string) => void,
  repository: PushNotificationSettingsRepository = new PushNotificationSettingsRepositoryImpl(),
): ReturnType => {
  const queryResult = useQuery<PushNotificationSettingsViewData, Error>(
    [PUSH_NOTIFICATION_SETTINGS_QUERY_KEY],
    async () => {
      const dto = await repository.fetch().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertDtoToViewData(dto);
    },
  );

  const fetchResult = createResult(queryResult);

  const onClick = (code: string, accepted: boolean): void => {
    const updateCache = (data: PushNotificationChangeSettingsDto): void => {
      const cached = cachedViewData();
      if (cached !== undefined) {
        queryClient.setQueryData([PUSH_NOTIFICATION_SETTINGS_QUERY_KEY], {
          categories: cached.categories.map((e) =>
            e.code === data.category.code ? data.category : { ...e },
          ),
        });
      }
    };

    const _ = repository
      .changeSettings(code, accepted)
      .then((res) => updateCache(res))
      .catch(() => onError('設定の変更に失敗しました。'));
  };

  return {
    fetchResult,
    onClick,
  };
};

export default usePushNotificationSettings;
