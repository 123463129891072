import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TutorialLocationState } from 'features/tutorial/hooks/use-tutorial-navigation';
import GlobalNavigationItem, {
  GlobalNavigationItemType,
} from './global-navigation-item';

type ReturnType = {
  navigationItems: GlobalNavigationItem[];
  needsGlobalNavigation: boolean;
  selectedNavigationItemType?: GlobalNavigationItemType;
  navigationItemClicked: (item: GlobalNavigationItem) => void;
  isTapEnabled: boolean;
};

// 以下の画面ではグロナビを非表示にする
// - サービスページ(/)
// - 新規登録関連(/sign_up/*)
// - ログイン関連(/sign_in/*)
// - 新規登録フロー以外の利用規約、プラポリ同意関連(/terms_and_privacy_policy/*)
// - メアド忘れ(/forget_email/*)
// - パスワード忘れ(/forget_password/*)
// - 退会完了(/withdrawal_settings/completed/*)
const shouldHideGlobalNavigation = (pathname: string): boolean =>
  pathname === '/' ||
  /^\/sign_up(\/.*)*/.test(pathname) ||
  /^\/sign_in(\/.*)*/.test(pathname) ||
  /^\/terms_and_privacy_policy(\/.*)*/.test(pathname) ||
  /^\/forget_email(\/.*)*/.test(pathname) ||
  /^\/forget_password(\/.*)*/.test(pathname) ||
  /^\/withdrawal_settings\/completed(\/.*)*/.test(pathname);

const navigationItems: GlobalNavigationItem[] = [
  {
    type: 'home',
    title: 'ホーム',
    iconName: 'icon-home.svg',
    path: '/top',
  },
  {
    type: 'offer',
    title: 'オファー',
    iconName: 'icon_offer.svg',
    path: '/offers',
  },
  {
    type: 'program',
    title: 'プログラム',
    iconName: 'icon-program.svg',
    path: '/program',
  },
  {
    type: 'self_check',
    title: 'セルフ\nチェック',
    iconName: 'icon-self-check.svg',
    path: '/questionnaires',
  },
  {
    type: 'mydata',
    title: 'マイデータ',
    iconName: 'icon-mydata.svg',
    path: '/mydata',
  },
];

const useGlobalNavigation = (): ReturnType => {
  const history = useHistory();
  const [selectedNavigationItemType, setSelectedNavigationItemType] =
    useState<GlobalNavigationItemType>();

  const [needsGlobalNavigation, setNeedsGlobalNavigation] = useState(false);
  const location = useLocation<TutorialLocationState | undefined>();

  useEffect(() => {
    setNeedsGlobalNavigation(!shouldHideGlobalNavigation(location.pathname));

    const selectedItem = navigationItems.find(
      (item) => item.path === location.pathname,
    );
    setSelectedNavigationItemType(selectedItem?.type);
  }, [location.pathname]);

  const navigationItemClicked = (item: GlobalNavigationItem) => {
    history.push(item.path);
  };

  const isTapEnabled = location.state?.isTutorial !== true;

  return {
    navigationItems,
    needsGlobalNavigation,
    selectedNavigationItemType,
    navigationItemClicked,
    isTapEnabled,
  };
};

export default useGlobalNavigation;
