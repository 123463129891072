import { Divider } from '@chakra-ui/react';
import PasswordAttention from 'global/components/PasswordAttention/PasswordAttention';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import Button from 'global/components/button/Button';
import Container from 'global/components/Container/Container';
import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import InputPassword from 'global/components/InputPassword/InputPassword';
import { VFC } from 'react';
import { useForm } from 'react-hook-form';
import styles from './PasswordPage.module.css';

type Props = {
  submitted: (password: string) => void;
};

type FormData = {
  password: string;
  passwordConfirmation: string;
};

const UPPER_CASE_PATTERN = 'A-Z';
const LOWER_CASE_PATTERN = 'a-z';
const NUMBER_PATTERN = '0-9';
const SYMBOL_PATTERN = '!#$%&()^\\\\@?_'; // DNPから指定の内容（DNP配信別サービスの「ライフラ」の仕様に準拠している)
const PASSWORD_PATTERN = `^[${UPPER_CASE_PATTERN}${LOWER_CASE_PATTERN}${NUMBER_PATTERN}${SYMBOL_PATTERN}]+$`;

const NOT_MATCH_CONFIRMATION_MSG = 'パスワードが一致していません。';
const INVALID_PATTERN_MSG = 'パスワード作成の条件を満たしていません。';
const REQUIRED_MSG = 'パスワードは入力必須の項目です。';
const MAX_LENGTH_MSG = '64文字以下で入力してください。';
const MIN_LENGTH_MSG = '12文字以上で入力してください。';

const PasswordPage: VFC<Props> = ({ submitted }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    getValues,
    setError,
    clearErrors,
  } = useForm<FormData>({ mode: 'onBlur' });

  const onSubmit = (data: FormData) => {
    submitted(data.password);
  };

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <div className={styles.body}>
          <div className={styles.text}>
            ここで入力したパスワードは
            <br />
            ログイン時に使用されます。
          </div>

          <Container marginTop="m">
            <FormContainer>
              {[
                <FormTitle size="large" text="パスワード" />,
                <InputPassword
                  name="password"
                  size="large"
                  register={register('password', {
                    required: REQUIRED_MSG,
                    minLength: {
                      value: 12,
                      message: MIN_LENGTH_MSG,
                    },
                    maxLength: {
                      value: 64,
                      message: MAX_LENGTH_MSG,
                    },
                    pattern: {
                      value: new RegExp(PASSWORD_PATTERN),
                      message: INVALID_PATTERN_MSG,
                    },
                    validate: (value) => {
                      // 英大文字、英小文字、数字、記号の４種類から、２種類以上を使う必要がある
                      const numberOfKind = [
                        new RegExp(`[${UPPER_CASE_PATTERN}]`),
                        new RegExp(`[${LOWER_CASE_PATTERN}]`),
                        new RegExp(`[${NUMBER_PATTERN}]`),
                        new RegExp(`[${SYMBOL_PATTERN}]`),
                      ].filter((regexp) => regexp.test(value)).length;
                      if (numberOfKind < 2) {
                        return INVALID_PATTERN_MSG;
                      }

                      // onBlurでバリデーションを実施しているため、
                      // passwordのほうを変更した結果一致した場合にpasswordConfirmationのエラーが消えない
                      // 同様にpasswordを変更した結果不一致になった場合にエラーが表示されなかった
                      // 一致しない場合にはpasswordConfirmationのエラーとしてsetErrorをして、一致する場合にはエラーを消すようにした
                      if (
                        value !== getValues('passwordConfirmation') &&
                        getValues('passwordConfirmation') !== ''
                      ) {
                        setError('passwordConfirmation', {
                          type: 'manual',
                          message: NOT_MATCH_CONFIRMATION_MSG,
                        });

                        return true;
                      }
                      clearErrors('passwordConfirmation');

                      return true;
                    },
                  })}
                />,
              ]}
            </FormContainer>
            <p className={styles.errorMessage}>{errors?.password?.message}</p>
          </Container>

          <Container marginTop="m">
            <FormContainer>
              {[
                <FormTitle size="large" text="パスワードの再確認" />,
                <InputPassword
                  name="passwordConfirmation"
                  size="large"
                  register={register('passwordConfirmation', {
                    validate: (value) =>
                      value === getValues('password') ||
                      'パスワードが一致していません。',
                  })}
                />,
              ]}
            </FormContainer>
            <p className={styles.errorMessage}>
              {errors?.passwordConfirmation?.message}
            </p>
          </Container>

          <Container marginTop="m">
            <PasswordAttention />
          </Container>
        </div>
      </Container>
      <div className={styles.bottomContainer}>
        <BottomContainer>
          <Divider orientation="horizontal" />
          <Container
            marginTop="s"
            marginBottom="s"
            marginLeft="s"
            marginRight="s"
          >
            <Button
              text="アカウント登録して次へ"
              type="primary"
              size="large"
              disabled={!isDirty || !isValid}
              onClick={handleSubmit(onSubmit)}
            />
          </Container>
        </BottomContainer>
      </div>
    </>
  );
};

export default PasswordPage;
