import { VFC } from 'react';
import { useHistory } from 'react-router';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import NotifyEmailPage from '../pages/NotifyEmailPage';
import useNotifyEmail from '../hooks/use-notify-email';

const NotifyEmailContainer: VFC = () => {
  const history = useHistory();
  useNavigationBarItemsSetting('メールアドレスの確認');
  const { isOpen, requestShowing, dialogProps, onClose } = useDialog();
  const { phoneNumber } =
    (history.location.state as {
      phoneNumber: string;
    }) || '';
  const { birthday } =
    (history.location.state as {
      birthday: string;
    }) || '';
  const { submitted } = useNotifyEmail((saveResult) => {
    if (saveResult.isSuccess()) {
      history.push('/forget_email/notify_email_complete');
    }
    if (saveResult.isFailure()) {
      requestShowing({
        title: 'エラー',
        description: saveResult.error,
        primaryButton: { text: 'OK' },
      });
    }
  });

  // 直接このページには飛べないようにする
  // 必ず、誕生日と生年月日の確認を終えてから
  if (!birthday || !phoneNumber) {
    history.push('/top');
  }

  return (
    <>
      <Dialog
        title={dialogProps?.title}
        description={dialogProps?.description}
        primaryButton={dialogProps?.primaryButton ?? { text: '閉じる' }}
        isOpen={isOpen}
        onClose={onClose}
      />

      <NotifyEmailPage
        submitted={submitted}
        phoneNumber={phoneNumber}
        birthday={birthday}
      />
    </>
  );
};

export default NotifyEmailContainer;
