import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import TopDailyScoresDto from '../dto/top-daily-scores-dto';

const createTopDailyScoresRequestConfig = (
  startDate: string,
  endDate: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/top/daily_scores/',
  },
  method: 'get',
  parameters: { started_date: startDate, ended_date: endDate },
});

interface TopDailyScoresRepository {
  fetch: (startDate: string, endDate: string) => Promise<TopDailyScoresDto>;
}

export class TopDailyScoresRepositoryImpl implements TopDailyScoresRepository {
  fetch = async (
    startDate: string,
    endDate: string,
  ): Promise<TopDailyScoresDto> =>
    new ApiClient().connect(
      createTopDailyScoresRequestConfig(startDate, endDate),
    );
}

export default TopDailyScoresRepository;
