import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import IntegrationCodeDTO from '../dto/integration-code-dto';

const createRequestConfig = (userData: IntegrationCodeSeed): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/integration_code/encrypt',
  },
  method: 'post',
  parameters: { data: userData },
});

export type IntegrationCodeSeed = {
  pid?: number;
  oid?: string;
  on?: string;
  dt: number;
};

interface IntegrationCodeRepository {
  generateIntegrationCode: (
    data: IntegrationCodeSeed,
  ) => Promise<IntegrationCodeDTO>;
}

export class IntegrationCodeRepositoryImpl
  implements IntegrationCodeRepository
{
  generateIntegrationCode = async (
    data: IntegrationCodeSeed,
  ): Promise<IntegrationCodeDTO> =>
    new ApiClient().connect(createRequestConfig(data));
}

export default IntegrationCodeRepository;
