import { useEffect, useState, VFC } from 'react';
import ServiceDescriptionPage from 'features/service-description/pages/ServiceDescriptionPage';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import requestWebCommand, {
  LoadFitStatsServicePage,
} from 'global/utilities/web-command';

const ServiceDescriptionContainer: VFC = () => {
  const [index, setIndex] = useState(0);
  const [isServicePageDisplayable, setIsServicePageDisplayable] =
    useState(false);
  useNavigationBarItemsSetting(
    { type: 'logo' },
    {
      isCloseButtonVisible: true,
      isMenuButtonVisible: false,
      isNotificationButtonVisible: false,
      isBackButtonVisible: true,
    },
  );

  useEffect(() => {
    // localStorageからfincUserIdが取得できない場合、その後の登録、ログイン処理を進めることができない.
    // そのため、partners-api経由でFitStatsのサービスページを読み込み直す.
    const fincUserId = localStorage.getItem('fincUserId');
    if (fincUserId == null) {
      requestWebCommand(new LoadFitStatsServicePage());
    }

    setIsServicePageDisplayable(fincUserId != null);
  }, []);

  if (isServicePageDisplayable) {
    return <ServiceDescriptionPage index={index} changeIndex={setIndex} />;
  }

  return <></>;
};
export default ServiceDescriptionContainer;
