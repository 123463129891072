import { VFC } from 'react';
import Loading from 'global/components/Loading/Loading';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import ScrollToTopWhenMounted from 'global/components/ScrollToTopWhenMounted/ScrollToTopWhenMounted';
import QuestionnaireTopPage from '../pages/QuestionnaireTopPage';
import useQuestionnaireTop from '../hooks/use-questionnaire-top';

const QuestionnairesTopContainer: VFC = () => {
  const { fetchResult } = useQuestionnaireTop();
  useNavigationBarItemsSetting('セルフチェック');

  if (fetchResult.isSuccess()) {
    return (
      <ScrollToTopWhenMounted>
        <QuestionnaireTopPage categories={fetchResult.value.categories} />
      </ScrollToTopWhenMounted>
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default QuestionnairesTopContainer;
