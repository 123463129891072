import UserInputItem, {
  UserInputPostItem,
  UserInputRawData,
} from 'features/profile/user-input-item';
import convertRecordToUserInputItems from 'features/profile/utils/convert-record-to-user-input-items';

const convertUserInputItemForRequest = (
  userInputItem: UserInputItem,
): UserInputPostItem => {
  if (Array.isArray(userInputItem.value)) {
    return {
      name: userInputItem.name,
      value: userInputItem.value.filter((v) => v.length !== 0),
    };
  }
  if (userInputItem.value == null || userInputItem.value.length === 0) {
    return {
      name: userInputItem.name,
      value: null,
    };
  }

  return userInputItem;
};

const convertUserInputRawDataForRequest = (
  userInput: UserInputRawData,
): UserInputPostItem[] => {
  const userInputItems = convertRecordToUserInputItems(userInput);

  const result: UserInputPostItem[] = [];
  userInputItems.map((item) =>
    result.push(convertUserInputItemForRequest(item)),
  );

  return result;
};

export default convertUserInputRawDataForRequest;
