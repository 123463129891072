import { VFC } from 'react';
import { useHistory } from 'react-router';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import useEmailResettingsAuthentication from '../hooks/use-email-resettings-authentication';
import EmailResettingsAuthenticationPage from '../pages/EmailResettingsAuthenticatonPage';

const EmailResettingsAuthenticationContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, dialogProps, onClose } = useDialog();
  useNavigationBarItemsSetting('メールアドレスの再設定');

  const { submitted } = useEmailResettingsAuthentication((saveResult) => {
    if (saveResult.isSuccess()) {
      requestShowing({
        description: 'メールアドレスの変更が完了しました。',
        primaryButton: { text: 'OK', onClick: () => history.push('/settings') },
      });
    }
    if (saveResult.isFailure()) {
      requestShowing({
        title: 'エラー',
        description: saveResult.error,
        primaryButton: { text: 'OK' },
      });
    }
  });

  return (
    <>
      <Dialog
        title={dialogProps?.title}
        description={dialogProps?.description}
        primaryButton={dialogProps?.primaryButton ?? { text: 'OK' }}
        isOpen={isOpen}
        onClose={onClose}
      />

      {/* これ以上style長くなりそうならcssファイルの切り出し検討する */}
      <div style={{ paddingTop: 60, paddingBottom: 150 }}>
        <EmailResettingsAuthenticationPage
          submitted={submitted}
          email={(history.location.state as { email: string }).email}
        />
      </div>
    </>
  );
};

export default EmailResettingsAuthenticationContainer;
