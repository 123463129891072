import React, { useRef, VFC } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import useAuthentication from 'features/authentication/hooks/use-authentication';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import Loading from 'global/components/Loading/Loading';
import useUserSwitchCheck from '../hooks/use-user-switch-check';

type DialogInfo = {
  description: string;
  primaryButtonAction: () => void;
};

const Authentication: VFC = () => {
  const history = useHistory();
  const { onClose } = useDialog();
  const dialogInfoRef = useRef<DialogInfo | undefined>();
  const primaryButtonActionRef = useRef<() => void>(() => onClose());
  const { search } = useLocation();
  const qs = queryString.parse(search);
  const authorizationCode = qs.code as string;
  const { redirectToSignInIfUserSwitched } = useUserSwitchCheck();
  const result = useAuthentication(
    authorizationCode,
    redirectToSignInIfUserSwitched,
  );

  if (result.isSuccess()) {
    return <Redirect to="/sign_up/email/registration" />;
  }

  if (result.isFailure()) {
    const description = '新規登録を最初の画面からやり直してください。';
    primaryButtonActionRef.current = () => {
      onClose();
      history.push('/');
    };

    dialogInfoRef.current = {
      description,
      primaryButtonAction: primaryButtonActionRef.current,
    };

    return (
      <Dialog
        title="エラー"
        description={dialogInfoRef.current.description}
        primaryButton={{
          text: 'OK',
          onClick: dialogInfoRef.current.primaryButtonAction,
        }}
        isOpen
        onClose={() => undefined}
        showsCloseButton={false}
      />
    );
  }

  return <Loading />;
};

export default Authentication;
