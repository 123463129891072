import { VFC } from 'react';
import Badge from '../../../global/components/Badge/Badge';
import {
  NoticeListViewData,
  NoticeType,
} from '../view-data/notice-top-view-data';
import styles from './NoticeListCell.module.css';

type Props = {
  viewData: NoticeListViewData;
  onTapNotice: (id: string, type: NoticeType, isRead: boolean) => void;
};

const NoticeTopListCell: VFC<Props> = ({ viewData, onTapNotice }) => (
  <button
    type="button"
    className={styles.bodyButton}
    onClick={() => onTapNotice(viewData.id, viewData.type, viewData.isRead)}
  >
    {!viewData.isRead && (
      <div className={styles.unreadBadge}>
        <Badge type={{ kind: 'unread' }} />
      </div>
    )}
    <div className={styles.title}>{viewData.title}</div>
    <div className={styles.summary}>{viewData.summary}</div>
    <div className={styles.summary}>{viewData.registeredAt}</div>
  </button>
);

export default NoticeTopListCell;
