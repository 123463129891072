type ErrorDTO = {
  error: ErrorInfoDTO;
};

type ErrorInfoDTO = {
  message: string;
  status:
    | 'ABORTED'
    | 'ALREADY_EXISTS'
    | 'FAILED_PRECONDITION'
    | 'INVALID_ARGUMENT'
    | 'INTERNAL'
    | 'NOT_FOUND'
    | 'PERMISSION_DENIED'
    | 'UNAUTHENTICATED'
    | 'UNKNOWN'
    | 'UNPROCESSABLE_ENTITY';
};

const isErrorInfoDTO = (data: unknown): data is ErrorInfoDTO => {
  if (data == null) {
    return false;
  }
  const record = data as Record<string, unknown>;

  return (
    typeof record.message === 'string' && typeof record.status === 'string'
  );
};

export const isErrorDTO = (data: unknown): data is ErrorDTO => {
  if (data == null) {
    return false;
  }
  const record = data as Record<string, unknown>;

  return isErrorInfoDTO(record.error);
};

export default ErrorDTO;
