import { VFC } from 'react';
import EmptyStateContainer from 'global/components/EmptyStateContainer/EmptyStateContainer';

const MaintainancePage: VFC = () => (
  <EmptyStateContainer
    iconSrc="/global/empty_icon.svg"
    title="ただいまメンテナンス中です"
  >
    大変ご迷惑をおかけしますが、作業終了までお待ち下さい。
  </EmptyStateContainer>
);

export default MaintainancePage;
