/* eslint-disable react/jsx-props-no-spreading */
import { VFC } from 'react';
import { HStack, PinInput, PinInputField } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import styles from 'global/components/AuthCodeFormContainer/AuthCodeFormContainer.module.css';

type Props = {
  name: string;
};

const PinInputFieldStyle = {
  height: '100%',
  pt: 0,
  pb: 0,
  borderRadius: '8px',
  fontFamily: 'Josefin Sans',
  fontSize: '20px',
  fontWeight: 300,
};

const AuthCodeFormContainer: VFC<Props> = ({ name }) => {
  const { register, setValue } = useFormContext();

  const options = {
    required: '認証コードは入力必須の項目です。',
    maxLength: 6,
    minLength: 6,
  };

  register(name, options);

  return (
    <HStack className={styles.authCodeForm}>
      <PinInput
        otp
        placeholder=""
        type="number"
        focusBorderColor="#009d85"
        onChange={(value) => {
          // NOTE: {...register}をPinInputのプロパティに当てはめることができなかっため、setValueを使用
          setValue(name, value, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }}
      >
        <PinInputField name={name} {...PinInputFieldStyle} />
        <PinInputField name={name} {...PinInputFieldStyle} />
        <PinInputField name={name} {...PinInputFieldStyle} />
        <PinInputField name={name} {...PinInputFieldStyle} />
        <PinInputField name={name} {...PinInputFieldStyle} />
        <PinInputField name={name} {...PinInputFieldStyle} />
      </PinInput>
    </HStack>
  );
};

export default AuthCodeFormContainer;
