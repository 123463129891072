import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import PersonaDataDto from '../dto/personal-data-dto';

const createPersonalDataRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/profile',
  },
  method: 'get',
  parameters: {},
});

interface PersonalDataRepository {
  fetch: () => Promise<PersonaDataDto>;
}

export class PersonalDataRepositoryImpl implements PersonalDataRepository {
  fetch = async (): Promise<PersonaDataDto> =>
    new ApiClient().connect(createPersonalDataRequestConfig());
}

export default PersonalDataRepository;
