import { FC } from 'react';
import Container from '../Container/Container';
import styles from './EmptyStateContainer.module.css';

export type Props = {
  iconSrc?: string;
  title?: string;
};

const EmptyStateContainer: FC<Props> = ({ children, iconSrc, title }) => (
  <div className={styles.emptyStateContainer}>
    <div className={styles.emptyStateContentWrapper}>
      <img
        className={styles.stateIcon}
        src={iconSrc || '/global/empty_icon.svg'}
        alt="icon"
      />
      <Container marginTop={16} marginBottom={16}>
        <div className={styles.stateTitle}>
          {title || 'データがありません。'}
        </div>
      </Container>

      <div className={styles.stateDescription}>{children}</div>
    </div>
  </div>
);

export default EmptyStateContainer;
