import Container from 'global/components/Container/Container';
import { Divider } from '@chakra-ui/react';
import Button from 'global/components/button/Button';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import { VFC } from 'react';
import styles from 'features/sign-up/phone-number/authentication-tips/pages/PhoneNumberAuthenticationTipsPage.module.css';

type Props = {
  submitted: () => void;
};

const PhoneNumberAuthenticationTipsPage: VFC<Props> = ({ submitted }) => (
  <>
    <div className={styles.body}>
      <Container marginLeft="s" marginRight="s">
        <div className={styles.imageContainer}>
          <img
            className={styles.image}
            src="/phone_number/sms@3x.png"
            alt="プロフィール入力"
          />
        </div>
        <Container marginTop="l">
          <p className={styles.label}>SMS認証</p>
        </Container>
        <Container marginTop="xl">
          <p className={styles.text}>
            より安全にお使いいただくために、
            本人確認として電話番号のSMS認証を行います。
          </p>
        </Container>
      </Container>
    </div>
    <BottomContainer>
      <Divider orientation="horizontal" />
      <Container
        marginTop="xxl"
        marginBottom="m"
        marginLeft="s"
        marginRight="s"
      >
        <Button
          text="次へ"
          type="primary"
          size="large"
          disabled={false}
          onClick={submitted}
        />
      </Container>
    </BottomContainer>
  </>
);

export default PhoneNumberAuthenticationTipsPage;
