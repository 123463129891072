import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { VFC } from 'react';
import CampaignPage from '../pages/CampaignPage';

const CampaignContainer: VFC = () => {
  useNavigationBarItemsSetting('登録キャンペーン');

  return <CampaignPage />;
};

export default CampaignContainer;
