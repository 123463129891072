import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import InputEmailRepository, {
  InputEmailRepositoryImpl,
} from '../data/repositories/input-email-repository';
import InputEmailViewData from '../view-data/input-email-view-data';

type ReturnType = {
  submitted: (email: string) => void;
};

const useInputEmail = (
  saveResult: (result: Result<InputEmailViewData, string>) => void,
  repository: InputEmailRepository = new InputEmailRepositoryImpl(),
): ReturnType => {
  const convertFromDto = (
    email: string,
    transactionId: string,
  ): InputEmailViewData => ({
    email,
    transactionId,
  });

  const submitted = (email: string): void => {
    const _ = repository
      .send(email)
      .then((res) => {
        saveResult(new Success(convertFromDto(email, res.transactionId)));
      })
      .catch((e) => {
        saveResult(
          new Failure(
            isErrorDTO(e) ? e.error.message : '認証コードの送信に失敗しました',
          ),
        );
      });
  };

  return {
    submitted,
  };
};

export default useInputEmail;
