import { VFC } from 'react';
import { useHistory } from 'react-router';
import Loading from 'global/components/Loading/Loading';
import CoarchMark from 'global/components/CoarchMark/CoarchMark';
import TutorialFetchErrorDialog from 'global/components/TutorialFetchErrorDialog/TutorialFetchErrorDialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import OfferTopPage from '../pages/OfferTopPage';
import useOfferTop from '../hooks/use-offer-top';
import TutorialOfferRepository, {
  tutorialAnnouncementOffer,
} from '../data/repositories/tutorial-offer-repository';

const TutorialOfferTopContainer: VFC = () => {
  useNavigationBarItemsSetting('オファー', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const {
    fetchResult,
    isNeedAddLoadShownListMore,
    onAddLoadShownListItem,
    topShownOffers,
  } = useOfferTop(new TutorialOfferRepository());

  const history = useHistory();

  const PopOverValue = {
    children: (
      <div>
        早速、「{tutorialAnnouncementOffer.companyName}
        」からオファーが届いてます。
        <br />
        オファーをタップして次の画面を開くと、
        {tutorialAnnouncementOffer.awardPoint}ptが付与されます。
      </div>
    ),
  };

  if (fetchResult.isSuccess()) {
    return (
      <>
        <OfferTopPage
          offers={topShownOffers}
          isNeedLoadMore={isNeedAddLoadShownListMore}
          onLoadItem={onAddLoadShownListItem}
          onTapOffersItem={(id, type) => {
            if (type.kind === 'announcement') {
              history.push(`/offers/announcement_offers/${id}`, {
                isTutorial: true,
              });
            }
            if (type.kind === 'questionnaire') {
              history.push(`/offers/questionnaire_offers/${id}`);
            }
          }}
        />
        <CoarchMark elementId="tutorial-offer" popOverOption={PopOverValue} />
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <TutorialFetchErrorDialog />;
  }

  return <Loading />;
};

export default TutorialOfferTopContainer;
