import { VFC } from 'react';
import Loading from 'global/components/Loading/Loading';
import { useLocation, useParams } from 'react-router-dom';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import useTutorialNavigation, {
  TutorialLocationState,
} from 'features/tutorial/hooks/use-tutorial-navigation';
import useDialogInSlideShow from 'global/components/dialog-in-slide-show/use-dialog-in-slide-show';
import DialogInSlideShow, {
  DialogUsedSlide,
} from 'global/components/dialog-in-slide-show/DialogInSlideShow';
import TutorialFetchErrorDialog from 'global/components/TutorialFetchErrorDialog/TutorialFetchErrorDialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import AnnouncementOfferDetailPage from '../pages/AnnouncementOfferDetailPage';
import useAnnouncementOfferDetail from '../hooks/use-announcement-offer-detail';
import TutorialOfferRepository from '../data/repositories/tutorial-offer-repository';

const TutorialAnnouncementOfferDetailContainer: VFC = () => {
  useNavigationBarItemsSetting('オファー内容', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const { isOpen, requestShowing, onClose } = useDialog();
  const { offerId } = useParams<{ offerId: string }>();
  const { state } = useLocation<TutorialLocationState | undefined>();

  const {
    isOpen: isSlideShowOpen,
    currentPage,
    button,
    requestShowing: requestSlideShowShowing,
    requestSlidePaging,
    requestButtonChanging,
    onClose: onSlideShowClose,
  } = useDialogInSlideShow();

  const {
    fetchResult,
    linkButtonTapped,
    linkButtonTappedAction,
    offerOpenEventFired,
    offerOpenEventFiredAction,
  } = useAnnouncementOfferDetail(
    offerId,
    new TutorialOfferRepository(),
    (offerOpenEventFiredSaveResult) => {
      if (offerOpenEventFiredSaveResult.isSuccess()) {
        // チュートリアルオファーの詳細を見せたいので、2秒待ってからダイアログ表示する
        setTimeout(() => {
          requestSlideShowShowing({
            text: 'オファーをさらに受け取る',
            onClick: (page) => {
              requestSlidePaging(page + 1);
            },
          });
        }, 2000);
      } else if (offerOpenEventFiredSaveResult.isFailure()) {
        requestShowing();
      }
    },
    (linkButtonTappedSaveResult) => {
      if (linkButtonTappedSaveResult.isFailure()) {
        requestShowing();
      }
    },
    state,
  );

  const slide1: DialogUsedSlide = {
    kind: 'common',
    imageSrc: '/global/get_point.png',
    title: offerOpenEventFiredAction?.title,
    description: offerOpenEventFiredAction?.description,
  };

  const slide2: DialogUsedSlide = {
    kind: 'common',
    imageSrc: '/tutorial/tutorial_07@3x.png',
    title: 'オファーを更に受け取るには',
    description:
      'オファーを受け取るためには、あなたのデータを企業へ公開する必要があります。',
  };

  const slide3: DialogUsedSlide = {
    kind: 'common',
    imageSrc: '/tutorial/tutorial_07@3x.png',
    title: '公開されたデータについて',
    description:
      '公開されたデータは、オファー配信時の参考情報や、企業のマーケティング情報として活用されます。',
  };

  const slide4: DialogUsedSlide = {
    kind: 'common',
    imageSrc: '/tutorial/tutorial_07@3x.png',
    title: '公開するデータを選びましょう',
    description:
      'どのデータをどの企業に公開するかはあなた自身が設定することができます。\n\n※設定した内容は、いつでも変更することができます。',
  };

  const elements = [slide1, slide2, slide3, slide4];

  const { navigate } = useTutorialNavigation();

  if (fetchResult.isSuccess()) {
    return (
      <>
        <DialogInSlideShow
          isOpen={isSlideShowOpen}
          slideElements={elements}
          selectedPage={currentPage}
          buttonText={button.text}
          onClose={() => ''}
          onButtonClicked={() => button.onClick(currentPage)}
          onPageChanged={(page) => {
            if (page !== 0 && page < elements.length - 1) {
              requestButtonChanging({
                text: '次へ',
                onClick: () => {
                  requestSlidePaging(page + 1);
                },
              });
            }
            if (page >= elements.length - 1) {
              requestButtonChanging({
                text: '公開するデータを選ぶ',
                onClick: () => {
                  onSlideShowClose();
                  navigate('/mydata/personal');
                },
              });
            }
            if (page !== currentPage) {
              requestSlidePaging(page);
            }
          }}
        />
        <AnnouncementOfferDetailPage
          offerDetailViewData={fetchResult.value}
          linkButtonTapped={linkButtonTapped}
          offerOpenEventFired={offerOpenEventFired}
        />
        {linkButtonTappedAction && (
          <Dialog
            title={linkButtonTappedAction?.title}
            description={linkButtonTappedAction?.description}
            primaryButton={{ text: 'OK' }}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
        {offerOpenEventFiredAction && (
          <Dialog
            title={offerOpenEventFiredAction?.title}
            description={offerOpenEventFiredAction?.description}
            imageSrc={offerOpenEventFiredAction?.imageSrc}
            primaryButton={
              offerOpenEventFiredAction?.primaryButton
                ? offerOpenEventFiredAction?.primaryButton
                : { text: 'OK' }
            }
            isOpen={isOpen}
            onClose={offerOpenEventFiredAction?.onClose || onClose}
          />
        )}
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <TutorialFetchErrorDialog />;
  }

  return <Loading />;
};

export default TutorialAnnouncementOfferDetailContainer;
