import { useQuery } from 'react-query';
import PointDetailRepository, {
  PointDetailRepositoryImpl,
} from 'features/point-detail/data/repositories/point-detail-repository';
import { isErrorDTO } from 'data/dto/error-dto';
import Result from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { useState } from 'react';
import YearMonthDay from 'global/utilities/year-month-day';
import { DateTime } from 'luxon';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import PointDetailViewData, {
  MonthlyPointDetailViewData,
  DailyPointViewData,
  DailyPointDetailViewData,
} from '../view-data/point-detail-view-data';
import { PointHistory } from '../data/dto/point-detail-dto';

type ReturnType = {
  pointDetailResult: Result<PointDetailViewData, Error>;
  pointDetailResultValue: PointDetailViewData;
  updatePointDetailResultValue: (value: PointDetailViewData) => void;
  didScrollToBottom: () => void;
  hasMore: boolean;
};

export const convertDtoToViewData = (
  pointsHistories: PointHistory[],
): PointDetailViewData => {
  // APIで取得された日毎のデータを月毎に整形しなおす
  // かなり読みにくいのでリファクタしたい(そもそも中間マップなしで整形したい)
  // データ整形用の中間マップ
  const intermediateMap = new Map<
    string,
    Map<string, DailyPointDetailViewData[]>
  >();

  pointsHistories.forEach((data) => {
    const ymdDate = new YearMonthDay(DateTime.fromSQL(data.pointShippingDate));
    if (ymdDate === undefined) {
      return;
    }
    const monthLabel = `${ymdDate.year}/${ymdDate.month}`;
    const dayLabel = ymdDate.separatedBySlash();
    const pointData: DailyPointDetailViewData = {
      action: data.pointAction,
      pointId: data.pointId,
      name: data.pointName,
      amount: data.points,
    };

    const monthMap = intermediateMap.get(monthLabel);
    if (monthMap) {
      const dayMap = monthMap.get(dayLabel);
      if (dayMap) {
        // monthLabelキーもdayLabelもある
        // dayMapに新しいポイント要素を追加
        monthMap.set(dayLabel, dayMap.concat(pointData));
        intermediateMap.set(monthLabel, monthMap);
      } else {
        // monthLableキーはあるがdayLabelがないのでmonthMapに新しいdayLabelの要素を追加
        monthMap.set(dayLabel, [pointData]);
        intermediateMap.set(monthLabel, monthMap);
      }
    } else {
      // monthLabelキーがないので全て初期化
      const newDayMap = new Map<string, DailyPointDetailViewData[]>();
      newDayMap.set(dayLabel, [pointData]);
      intermediateMap.set(monthLabel, newDayMap);
    }
  });

  // intermediateMap を MonthlyPointDetailViewData[] に変換する
  const monthlyPointDetailViewDataList: MonthlyPointDetailViewData[] = [];
  intermediateMap.forEach((monthMap, monthKey) => {
    const dailyPointViewDataList: DailyPointViewData[] = [];
    monthMap.forEach((dayList, dayKey) => {
      const dailyPointViewData: DailyPointViewData = {
        date: dayKey,
        points: dayList,
      };
      dailyPointViewDataList.push(dailyPointViewData);
    });
    const monthlyPointDetailViewData: MonthlyPointDetailViewData = {
      month: monthKey,
      days: dailyPointViewDataList,
    };
    monthlyPointDetailViewDataList.push(monthlyPointDetailViewData);
  });

  return monthlyPointDetailViewDataList;
};

const usePointDetail = (
  repository: PointDetailRepository = new PointDetailRepositoryImpl(),
): ReturnType => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isUpdating, changeIsUpdating] = useState(false);
  const [hasMore, changeHasMore] = useState(true);

  // pointDetailResultのvalueを格納しておくためのState
  // View側で動的にvalueを読み込むとisInProgress <=> isSuccess の間で再描画を起こしてしまうため
  // valueが確定したものを格納して描画するようにする
  const initValue: PointDetailViewData = [];
  const [pointDetailResultValue, setPointDetailResultValue] =
    useState(initValue);

  // pointHistoryを格納しておくState
  const initHistories: PointHistory[] = [];
  const [pointsHistories, addPointsHistories] = useState(initHistories);

  const didScrollToBottom = (): void => {
    if (!isUpdating) {
      changeIsUpdating(() => true);
      setCurrentPage((old) => old + 1);
    }
  };

  const updatePointDetailResultValue = (value: PointDetailViewData): void => {
    setPointDetailResultValue(() => value);
  };

  const fetchPointDetail = useQuery<PointDetailViewData, Error>(
    ['/users/me/fit_stats_point/reports', currentPage],
    async () => {
      const dto = await repository.fetch(currentPage).catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });
      changeIsUpdating(() => false);

      if (dto.currentPage === dto.totalPages) {
        changeHasMore(() => false);
      }

      const margedPointHistories = pointsHistories.concat(dto.pointHistory);
      addPointsHistories(() => margedPointHistories);

      return convertDtoToViewData(margedPointHistories);
    },
  );

  const pointDetailResult = createResult(fetchPointDetail);

  return {
    pointDetailResult,
    pointDetailResultValue,
    updatePointDetailResultValue,
    didScrollToBottom,
    hasMore,
  };
};

export default usePointDetail;
