import { ConsentItem } from 'features/my-data/data/repositories/data-consent-repository';
import ClientCompanyViewData from '../../view-data/client-company-view-data';

const buildConsentItems = (viewData: ClientCompanyViewData): ConsentItem[] =>
  viewData.sections.flatMap((section) =>
    section.clients.map(
      (client): ConsentItem => ({
        name: client.clientId,
        consent: client.consent,
      }),
    ),
  );

export default buildConsentItems;
