import PersonalDataViewData from '../../view-data/personal-page-view-data';
import { ConsentItem } from '../../../data/repositories/data-consent-repository';

const buildConsentProperties = (
  viewData: PersonalDataViewData,
): ConsentItem[] =>
  viewData.sections.flatMap((section) =>
    section.items.flatMap((item) =>
      item.properties.map(
        (property): ConsentItem => ({
          name: property.name,
          consent: item.consent,
        }),
      ),
    ),
  );

export default buildConsentProperties;
