import ApiClient from '../../../../../../data/api-client/api-client';
import RequestConfig, {
  URL,
} from '../../../../../../data/request-configs/request-config';
import {
  HontoCouponFetchEmailResponseDto,
  HontoCouponFetchPointResponseDto,
} from '../dto/honto-coupon-dto';

const createFitStatsPointRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/fit_stats_point/balance',
  },
  method: 'get',
  parameters: {},
});

const createUsersInfoRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/information',
  },
  method: 'get',
  parameters: {},
});

const createHontoCouponExchangeRequestConfig = (
  exchangeQuantity: number,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/fit_stats_point/honto_coupon',
  },
  method: 'post',
  parameters: {
    exchange_quantity: exchangeQuantity,
  },
});

interface HontoCouponRepository {
  fetchPoint: () => Promise<HontoCouponFetchPointResponseDto>;
  fetchEmail: () => Promise<HontoCouponFetchEmailResponseDto>;
  exchangeCoupon: (exchangeQuantity: number) => Promise<void>;
}

export class HontoCouponRepositoryImpl implements HontoCouponRepository {
  fetchPoint = async (): Promise<HontoCouponFetchPointResponseDto> =>
    new ApiClient().connect(createFitStatsPointRequestConfig());

  fetchEmail = async (): Promise<HontoCouponFetchEmailResponseDto> =>
    new ApiClient().connect(createUsersInfoRequestConfig());

  exchangeCoupon = async (exchangeQuantity: number): Promise<void> =>
    new ApiClient().connect(
      createHontoCouponExchangeRequestConfig(exchangeQuantity),
    );
}

export default HontoCouponRepository;
