import { useQuery } from 'react-query';
import UserLifelogRecordsRepository from 'features/top/data/repositories/user-lifelog-records-repository';
import { isErrorDTO } from 'data/dto/error-dto';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';

const useUserLifelogRecords = (): void => {
  useQuery<void, Error>(['/users/me/lifelog_records/sync'], async () => {
    const repository = new UserLifelogRecordsRepository();
    await repository.sync().catch((error) => {
      if (isErrorDTO(error)) {
        throw Error(error.error.message);
      }
      throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
    });
  });
};

export default useUserLifelogRecords;
