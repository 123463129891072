import { VFC } from 'react';
import { useHistory } from 'react-router';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import SignInPage from 'features/sign-in/email-password/pages/SignInPage';
import useSignIn from 'features/sign-in/email-password/hooks/use-sign-in';
import { SignInRepositoryImpl } from 'features/sign-in/email-password/data/repositories/sign-in-repository';
import useIntroductionProgress from 'features/introduction-progress/hooks/use-introduction-progress';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';

const SignInContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, onClose } = useDialog();
  const { redirectToRecoveryPoint } = useIntroductionProgress();
  useNavigationBarItemsSetting('ログイン', {
    isCloseButtonVisible: true,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: true,
  });
  const { submitErrorMessage, submitted } = useSignIn(
    new SignInRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        if (saveResult.value.mfaLogin) {
          history.push('/sign_in/phone_number_authentication', {
            transactionId: saveResult.value.transactionId,
          });
        } else {
          redirectToRecoveryPoint(true);
        }
      }
      if (saveResult.isFailure()) {
        requestShowing();
      }
    },
  );

  return (
    <>
      <Dialog
        title="エラー"
        description={submitErrorMessage}
        primaryButton={{ text: 'OK' }}
        isOpen={isOpen}
        onClose={onClose}
      />

      <SignInPage submitted={submitted} />
    </>
  );
};

export default SignInContainer;
