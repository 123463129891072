import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import Loading from 'global/components/Loading/Loading';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { VFC } from 'react';
import useProfileDescription from '../hooks/use-profile-description';
import ProfileDescriptionPage from '../pages/ProfileDescriptionPage';

const ProfileDescriptionContainer: VFC = () => {
  const { fetchResult } = useProfileDescription();
  useNavigationBarItemsSetting('プロフィールの登録', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });
  if (fetchResult.isSuccess()) {
    return <ProfileDescriptionPage />;
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default ProfileDescriptionContainer;
