import { VFC } from 'react';
import Button from 'global/components/button/Button';
import { useForm } from 'react-hook-form';
import Container from 'global/components/Container/Container';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import { Divider } from '@chakra-ui/react';
import FormItemList from 'features/profile/components/FormItemList';
import TopContainer from 'global/components/TopContainer/TopContainer';
import ProgressBar from 'features/sign-up/components/ProgressBar/ProgressBar';
import { ProfileSinglePageViewData } from '../../../profile/view-data/profile-view-data';
import styles from './ProfileDetailPage.module.css';
import { UserInputRawData } from '../../../profile/user-input-item';

type Props = {
  result: ProfileSinglePageViewData;
  nextButtonTapped: (userInput: UserInputRawData) => void;
};

const ProfileDetailPage: VFC<Props> = ({ result, nextButtonTapped }) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    setError,
    clearErrors,
  } = useForm<UserInputRawData>({
    mode: 'onBlur',
    defaultValues: result.userInputItems?.reduce(
      (obj, item) => ({ ...obj, [item.name]: item.value }),
      {},
    ),
  });

  return (
    <>
      <TopContainer>
        <ProgressBar max={11} value={10} />
      </TopContainer>

      <div className={styles.profileDetailPageWrapper}>
        <Container marginTop="m" marginBottom="m">
          <div className={styles.profilePageDescription}>
            <span className={styles.profilePageDescriptionWarning}>
              マンション・建物名を除き、項目の入力は必須です。
            </span>
          </div>
        </Container>
        <Container marginTop="s" marginBottom="s">
          <form>
            {result.profilePageViewData.sections.map((section) => (
              <Container marginBottom="m">
                <div className={styles.sectionTitle}>{section.title}</div>
                <FormItemList
                  formItems={section.formItems}
                  register={register}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                  setError={setError}
                  clearErrors={clearErrors}
                />
              </Container>
            ))}
          </form>
        </Container>
      </div>

      <BottomContainer>
        <Divider orientation="horizontal" />
        <Container
          marginTop={50}
          marginBottom={50}
          marginLeft="s"
          marginRight="s"
        >
          <Button
            text="次へ"
            type="primary"
            size="large"
            disabled={!isValid}
            onClick={handleSubmit(nextButtonTapped)}
          />
        </Container>
      </BottomContainer>
    </>
  );
};

export default ProfileDetailPage;
