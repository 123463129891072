/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, VFC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Divider } from '@chakra-ui/react';
import Container from 'global/components/Container/Container';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import Button from 'global/components/button/Button';
import AuthCodeFormContainer from 'global/components/AuthCodeFormContainer/AuthCodeFormContainer';
import UndraggableLink from 'global/components/UndraggableLink/UndraggableLink';
import styles from './EmailAuthenticationPage.module.css';

type Props = {
  submitted: (emailAuthCode: number) => void;
  email: string;
};

type FormData = {
  emailAuthCode: number;
};

const EmailAuthenticationPage: VFC<Props> = ({ submitted, email }) => {
  const methods = useForm<FormData>({ mode: 'onBlur' });

  const onSubmit = (data: FormData) => {
    submitted(data.emailAuthCode);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <p className={styles.topMessage}>
          登録いただいたメールアドレス
          <br />
          <span className={styles.highlightText}>{email}</span>
          に認証コードを送信しました。
          <br />
          メールにて送付した6桁の認証コードを入力してください。
        </p>
        <FormProvider {...methods}>
          <Container marginTop="xxl">
            <AuthCodeFormContainer name="emailAuthCode" />
            <p className={styles.errorMessage}>
              {methods.formState.errors.emailAuthCode?.message}
            </p>
          </Container>
        </FormProvider>
        <Container marginTop="xxl">
          <p className={styles.text}>
            メールが届かない場合は、自動的に振り分けられている可能性がありますので、一度、迷惑メールフォルダをご確認ください。
          </p>
        </Container>

        <Container marginTop="3xs">
          <p className={styles.text}>
            上記に当てはまらずメールが届かない場合や、認証コードの有効期限が切れた場合は
            「
            <UndraggableLink
              className={styles.highlightText}
              to="/sign_up/email/registration"
            >
              メールアドレスの登録
            </UndraggableLink>
            」にて再送してください。
          </p>
        </Container>

        <Container marginTop="3xs">
          <p className={styles.text}>
            お使いのメールサービスの設定画面より「fitstats-dnp.com」ドメインを受信できる設定になっているかをご確認ください。
          </p>
        </Container>
      </Container>

      <BottomContainer>
        <Divider orientation="horizontal" />
        <Container
          marginTop="xxl"
          marginBottom="m"
          marginLeft="s"
          marginRight="s"
        >
          <Button
            text="次へ"
            type="primary"
            size="large"
            disabled={!methods.formState.isDirty || !methods.formState.isValid}
            onClick={methods.handleSubmit(onSubmit)}
          />
        </Container>
      </BottomContainer>
    </>
  );
};

export default EmailAuthenticationPage;
