import { VFC } from 'react';
import {
  AcountSettingsCategory,
  AcountSettingsMenuType,
} from '../view-data/acount-settings-root-view-data';
import AcountSettingsMenuListCell from './AcountSettingsMenuListCell';
import styles from './AcountSettingsCategoryList.module.css';

type Props = {
  category: AcountSettingsCategory;
  onMenuClick: (type: AcountSettingsMenuType) => void;
};

const AcountSettingsCategoryList: VFC<Props> = ({ category, onMenuClick }) => (
  <>
    <div className={styles.category}>
      <div className={styles.text}>{category.name}</div>
    </div>
    {category.menus.map((e) => (
      <div key={e.type}>
        <AcountSettingsMenuListCell menu={e} onClick={onMenuClick} />
      </div>
    ))}
  </>
);

export default AcountSettingsCategoryList;
