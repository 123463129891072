import requestWebCommand, {
  CloseFitStatsView,
} from 'global/utilities/web-command';
import { VFC } from 'react';
import Dialog from '../dialog/Dialog';

const TutorialFetchErrorDialog: VFC = () => (
  <Dialog
    title="エラーが発生しました。"
    description="一度FitStatsを終了させて再度お試しください。"
    primaryButton={{
      text: 'FitStatsを閉じて、FiNCに戻る',
      onClick: () => requestWebCommand(new CloseFitStatsView()),
    }}
    isOpen
    showsCloseButton={false}
    onClose={() => {
      // チュートリアル中なので、閉じない
    }}
  />
);

export default TutorialFetchErrorDialog;
