import useScrollReset from 'global/hooks/use-scroll-reset';
import { VFC } from 'react';

type Props = {
  children: JSX.Element | JSX.Element[];
};
const ScrollToTopWhenMounted: VFC<Props> = ({ children }) => {
  const { scrollResetted } = useScrollReset();

  if (scrollResetted) {
    return <>{children}</>;
  }

  return <></>;
};

export default ScrollToTopWhenMounted;
