import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import CampaignSummaryDto from '../dto/campaign-summary-dto';

const createCampaignSummaryRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/campaigns/register/progress',
  },
  method: 'post',
  parameters: {},
});

const campaignSummaryDto: CampaignSummaryDto = {
  isAchieve: false,
  totalQuestionsCount: 115,
  answeredQuestionsCount: 50,
  totalRecieptPoint: 1000,
  endAt: '2029-12-31T23:59:59+0900',
  steps: [
    {
      achieveCount: 15,
      point: 500,
      isAchieve: true,
    },
    {
      achieveCount: 40,
      point: 200,
      isAchieve: true,
    },
    {
      achieveCount: 70,
      point: 300,
      isAchieve: true,
    },
    {
      achieveCount: 115,
      point: 500,
      isAchieve: false,
    },
  ],
};

interface CampaignSummaryRepository {
  fetch: () => Promise<CampaignSummaryDto>;
}

export class CampaignSummaryRepositoryImpl
  implements CampaignSummaryRepository
{
  fetch = async (): Promise<CampaignSummaryDto> =>
    new ApiClient().connect(createCampaignSummaryRequestConfig());
}

export class MockCampaignSummaryRepositoryImpl
  implements CampaignSummaryRepository
{
  fetch = async (): Promise<CampaignSummaryDto> =>
    new Promise((resolve, _) =>
      setTimeout(() => resolve(campaignSummaryDto), 1500),
    );
}

export default CampaignSummaryRepository;
