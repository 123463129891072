import { useEffect, VFC } from 'react';
import { useHistory } from 'react-router';

const AttemptLogoutContainer: VFC = () => {
  const history = useHistory();

  useEffect(() => {
    history.push('/');
  }, [history]);

  return <></>;
};

export default AttemptLogoutContainer;
