import { useHistory } from 'react-router';

export type TutorialLocationState = {
  isTutorial: boolean;
  hideCoarchMark?: boolean;
};

type ReturnType = {
  navigate: (to: string) => void;
};

const useTutorialNavigation = (): ReturnType => {
  const history = useHistory<TutorialLocationState>();
  const navigate = (to: string) => {
    history.push(to, { isTutorial: true, hideCoarchMark: false });
  };

  return { navigate };
};

export default useTutorialNavigation;
