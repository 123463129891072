import { useEffect, useState } from 'react';

type ReturnType = {
  scrollResetted: boolean;
};

const useScrollReset = (): ReturnType => {
  const [scrollResetted, setScrollResetted] = useState(false); // 画面ちらつき防止のため、スクロールリセット前には画面表示をしない制御を想定
  useEffect(() => {
    window.scroll(0, 0);
    setScrollResetted(true);
  }, []);

  return { scrollResetted };
};

export default useScrollReset;
