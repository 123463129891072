import UserInputItem from 'features/profile/user-input-item';
import YearMonthDay, { isYearMonthDay } from 'global/utilities/year-month-day';
import PersonaDataDto, {
  UserPersonaItemDto,
} from '../../data/dto/personal-data-dto';
import { PersonalDataViewData } from '../../view-data/edit-personal-page-view-data';

const convertUserPersonaItemDtoToUserInputItem = (
  dto: UserPersonaItemDto,
): UserInputItem => {
  if (isYearMonthDay(dto.value)) {
    return {
      name: dto.name,
      value: dto.value.separatedYMMDDByHyphen(),
    };
  }

  return {
    name: dto.name,
    value: dto.value,
  };
};

const convertPersonalDataDtoToViewData = (
  dto: PersonaDataDto,
): PersonalDataViewData => {
  const result: UserInputItem[] = [];

  dto.userProfile.forEach((p) => {
    if (p.name === 'birthday') {
      const birthday = p.value as YearMonthDay;
      if (birthday) {
        result.push(
          convertUserPersonaItemDtoToUserInputItem({
            name: 'year',
            value: birthday.year.toString(),
          }),
        );
        result.push(
          convertUserPersonaItemDtoToUserInputItem({
            name: 'month',
            value: `00${birthday.month.toString()}`.slice(-2),
          }),
        );
        result.push(
          convertUserPersonaItemDtoToUserInputItem({
            name: 'day',
            value: `00${birthday.day.toString()}`.slice(-2),
          }),
        );
      }

      return;
    }
    result.push(convertUserPersonaItemDtoToUserInputItem(p));
  });

  return { userProfile: result };
};

export default convertPersonalDataDtoToViewData;
