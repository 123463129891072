import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import styles from './PopOver.module.css';

export type Props = {
  enabled: boolean;
  onClick: () => void;
  buttonText?: string;
};

const PopOver: FC<Props> = ({ children, enabled, onClick, buttonText }) =>
  enabled ? (
    <div className={styles.popOverContainer}>
      <Flex alignItems="flex-start" justifyContent="center">
        <div className={styles.popOverIcon} />
        <div className={styles.popOverDescription}>
          {children}
          <div className={styles.popOverButtonWrapper}>
            <button
              type="button"
              className={styles.popOverButton}
              onClick={onClick}
            >
              {buttonText || 'OK'}
            </button>
          </div>
        </div>
      </Flex>
    </div>
  ) : (
    <></>
  );
export default PopOver;
