import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';

const createPhoneNumberResettingsRequestConfig = (
  changedPhoneNumber: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/phone_number/change_request',
  },
  method: 'post',
  parameters: {
    phone_number: changedPhoneNumber,
  },
});

interface PhoneNumberResettingsRepository {
  resetting: (changedPhoneNumber: string) => Promise<void>;
}

export class PhoneNumberResettingsRepositoryImpl
  implements PhoneNumberResettingsRepository
{
  resetting = async (changedPhoneNumber: string): Promise<void> =>
    new ApiClient().connect(
      createPhoneNumberResettingsRequestConfig(changedPhoneNumber),
    );
}

export default PhoneNumberResettingsRepository;
