import { useQuery } from 'react-query';
import PrivacyPolicyRepository, {
  PrivacyPolicyRepositoryImpl,
} from 'features/sign-up/privacy-policy/data/repositories/privacy-policy-repository';
import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import TermsOfUseAndPrivacyPolicyViewData from 'global/view-data/terms-of-use-and-privacy-policy-view-data';
import { useRef } from 'react';
import queryClient from 'global/query-client';
import convertDtoToTermsOfUseAndPrivacyPolicyViewData from 'features/terms-and-privacy-policy/utils/convert-dto-to-terms-of-use-and-privacy-policy-view-data';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';

type ReturnType = {
  fetchResult: Result<TermsOfUseAndPrivacyPolicyViewData, Error>;
  submitErrorMessage?: string;
  submitted: () => void;
};

const PRIVACY_POLICY_QUERY_KEY = '/privacy-policy';

const cachedPrivacyPolicyViewData = ():
  | TermsOfUseAndPrivacyPolicyViewData
  | undefined => queryClient.getQueryData([PRIVACY_POLICY_QUERY_KEY]);

const usePrivacyPolicy = (
  authorize: () => Promise<string>,
  repository: PrivacyPolicyRepository = new PrivacyPolicyRepositoryImpl(),
  saveResult: (result: Result<string, void>) => void,
): ReturnType => {
  const submitErrorMessage = useRef<string | undefined>(undefined);

  const queryResult = useQuery<TermsOfUseAndPrivacyPolicyViewData, Error>(
    [PRIVACY_POLICY_QUERY_KEY],
    async () => {
      const dto = await repository.fetch().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertDtoToTermsOfUseAndPrivacyPolicyViewData(dto);
    },
  );

  const fetchResult = createResult(queryResult);

  const submitted = async () => {
    const docVersion = cachedPrivacyPolicyViewData()?.docVersion;
    if (!docVersion) {
      throw Error('個人情報の取り扱いの取得に失敗しました');
    }

    try {
      localStorage.setItem('privacyPolicyVersion', docVersion);
      const redirectUrl = await authorize();
      saveResult(new Success(redirectUrl));
    } catch (error) {
      submitErrorMessage.current = isErrorDTO(error)
        ? error.error.message
        : '認可画面へのリダイレクトに失敗しました';
      saveResult(new Failure(undefined));
    }
  };

  return {
    fetchResult,
    submitErrorMessage: submitErrorMessage.current,
    submitted,
  };
};

export default usePrivacyPolicy;
