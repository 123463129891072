import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import PhoneNumberAuthenticationContainer from 'features/sign-in/phone-number-authentication/containers';
import SignInContainer from 'features/sign-in/email-password/containers';

const SignInRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`}>
      <SignInContainer />
    </Route>
    <Route exact path={`${match.path}/phone_number_authentication`}>
      <PhoneNumberAuthenticationContainer />
    </Route>
  </Switch>
);

export default SignInRouter;
