import { VFC, useRef } from 'react';
import { useHistory } from 'react-router';
import { PhoneNumberAuthenticationRepositoryImpl } from 'features/sign-in/phone-number-authentication/data/repositories/phone-number-authentication-repository';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import useIntroductionProgress from 'features/introduction-progress/hooks/use-introduction-progress';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import usePhoneNumberAuthentication from '../hooks/use-phone-number-authentication';
import PhoneNumberAuthenticationPage from '../pages/PhoneNumberAuthenticationPage';

type DialogInfo = {
  buttonAction: () => void;
};

const PhoneNumberAuthenticationContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, onClose } = useDialog();
  const { transactionId } = history.location.state as { transactionId: string };
  const dialogInfoRef = useRef<DialogInfo | undefined>();
  const { redirectToRecoveryPoint } = useIntroductionProgress();
  useNavigationBarItemsSetting('認証コード入力', {
    isCloseButtonVisible: true,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: true,
  });

  const { submitErrorMessage, submitted } = usePhoneNumberAuthentication(
    transactionId,
    new PhoneNumberAuthenticationRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        redirectToRecoveryPoint(true);
      }
      if (saveResult.isFailure()) {
        // NOTE: 400が返ってきたらダイアログを表示→ダイアログを閉じたら問答無用でログイン画面に飛ばす
        // それ以外の場合、ダイアログを表示してその画面のままにする
        if (saveResult.error) {
          dialogInfoRef.current = {
            buttonAction: () => {
              history.push('/sign_in');
            },
          };
        } else {
          dialogInfoRef.current = {
            buttonAction: onClose,
          };
        }

        requestShowing();
      }
    },
  );

  return (
    <>
      {dialogInfoRef.current && (
        <Dialog
          title="エラー"
          description={submitErrorMessage}
          primaryButton={{
            text: 'OK',
            onClick: dialogInfoRef.current.buttonAction,
          }}
          isOpen={isOpen}
          onClose={dialogInfoRef.current.buttonAction}
        />
      )}

      <PhoneNumberAuthenticationPage submitted={submitted} />
    </>
  );
};

export default PhoneNumberAuthenticationContainer;
