import { UserOfferViewData } from 'features/offer/view-data/offer-top-view-data';

const NUMBER_OF_OFFERS_DISPLAYED = 2;

const FilterOffersForTop = (offers: UserOfferViewData[]): UserOfferViewData[] =>
  offers
    .filter((o) => o.isOfferOpened === false)
    .slice(0, NUMBER_OF_OFFERS_DISPLAYED);

export default FilterOffersForTop;
