import ClientCompanyViewData, {
  ClientCompanyItemViewData,
  ClientCompanySectionViewData,
  ConsentElement,
} from 'features/my-data/client-company/view-data/client-company-view-data';

const update = (
  originalClientlData: ClientCompanyViewData,
  consentElement: ConsentElement,
  sectionFindPredicate: (client: ClientCompanySectionViewData) => boolean,
  itemsMapFunction: (
    item: ClientCompanyItemViewData,
  ) => ClientCompanyItemViewData,
): ClientCompanyViewData => {
  const newClientlData = originalClientlData;

  const targetSectionIndex =
    newClientlData.sections.findIndex(sectionFindPredicate);

  if (targetSectionIndex === -1) {
    return newClientlData;
  }

  const targetSection = newClientlData.sections[targetSectionIndex];
  const newItems = targetSection.clients.map(itemsMapFunction);
  targetSection.clients = newItems;
  newClientlData.sections[targetSectionIndex] = targetSection;

  return newClientlData;
};

const updateConsentState = (
  originalClientCompanyData: ClientCompanyViewData,
  consentElement: ConsentElement,
): ClientCompanyViewData => {
  switch (consentElement.type) {
    case 'item': {
      const sectionFindPredicate = (
        section: ClientCompanySectionViewData,
      ): boolean =>
        section.clients.some(
          (client) => client.clientId === consentElement.name,
        );

      const itemsMapFunction = (
        item: ClientCompanyItemViewData,
      ): ClientCompanyItemViewData => {
        const newItem = item;
        if (newItem.clientId === consentElement.name) {
          newItem.consent = consentElement.isChecked;
        }

        return newItem;
      };

      return update(
        originalClientCompanyData,
        consentElement,
        sectionFindPredicate,
        itemsMapFunction,
      );
    }
    case 'section': {
      const sectionFindPredicate = (
        section: ClientCompanySectionViewData,
      ): boolean => section.title === consentElement.name;

      const itemsMapFunction = (
        item: ClientCompanyItemViewData,
      ): ClientCompanyItemViewData => {
        const newItem = item;
        newItem.consent = consentElement.isChecked;

        return newItem;
      };

      return update(
        originalClientCompanyData,
        consentElement,
        sectionFindPredicate,
        itemsMapFunction,
      );
    }
    case 'all': {
      const newClientCompanySectionData: ClientCompanySectionViewData[] = [];

      const clientCompanyItems = (
        clients: ClientCompanyItemViewData[],
      ): ClientCompanyItemViewData[] => {
        const newClientCompanyItems: ClientCompanyItemViewData[] = [];

        clients.forEach((client) =>
          newClientCompanyItems.push({
            ...client,
            consent: consentElement.isChecked,
          }),
        );

        return newClientCompanyItems;
      };

      originalClientCompanyData.sections.forEach((section) =>
        newClientCompanySectionData.push({
          title: section.title,
          clients: clientCompanyItems(section.clients),
        }),
      );

      return { sections: newClientCompanySectionData };
    }
    default:
      return originalClientCompanyData;
  }
};

export default updateConsentState;
