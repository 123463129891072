import ApiClient from '../../../../data/api-client/api-client';
import RequestConfig, {
  URL,
} from '../../../../data/request-configs/request-config';
import ProgramTopDto from '../dto/program-top-dto';

const createProgramTopRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/mission_program/top',
  },
  method: 'get',
  parameters: {},
});

interface ProgramTopRepository {
  fetch: () => Promise<ProgramTopDto>;
}

export class ProgramTopRepositoryImpl implements ProgramTopRepository {
  fetch = async (): Promise<ProgramTopDto> =>
    new ApiClient().connect(createProgramTopRequestConfig());
}

export default ProgramTopRepository;
