import ApiClient from '../../../../data/api-client/api-client';
import RequestConfig, {
  URL,
} from '../../../../data/request-configs/request-config';
import ActionId from '../common/action-id';
import ProgramId from '../common/program-id';
import ProgramDetailDto, {
  ProgramDetailActionResponseDto,
} from '../dto/program-detail-dto';

const createProgramDetailFetchRequestConfig = (
  id: ProgramId,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/mission_program/programs/${id.value}`,
  },
  method: 'get',
  parameters: {},
});

const createProgramJoinRequestConfig = (id: ProgramId): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/mission_program/programs/${id.value}/attend`,
  },
  method: 'post',
  parameters: {},
});

const createProgramLeaveRequestConfig = (id: ProgramId): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/mission_program/programs/${id.value}/cancel`,
  },
  method: 'post',
  parameters: {},
});

const createProgramSelfReportRequestConfig = (
  id: ProgramId,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/mission_program/actions/${id.value}/achieve`,
  },
  method: 'patch',
  parameters: {},
});

const createProgramReceiveActionRewardRequestConfig = (
  id: ProgramId,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/mission_program/actions/${id.value}/accept_reward`,
  },
  method: 'patch',
  parameters: {},
});

interface ProgramDetailRepository {
  fetch: (id: ProgramId) => Promise<ProgramDetailDto>;
  joinProgram: (id: ProgramId) => Promise<ProgramDetailDto>;
  leaveProgram: (id: ProgramId) => Promise<ProgramDetailDto>;
  selfReportingAction: (
    actionId: ActionId,
  ) => Promise<ProgramDetailActionResponseDto>;
  receiveActionReward: (
    actionId: ActionId,
  ) => Promise<ProgramDetailActionResponseDto>;
}

export class ProgramDetailRepositoryImpl implements ProgramDetailRepository {
  fetch = async (id: ProgramId): Promise<ProgramDetailDto> =>
    new ApiClient().connect(createProgramDetailFetchRequestConfig(id));

  joinProgram = async (id: ProgramId): Promise<ProgramDetailDto> =>
    new ApiClient().connect(createProgramJoinRequestConfig(id));

  leaveProgram = async (id: ProgramId): Promise<ProgramDetailDto> =>
    new ApiClient().connect(createProgramLeaveRequestConfig(id));

  selfReportingAction = async (
    id: ActionId,
  ): Promise<ProgramDetailActionResponseDto> =>
    new ApiClient().connect(createProgramSelfReportRequestConfig(id));

  receiveActionReward = async (
    id: ActionId,
  ): Promise<ProgramDetailActionResponseDto> =>
    new ApiClient().connect(createProgramReceiveActionRewardRequestConfig(id));
}

export default ProgramDetailRepository;
