import { VFC } from 'react';
import Button from 'global/components/button/Button';
import { useHistory } from 'react-router';
import styles from './WithdrawalCompletedPage.module.css';

const WithdrawalCompletedPage: VFC = () => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.message}>退会が完了しました</div>
      <div className={styles.button}>
        <Button
          type="primary"
          size="large"
          text="OK"
          onClick={() => {
            history.push('/');
          }}
        />
      </div>
    </div>
  );
};

export default WithdrawalCompletedPage;
