import { isErrorDTO } from 'data/dto/error-dto';
import queryClient from 'global/query-client';
import Result, { Failure, InProgress, Success } from 'global/utilities/result';
import { useRef } from 'react';
import ProfileRepository, {
  ProfileRepositoryImpl,
} from '../../../profile/data/repositories/profile-repository';
import UserInputItem from '../../../profile/user-input-item';
import ProfileViewData, {
  ProfileMultiplePageViewData,
} from '../../../profile/view-data/profile-view-data';
import {
  PROFILE_CACHE_KEY,
  USER_PROFILE_BASIC_CACHE_KEY,
  USER_PROFILE_DETAIL_CACHE_KEY,
} from './constants';

type ReturnType = {
  fetchResult: Result<ProfileMultiplePageViewData, Error>;
  submitErrorMessage?: string;
  submitted: () => void;
};

const getProfileConfirmViewData = (): ProfileViewData | undefined => {
  const cachedProfile: ProfileViewData | undefined =
    queryClient.getQueryData(PROFILE_CACHE_KEY);

  if (cachedProfile == null) {
    return undefined;
  }

  return cachedProfile;
};

const getUserInputItems = (): UserInputItem[] | undefined => {
  const userProfileBasic: UserInputItem[] | undefined =
    queryClient.getQueryData(USER_PROFILE_BASIC_CACHE_KEY);
  const userProfileDetail: UserInputItem[] | undefined =
    queryClient.getQueryData(USER_PROFILE_DETAIL_CACHE_KEY);

  const userInputItems: UserInputItem[] = [];

  const rejectEmptySelection = (value: UserInputItem): UserInputItem => {
    if (Array.isArray(value.value)) {
      const notEmptyValues = value.value.filter((x) => x.length > 0);

      return { name: value.name, value: notEmptyValues };
    }

    return value;
  };

  if (userProfileBasic && userProfileDetail) {
    const basic = userProfileBasic.map(rejectEmptySelection);
    const detail = userProfileDetail.map(rejectEmptySelection);

    return userInputItems.concat(basic, detail);
  }

  return undefined;
};

const getProfileConfirmResult = (
  fetchResult: Result<ProfileViewData, Error>,
): Result<ProfileMultiplePageViewData, Error> => {
  let result: Result<ProfileMultiplePageViewData, Error>;

  if (fetchResult.isSuccess()) {
    const detailProfile = fetchResult.value.pages;
    const userInputItems = getUserInputItems();

    if (detailProfile && userInputItems) {
      const filteredDetailProfile = detailProfile.filter((page) =>
        ['profile_basic_info', 'profile_detail'].includes(page.key),
      );

      const viewData: ProfileMultiplePageViewData = {
        profileViewData: filteredDetailProfile,
        userInputItems,
      };

      result = new Success(viewData);
    } else {
      result = new Failure(new Error('データの取得に失敗しました'));
    }
  } else if (fetchResult.isFailure()) {
    result = new Failure(fetchResult.error);
  } else {
    result = new InProgress();
  }

  return result;
};

type SaveSuccessMessage = string;

const useProfileConfirm = (
  repository: ProfileRepository = new ProfileRepositoryImpl(),
  saveResult: (result: Result<SaveSuccessMessage, Error>) => void,
): ReturnType => {
  const submitErrorMessage = useRef<string | undefined>(undefined);

  const profileDetail = getProfileConfirmViewData();
  let result: Result<ProfileViewData, Error>;
  if (profileDetail == null) {
    result = new Failure(new Error('データの取得に失敗しました'));
  } else {
    result = new Success(profileDetail);
  }

  const fetchResult = getProfileConfirmResult(result);

  const removeUnansweredItem = (
    userInputItems: UserInputItem[],
  ): UserInputItem[] => {
    const filteredResult = userInputItems
      .filter((item) => item.value != null && item.value.length > 0)
      .filter((item) => item.name !== 'year')
      .filter((item) => item.name !== 'month')
      .filter((item) => item.name !== 'day');

    return filteredResult;
  };

  const submitted = () => {
    const userInputItems: UserInputItem[] = getUserInputItems() || [];
    const _ = repository
      .save(removeUnansweredItem(userInputItems))
      .then(() => {
        saveResult(new Success('プロフィールの登録に成功しました'));
        // プロフィール情報のキャッシュ時間を無期限にしているため、
        // 保存が成功したタイミングでキャッシュを削除する
        queryClient.removeQueries(PROFILE_CACHE_KEY);
        queryClient.removeQueries(USER_PROFILE_BASIC_CACHE_KEY);
        queryClient.removeQueries(USER_PROFILE_DETAIL_CACHE_KEY);
      })
      .catch((error) => {
        submitErrorMessage.current = isErrorDTO(error)
          ? error.error.message
          : 'プロフィールの登録に失敗しました';
        saveResult(new Failure(Error('プロフィールの登録に失敗しました')));
      });
  };

  return {
    fetchResult,
    submitErrorMessage: submitErrorMessage.current,
    submitted,
  };
};

export default useProfileConfirm;
