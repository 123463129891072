import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import UserRankBonusesDto from '../dto/user-rank-bonuses-dto';

const createUserRankBonusesRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/rank_bonuses',
  },
  method: 'patch',
  parameters: {},
});

interface UserRankBonusesRepository {
  fetch: () => Promise<UserRankBonusesDto>;
}

export class UserRankBonusesRepositoryImpl
  implements UserRankBonusesRepository
{
  fetch = async (): Promise<UserRankBonusesDto> =>
    new ApiClient().connect(createUserRankBonusesRequestConfig());
}

export default UserRankBonusesRepository;
