import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import OfferRepository, {
  OfferRepositoryImpl,
} from 'features/offer/data/repositories/offer-repository';
import OfferEventRepository, {
  OfferEventRepositoryImpl,
  OfferEvent,
} from 'features/offer/data/repositories/offer-event-repository';
import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import requestWebCommand, {
  OpenExternalWeb,
} from 'global/utilities/web-command';
import IntegrationCodeRepository, {
  IntegrationCodeRepositoryImpl,
  IntegrationCodeSeed,
} from 'features/integration-code/data/repositories/integration-code-repository';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { DialogButton } from 'global/components/dialog/Dialog';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import QuestionnaireOfferDetailViewData from '../view-data/questionnaire-offer-detail-view-data';
import QuestionnaireOfferDto from '../data/dto/questionnaire-offer-dto';

type ReturnType = {
  fetchResult: Result<QuestionnaireOfferDetailViewData, Error>;
  linkButtonTapped: (
    offerDetailViewData: QuestionnaireOfferDetailViewData,
  ) => void;
  linkButtonTappedAction: DialogActionType | undefined;
  offerOpenEventFired: (
    offerDetailViewData: QuestionnaireOfferDetailViewData,
  ) => void;
  offerOpenEventFiredAction: DialogActionType | undefined;
};

type DialogActionType = {
  title?: string;
  description?: string;
  primaryButton?: DialogButton;
  onClose?: () => void;
};

type SaveSuccessMessage = string;

const convertDtoToViewData = (
  dto: QuestionnaireOfferDto,
): QuestionnaireOfferDetailViewData => {
  const convertedScheduledDeliveryEnd = dto.scheduledDeliveryEndDatetime
    .setZone('Asia/Tokyo')
    .toFormat('yyyy/M/d HH:mm');

  return {
    ...dto,
    termDescription: `${convertedScheduledDeliveryEnd} まで`,
  };
};

const useQuestionnaireOfferDetail = (
  offerId: string,
  repository: OfferRepository = new OfferRepositoryImpl(),
  offerOpenEventFiredSaveResult: (
    result: Result<SaveSuccessMessage, Error>,
  ) => void,
  linkButtonTappedSaveResult: (
    result: Result<SaveSuccessMessage, Error>,
  ) => void,
): ReturnType => {
  const history = useHistory();

  const linkButtonTappedAction =
    useRef<DialogActionType | undefined>(undefined);
  const offerOpenEventFiredAction =
    useRef<DialogActionType | undefined>(undefined);

  const queryResult = useQuery<QuestionnaireOfferDetailViewData, Error>(
    ['/users/me/offers/questionnaire_offers', offerId],
    async () => {
      const dto = await repository
        .fetchQuestionnaireOffer(offerId)
        .catch((error) => {
          if (isErrorDTO(error) && error.error.status === 'NOT_FOUND') {
            throw Error(
              '該当オファーが見つかりません。存在しないか、掲載期限を過ぎている、既に達成済のオファーである可能性があります。',
            );
          } else if (isErrorDTO(error)) {
            throw Error(error.error.message);
          }
          throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
        });

      return convertDtoToViewData(dto);
    },
  );

  const fetchResult = createResult(queryResult);

  const linkButtonTapped = (
    offerDetailViewData: QuestionnaireOfferDetailViewData,
    integrationCodeRepository: IntegrationCodeRepository = new IntegrationCodeRepositoryImpl(),
  ) => {
    const seed: IntegrationCodeSeed = {
      pid: offerDetailViewData.pointId,
      oid: offerDetailViewData.offerId,
      on: offerDetailViewData.title,
      dt: DateTime.now().toMillis(),
    };

    const _ = integrationCodeRepository
      .generateIntegrationCode(seed)
      .then((value) => {
        requestWebCommand(
          new OpenExternalWeb(
            `${offerDetailViewData.answeringQuestionnaireUrl}/${value.code}`,
          ),
        );
        linkButtonTappedSaveResult(
          new Success('アンケート回答情報の取得に成功しました'),
        );
      })
      .catch((error) => {
        linkButtonTappedAction.current = {
          title: 'エラー',
          description: isErrorDTO(error)
            ? error.error.message
            : 'アンケート回答情報の取得に失敗しました',
        };
        linkButtonTappedSaveResult(
          new Failure(Error('アンケート回答情報の取得に失敗しました')),
        );
      });
  };

  const offerOpenEventFired = (
    offerDetailViewData: QuestionnaireOfferDetailViewData,
    offerEventRepository: OfferEventRepository = new OfferEventRepositoryImpl(),
  ) => {
    if (offerDetailViewData.isOfferOpened) return;

    const eventType: OfferEvent = OfferEvent.QUESTIONNAIRE_OFFER_OPEN_EVENT;
    const _ = offerEventRepository
      .createEvent(offerId, eventType)
      .then(() => {
        offerOpenEventFiredSaveResult(
          new Success('オファーの開封に成功しました'),
        );
      })
      .catch(() => {
        offerOpenEventFiredAction.current = {
          title: 'エラー',
          description:
            'オファーを開封できませんでした。恐れ入りますが再度お試しください。',
          primaryButton: {
            text: 'オファ一覧に戻る',
            onClick: () => history.push('/offers'),
          },
          onClose: () => history.go(0), // reload
        };
        offerOpenEventFiredSaveResult(
          new Failure(Error('オファーを開封できませんでした')),
        );
      });
  };

  return {
    fetchResult,
    linkButtonTapped,
    linkButtonTappedAction: linkButtonTappedAction.current,
    offerOpenEventFired,
    offerOpenEventFiredAction: offerOpenEventFiredAction.current,
  };
};

export default useQuestionnaireOfferDetail;
