import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import NoticeTopContainer from 'features/notice/containers/index';
import NoticeDetailContainer from 'features/notice/containers/show';

const NoticeRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`}>
      <NoticeTopContainer />
    </Route>
    <Route exact path={`${match.path}/detail/:noticeId`}>
      <NoticeDetailContainer />
    </Route>
  </Switch>
);

export default NoticeRouter;
