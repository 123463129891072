import { VFC } from 'react';
import { useHistory } from 'react-router';
import TermsOfUseAndPrivacyPolicyPage from 'global/pages/terms-of-use-and-privacy-policy/TermsOfUseAndPrivacyPolicyPage';
import Loading from 'global/components/Loading/Loading';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import TopContainer from 'global/components/TopContainer/TopContainer';
import ProgressBar from 'features/sign-up/components/ProgressBar/ProgressBar';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import useTermsOfUse from '../hooks/use-terms-of-use';
import { TermsOfUseRepositoryImpl } from '../data/repositories/terms-of-use-repository';
import { UserRepositoryImpl } from '../data/repositories/user-repository';

const TermsOfUseContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, onClose } = useDialog();
  useNavigationBarItemsSetting('利用規約', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const { fetchResult, submitErrorMessage, submitted } = useTermsOfUse(
    new TermsOfUseRepositoryImpl(),
    new UserRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        history.push('/sign_up/privacy_policy');
      }
      if (saveResult.isFailure()) {
        requestShowing();
      }
    },
  );

  if (fetchResult.isSuccess()) {
    return (
      <>
        <Dialog
          title="エラー"
          description={submitErrorMessage}
          primaryButton={{ text: 'OK' }}
          isOpen={isOpen}
          onClose={onClose}
        />

        {/* TermsOfUseAndPrivacyPolicyPageは初回登録以外でも呼ばれる可能性があるためここで初回登録用のプログレスバーを設置 */}
        <TopContainer>
          <ProgressBar max={9} value={1} />
        </TopContainer>
        {/* これ以上style長くなりそうならcssファイルの切り出し検討する */}
        <div style={{ paddingTop: 80 }}>
          <TermsOfUseAndPrivacyPolicyPage
            name="利用規約"
            content={fetchResult.value.content}
            submitted={submitted}
          />
        </div>
      </>
    );
  }

  if (fetchResult.isFailure())
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;

  return <Loading />;
};

export default TermsOfUseContainer;
