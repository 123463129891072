import { VFC, useRef, useCallback, useEffect } from 'react';
import Container from 'global/components/Container/Container';
import Button from 'global/components/button/Button';
import QuestionnaireOfferDetailViewData from '../view-data/questionnaire-offer-detail-view-data';
import styles from './QuestionnaireOfferDetailPage.module.css';
import QuestionnaireCaution from '../components/QuestionnaireCaution';
// import Reference from '../components/Reference';
import DetailItem from '../components/DetailItem';
import PointNotice from '../components/PointNotice';
import DetailIntro from '../components/DetailIntro';

type Props = {
  offerDetailViewData: QuestionnaireOfferDetailViewData;
  linkButtonTapped: (
    offerDetailViewData: QuestionnaireOfferDetailViewData,
  ) => void;
  offerOpenEventFired: (
    offerDetailViewData: QuestionnaireOfferDetailViewData,
  ) => void;
};

const QuestionnaireOfferDetailPage: VFC<Props> = ({
  offerDetailViewData,
  linkButtonTapped,
  offerOpenEventFired,
}) => {
  const pageTopRef = useRef<HTMLDivElement>(null);
  const scrollToTop = useCallback(() => {
    pageTopRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'start',
    });
  }, []);
  useEffect(() => {
    scrollToTop();
    offerOpenEventFired(offerDetailViewData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToTop]);
  const linkButtonTappedAction = () => {
    linkButtonTapped(offerDetailViewData);
  };

  return (
    <>
      <Container>
        <div className={styles.container} ref={pageTopRef}>
          <img
            src={offerDetailViewData.mainImageAccessKey}
            alt="main"
            onError={(e) => {
              e.currentTarget.style.display = 'none';
            }}
          />
        </div>
      </Container>
      <Container marginTop="m" marginBottom="s" marginLeft="s" marginRight="s">
        <DetailIntro
          offerTitle={offerDetailViewData.title}
          companyName={offerDetailViewData.companyName}
          termDescription={offerDetailViewData.termDescription}
        />
      </Container>
      <PointNotice
        pointReason="アンケートに答えて"
        // NOTE: システム基盤側に登録された獲得pt数は実際の獲得数と合致する、かつ変更の可能性がない前提で表示pt数にawardPointを使用
        pointDetail={`+${offerDetailViewData.awardPoint}pt 獲得！`}
      />
      <Container marginTop="s" marginBottom="s" marginLeft="s" marginRight="s">
        <DetailItem
          headline={offerDetailViewData.headline1}
          bodyText={offerDetailViewData.bodyText1}
        />
      </Container>
      <Container
        marginTop="l"
        marginBottom="xxl"
        marginLeft="s"
        marginRight="s"
      >
        <Button
          text="アンケートに答える"
          type="primary"
          size="large"
          onClick={linkButtonTappedAction}
          iconType="external"
        />
      </Container>
      <QuestionnaireCaution text={offerDetailViewData.caution} />
      {/* 4/6ローンチ時点ではこの部分を出したくないとのことでいったん非表示にする<Reference
        companyName={offerDetailViewData.companyName}
        deliveryTargetConditions={offerDetailViewData.deliveryTargetConditions}
      /> */}
    </>
  );
};

export default QuestionnaireOfferDetailPage;
