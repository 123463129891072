/* eslint-disable react/jsx-props-no-spreading */
import { VFC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Container from 'global/components/Container/Container';
import Button from 'global/components/button/Button';
import AuthCodeFormContainer from 'global/components/AuthCodeFormContainer/AuthCodeFormContainer';
import UndraggableLink from 'global/components/UndraggableLink/UndraggableLink';
import styles from './EmailResettingsAuthenticatonPage.module.css';

type Props = {
  submitted: (emailAuthCode: number) => void;
  email: string;
};

type FormData = {
  emailAuthCode: number;
};

const EmailResettingsAuthenticationPage: VFC<Props> = ({
  submitted,
  email,
}) => {
  const methods = useForm<FormData>({ mode: 'onBlur' });

  const onSubmit = (data: FormData) => {
    submitted(data.emailAuthCode);
  };

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <p className={styles.topMessage}>
          登録いただいたメールアドレス(
          <span className={styles.highlightText}>{email}</span>
          )に認証コードを送信しました。
          <br />
          メールにて送付した6桁の認証コードを入力してください。
        </p>
        <FormProvider {...methods}>
          <Container marginTop="xxl">
            <AuthCodeFormContainer name="emailAuthCode" />
            <p className={styles.errorMessage}>
              {methods.formState.errors.emailAuthCode?.message}
            </p>
          </Container>
        </FormProvider>
        <Container marginTop="xxl">
          <p className={styles.text}>
            メールが届かない場合は、自動的に振り分けられている可能性がありますので、一度、迷惑メールフォルダをご確認ください。
          </p>
        </Container>

        <Container marginTop="3xs">
          <p className={styles.text}>
            上記に当てはまらずメールが届かない場合や、認証コードの有効期限が切れた場合は
            「
            <UndraggableLink
              className={styles.highlightText}
              to="/email_resettings"
            >
              メールアドレス登録
            </UndraggableLink>
            」にて再送信してください。
            <br />
            お使いのメールサービスの設定画面より「fitstats-dnp.com」ドメインを受信できる設定になっているかをご確認ください。
          </p>
        </Container>
      </Container>

      <Container marginTop="4xl" marginLeft="s" marginRight="s">
        <Button
          text="設定"
          type="primary"
          size="large"
          disabled={!methods.formState.isDirty || !methods.formState.isValid}
          onClick={methods.handleSubmit(onSubmit)}
        />
      </Container>
    </>
  );
};

export default EmailResettingsAuthenticationPage;
