import { Divider, Flex } from '@chakra-ui/react';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import Button from 'global/components/button/Button';
import Container from 'global/components/Container/Container';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import NoticeContainer from 'global/components/NoticeContainer/NoticeContainer';
import ToggleSwitch from 'global/components/toggle-switch/ToggleSwitch';
import { createRef, RefObject, useState, VFC } from 'react';
import TopContainer from 'global/components/TopContainer/TopContainer';
import { TutorialLocationState } from 'features/tutorial/hooks/use-tutorial-navigation';
import {
  HELP_DETAIL_OF_LIFELOG,
  OpenHelpPage,
} from 'global/utilities/open-help-page';
import PersonalDataViewData, {
  ConsentElement,
  StorageViewData,
} from '../view-data/personal-page-view-data';
import styles from './PersonalData.module.css';

type SectionReference = {
  identifier: string;
  ref: RefObject<HTMLDivElement>;
};

type Props = {
  personalData: PersonalDataViewData;
  storageData: StorageViewData;
  closeButtonTapped: () => void;
  didConsentChanged: (element: ConsentElement) => void;
  saveButtonTapped: () => void;
  locationState?: TutorialLocationState | undefined;
};

const PersonalDataPage: VFC<Props> = ({
  personalData,
  storageData,
  closeButtonTapped,
  didConsentChanged,
  saveButtonTapped,
  locationState,
}) => {
  const { isOpen, requestShowing, onClose } = useDialog();

  // NOTE: カテゴリメニューは別コンポーネントに持ち、選択状態もそのコンポーネントに持たせるのが望ましい
  const [selectedItem, setSelectedItem] = useState('');

  const sectionRefs = personalData.sections.map(
    (section): SectionReference => ({
      identifier: section.title,
      ref: createRef<HTMLDivElement>(),
    }),
  );

  const scrollToSection = (identifier: string) => {
    const ref = sectionRefs.find(
      (sectionRef) => sectionRef.identifier === identifier,
    );
    if (ref?.ref.current == null) {
      return;
    }
    ref.ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const sectionListItemTapped = (identifier: string) => {
    setSelectedItem(identifier);
    scrollToSection(identifier);
  };

  const valueForDisplay = (value: string | undefined): string => {
    if (value == null) {
      return '';
    }

    return value.length > 0 ? value : '未登録';
  };

  return (
    <>
      <TopContainer>
        <div className={styles.sectionList}>
          {personalData.sections.map((section) => (
            <button
              type="button"
              className={[
                styles.sectionListItem,
                selectedItem === section.title
                  ? styles.sectionListItemChecked
                  : '',
              ].join(' ')}
              onClick={() => sectionListItemTapped(section.title)}
            >
              {section.title}
            </button>
          ))}
        </div>
      </TopContainer>

      <div className={styles.personalDataContainer}>
        {!locationState?.isTutorial &&
          storageData.isCloseButtonTapped !== true && (
            <Container
              marginTop="s"
              marginBottom="s"
              marginLeft="s"
              marginRight="s"
            >
              <NoticeContainer closeButtonCustomAction={closeButtonTapped}>
                ここで「公開」を選択したデータが、企業に公開されます。
                <br />
                公開するデータが多いほど、よりあなたにマッチした多彩なオファーが届きます。
              </NoticeContainer>
            </Container>
          )}
        {locationState?.isTutorial && (
          <div id="tutorial-mydata-personal">
            <Container marginTop="s" marginBottom="s">
              <div
                className={[
                  styles.sectionWrapper,
                  styles.sectionWrapperHighlight,
                ].join(' ')}
              >
                <div
                  id="all-items"
                  className={[
                    styles.sectionTitle,
                    styles.sectionTitleHighlight,
                  ].join(' ')}
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    推奨：登録したデータを全て公開選択する
                    <ToggleSwitch
                      onOffText={{ onText: '公開', offText: '非公開' }}
                      isOn={personalData.sections.every((section) =>
                        section.items.every((item) => item.consent),
                      )}
                      onClick={(isChecked) =>
                        didConsentChanged({
                          type: 'all',
                          title: 'all-items',
                          isChecked,
                        })
                      }
                    />
                  </Flex>
                </div>
              </div>
            </Container>
          </div>
        )}

        {personalData.sections.map((section) => (
          <>
            <div className={styles.sectionWrapper}>
              <div
                id={section.title}
                className={styles.sectionTitle}
                ref={
                  sectionRefs.find(
                    (sectionRef) => sectionRef.identifier === section.title,
                  )?.ref
                }
              >
                <Flex alignItems="center" justifyContent="space-between">
                  {section.title}
                  <Flex alignItems="center" justifyContent="space-between">
                    <div className={styles.toggleSwitchText}>一括選択</div>
                    <ToggleSwitch
                      onOffText={{ onText: '公開', offText: '非公開' }}
                      isOn={section.items.every((item) => item.consent)}
                      onClick={(isChecked) =>
                        didConsentChanged({
                          type: 'section',
                          title: section.title,
                          isChecked,
                        })
                      }
                    />
                  </Flex>
                </Flex>
              </div>
            </div>
            {section.items.map((item) => {
              const titleAndValue =
                // データ編集の対象でない「総合スタッツ」などの項目は値の表示領域をタイトルの表示に使うことで折返しを防ぐ
                // これらの項目はvalueがundefinedで、編集対象だが未入力の項目は''なので、これを利用して判別する
                typeof item.value === 'string' ? (
                  <>
                    <div className={styles.itemTitle}>{item.title}</div>
                    <div
                      className={[
                        styles.item,
                        item.value != null &&
                          item.value.length === 0 &&
                          styles.noItem,
                      ].join(' ')}
                    >
                      {valueForDisplay(item.value)}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.itemTitleLong}>{item.title}</div>
                  </>
                );

              return (
                <>
                  <div className={styles.itemWrapper}>
                    <Flex alignItems="center" justifyContent="space-between">
                      {titleAndValue}
                      <ToggleSwitch
                        onOffText={{ onText: '公開', offText: '非公開' }}
                        isOn={item.consent}
                        onClick={(isChecked) =>
                          didConsentChanged({
                            type: 'item',
                            title: item.title,
                            isChecked,
                          })
                        }
                      />
                    </Flex>
                  </div>
                  <div className={styles.dividerWrapper}>
                    <Divider orientation="horizontal" />
                  </div>
                </>
              );
            })}
          </>
        ))}
        <BottomContainer>
          <Divider orientation="horizontal" />
          <Container
            marginTop="s"
            marginBottom="s"
            marginLeft="s"
            marginRight="s"
          >
            <Button
              text={
                locationState?.isTutorial
                  ? '公開するデータを確定する'
                  : '更新する'
              }
              type="primary"
              size="large"
              onClick={requestShowing}
            />
            {!locationState?.isTutorial && (
              <div className={styles.lifelogHelpWrapper}>
                <button
                  type="button"
                  className={styles.lifelogHelpLink}
                  onClick={() => OpenHelpPage(HELP_DETAIL_OF_LIFELOG)}
                >
                  「ライフログ」の詳細を見る
                </button>
              </div>
            )}
          </Container>
        </BottomContainer>
      </div>
      <Dialog
        title="確認画面"
        description="この画面で公開選択した内容が、「データ公開する企業の選択」画面で「公開」を選択している企業に公開されます。"
        primaryButton={{
          text: '更新する',
          onClick: saveButtonTapped,
        }}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default PersonalDataPage;
