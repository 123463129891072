import { VFC } from 'react';
import { Spinner } from '@chakra-ui/react';
import styles from './Loading.module.css';

const Loading: VFC = () => (
  <div className={styles.spinnerWrapper}>
    <Spinner color="fitstats.primary.50" />
  </div>
);

export default Loading;
