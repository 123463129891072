import { useState, VFC } from 'react';
import styles from 'global/components/ClientEnvIdentifier/ClientEnvIdentifier.module.css';

export type Props = {
  clientEnv: string;
};

const backgroundColor = (clientEnv: string): string => {
  switch (clientEnv) {
    case 'development':
      return 'green';
    case 'staging':
      return 'deeppink';
    case 'dnp-staging':
      return 'blue';
    default:
      return '';
  }
};

const ClientEnvIdentifier: VFC<Props> = ({ clientEnv }) => {
  const [currentButtonTapped, setButtonTapped] = useState(false);

  return clientEnv === 'production' ? (
    <></>
  ) : (
    <button
      type="button"
      className={styles.ribbonWrapper}
      onClick={() => setButtonTapped(true)}
      style={{
        display: currentButtonTapped ? 'none' : 'block',
      }}
    >
      <span
        className={styles.ribbonText}
        style={{
          backgroundColor: backgroundColor(clientEnv),
        }}
      >
        {clientEnv}
      </span>
    </button>
  );
};

export default ClientEnvIdentifier;
