import AuthorizationRepository from 'features/sign-up/privacy-policy/data/repositories/authorization-repository';

type ReturnType = {
  authorize: () => Promise<string>;
};

const useAuthorize = (): ReturnType => {
  const authorizationRepository = new AuthorizationRepository();

  const authorize = async () => {
    const authorizationResult = await authorizationRepository.authorize();

    return authorizationResult.redirectUrl;
  };

  return { authorize };
};

export default useAuthorize;
