import { VFC } from 'react';
import Result from 'global/utilities/result';
import Container from 'global/components/Container/Container';
import Loading from 'global/components/Loading/Loading';
import TopDailyScoresViewData from '../view-data/top-daily-scores-view-data';
import styles from './TopDailyScores.module.css';
import TopDailyScoresChart from './TopDailyScoresChart';

type Props = {
  dailyScoresFetchResult: Result<TopDailyScoresViewData, Error>;
  prevButtonTapped: () => void;
  nextButtonTapped: () => void;
};

const TopDailyScores: VFC<Props> = ({
  dailyScoresFetchResult,
  prevButtonTapped,
  nextButtonTapped,
}) => (
  <>
    <div className={styles.sectionTitle}>ライフスタッツ推移</div>
    {dailyScoresFetchResult.isSuccess() && (
      <>
        <Container
          marginTop="s"
          marginBottom="s"
          marginLeft={14}
          marginRight={14}
        >
          <div className={styles.lineChartPeriodWapper}>
            <button
              type="button"
              onClick={prevButtonTapped}
              disabled={!dailyScoresFetchResult.value.hasPrev}
              className={styles.lineChartPeriodBtnWrapper}
            >
              <div
                className={[
                  styles.lineChartPeriodBtnPrev,
                  dailyScoresFetchResult.value.hasPrev
                    ? styles.lineChartPeriodBtnActive
                    : styles.lineChartPeriodBtnInActive,
                ].join(' ')}
              />
            </button>
            <div className={styles.lineChartPeriod}>
              {dailyScoresFetchResult.value.startDate}
              <span className={styles.lineChartPeriodSeparation}>~</span>
              {dailyScoresFetchResult.value.endDate}
            </div>
            <button
              type="button"
              onClick={nextButtonTapped}
              disabled={!dailyScoresFetchResult.value.hasNext}
              className={styles.lineChartPeriodBtnWrapper}
            >
              <div
                className={[
                  styles.lineChartPeriodBtnNext,
                  dailyScoresFetchResult.value.hasNext
                    ? styles.lineChartPeriodBtnActive
                    : styles.lineChartPeriodBtnInActive,
                ].join(' ')}
              />
            </button>
          </div>
        </Container>

        <TopDailyScoresChart chartData={dailyScoresFetchResult.value} />
      </>
    )}
    {dailyScoresFetchResult.isFailure() && (
      <p>{dailyScoresFetchResult.error.message}</p>
    )}
    {dailyScoresFetchResult.isInProgress() && <Loading />}
  </>
);
export default TopDailyScores;
