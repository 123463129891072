import { useQuery } from 'react-query';
import TermsOfUseRepository, {
  TermsOfUseRepositoryImpl,
} from 'features/terms-and-privacy-policy/terms-of-use/data/repositories/terms-of-use-repository';
import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import queryClient from 'global/query-client';
import createResult from 'global/utilities/create-result-from-query-result';
import TermsOfUseAndPrivacyPolicyViewData from 'global/view-data/terms-of-use-and-privacy-policy-view-data';
import { useRef } from 'react';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import convertDtoToTermsOfUseAndPrivacyPolicyViewData from '../../utils/convert-dto-to-terms-of-use-and-privacy-policy-view-data';

type ReturnType = {
  fetchResult: Result<TermsOfUseAndPrivacyPolicyViewData, Error>;
  submitErrorMessage?: string;
  submitted: () => void;
};

const TERMS_OF_USE_KEY = '/terms-of-use';

const cachedTermsOfUseViewData = ():
  | TermsOfUseAndPrivacyPolicyViewData
  | undefined => queryClient.getQueryData([TERMS_OF_USE_KEY]);

const useTermsOfUse = (
  repository: TermsOfUseRepository = new TermsOfUseRepositoryImpl(),
  saveResult: (result: Result<void, void>) => void,
): ReturnType => {
  const submitErrorMessage = useRef<string | undefined>(undefined);
  const queryResult = useQuery<TermsOfUseAndPrivacyPolicyViewData, Error>(
    [TERMS_OF_USE_KEY],
    async () => {
      const dto = await repository.fetch().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertDtoToTermsOfUseAndPrivacyPolicyViewData(dto);
    },
  );

  const fetchResult = createResult(queryResult);

  const submitted = async () => {
    const docVersion = cachedTermsOfUseViewData()?.docVersion;
    if (!docVersion) {
      throw Error('利用規約の取得に失敗しました');
    }
    try {
      await repository.save(docVersion);
      saveResult(new Success(undefined));
    } catch (error) {
      submitErrorMessage.current = isErrorDTO(error)
        ? error.error.message
        : '利用規約の取得に失敗しました';
      saveResult(new Failure(undefined));
    }
  };

  return {
    fetchResult,
    submitErrorMessage: submitErrorMessage.current,
    submitted,
  };
};

export default useTermsOfUse;
