import { extendTheme } from '@chakra-ui/react';

// https://chakra-ui.com/docs/theming/customize-theme#customizing-global-styles
const theme = extendTheme({
  styles: {
    global: {
      body: {
        color: 'black',
        fontFamily: 'Hiragino Sans',
        transitionProperty: 'all',
        transitionDuration: '0s',
        lineHeight: 'normal',
      },
    },
  },
  components: {
    Divider: {
      baseStyle: {
        borderColor: '#ecf0f7',
        opacity: 1,
      },
    },
  },
  colors: {
    fitstats: {
      primary: {
        50: 'rgba(0, 157, 133, 0.5)',
      },
    },
  },
});

export default theme;
