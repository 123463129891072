import { VFC } from 'react';
import { Divider } from '@chakra-ui/react';
import {
  SettingsCategory,
  SettingsContentsViewData,
} from '../view-data/settings-root-view-data';
import styles from './SettingsContentsListCell.module.css';

type Props = {
  viewData: SettingsContentsViewData;
  onClick: (category: SettingsCategory) => void;
};

const SettingsContentsListCell: VFC<Props> = ({ viewData, onClick }) => (
  <>
    <button
      className={styles.button}
      type="button"
      onClick={() => onClick(viewData.category)}
    >
      <div className={styles.title}>{viewData.name}</div>
      <div className={styles.rightArrow}>
        <img src="/settings/icon-right-arrow.svg" alt="" />
      </div>
    </button>
    <Divider orientation="horizontal" />
  </>
);

export default SettingsContentsListCell;
