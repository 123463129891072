import ToggleSwitch from 'global/components/toggle-switch/ToggleSwitch';
import { VFC } from 'react';
import { PushNotificationCategorySettingsViewData } from '../view-data/push-notification-settings-view-data';
import styles from './PushNotificationSettingsListCell.module.css';

type Props = {
  viewData: PushNotificationCategorySettingsViewData;
  onClick: (code: string, accepted: boolean) => void;
};

const PushNotificationSettingsListCell: VFC<Props> = ({
  viewData,
  onClick,
}) => (
  <div className={styles.container}>
    <div className={styles.name}>{viewData.name}</div>
    <ToggleSwitch
      onOffText={{ onText: 'ON', offText: 'OFF' }}
      isOn={viewData.accepted}
      onClick={(isOn) => onClick(viewData.code, isOn)}
    />
  </div>
);

export default PushNotificationSettingsListCell;
