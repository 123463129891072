import { useRef, VFC } from 'react';
import { useForm } from 'react-hook-form';
import Container from 'global/components/Container/Container';
import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import InputText from 'global/components/InputText/InputText';
import Button from 'global/components/button/Button';
import styles from './InputEmailPage.module.css';

type Props = {
  submitted: (email: string) => void;
};

type FormData = {
  email: string;
};

const InputEmailPage: VFC<Props> = ({ submitted }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<FormData>({ mode: 'onChange' });

  const processing = useRef(false);
  const onSubmit = (data: FormData) => {
    // TODO: 今後のことを考えると専用のコンポーネントにするのが望ましい
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;

    // 処理中フラグを上げる
    processing.current = true;

    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 3000);

    submitted(data.email);
  };

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <div className={styles.body}>
          <p className={styles.topMessage}>メールアドレスの入力</p>
          <p className={styles.message}>
            メールアドレスを再設定します。
            <br />
            あなたが登録しているメールアドレスを入力してください。
          </p>
          <Container marginTop="m">
            <FormContainer>
              {[
                <FormTitle size="large" text="登録しているメールアドレス" />,
                <InputText
                  name="email"
                  placeholder="example@stats.com"
                  size="large"
                  register={register('email', {
                    required: 'メールアドレスは入力必須の項目です。',
                    pattern: {
                      value: /.+@.+/,
                      message: '正しいメールアドレスを入力してください。',
                    },
                    maxLength: {
                      value: 50,
                      message: '50文字以下で入力してください。',
                    },
                  })}
                />,
              ]}
            </FormContainer>
            <p className={styles.errorMessage}>{errors?.email?.message}</p>
          </Container>
        </div>
      </Container>
      <Container marginTop="4xl" marginLeft="s" marginRight="s">
        <Button
          text="通知する"
          type="primary"
          size="large"
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(onSubmit)}
        />
      </Container>
    </>
  );
};

export default InputEmailPage;
