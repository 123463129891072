import { useRef, VFC } from 'react';
import { useHistory } from 'react-router';
import RegistrationPage from 'features/sign-up/email/registration/pages/EmailRegistrationPage';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import TopContainer from 'global/components/TopContainer/TopContainer';
import ErrorDTO from 'data/dto/error-dto';
import ProgressBar from 'features/sign-up/components/ProgressBar/ProgressBar';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import useEmailRegistration from '../hooks/use-email-registration';
import { EmailRegistrationRepositoryImpl } from '../data/repositories/email-registration-repository';

type DialogInfo = {
  description: string;
  primaryButtonAction: () => void;
};

const RegistrationContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, onClose } = useDialog();
  const dialogInfoRef = useRef<DialogInfo | undefined>();
  const primaryButtonActionRef = useRef<() => void>(() => onClose());

  useNavigationBarItemsSetting('メールアドレスの登録', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const isAlreadyExistsError = (error: ErrorDTO | undefined) =>
    error && error.error.status === 'ALREADY_EXISTS';
  const { submitted } = useEmailRegistration(
    new EmailRegistrationRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        history.push('/sign_up/email/authentication', {
          email: saveResult.value,
        });
      }
      if (saveResult.isFailure()) {
        const description = saveResult.error
          ? saveResult.error.error.message
          : 'メールアドレスの登録に失敗しました。';

        if (isAlreadyExistsError(saveResult.error)) {
          primaryButtonActionRef.current = () => {
            onClose();
            history.push('/sign_in');
          };
        }

        dialogInfoRef.current = {
          description,
          primaryButtonAction: primaryButtonActionRef.current,
        };

        requestShowing();
      }
    },
  );

  return (
    <>
      {dialogInfoRef.current && (
        <Dialog
          title="エラー"
          description={dialogInfoRef.current.description}
          primaryButton={{
            text: 'OK',
            onClick: dialogInfoRef.current.primaryButtonAction,
          }}
          isOpen={isOpen}
          onClose={() => undefined}
          showsCloseButton={false}
        />
      )}

      {/* RegistrationPageは初回登録以外でも呼ばれる可能性があるためここで初回登録用のプログレスバーを設置 */}
      <TopContainer>
        <ProgressBar max={8} value={3} />
      </TopContainer>
      {/* これ以上style長くなりそうならcssファイルの切り出し検討する */}
      <div style={{ paddingTop: 60 }}>
        <RegistrationPage submitted={submitted} />
      </div>
    </>
  );
};

export default RegistrationContainer;
