import { VFC } from 'react';
import styles from 'global/components/checkbox/Checkbox.module.css';

export type Props = {
  name: string;
  value: string;
  checked: boolean;
  onClick: (name: string, value: string) => void;
};

const Checkbox: VFC<Props> = ({ name, value, checked, onClick }) => (
  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  <button
    type="button"
    className={[
      styles.checkbox,
      checked ? styles.checked : styles.unchecked,
    ].join(' ')}
    onClick={() => onClick(name, value)}
  />
);
export default Checkbox;
