import { VFC } from 'react';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import { DateTime } from 'luxon';
import YearMonthDay from 'global/utilities/year-month-day';
import { useHistory } from 'react-router';
import styles from './SummaryBoard.module.css';
import useCampaignSummary from '../hooks/use-campaign-summary';

type Props = { onTop: boolean };

const SummaryBoard: VFC<Props> = ({ onTop }) => {
  const history = useHistory();

  const {
    summaryBoardVisibleOnTop,
    hideSummaryBoadFromTop,
    checkSummaryBoardDisalbe,
    campaignSummaryResult,
  } = useCampaignSummary();
  const { isOpen, requestShowing, onClose } = useDialog();

  const hideCampaign = () => {
    const ymd = new YearMonthDay(DateTime.local());
    localStorage.setItem(
      'campaignTopBoardHidedAt',
      `${ymd.year}-${ymd.month}-${ymd.day}`,
    );
    hideSummaryBoadFromTop();
  };

  const linkToCampaign = () => {
    if (onTop) {
      history.push('/campaign');
    }
  };

  // summaryBoardVisibleOnTop: トップ画面でサマリーボードを見せるかどうか
  // トップ画面(onTop)でない場合は表示する
  const summaryBoardVisible = summaryBoardVisibleOnTop || !onTop;

  return (
    <>
      {campaignSummaryResult.isSuccess() &&
        checkSummaryBoardDisalbe(campaignSummaryResult.value)}
      {summaryBoardVisible && (
        <div>
          <Dialog
            description="登録キャンペーンの案内を消しますか？この案内は、明日再度表示されます。"
            destructiveButton={{ text: 'はい', onClick: hideCampaign }}
            primaryButton={{ text: 'いいえ' }}
            isOpen={isOpen}
            onClose={onClose}
          />
          {campaignSummaryResult.isSuccess() && (
            <div>
              {!onTop && (
                <div className={styles.topBanner}>
                  <div className={styles.topBannerBlur}>
                    <div className={styles.topBannerString}>
                      {campaignSummaryResult.value.endAt}までに
                      <br />
                      全問回答で
                      {campaignSummaryResult.value.totalPoint.toLocaleString()}
                      ptGET!
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.boardTitle}>
                全ての質問に回答すると
                {campaignSummaryResult.value.totalPoint.toLocaleString()}pt GET!
                {onTop && (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label
                  <button
                    className={styles.closeButton}
                    type="button"
                    onClick={() => requestShowing()}
                  />
                )}
              </div>
              <div
                className={styles.progressGraph}
                aria-hidden
                onClick={linkToCampaign}
              >
                <div className={styles.progressNotification}>
                  <div className={styles.progressNotificationString}>
                    <div>
                      あと{campaignSummaryResult.value.questionCountForNextStep}
                      問で
                    </div>
                    <div className={styles.progressNotificationPointString}>
                      {campaignSummaryResult.value.pointForNextStep.toLocaleString()}
                      pt
                    </div>
                    <div>GET!</div>
                  </div>
                </div>
                <div className={styles.questionTick}>
                  {campaignSummaryResult.value.steps.map((step, index) => (
                    <div
                      className={
                        index + 1 <= campaignSummaryResult.value.achievedStep
                          ? styles.questionTickItemAchieved
                          : styles.questionTickItem
                      }
                      key={step.achieveCount}
                    >
                      <div className={styles.questionTickItemNumber}>
                        {step.achieveCount}
                      </div>
                      <div>問</div>
                    </div>
                  ))}
                </div>
                <div className={styles.progressContainer}>
                  <div className={styles.progressIcon}>
                    {campaignSummaryResult.value.steps.map((step, index) =>
                      index + 1 <= campaignSummaryResult.value.achievedStep ? (
                        <div
                          className={styles.progressIconItem}
                          key={step.achieveCount}
                        />
                      ) : (
                        <div
                          className={styles.progressIconItemUndone}
                          key={step.achieveCount}
                        />
                      ),
                    )}
                  </div>
                  <div className={styles.progressBarContainer}>
                    <progress
                      className={styles.progressBar}
                      value={campaignSummaryResult.value.progress}
                      max="100"
                    />
                  </div>
                </div>
                <div className={styles.pointTick}>
                  {campaignSummaryResult.value.steps.map((step, index) =>
                    index + 1 <= campaignSummaryResult.value.achievedStep ? (
                      <div
                        className={styles.pointTickItemAchieved}
                        key={step.achieveCount}
                      >
                        達成！
                      </div>
                    ) : (
                      <div
                        className={styles.pointTickItem}
                        key={step.achieveCount}
                      >
                        {step.point}pt
                      </div>
                    ),
                  )}
                </div>
              </div>
              {!onTop && (
                <div className={styles.boardDescription}>
                  2022/6/30 までのキャンペーンは終了しました。2022/6/30 開始したキャンペーンにご参加ください。質問に多く答えることで、ライフスタッツがアップしたり、より多くのオファーが届きます。
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default SummaryBoard;
