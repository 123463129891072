import { VFC } from 'react';
import { Divider } from '@chakra-ui/react';
import {
  AcountSettingsMenu,
  AcountSettingsMenuType,
} from '../view-data/acount-settings-root-view-data';
import styles from './AcountSettingsMenuListCell.module.css';

type Props = {
  menu: AcountSettingsMenu;
  onClick: (type: AcountSettingsMenuType) => void;
};

const AcountSettingsMenuListCell: VFC<Props> = ({ menu, onClick }) => (
  <>
    <button
      className={styles.button}
      type="button"
      onClick={() => onClick(menu.type)}
    >
      <div className={styles.menuTitle}>{menu.name}</div>
      <div className={styles.rightArrow}>
        <img src="settings/icon-right-arrow.svg" alt="" />
      </div>
    </button>
    <Divider orientation="horizontal" />
  </>
);

export default AcountSettingsMenuListCell;
