import { useState, useRef } from 'react';
import {
  DialogButton,
  ImageSize,
} from '../../../../global/components/dialog/Dialog';

type UseProgramDetailDialogReturn = {
  isOpen: boolean;
  dialogTitle?: string;
  dialogDescription?: string;
  imageSrc?: string;
  imageSize?: ImageSize;
  primaryButton: DialogButton;
  destructiveButton?: DialogButton;
  requestShowing: (
    title?: string,
    description?: string,
    imageSrc?: string,
    imageSize?: ImageSize,
    primaryButton?: DialogButton,
    destructiveButton?: DialogButton,
  ) => void;
  onClose: () => void;
};
const useProgramDetailDialog = (): UseProgramDetailDialogReturn => {
  const [isOpen, setIsOpen] = useState(false);
  const title = useRef<string | undefined>(undefined);
  const description = useRef<string | undefined>(undefined);
  const imageSrc = useRef<string | undefined>(undefined);
  const imageSize = useRef<ImageSize | undefined>(undefined);
  const primaryBtn = useRef<DialogButton | undefined>(undefined);
  const destructiveBtn = useRef<DialogButton | undefined>(undefined);

  const requestShowing = (
    t?: string,
    d?: string,
    imgSrc?: string,
    imgSize?: ImageSize,
    mainButton?: DialogButton,
    secondaryButton?: DialogButton,
  ) => {
    title.current = t;
    description.current = d;
    imageSrc.current = imgSrc;
    imageSize.current = imgSize;
    primaryBtn.current = mainButton;
    destructiveBtn.current = secondaryButton;
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    dialogTitle: title.current,
    dialogDescription: description.current,
    imageSrc: imageSrc.current,
    imageSize: imageSize.current,
    primaryButton: primaryBtn.current ?? { text: 'OK' },
    destructiveButton: destructiveBtn.current,
    requestShowing,
    onClose,
  };
};

export default useProgramDetailDialog;
