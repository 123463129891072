import { useEffect, VFC } from 'react';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import requestWebCommand, {
  OpenExternalWeb,
} from 'global/utilities/web-command';
import { useHistory } from 'react-router';
import { isErrorDTO } from 'data/dto/error-dto';
import { UserRankBonusesRepositoryImpl } from '../data/repositories/user-rank-bonuses-repository';
import convertUserRankBonusesDtoToViewData from '../hooks/utils/convert-user-rank-bonuses-dto';
import { UserTermsAgreementStatusesRepositoryImpl } from '../data/repositories/user-terms-agreement-statuses-repository';
import { UserAnnouncementModalRepositoryImpl } from '../data/repositories/user-announcement-modal-repository';

const TopDisplayOrderModals: VFC = () => {
  const { isOpen, requestShowing, dialogProps, onClose } = useDialog();
  const history = useHistory();

  /* top画面表示時に利用規約 > 告知 > ランクアップ の優先順位で1つモーダルを表示する */
  useEffect(() => {
    void new UserTermsAgreementStatusesRepositoryImpl()
      .fetch()
      .then((dto) => {
        const description = (content: string): string =>
          `${content}が更新されました。詳細をご確認の上、同意をお願いします。`;

        if (dto.termsOfUse && dto.privacyPolicy) {
          requestShowing({
            description: description('利用規約および個人情報の取り扱い'),
            allowedOnClose: false,
            showCloseButton: false,
            primaryButton: {
              text: 'OK',
              onClick: () => {
                history.push('/terms_and_privacy_policy/terms_of_use', {
                  privacyPolicy: true,
                });
              }
            },
          });
        } else if (dto.termsOfUse) {
          requestShowing({
            description: description('利用規約'),
            allowedOnClose: false,
            showCloseButton: false,
            primaryButton: {
              text: 'OK',
              onClick: () => {
                history.push('/terms_and_privacy_policy/terms_of_use', {
                  privacyPolicy: false,
                });
              },
            },
          });
        } else if (dto.privacyPolicy) {
          requestShowing({
            description: description('個人情報の取り扱い'),
            allowedOnClose: false,
            showCloseButton: false,
            primaryButton: {
              text: 'OK',
              onClick: () => {
                history.push('/terms_and_privacy_policy/privacy_policy');
              },
            },
          });
        }

        if (!dto.termsOfUse || !dto.privacyPolicy) {
          void new UserAnnouncementModalRepositoryImpl()
            .fetch()
            .then((modalsDto) => {
              if (modalsDto.announcementModals?.length) {
                const modalInfo = modalsDto.announcementModals[0];
                const primaryButtonAction = () => {
                  if (modalInfo.urlType === "external") {
                    requestWebCommand(new OpenExternalWeb(modalInfo.url));
                  } else if (modalInfo.urlType === "internal") {
                    history.push(modalInfo.url);
                  }
                }
                requestShowing({
                  title: modalInfo.title,
                  description: modalInfo.description,
                  imageSrc: modalInfo.imageSrc,
                  imageSize: 'large',
                  showCloseButton: modalInfo.showClose,
                  allowedOnClose: true,
                  primaryButton: {
                    text: modalInfo.buttonText,
                    onClick: modalInfo.urlType === "nothing" ? undefined : primaryButtonAction
                  },
                  alignDescriptionLeft: true,
                });
              } else {
                void new UserRankBonusesRepositoryImpl()
                  .fetch()
                  .then((userRankBounseDto) => {
                    const viewData = convertUserRankBonusesDtoToViewData(userRankBounseDto);

                    if (viewData.userRankUpBonus) {
                      const bounseDescription = viewData.userRankUpBonus.details.reduce(
                        (desc, detail) => `${desc}\n${detail}`,
                        `ランクアップボーナスとして\nFitStatsポイントを\n${viewData.userRankUpBonus.totalPoint}pt獲得しました。\n`,
                      );
                      requestShowing({
                        title: 'ランクアップボーナス獲得！',
                        description: bounseDescription,
                        imageSrc: '/global/get_point.png',
                        imageSize: 'large',
                        allowedOnClose: true,
                        showCloseButton: true,
                        primaryButton: { text: 'OK' },
                      });
                    }
                    if (viewData.userRankKeepBonus) {
                      requestShowing({
                        title: 'Aランク維持ボーナス獲得！',
                        description: `総合ランクAを${viewData.userRankKeepBonus.days}日間維持したので、FitStatsポイントを${viewData.userRankKeepBonus.totalPoint}pt獲得しました`,
                        imageSrc: '/global/get_point.png',
                        imageSize: 'large',
                        allowedOnClose: true,
                        showCloseButton: true,
                        primaryButton: { text: 'OK' },
                      });
                    }
                  })
                  .catch((error) => {
                    const errorMessage = isErrorDTO(error)
                      ? error.error.message
                      : 'ランクボーナスのデータ取得に失敗しました';

                    requestShowing({
                      title: 'エラー',
                      description: errorMessage,
                      imageSrc: '',
                      allowedOnClose: true,
                      showCloseButton: true,
                      primaryButton: { text: 'OK' },
                    });
                  });
              }
            })
            .catch((error) => {
              const errorMessage = isErrorDTO(error)
                ? error.error.message
                : '告知情報の取得に失敗しました';

              requestShowing({
                title: 'エラー',
                description: errorMessage,
                imageSrc: '',
                allowedOnClose: true,
                showCloseButton: true,
                primaryButton: { text: 'OK' },
              });
            });
        }
      })
      .catch((error) => {
        const errorMessage = isErrorDTO(error)
          ? error.error.message
          : 'ユーザーの利用規約および個人情報の取り扱いの同意ステータスの取得に失敗しました';

        requestShowing({
          title: 'エラー',
          description: errorMessage,
          showCloseButton: false,
          allowedOnClose: false,
          primaryButton: {
            text: 'OK',
            onClick: () => {
              history.go(0);
            },
          },
        });
      });
  }, [history, requestShowing, onClose]);

  return (
    <>
      {dialogProps && (
        <Dialog
          title={dialogProps.title}
          description={dialogProps.description}
          primaryButton={dialogProps.primaryButton}
          imageSrc={dialogProps.imageSrc}
          imageSize={dialogProps.imageSize}
          isOpen={isOpen}
          showsCloseButton={dialogProps.showCloseButton}
          onClose={dialogProps.allowedOnClose ? onClose : () => undefined}
          alignDescriptionLeft={dialogProps.alignDescriptionLeft}
        />
      )}
    </>
  );
};

export default TopDisplayOrderModals;
