import { VFC } from 'react';
import Container from 'global/components/Container/Container';
import Button from 'global/components/button/Button';
import { useHistory } from 'react-router';
import UndraggableLink from 'global/components/UndraggableLink/UndraggableLink';
import styles from './NotifyEmailCompletePage.module.css';

const NotifyEmailPage: VFC = () => {
  const history = useHistory();
  const onSubmit = () => {
    history.push('/sign_in');
  };

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <p className={styles.topMessage}>メールアドレスを通知しました</p>
      </Container>
      <Container marginLeft="s" marginRight="s">
        <p className={styles.message}>
          認証コードが届かない場合は、
          <UndraggableLink
            className={styles.highlightText}
            to="/forget_email/confirm_phone_number"
          >
            「電話番号の登録」
          </UndraggableLink>
          にて再送してください。
        </p>
      </Container>

      <Container marginTop="l" marginBottom="s" marginLeft="s" marginRight="s">
        <Button
          text="ログイン画面に戻る"
          type="primary"
          size="large"
          onClick={onSubmit}
        />
      </Container>
    </>
  );
};

export default NotifyEmailPage;
