import { VFC } from 'react';
import BadgeType from '../../utilities/badge-type';
import styles from './Badge.module.css';

type Props = {
  type: BadgeType;
};

const Badge: VFC<Props> = ({ type }) => (
  <>
    {type.kind === 'unread' && <div className={styles.unreadBadge} />}
  </>
);

export default Badge;
