import { VFC } from 'react';
import { useParams } from 'react-router';
import Loading from 'global/components/Loading/Loading';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import NoticeDetailPage from '../pages/NoticeDetailPage';
import useNoticeDetail from '../hooks/use-notice-detail';

const NoticeDetailContainer: VFC = () => {
  const { noticeId } = useParams<{ noticeId: string }>();
  const { fetchResult } = useNoticeDetail(noticeId);
  useNavigationBarItemsSetting('お知らせ');

  if (fetchResult.isSuccess()) {
    return <NoticeDetailPage viewData={fetchResult.value} />;
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default NoticeDetailContainer;
