import { useQuery } from 'react-query';
import TopScoresRepository, {
  TopScoresRepositoryImpl,
} from 'features/top/data/repositories/top-scores-repository';
import { isErrorDTO } from 'data/dto/error-dto';
import Result from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { useState } from 'react';
import { DateTime } from 'luxon';
import YearMonthDay from 'global/utilities/year-month-day';
import queryClient from 'global/query-client';
import OfferTopViewData from 'features/offer/view-data/offer-top-view-data';
import OfferRepository, {
  OfferRepositoryImpl,
} from 'features/offer/data/repositories/offer-repository';
import { convertDtoToOfferTopViewData } from 'features/offer/hooks/use-offer-top';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import TopScoresViewData from '../view-data/top-scores-view-data';
import convertTopScoresDtoToViewData from './utils/convert-top-scores-dto';
import TopDailyScoresRepository, {
  TopDailyScoresRepositoryImpl,
} from '../data/repositories/top-daily-scores-repository';
import TopDailyScoresViewData from '../view-data/top-daily-scores-view-data';
import convertTopDailyScoresDtoToViewData from './utils/convert-top-daily-scores-dto';
import FilterOffersForTop from './utils/filter-offers-for-top';

const NUMBER_OF_DAYS_FOR_DAILY_SCORES = 29;

type ReturnType = {
  scoresFetchResult: Result<TopScoresViewData, Error>;
  dailyScoresFetchResult: Result<TopDailyScoresViewData, Error>;
  dailyScoresPrevButtonTapped: () => void;
  dailyScoresNextButtonTapped: () => void;
  offersFetchResult: Result<OfferTopViewData, Error>;
};

const useTop = (
  scoresRepository: TopScoresRepository = new TopScoresRepositoryImpl(),
  dailyScoresRepository: TopDailyScoresRepository = new TopDailyScoresRepositoryImpl(),
  offersRepository: OfferRepository = new OfferRepositoryImpl(),
): ReturnType => {
  const [currentEndDate, setCurrentEndDate] = useState(
    new YearMonthDay(DateTime.local()),
  );

  const scoresQueryResult = useQuery<TopScoresViewData, Error>(
    ['/top/scores'],
    async () => {
      const dto = await scoresRepository.fetch().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertTopScoresDtoToViewData(dto);
    },
  );

  // 指定された日付キーのデータが存在する場合はそのデータを返却し、なければAPIから取得する
  const fetchDailyScores = (
    startDate: YearMonthDay,
    endDate: YearMonthDay,
  ): Promise<TopDailyScoresViewData> => {
    const result: TopDailyScoresViewData | undefined = queryClient.getQueryData(
      ['/top/daily_scores', endDate.separatedByHyphen()],
    );

    if (!result) {
      return dailyScoresRepository
        .fetch(startDate.separatedByHyphen(), endDate.separatedByHyphen())
        .then((dto) =>
          convertTopDailyScoresDtoToViewData(dto, startDate, endDate),
        )
        .catch((error) => {
          if (isErrorDTO(error)) {
            throw Error(error.error.message);
          }
          throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
        });
    }

    return new Promise((resolve, _) => resolve(result));
  };

  const dailyScoresQueryResult = useQuery<TopDailyScoresViewData, Error>(
    ['/top/daily_scores', currentEndDate.separatedByHyphen()],
    async () => {
      const endDate = currentEndDate;
      const startDate = endDate.addDays(-NUMBER_OF_DAYS_FOR_DAILY_SCORES + 1);

      return fetchDailyScores(startDate, endDate);
    },
    { keepPreviousData: true },
  );

  const scoresFetchResult = createResult(scoresQueryResult);
  const dailyScoresFetchResult = createResult(dailyScoresQueryResult);

  const dailyScoresPrevButtonTapped = () => {
    setCurrentEndDate((old) => old.addDays(-NUMBER_OF_DAYS_FOR_DAILY_SCORES));
  };

  const dailyScoresNextButtonTapped = () => {
    setCurrentEndDate((old) => old.addDays(NUMBER_OF_DAYS_FOR_DAILY_SCORES));
  };

  const offersQueryResult = useQuery<OfferTopViewData, Error>(
    ['pickup/offer/top'],
    async () => {
      const dto = await offersRepository.fetchList().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      const offerTopViewData = convertDtoToOfferTopViewData(dto);

      return { offers: FilterOffersForTop(offerTopViewData.offers) };
    },
  );
  const offersFetchResult = createResult(offersQueryResult);

  return {
    scoresFetchResult,
    dailyScoresFetchResult,
    dailyScoresPrevButtonTapped,
    dailyScoresNextButtonTapped,
    offersFetchResult,
  };
};

export default useTop;
