import { VFC } from 'react';
import AttentionContainer from 'global/components/AttentionContainer/AttentionContainer';

const PasswordAttention: VFC = () => (
  <AttentionContainer>
    ※パスワードとして使用できる文字は以下の4種類です。
    <br />
    ・英大文字 <br />
    ・英小文字 <br />
    ・数字 <br />
    ・記号（ ! # $ % & ( ) ^ \ @ ? _） <br />
    ※4種類のうち、2種類以上の文字を使って下さい。
    <br />
    ※12文字以上で登録してください。
    <br />
    ※第三者による不正ログイン防止のため、他社サービスでご利用のパスワードとは異なる文字列を推奨します。
  </AttentionContainer>
);

export default PasswordAttention;
