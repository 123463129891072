import PersonalDataViewData, {
  ConsentElement,
  PersonalDataSectionViewData,
  PersonalDataItemViewData,
} from '../../view-data/personal-page-view-data';

const update = (
  originalPersonalData: PersonalDataViewData,
  consentElement: ConsentElement,
  sectionFindPredicate: (section: PersonalDataSectionViewData) => boolean,
  itemsMapFunction: (
    item: PersonalDataItemViewData,
  ) => PersonalDataItemViewData,
): PersonalDataViewData => {
  const newPersonalData = originalPersonalData;

  const targetSectionIndex =
    newPersonalData.sections.findIndex(sectionFindPredicate);

  if (targetSectionIndex === -1) {
    return newPersonalData;
  }

  const targetSection = newPersonalData.sections[targetSectionIndex];
  const newItems = targetSection.items.map(itemsMapFunction);
  targetSection.items = newItems;
  newPersonalData.sections[targetSectionIndex] = targetSection;

  return newPersonalData;
};

const updateConsentState = (
  originalPersonalData: PersonalDataViewData,
  consentElement: ConsentElement,
): PersonalDataViewData => {
  switch (consentElement.type) {
    case 'item': {
      const sectionFindPredicate = (
        section: PersonalDataSectionViewData,
      ): boolean =>
        section.items.some((item) => item.title === consentElement.title);

      const itemsMapFunction = (
        item: PersonalDataItemViewData,
      ): PersonalDataItemViewData => {
        const newItem = item;
        if (newItem.title === consentElement.title) {
          newItem.consent = consentElement.isChecked;
        }

        return newItem;
      };

      return update(
        originalPersonalData,
        consentElement,
        sectionFindPredicate,
        itemsMapFunction,
      );
    }
    case 'section': {
      const sectionFindPredicate = (
        section: PersonalDataSectionViewData,
      ): boolean => section.title === consentElement.title;

      const itemsMapFunction = (
        item: PersonalDataItemViewData,
      ): PersonalDataItemViewData => {
        const newItem = item;
        newItem.consent = consentElement.isChecked;

        return newItem;
      };

      return update(
        originalPersonalData,
        consentElement,
        sectionFindPredicate,
        itemsMapFunction,
      );
    }
    case 'all': {
      const newPersonalSectionData: PersonalDataSectionViewData[] = [];

      const personalDataItems = (
        items: PersonalDataItemViewData[],
      ): PersonalDataItemViewData[] => {
        const newPersonalDataItems: PersonalDataItemViewData[] = [];

        items.forEach((item) =>
          newPersonalDataItems.push({
            ...item,
            consent: consentElement.isChecked,
          }),
        );

        return newPersonalDataItems;
      };

      originalPersonalData.sections.forEach((section) =>
        newPersonalSectionData.push({
          title: section.title,
          items: personalDataItems(section.items),
        }),
      );

      return { sections: newPersonalSectionData };
    }
    default:
      return originalPersonalData;
  }
};

export default updateConsentState;
