import { VFC } from 'react';
import { useForm } from 'react-hook-form';
import Container from 'global/components/Container/Container';
import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import InputPassword from 'global/components/InputPassword/InputPassword';
import Button from 'global/components/button/Button';
import PasswordAttention from 'global/components/PasswordAttention/PasswordAttention';
import styles from './NewPasswordPage.module.css';

type Props = {
  submitted: (newPassword: string, transactionId: string) => void;
  transactionId: string;
};

type FormData = {
  newPassword: string;
  newPasswordConfirmation: string;
};

// NOTE: パスワード変更画面からコピペのため共通化するのが望ましい.
const UPPER_CASE_PATTERN = 'A-Z';
const LOWER_CASE_PATTERN = 'a-z';
const NUMBER_PATTERN = '0-9';
const SYMBOL_PATTERN = '!#$%&()^\\\\@?_';
const PASSWORD_PATTERN = `^[${UPPER_CASE_PATTERN}${LOWER_CASE_PATTERN}${NUMBER_PATTERN}${SYMBOL_PATTERN}]+$`;

const NOT_MATCH_CONFIRMATION_MSG =
  '入力したパスワードが「再確認」と一致しません。';
const INVALID_PATTERN_MSG = 'パスワード作成の条件を満たしていません。';
const REQUIRED_MSG = 'パスワードは入力必須の項目です。';
const MAX_LENGTH_MSG = '64文字以下で入力してください。';
const MIN_LENGTH_MSG = '12文字以上で入力してください。';

const NewPasswordPage: VFC<Props> = ({ submitted, transactionId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    getValues,
    setError,
    clearErrors,
  } = useForm<FormData>({ mode: 'onBlur' });

  const validateInput = (value: string): string | undefined => {
    const numberOfKind = [
      new RegExp(`[${UPPER_CASE_PATTERN}]`),
      new RegExp(`[${LOWER_CASE_PATTERN}]`),
      new RegExp(`[${NUMBER_PATTERN}]`),
      new RegExp(`[${SYMBOL_PATTERN}]`),
    ].filter((regexp) => regexp.test(value)).length;

    if (numberOfKind < 2) {
      return INVALID_PATTERN_MSG;
    }

    return undefined;
  };

  const onSubmit = (data: FormData) => {
    submitted(data.newPassword, transactionId);
  };

  return (
    <Container marginLeft="s" marginRight="s">
      <p className={styles.message}>新しいパスワードを設定してください。</p>
      <Container marginTop="s">
        <FormContainer>
          {[
            <FormTitle size="large" text="新しいパスワード" />,
            <InputPassword
              name="password"
              size="large"
              register={register('newPassword', {
                required: REQUIRED_MSG,
                minLength: {
                  value: 12,
                  message: MIN_LENGTH_MSG,
                },
                maxLength: {
                  value: 64,
                  message: MAX_LENGTH_MSG,
                },
                pattern: {
                  value: new RegExp(PASSWORD_PATTERN),
                  message: INVALID_PATTERN_MSG,
                },
                validate: (value) => {
                  const invalidMessage = validateInput(value);
                  if (invalidMessage !== undefined) {
                    return invalidMessage;
                  }

                  if (
                    value !== getValues('newPasswordConfirmation') &&
                    getValues('newPasswordConfirmation') !== ''
                  ) {
                    setError('newPasswordConfirmation', {
                      type: 'manual',
                      message: NOT_MATCH_CONFIRMATION_MSG,
                    });

                    return true;
                  }

                  clearErrors('newPasswordConfirmation');

                  return true;
                },
              })}
            />,
          ]}
        </FormContainer>
        {errors?.newPassword && (
          <p className={styles.errorMessage}>{errors.newPassword.message}</p>
        )}
      </Container>
      <Container marginTop="s">
        <FormContainer>
          {[
            <FormTitle size="large" text="新しいパスワードの再確認" />,
            <InputPassword
              name="passwordConfirmation"
              size="large"
              register={register('newPasswordConfirmation', {
                validate: (value) =>
                  value === getValues('newPassword') ||
                  'パスワードが一致していません。',
              })}
            />,
          ]}
        </FormContainer>
        {errors?.newPasswordConfirmation && (
          <p className={styles.errorMessage}>
            {errors.newPasswordConfirmation.message}
          </p>
        )}
      </Container>
      <Container marginTop="m">
        <PasswordAttention />
      </Container>
      <Container marginTop="m">
        <Button
          text="パスワードを再設定する"
          type="primary"
          size="large"
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(onSubmit)}
        />
      </Container>
    </Container>
  );
};

export default NewPasswordPage;
