import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import NotAchievedProgramActionsCount from 'global/data/dto/not-achieved-program-actions-count';

const createFetchRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/mission_program/actions/not_achieved_count',
  },
  method: 'get',
  parameters: {},
});

interface ProgramActionsNotAchievedCountRepository {
  fetch: () => Promise<NotAchievedProgramActionsCount>;
}

export class ProgramActionsNotAchievedCountRepositoryImpl
  implements ProgramActionsNotAchievedCountRepository
{
  fetch = async (): Promise<NotAchievedProgramActionsCount> =>
    new ApiClient().connect(createFetchRequestConfig());
}

export default ProgramActionsNotAchievedCountRepository;
