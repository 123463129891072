import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import PrivacyPolicyContainer from 'features/terms-and-privacy-policy/privacy-policy/containers/index';
import TermsOfUseContainer from 'features/terms-and-privacy-policy/terms-of-use/containers/index';

const TermsAndPrivacyPolicyRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/privacy_policy`}>
      <PrivacyPolicyContainer />
    </Route>
    <Route exact path={`${match.path}/terms_of_use`}>
      <TermsOfUseContainer />
    </Route>
  </Switch>
);

export default TermsAndPrivacyPolicyRouter;
