import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import ConfirmPhoneNumberContainer from './confirm-phone-number/containers/index';
import ConfirmBirthdayContainer from './confirm-birthday/containers/index';
import NotifyEmailContainer from './notify-email/containers/index';
import NotifyEmailCompleteContainer from './notify-email-complete/containers/index';

const ForgetEmailRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/confirm_phone_number`}>
      <ConfirmPhoneNumberContainer />
    </Route>
    <Route exact path={`${match.path}/confirm_birthday`}>
      <ConfirmBirthdayContainer />
    </Route>
    <Route exact path={`${match.path}/notify_email`}>
      <NotifyEmailContainer />
    </Route>
    <Route exact path={`${match.path}/notify_email_complete`}>
      <NotifyEmailCompleteContainer />
    </Route>
  </Switch>
);

export default ForgetEmailRouter;
