import SignInRepository, {
  SignInRepositoryImpl,
} from 'features/sign-in/email-password/data/repositories/sign-in-repository';
import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import { useRef } from 'react';
import SignInViewData from '../view-data/sign-in-view-data';

type ReturnType = {
  submitErrorMessage?: string;
  submitted: (email: string, password: string) => void;
};

const useSignIn = (
  repository: SignInRepository = new SignInRepositoryImpl(),
  saveResult: (result: Result<SignInViewData, void>) => void,
): ReturnType => {
  const submitErrorMessage = useRef<string | undefined>(undefined);

  const submitted = async (email: string, password: string): Promise<void> => {
    try {
      const fincUserId = localStorage.getItem('fincUserId');
      const deviceId = localStorage.getItem('deviceId');

      if (fincUserId) {
        const result = await repository.signIn(
          email,
          password,
          fincUserId,
          deviceId,
        );

        const viewData: SignInViewData = {
          mfaLogin: result.mfaLogin,
          transactionId: result.transactionId,
        };

        saveResult(new Success(viewData));
      } else {
        submitErrorMessage.current = 'FiNCのユーザーIDが存在しません';
        saveResult(new Failure(undefined));
      }
    } catch (error) {
      submitErrorMessage.current = isErrorDTO(error)
        ? error.error.message
        : 'ログインに失敗しました';
      saveResult(new Failure(undefined));
    }
  };

  return {
    submitErrorMessage: submitErrorMessage.current,
    submitted,
  };
};

export default useSignIn;
