import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';

const createSignOutRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/sign_out',
  },
  method: 'delete',
  parameters: {},
});

interface SignOutRepository {
  delete: () => Promise<void>;
}

export class SignOutRepositoryImpl implements SignOutRepository {
  delete = async (): Promise<void> =>
    new ApiClient().connect(createSignOutRequestConfig());
}

export default SignOutRepository;
