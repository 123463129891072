import Result, { Failure, Success } from 'global/utilities/result';
import { isErrorDTO } from 'data/dto/error-dto';
import PasswordResettingsRepository, {
  PasswordResettingsRepositoryImpl,
} from '../data/repositories/password-resettings-repository';

type ReturnType = {
  submitted: (currentPassword: string, newPassword: string) => void;
};

type PasswordInfo = {
  currentPassword: string;
  newPassword: string;
};

const usePasswordResettings = (
  saveResult: (result: Result<PasswordInfo, string>) => void,
  repository: PasswordResettingsRepository = new PasswordResettingsRepositoryImpl(),
): ReturnType => {
  const submitted = (currentPassword: string, newPassword: string): void => {
    const _ = repository
      .resetting(currentPassword, newPassword)
      .then(() => {
        saveResult(new Success({ currentPassword, newPassword }));
      })
      .catch((e) => {
        saveResult(
          new Failure(
            isErrorDTO(e) ? e.error.message : 'パスワードの設定に失敗しました',
          ),
        );
      });
  };

  return {
    submitted,
  };
};

export default usePasswordResettings;
