import { VFC } from 'react';
import styles from './DailyPointDetail.module.css';
import { DailyPointViewData } from '../view-data/point-detail-view-data';

type Props = {
  dailyPointViewDataList: DailyPointViewData[];
};

const DailyPointDetail: VFC<Props> = ({ dailyPointViewDataList }) => (
  <div>
    {dailyPointViewDataList.map((data) => (
      <div className={styles.monthSection} key={data.date}>
        <div className={styles.monthSectionItem}>{data.date}</div>
        <div className={styles.monthSectionItem}>
          {data.points.map((point) => (
            <div className={styles.daySection} key={point.pointId}>
              <div className={styles.daySectionItem}>{point.name}</div>
              <div className={styles.daySectionItem}>
                <div
                  className={
                    // 分類：0：ポイント交換、1：ポイント発行、2：ポイント失効
                    point.action === 1
                      ? styles.positivePoint
                      : styles.negativePoint
                  }
                >
                  {point.action === 1 ? '+' : '-'}
                  {point.amount}pt
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
);

export default DailyPointDetail;
