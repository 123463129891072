import Button from 'global/components/button/Button';
import Container from 'global/components/Container/Container';
import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import InputText from 'global/components/InputText/InputText';
import { useEffect, useRef, VFC } from 'react';
import { useForm } from 'react-hook-form';
import { Divider } from '@chakra-ui/react';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import AttentionContainer from 'global/components/AttentionContainer/AttentionContainer';
import styles from './EmailRegistrationPage.module.css';

type Props = {
  submitted: (email: string) => void;
};

type FormData = {
  email: string;
  emailConfirmation: string;
};

const RegistrationPage: VFC<Props> = ({ submitted }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    getValues,
    setError,
    clearErrors,
  } = useForm<FormData>({ mode: 'onChange' });
  const { isOpen, requestShowing, onClose } = useDialog();

  const processing = useRef(false);
  const onSubmit = (data: FormData) => {
    // TODO: 今後のことを考えると専用のコンポーネントにするのが望ましい
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;

    // 処理中フラグを上げる
    processing.current = true;

    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 3000);

    submitted(data.email);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={styles.contentArea}>
        <Container marginLeft="s" marginRight="s">
          <p className={styles.topMessage}>
            ここで入力したメールアドレスは
            <br />
            ユーザーIDとなり、ログイン時に使用されます。
          </p>

          <Container marginTop="xl">
            <FormContainer>
              {[
                <FormTitle size="large" text="メールアドレス" />,
                <InputText
                  name="email"
                  size="large"
                  placeholder="example@fitstats.com"
                  register={register('email', {
                    required: 'メールアドレスは入力必須の項目です。',
                    pattern: {
                      value: /.+@.+/,
                      message: '正しいメールアドレスを入力してください。',
                    },
                    validate: (value) => {
                      // onBlurでバリデーションを実施しているため、
                      // emailのほうを変更した結果一致した場合にemailConfirmationのエラーが消えない
                      // 同様にemailを変更した結果不一致になった場合にエラーが表示されなかった
                      // 一致しない場合にはemailConfirmationのエラーとしてsetErrorをして、一致する場合にはエラーを消すようにした
                      if (
                        value !== getValues('emailConfirmation') &&
                        getValues('emailConfirmation') !== ''
                      ) {
                        setError('emailConfirmation', {
                          type: 'manual',
                          message: 'メールアドレスが一致していません。',
                        });

                        return true;
                      }
                      clearErrors('emailConfirmation');

                      return true;
                    },
                  })}
                />,
              ]}
            </FormContainer>
            <p className={styles.errorMessage}>{errors?.email?.message}</p>
          </Container>

          <Container marginTop="xl">
            <FormContainer>
              {[
                <FormTitle
                  size="large"
                  text="もう一度メールアドレスを入力してください"
                />,
                <InputText
                  name="emailConfirmation"
                  size="large"
                  placeholder="example@fitstats.com"
                  register={register('emailConfirmation', {
                    validate: (value) =>
                      value === getValues('email') ||
                      'メールアドレスが一致していません。',
                  })}
                />,
              ]}
            </FormContainer>
            <p className={styles.errorMessage}>
              {errors?.emailConfirmation?.message}
            </p>
          </Container>

          <Container marginTop="xl">
            <button
              type="button"
              onClick={() => requestShowing()}
              className={styles.helpLink}
            >
              メールアドレスの使用目的について
            </button>
          </Container>

          <Container marginTop="xl">
            <AttentionContainer>
              <div className={styles.attentionText}>
                ここで入力したメールアドレスに認証コードをお送りします。送信後しばらくして認証番号が届かない場合は再度この画面でメールアドレスを入力してください。
              </div>
            </AttentionContainer>
          </Container>
        </Container>
      </div>

      <BottomContainer>
        <Divider orientation="horizontal" />
        <Container
          marginTop="xxl"
          marginBottom="m"
          marginLeft="s"
          marginRight="s"
        >
          <Button
            text="認証コードを受け取る"
            type="primary"
            size="large"
            disabled={!isDirty || !isValid}
            onClick={handleSubmit(onSubmit)}
          />
        </Container>
      </BottomContainer>

      <Dialog
        title="メールアドレスの使用目的"
        primaryButton={{ text: 'OK' }}
        description={
          'FitStatsで登録いただいたメールアドレスは下記のように取り扱います。\n\n・FitStatsのユーザーIDとして利用いたします。\n・サービスに関する連絡・通知の目的で利用いたします。\n・登録されたメールアドレスは、お客さまの同意がある場合、または法令に基づく場合を除き第三者に個人情報を公開しません。'
        }
        isOpen={isOpen}
        onClose={onClose}
        alignDescriptionLeft
      />
    </>
  );
};

export default RegistrationPage;
