import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import SignUpTermsOfUseContainer from 'features/sign-up/terms-of-use/containers';
import SignUpPrivacyPolicyContainer from 'features/sign-up/privacy-policy/containers';
import EmailRouter from 'features/sign-up/email/email-router';
import PhoneNumberRouter from 'features/sign-up/phone-number/phone-number-router';
import PasswordRouter from 'features/sign-up/password/password-router';
import ProfileRouter from 'features/sign-up/profile/profile-router';
import AgreementRouter from 'features/sign-up/agreement/agreement-router';

const SignUpRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.path}/terms_of_use`}
      component={SignUpTermsOfUseContainer}
    />
    <Route
      exact
      path={`${match.path}/privacy_policy`}
      component={SignUpPrivacyPolicyContainer}
    />
    <Route path={`${match.path}/email`} component={EmailRouter} />
    <Route path={`${match.path}/phone_number`} component={PhoneNumberRouter} />
    <Route path={`${match.path}/password`} component={PasswordRouter} />
    <Route path={`${match.path}/profile`} component={ProfileRouter} />
    <Route path={`${match.path}/agreement`} component={AgreementRouter} />
  </Switch>
);

export default SignUpRouter;
