import { useRef, VFC } from 'react';
import { useForm } from 'react-hook-form';
import Container from 'global/components/Container/Container';
import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import InputPassword from 'global/components/InputPassword/InputPassword';
import Button from 'global/components/button/Button';
import PasswordAttention from 'global/components/PasswordAttention/PasswordAttention';
import styles from './PasswordResettingsPage.module.css';

type Props = {
  submitted: (currentPassword: string, newPassword: string) => void;
};

type FormData = {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

// NOTE: 新規登録と同じmatchなのでglobalな存在にしてもいいかも
const UPPER_CASE_PATTERN = 'A-Z';
const LOWER_CASE_PATTERN = 'a-z';
const NUMBER_PATTERN = '0-9';
const SYMBOL_PATTERN = '!#$%&()^\\\\@?_';
const PASSWORD_PATTERN = `^[${UPPER_CASE_PATTERN}${LOWER_CASE_PATTERN}${NUMBER_PATTERN}${SYMBOL_PATTERN}]+$`;

const NOT_MATCH_CONFIRMATION_MSG =
  '入力したパスワードが「再確認」と一致しません。';
const INVALID_PATTERN_MSG = 'パスワード作成の条件を満たしていません。';
const REQUIRED_MSG = 'パスワードは入力必須の項目です。';
const MAX_LENGTH_MSG = '64文字以下で入力してください。';
const MIN_LENGTH_MSG = '12文字以上で入力してください。';

const PasswordResettingsPage: VFC<Props> = ({ submitted }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    getValues,
    setError,
    clearErrors,
  } = useForm<FormData>({ mode: 'onBlur' });

  const validateInput = (value: string): string | undefined => {
    const numberOfKind = [
      new RegExp(`[${UPPER_CASE_PATTERN}]`),
      new RegExp(`[${LOWER_CASE_PATTERN}]`),
      new RegExp(`[${NUMBER_PATTERN}]`),
      new RegExp(`[${SYMBOL_PATTERN}]`),
    ].filter((regexp) => regexp.test(value)).length;

    if (numberOfKind < 2) {
      return INVALID_PATTERN_MSG;
    }

    return undefined;
  };

  const processing = useRef(false);
  const onSubmit = (data: FormData) => {
    // TODO: 今後のことを考えると専用のコンポーネントにするのが望ましい
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;

    // 処理中フラグを上げる
    processing.current = true;

    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 3000);

    submitted(data.currentPassword, data.newPassword);
  };

  return (
    <Container marginLeft="s" marginRight="s">
      <Container marginTop="m">
        <FormContainer>
          {[
            <FormTitle size="large" text="現在のパスワード" />,
            <InputPassword
              name="password"
              size="large"
              register={register('currentPassword', {
                required: REQUIRED_MSG,
                minLength: {
                  value: 12,
                  message: MIN_LENGTH_MSG,
                },
                maxLength: {
                  value: 64,
                  message: MAX_LENGTH_MSG,
                },
                pattern: {
                  value: new RegExp(PASSWORD_PATTERN),
                  message: INVALID_PATTERN_MSG,
                },
                validate: (value) => {
                  const invalidMessage = validateInput(value);
                  if (invalidMessage !== undefined) {
                    return invalidMessage;
                  }

                  clearErrors('currentPassword');

                  return true;
                },
              })}
            />,
          ]}
        </FormContainer>
        {errors?.currentPassword && (
          <p className={styles.errorMessage}>
            {errors.currentPassword.message}
          </p>
        )}
      </Container>
      <Container marginTop="s">
        <FormContainer>
          {[
            <FormTitle size="large" text="新しいパスワード" />,
            <InputPassword
              name="password"
              size="large"
              register={register('newPassword', {
                required: REQUIRED_MSG,
                minLength: {
                  value: 12,
                  message: MIN_LENGTH_MSG,
                },
                maxLength: {
                  value: 64,
                  message: MAX_LENGTH_MSG,
                },
                pattern: {
                  value: new RegExp(PASSWORD_PATTERN),
                  message: INVALID_PATTERN_MSG,
                },
                validate: (value) => {
                  const invalidMessage = validateInput(value);
                  if (invalidMessage !== undefined) {
                    return invalidMessage;
                  }

                  if (
                    value !== getValues('newPasswordConfirmation') &&
                    getValues('newPasswordConfirmation') !== ''
                  ) {
                    setError('newPasswordConfirmation', {
                      type: 'manual',
                      message: NOT_MATCH_CONFIRMATION_MSG,
                    });

                    return true;
                  }

                  clearErrors('newPasswordConfirmation');

                  return true;
                },
              })}
            />,
          ]}
        </FormContainer>
        {errors?.newPassword && (
          <p className={styles.errorMessage}>{errors.newPassword.message}</p>
        )}
      </Container>
      <Container marginTop="s">
        <FormContainer>
          {[
            <FormTitle size="large" text="新しいパスワードの再確認" />,
            <InputPassword
              name="passwordConfirmation"
              size="large"
              register={register('newPasswordConfirmation', {
                validate: (value) =>
                  value === getValues('newPassword') ||
                  '入力したパスワードが「再確認」と一致しません。',
              })}
            />,
          ]}
        </FormContainer>
        {errors?.newPasswordConfirmation && (
          <p className={styles.errorMessage}>
            {errors.newPasswordConfirmation.message}
          </p>
        )}
      </Container>
      <Container marginTop="m">
        <PasswordAttention />
      </Container>
      <Container marginTop="m">
        <Button
          text="変更する"
          type="primary"
          size="large"
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(onSubmit)}
        />
      </Container>
    </Container>
  );
};

export default PasswordResettingsPage;
