import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import Loading from 'global/components/Loading/Loading';
import { VFC } from 'react';
import DialogPrompt from 'global/components/dialog-prompt/DialogPrompt';
import { useHistory } from 'react-router';
import { isErrorDTO } from 'data/dto/error-dto';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import usePersonalDataProfile from '../hooks/use-personal-data-profile';
import PersonalDataPage from '../pages/PersonalDataPage';

const PersonalDataContainer: VFC = () => {
  const { isOpen, dialogProps, requestShowing, onClose } = useDialog();
  const history = useHistory();
  useNavigationBarItemsSetting('公開するデータの選択');
  const {
    fetchResult,
    storageData,
    isConsentStateChanged,
    closeButtonTapped,
    didConsentChanged,
    saveButtonTapped,
  } = usePersonalDataProfile((result) => {
    if (result.isSuccess()) {
      requestShowing({
        title: '内容が更新されました。',
        description:
          'データを公開する企業を編集する場合は、「データ公開する企業の選択」を押してください。',
        primaryButton: {
          text: 'データ公開する企業の選択',
          onClick: () => history.push('/mydata/client_company'),
        },
      });
    } else if (result.isFailure()) {
      requestShowing({
        title: 'エラー',
        description: isErrorDTO(result.error)
          ? result.error.error.message
          : '公開するデータの更新に失敗しました。',
        primaryButton: { text: 'OK' },
      });
    }
  });

  if (fetchResult.isSuccess()) {
    return (
      <>
        <DialogPrompt
          when={isConsentStateChanged}
          dialogProps={{
            description:
              'データを編集中です。編集したデータを更新せず終了してよろしいですか？',
            primaryButtonTitle: '編集に戻る',
            destructiveButtonTitle: 'はい',
          }}
        />
        <Dialog
          title={dialogProps?.title}
          description={dialogProps?.description}
          primaryButton={dialogProps?.primaryButton ?? { text: 'OK' }}
          isOpen={isOpen}
          onClose={onClose}
        />
        <PersonalDataPage
          personalData={fetchResult.value}
          storageData={storageData}
          closeButtonTapped={closeButtonTapped}
          didConsentChanged={didConsentChanged}
          saveButtonTapped={saveButtonTapped}
        />
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default PersonalDataContainer;
