import axios, { AxiosAdapter, AxiosError, AxiosInstance } from 'axios';
import { HttpClient } from 'data/api-client/http-client';
import RequestConfig, { HttpMethod } from 'data/request-configs/request-config';
import ErrorDTO from 'data/dto/error-dto';
import queryString from 'query-string';
import { camelizeKeys } from 'humps';
import { DateTime } from 'luxon';
import YearMonthDay from 'global/utilities/year-month-day';

const ISO8601_DATE_REGEX =
  /^\d{4}-?\d\d-?\d\d(?:T\d\d(?::?\d\d(?::?\d\d(?:\.\d+)?)?)?(?:Z|[+-]\d\d:?\d\d)?)?$/;
class AxiosHttpClient implements HttpClient {
  axiosInstance: AxiosInstance;
  constructor(adapter: AxiosAdapter | undefined = undefined) {
    this.axiosInstance = axios.create({
      paramsSerializer: (params) =>
        queryString.stringify(params, { arrayFormat: 'bracket' }),
      adapter,
      withCredentials: process.env.NODE_ENV !== 'development',
      // NOTE: curlベースでのアクセスを防ぐ目的で本番環境のみカスタムヘッダーを定義し、ALBで認証するようにしている
      headers: {
        'Front-Authorization': process.env.REACT_APP_FRONT_AUTHORIZATION || '',
      },
    });
    this.axiosInstance.interceptors.response.use(
      (response) => {
        if (this.isObject(response.data)) {
          response.data = camelizeKeys(response.data);
          response.data = this.convertToDateTimeIfNecessary(response.data);
        }

        return response;
      },
      (error: AxiosError<ErrorDTO>) => {
        if (error.response?.status === 401) {
          this.didAuthenticationExpired(error.response.data);

          return new Promise(() => '');
        }
        if (error.response?.status === 503) {
          this.didReceiveServiceUnavailable();
        }

        if (error.response?.data !== undefined) {
          return Promise.reject(error.response.data);
        }

        return Promise.reject(
          new Error(
            `error occured while connecting. status_code: ${
              error.response?.status ?? 'unknown'
            }, url: ${error.response?.config.url ?? 'unknown'}, message: ${
              error.message
            }`,
          ),
        );
      },
    );
  }

  connect = async <T>(config: RequestConfig): Promise<T> => {
    const response = await this.axiosInstance.request<T>({
      method: config.method,
      url: `${config.endpoint.baseUrl}${config.endpoint.path}`,
      params: !this.shouldSetHttpBody(config.method) ? config.parameters : null,
      data: this.shouldSetHttpBody(config.method) ? config.parameters : null,
    });

    return response.data;
  };

  didAuthenticationExpired: (error: ErrorDTO | undefined) => void = () => '';
  didReceiveServiceUnavailable: () => void = () => '';

  setDidAuthenticationExpired = (
    didAuthenticationExpired: (error: ErrorDTO | undefined) => void,
  ): void => {
    this.didAuthenticationExpired = didAuthenticationExpired;
  };

  setDidReceiveServiceUnavailable = (
    didReceiveServiceUnavailable: () => void,
  ): void => {
    this.didReceiveServiceUnavailable = didReceiveServiceUnavailable;
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  private isObject = (o: unknown): o is object =>
    o !== null && (typeof o === 'object' || typeof o === 'function');

  private shouldSetHttpBody = (method: HttpMethod): boolean => {
    switch (method) {
      case 'post':
      case 'put':
      case 'patch':
        return true;
      case 'get':
      case 'delete':
        return false;
      default:
        return false;
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  convertToDateTimeIfNecessary = (body: object): object => {
    type StringKeyObject = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    };
    const result = body as StringKeyObject;
    Object.keys(body).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const value = result[key];

      if (this.isObject(value)) {
        result[key] = this.convertToDateTimeIfNecessary(value);
      } else if (ISO8601_DATE_REGEX.test(value)) {
        // オファーのタイトルに「20220610」という文字列が指定されて、Datetimeと判断されてしまったためエラーとなった。
        // その暫定対処としてtitleの場合にはそのまま返すようにした。
        // 日付のような文字列が入力されることが想定される場合、どのようにするか検討が必要。
        if (key === 'title') {
          result[key] = String(value);
        } else {
          const ymd = YearMonthDay.fromString(value);
          if (typeof ymd !== 'undefined') {
            result[key] = ymd;
          } else {
            const dateTime = DateTime.fromISO(value);
            if (dateTime.isValid) {
              result[key] = DateTime.fromISO(value);
            }
          }
        }
      }
    });

    return result;
  };
}

export default AxiosHttpClient;
