import { VFC } from 'react';
import AcountSettingsRootViewData, {
  AcountSettingsMenuType,
} from '../view-data/acount-settings-root-view-data';
import AcountSettingsCategoryList from '../component/AcountSettingsCategoryList';

type Props = {
  viewData: AcountSettingsRootViewData;
  onMenuClick: (type: AcountSettingsMenuType) => void;
};

const AcountSettingsRootPage: VFC<Props> = ({ viewData, onMenuClick }) => (
  <>
    {viewData.categories.map((e) => (
      <div key={e.name}>
        <AcountSettingsCategoryList category={e} onMenuClick={onMenuClick} />
      </div>
    ))}
  </>
);

export default AcountSettingsRootPage;
