import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import Loading from 'global/components/Loading/Loading';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { VFC } from 'react';
import { useHistory } from 'react-router';
import { ProfileRepositoryImpl } from '../../../profile/data/repositories/profile-repository';
import useProfileConfirm from '../hooks/use-profile-confirm';
import ProfileConfirmPage from '../pages/ProfileConfirmPage';

const ProfileConfirmContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, onClose } = useDialog();
  useNavigationBarItemsSetting('登録情報の確認', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const { fetchResult, submitErrorMessage, submitted } = useProfileConfirm(
    new ProfileRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        history.push('/top', { isTutorial: true });
      }
      if (saveResult.isFailure()) {
        requestShowing();
      }
    },
  );

  if (fetchResult.isSuccess()) {
    return (
      <>
        <Dialog
          title="エラー"
          description={submitErrorMessage}
          primaryButton={{ text: 'OK' }}
          isOpen={isOpen}
          onClose={onClose}
        />
        <ProfileConfirmPage result={fetchResult.value} submitted={submitted} />
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default ProfileConfirmContainer;
