import RequestConfig from 'data/request-configs/request-config';
import AxiosHttpClient from 'data/api-client/axios-http-client';
import { HttpClient } from 'data/api-client/http-client';
import ErrorDTO from 'data/dto/error-dto';

class ApiClient {
  httpClient: HttpClient;

  static didAuthenticationExpired: (error: ErrorDTO | undefined) => void;
  static didReceiveServiceUnavailable: () => void;

  constructor(httpClient: HttpClient = new AxiosHttpClient()) {
    this.httpClient = httpClient;
    this.httpClient.setDidAuthenticationExpired(
      ApiClient.didAuthenticationExpired,
    );
    this.httpClient.setDidReceiveServiceUnavailable(
      ApiClient.didReceiveServiceUnavailable,
    );
  }

  connect = async <T>(config: RequestConfig): Promise<T> =>
    this.httpClient.connect(config);

  static setDidAuthenticationExpired = (
    didAuthenticationExpired: (error: ErrorDTO | undefined) => void,
  ): void => {
    ApiClient.didAuthenticationExpired = didAuthenticationExpired;
  };

  static setDidReceiveServiceUnavailable = (
    didReceiveServiceUnavailable: () => void,
  ): void => {
    ApiClient.didReceiveServiceUnavailable = didReceiveServiceUnavailable;
  };
}

export default ApiClient;
