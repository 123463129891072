import EmailRegistrationRepository, {
  EmailRegistrationRepositoryImpl,
} from 'features/sign-up/email/registration/data/repositories/email-registration-repository';
import ErrorDTO, { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';

type ReturnType = {
  submitted: (email: string) => void;
};

const useEmailRegistration = (
  repository: EmailRegistrationRepository = new EmailRegistrationRepositoryImpl(),
  saveResult: (result: Result<string, ErrorDTO | undefined>) => void,
): ReturnType => {
  const submitted = async (email: string): Promise<void> => {
    try {
      await repository.save(email);
      saveResult(new Success(email));
    } catch (error) {
      const _ = isErrorDTO(error)
        ? saveResult(new Failure(error))
        : saveResult(new Failure(undefined));
    }
  };

  return {
    submitted,
  };
};

export default useEmailRegistration;
