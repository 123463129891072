import { useEffect } from 'react';

type Address = {
  region: string;
  locality: string;
  street: string;
  extended: string;
};
type AddressSearchCallBack = (addr: Address) => void;
export type ReturnType = {
  getAddress: (yubin7: string, callback: AddressSearchCallBack) => void;
};

const useAddressSearch = (): ReturnType => {
  useEffect(() => {
    const head = document.getElementsByTagName('head')[0] as HTMLElement;
    const scriptUrl = document.createElement('script');
    scriptUrl.type = 'text/javascript';
    scriptUrl.src =
      'https://yubinbango.github.io/yubinbango-core/yubinbango-core.js';
    head.appendChild(scriptUrl);
  }, []);

  const getAddress = (yubin7: string, callback: AddressSearchCallBack) => {
    /* eslint-disable */
    // 元のライブラリがデータファイルがない場合のエラーを無視しているため、無視しないようにするonerrorのみ追加する
    // @ts-ignore
    class AddressSearch extends YubinBango.Core {
      jsonp(url: string, fn: (data: any) => void) {
        // @ts-ignore
        window['$yubin'] = (data) => fn(data);
        const scriptTag = document.createElement('script');
        scriptTag.setAttribute('type', 'text/javascript');
        scriptTag.setAttribute('charset', 'UTF-8');
        scriptTag.setAttribute('src', url);
        scriptTag.onerror = () => {
          // 元のライブラリが住所が見つからない時に空の文字列で返しているので、それに合わせる
          callback({
            region: '',
            locality: '',
            street: '',
            extended: '',
          });
        };
        document.head.appendChild(scriptTag);
      }
    }

    // new YubinBango.Core(yubin7, callback);
    // @ts-ignore
    new AddressSearch(yubin7, callback);
    /* eslint-enable */
  };

  return { getAddress };
};

export default useAddressSearch;
