import { VFC } from 'react';
import styles from './ScoreTrend.module.css';

type Props = {
  differenceFromPrevious?: number;
};

type ScoreTrendItem = {
  style: string;
  value: string;
};

const differenceFromPreviousLayoutItem = (
  differenceFromPrevious?: number,
): ScoreTrendItem => {
  if (typeof differenceFromPrevious === 'number') {
    if (differenceFromPrevious > 0) {
      return {
        style: styles.up,
        value: `+${differenceFromPrevious}`,
      };
    }

    if (differenceFromPrevious < 0) {
      return {
        style: styles.down,
        value: `${differenceFromPrevious}`,
      };
    }

    return {
      style: styles.unchanged,
      value: `${differenceFromPrevious}`,
    };
  }

  return {
    style: styles.undefined,
    value: '',
  };
};

const ScoreTrend: VFC<Props> = ({ differenceFromPrevious }) => {
  const layoutItem = differenceFromPreviousLayoutItem(differenceFromPrevious);

  return (
    <>
      <div className={[styles.scoreTrend, layoutItem.style].join(' ')}>
        {layoutItem.value}
      </div>
    </>
  );
};
export default ScoreTrend;
