import { VFC } from 'react';
import Loading from 'global/components/Loading/Loading';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import PushNotificationSettingsPage from '../pages/PushNotificationSettingsPage';
import usePushNotificationSettings from '../hooks/use-push-notification-settings';

const PushNotificationSettingsContainer: VFC = () => {
  const { isOpen, requestShowing, dialogProps, onClose } = useDialog();
  useNavigationBarItemsSetting('プッシュ通知設定');
  const { fetchResult, onClick } = usePushNotificationSettings(
    (errorMessage) => {
      requestShowing({
        title: 'エラー',
        description: errorMessage,
        primaryButton: { text: '閉じる' },
      });
    },
  );

  if (fetchResult.isSuccess()) {
    return (
      <>
        <Dialog
          isOpen={isOpen}
          title={dialogProps?.title}
          description={dialogProps?.description}
          primaryButton={dialogProps?.primaryButton ?? { text: '閉じる' }}
          onClose={onClose}
        />

        <PushNotificationSettingsPage
          viewData={fetchResult.value}
          onClick={onClick}
        />
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default PushNotificationSettingsContainer;
