import { VFC, useRef, useCallback, useEffect } from 'react';
import OfferList from 'features/offer/components/OfferList';
import Container from 'global/components/Container/Container';
import {
  HELP_WHAT_IS_OFFER,
  OpenHelpPage,
} from 'global/utilities/open-help-page';
import UndraggableLink from 'global/components/UndraggableLink/UndraggableLink';
import {
  UserOfferTypeViewData,
  UserOfferViewData,
} from '../view-data/offer-top-view-data';
import styles from './OfferTopPage.module.css';

type Props = {
  offers: UserOfferViewData[];
  isNeedLoadMore: boolean;
  onLoadItem: (cursor: number) => void;
  onTapOffersItem: (id: string, type: UserOfferTypeViewData) => void;
};

const OfferTopPage: VFC<Props> = ({
  offers,
  isNeedLoadMore,
  onLoadItem,
  onTapOffersItem,
}) => {
  const pageTopRef = useRef<HTMLDivElement>(null);
  const scrollToTop = useCallback(() => {
    pageTopRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'start',
    });
  }, []);
  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  return (
    <div className={styles.container} ref={pageTopRef}>
      <button type="button" onClick={() => OpenHelpPage(HELP_WHAT_IS_OFFER)}>
        <div className={styles.titleCard}>
          <img src="/offer/offertop@3x.png" alt="what_is_offer" />
        </div>
      </button>

      <OfferList
        offers={offers}
        isNeedLoadMore={isNeedLoadMore}
        onLoadItem={onLoadItem}
        onTapOffersItem={onTapOffersItem}
      />
      {offers.length === 0 && (
        <div className={styles.noOfferWrapper}>
          <div className={styles.noOfferImg}>
            <img src="/offer/icon-no-offer.svg" alt="no_offer" />
          </div>
          <div className={styles.noOfferTitle}>
            配信中のオファーはありません
          </div>
          <Container marginLeft="xl" marginRight="xl">
            マイデータを編集すると、よりあなたにマッチした多彩なオファーをお届けできるようになります。
            <br />
            <br />
            データを公開する企業を増やすと、より多くのオファーが届きます。
            <br />
            <br />
            <UndraggableLink
              to="/mydata/client_company"
              className={styles.noOfferLinkWrapper}
            >
              &gt;&gt;{' '}
              <span className={styles.noOfferLink}>
                データを公開する企業を確認する
              </span>
            </UndraggableLink>
          </Container>
        </div>
      )}
    </div>
  );
};

export default OfferTopPage;
