import { VFC } from 'react';
import { useForm } from 'react-hook-form';
import Container from 'global/components/Container/Container';
import { FormTitle } from 'global/components/FormContainer/FormContainer';
import Button from 'global/components/button/Button';
import InputBirthDate from 'global/components/InputBirthDate/InputBirthDate';
import styles from './ConfirmBirthdayPage.module.css';

type Props = {
  submitted: (birthday: string) => void;
};

type FormData = {
  year: string;
  month: string;
  day: string;
  birthday: string;
};

const ConfirmBirthdayPage: VFC<Props> = ({ submitted }) => {
  const {
    watch,
    register,
    handleSubmit,
    formState: { isDirty, isValid },
    setValue,
  } = useForm<FormData>({
    mode: 'onBlur',
  });

  const onSubmit = (data: FormData) => {
    submitted(`${data.year}-${data.month}-${data.day}`);
  };

  const validateBirthday = () => {
    const year = Number(watch('year'));
    const month = Number(watch('month'));
    const day = Number(watch('day'));
    const lastday = new Date(year, month, 0).getDate();
    if (day > lastday) {
      setValue('day', '');
    }
  };

  const registerYear = register('year', {
    required: '年は入力必須の項目です',
    onBlur: () => {
      validateBirthday();
    },
  });
  const registerMonth = register('month', {
    required: '月は入力必須の項目です',
    onBlur: () => {
      validateBirthday();
    },
  });
  const registerDay = register('day', {
    required: '日は入力必須の項目です',
    onBlur: () => {
      validateBirthday();
    },
  });

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <p className={styles.topMessage}>
          FitStatsに登録している
          <br />
          生年月日を入力してください。
        </p>
        <Container marginTop="s">
          <FormTitle text="生年月日" />
          <InputBirthDate
            registerYear={registerYear}
            registerMonth={registerMonth}
            registerDay={registerDay}
            watchYear={watch('year')}
            watchMonth={watch('month')}
          />
        </Container>
      </Container>

      <Container marginTop="l" marginBottom="s" marginLeft="s" marginRight="s">
        <Button
          text="次へ"
          type="primary"
          size="large"
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(onSubmit)}
        />
      </Container>
    </>
  );
};

export default ConfirmBirthdayPage;
