import QuestionnaireDetailDto, {
  QuestionnaireSectionDto,
  QuestionnaireQuestionDto,
} from 'features/questionnaire/data/dto/questionnaire-detail-dto';
import QuestionnaireDetailViewData, {
  QuestionnairePageViewData,
  QuestionnaireQuestion,
  QuestionnaireSection,
} from 'features/questionnaire/view-data/questionnaire-detail-view-data';

const NUMBER_OF_QUESTIONS_PER_PAGE = 10;

const numberOfQuestions = (sections: QuestionnaireSectionDto[]): number =>
  sections.reduce((result, section) => result + section.questions.length, 0);

const firstUnansweredQuestionId = (
  section: QuestionnaireSectionDto,
): number | null => {
  const firstQuestionId = section.questions[0];
  const firstUnansredQuestion = section.questions.find(
    (question) => question.userAnsweredOptionId === null,
  );

  if (firstQuestionId === firstUnansredQuestion) {
    return null;
  }

  return firstUnansredQuestion?.id ?? null;
};

const convertQuestionnaireQuestionDtoToViewData = (
  dto: QuestionnaireQuestionDto,
): QuestionnaireQuestion => ({ ...dto, number: 0 });

const convertQuestionaireSectionDtoToViewData = (
  dto: QuestionnaireSectionDto,
): QuestionnaireSection => ({
  ...dto,
  questions: dto.questions.map((question) =>
    convertQuestionnaireQuestionDtoToViewData(question),
  ),
});

const convertQuestionnaireDetailDtoToPagesViewData = (
  dto: QuestionnaireDetailDto,
  categoryId: string,
): QuestionnairePageViewData[] => {
  // セクション毎、または同セクション内の設問10件毎に別ページとして扱う
  const pages = dto.sections.map(
    (section): QuestionnairePageViewData => ({
      name: dto.name,
      categoryId,
      numberOfQuestions: numberOfQuestions(dto.sections),
      section: convertQuestionaireSectionDtoToViewData(section),
      firstUnansweredQuestionId: firstUnansweredQuestionId(section),
    }),
  );

  let lastQuestionNumber = 0;
  const newPages = pages.map((page) => {
    const questions = page.section.questions.reduce(
      (result, _, i) =>
        i % NUMBER_OF_QUESTIONS_PER_PAGE
          ? result
          : [
              ...result,
              page.section.questions.slice(i, i + NUMBER_OF_QUESTIONS_PER_PAGE),
            ],
      [] as QuestionnaireQuestion[][],
    );

    // 設問Noの付与
    const numberAssignedQuestions = questions.map((question) =>
      question.map((q): QuestionnaireQuestion => {
        lastQuestionNumber += 1;

        return { ...q, number: lastQuestionNumber };
      }),
    );

    return numberAssignedQuestions.map(
      (question): QuestionnairePageViewData => ({
        name: page.name,
        categoryId: page.categoryId,
        numberOfQuestions: page.numberOfQuestions,
        section: {
          id: page.section.id,
          sentence: page.section.sentence,
          questions: question,
        },
        firstUnansweredQuestionId: page.firstUnansweredQuestionId,
      }),
    );
  });

  return newPages.flatMap((page) => page);
};

const convertQuestionnaireDetailDtoToViewData = (
  dto: QuestionnaireDetailDto,
  categoryId: string,
): QuestionnaireDetailViewData => ({
  pages: convertQuestionnaireDetailDtoToPagesViewData(dto, categoryId),
});

export default convertQuestionnaireDetailDtoToViewData;
