import { VFC } from 'react';
import styles from './FloatingBackButton.module.css';

type Props = {
  onClick: () => void;
};

const FloatingBackButton: VFC<Props> = ({ onClick }) => (
  <button
    type="button"
    aria-label="Back Button"
    className={styles.backButton}
    onClick={onClick}
  />
);

export default FloatingBackButton;
