/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, VFC } from 'react';
import Button from 'global/components/button/Button';
import ProgramDetailViewData from '../view-data/program_detail-view-data';
import MissionCarousel from '../component/MissionCarousel';
import MissionPanelListCell from '../component/MissionPanelListCell';
import styles from './ProgramDetailPage.module.css';
import ActionId from '../data/common/action-id';

type Props = {
  viewData: ProgramDetailViewData;
  joinProgramButtonTapped: () => void;
  leaveProgramButtonTapped: () => void;
  actionButtonTapped: (id: ActionId) => void;
};

const ProgramDetailPage: VFC<Props> = ({
  viewData,
  joinProgramButtonTapped,
  leaveProgramButtonTapped,
  actionButtonTapped,
}) => {
  // ページが描画されたら先頭までスクロール
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={styles.headerBanner}>
        <img src={viewData.bannerImage.original.url} alt="headerBanner" />
      </div>
      <div className={styles.container}>
        <div className={styles.title}>{viewData.title}</div>
        <div className={styles.termDescription}>{viewData.termDescription}</div>
        <div className={styles.keywordArea}>
          <div className={styles.keyword}>{`${viewData.scoreDescription}`}</div>
          <div className={styles.keyword}>
            {`ミッション数: ${viewData.missionCount}`}
          </div>
        </div>
        <div className={styles.hr} />
        <div className={styles.description}>{viewData.description}</div>
        <div className={styles.hr} />
        <div className={styles.missionPanelTitle}>ミッションリスト</div>
        <div className={styles.disclaimerText}>
          ※FiNCのライフログがアクションの達成に反映されるまで、30分以上かかる場合があります。
        </div>
        <MissionCarousel
          selectedIndex={viewData.currentForcusMissionPanelIndex}
        >
          {viewData.missions.map((viewObject) => (
            <div key={viewObject.id.value}>
              <MissionPanelListCell
                {...viewObject}
                actionButtonTapped={actionButtonTapped}
              />
            </div>
          ))}
        </MissionCarousel>
        <div className={styles.noticeTitle}>注意事項</div>
        <div className={styles.noticeDescription}>
          {viewData.notice.topDescription}
        </div>
        {viewData.notice.contents.map((e) => (
          <div key={e.title}>
            <div className={styles.noticeContentsTitle}>{e.title}</div>
            <div className={styles.noticeDescription}>{e.description}</div>
          </div>
        ))}
      </div>
      <div className={styles.floatButton}>
        <>
          {viewData.joinedState.kind === 'notJoined' && (
            <Button
              type="primary"
              size="large"
              text="参加する"
              onClick={joinProgramButtonTapped}
            />
          )}
          {viewData.joinedState.kind === 'joinedCancellable' && (
            <Button
              type="primary"
              size="large"
              text="参加をキャンセルする"
              onClick={leaveProgramButtonTapped}
            />
          )}
        </>
      </div>
    </>
  );
};

export default ProgramDetailPage;
