import requestWebCommand, {
  OpenExternalWeb,
} from 'global/utilities/web-command';

export const HELP_TOP = '';
export const HELP_WHAT_IS_OFFER = '--61aec78d977337001e95d398';
export const HELP_WHAT_IS_FITSTATS_POINT = '--61aec78c323140001dfd11e2';
export const HELP_DETAIL_OF_LIFELOG = '--61aec78c977337001e95d33b';
export const HELP_DETAIL_OF_LIFESTATS = '--61aec78c977337001e95d2e6';

export const OpenHelpPage = (path: string): void => {
  const url =
    path.length > 0
      ? `https://help.fitstats-dnp.com/${path}/?inapp=true`
      : `https://help.fitstats-dnp.com/?inapp=true`; // 二重スラッシュ抑止
  requestWebCommand(new OpenExternalWeb(url));
};
