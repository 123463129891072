import { VFC } from 'react';
import styles from 'global/components/square-checkbox-with-label/SquareCheckboxWithLabel.module.css';

type LabelSize = 'large' | 'medium';

export type Props = {
  name: string;
  value: string;
  size?: LabelSize;
  label: string;
  checked: boolean;
  onClick: (name: string, value: string) => void;
};

const classNameForLabel = (size?: LabelSize): string => {
  switch (size) {
    case 'medium':
      return styles.label;
    case 'large':
      return styles.labelLarge;
    default:
      return styles.label;
  }
};

const SquareCheckboxWithLabel: VFC<Props> = ({
  name,
  value,
  size,
  label,
  checked,
  onClick,
}) => (
  <>
    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
    <button
      type="button"
      className={[
        styles.checkbox,
        styles.checkboxSize,
        checked ? styles.checked : styles.unchecked,
      ].join(' ')}
      onClick={() => onClick(name, value)}
    />
    <button
      type="button"
      className={[styles.checkbox, classNameForLabel(size)].join(' ')}
      onClick={() => onClick(name, value)}
    >
      {label}
    </button>
  </>
);

export default SquareCheckboxWithLabel;
