import { useQuery } from 'react-query';
import OfferRepository, {
  OfferRepositoryImpl,
} from 'features/offer/data/repositories/offer-repository';
import OfferEventRepository, {
  OfferEventRepositoryImpl,
  OfferEvent,
} from 'features/offer/data/repositories/offer-event-repository';
import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import requestWebCommand, {
  OpenExternalWeb,
} from 'global/utilities/web-command';
import { useRef } from 'react';
import { DialogButton } from 'global/components/dialog/Dialog';
import { useHistory } from 'react-router';
import { TutorialLocationState } from 'features/tutorial/hooks/use-tutorial-navigation';
import FitStatsPointRepository, {
  FitStatsPointRepositoryImpl,
  PointEvent,
} from 'features/tutorial/data/repositories/fit-stats-point-repository';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import AnnouncementOfferDetailViewData from '../view-data/announcement-offer-detail-view-data';
import AnnouncementOfferDto from '../data/dto/announcement-offer-dto';

type ReturnType = {
  fetchResult: Result<AnnouncementOfferDetailViewData, Error>;
  linkButtonTapped: (
    offerDetailViewData: AnnouncementOfferDetailViewData,
    buttonNumber: number,
  ) => void;
  linkButtonTappedAction: DialogActionType | undefined;
  offerOpenEventFired: (
    offerDetailViewData: AnnouncementOfferDetailViewData,
  ) => void;
  offerOpenEventFiredAction: DialogActionType | undefined;
  locationState?: TutorialLocationState | undefined;
};

type DialogActionType = {
  title?: string;
  description?: string;
  imageSrc?: string;
  primaryButton?: DialogButton;
  onClose?: () => void;
};

type SaveSuccessMessage = string;

const convertDtoToViewData = (
  dto: AnnouncementOfferDto,
): AnnouncementOfferDetailViewData => {
  const convertedScheduledDeliveryEnd = dto.scheduledDeliveryEndDatetime
    .setZone('Asia/Tokyo')
    .toFormat('yyyy/M/d HH:mm');

  return {
    ...dto,
    termDescription: `${convertedScheduledDeliveryEnd} まで`,
  };
};

const useAnnouncementOfferDetail = (
  offerId: string,
  repository: OfferRepository = new OfferRepositoryImpl(),
  offerOpenEventFiredSaveResult: (
    result: Result<SaveSuccessMessage, Error>,
  ) => void,
  linkButtonTappedSaveResult: (
    result: Result<SaveSuccessMessage, Error>,
  ) => void,
  locationState?: TutorialLocationState | undefined,
): ReturnType => {
  const history = useHistory();

  const linkButtonTappedAction =
    useRef<DialogActionType | undefined>(undefined);
  const offerOpenEventFiredAction =
    useRef<DialogActionType | undefined>(undefined);

  const queryResult = useQuery<AnnouncementOfferDetailViewData, Error>(
    ['/users/me/offers/announcement_offers', offerId],
    async () => {
      const dto = await repository
        .fetchAnnouncementOffer(offerId)
        .catch((error) => {
          if (isErrorDTO(error) && error.error.status === 'NOT_FOUND') {
            throw Error(
              '該当オファーが見つかりません。存在しないか、掲載期限を過ぎている、既に達成済のオファーである可能性があります。',
            );
          } else if (isErrorDTO(error)) {
            throw Error(error.error.message);
          }
          throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
        });

      return convertDtoToViewData(dto);
    },
  );

  const fetchResult = createResult(queryResult);

  const linkButtonTapped = (
    offerDetailViewData: AnnouncementOfferDetailViewData,
    buttonNumber: number,
    offerEventRepository: OfferEventRepository = new OfferEventRepositoryImpl(),
  ) => {
    let eventType: OfferEvent;
    let linkUrl = '';
    let isEventPushed = false;

    if (locationState?.isTutorial) return;

    if (buttonNumber === 1) {
      eventType = OfferEvent.LINK_URL_1_CLICK_EVENT;
      linkUrl = offerDetailViewData.announcementLinkUrl1;
      isEventPushed = offerDetailViewData.isLinkUrl1Clicked;
    } else if (buttonNumber === 2) {
      eventType = OfferEvent.LINK_URL_2_CLICK_EVENT;
      linkUrl = offerDetailViewData.announcementLinkUrl2;
      isEventPushed = offerDetailViewData.isLinkUrl2Clicked;
    } else {
      linkButtonTappedAction.current = {
        title: 'プログラムエラー',
        description: 'ハンドリングされないボタンがタップされました',
      };
      linkButtonTappedSaveResult(
        new Failure(Error('ハンドリングされないボタンがタップされました')),
      );

      return;
    }

    requestWebCommand(new OpenExternalWeb(linkUrl));

    if (isEventPushed) return;

    const _ = offerEventRepository
      .createEvent(offerId, eventType)
      .then(() => {
        linkButtonTappedSaveResult(
          new Success('オファーのイベント送信に成功しました'),
        );
      })
      .catch((error) => {
        linkButtonTappedAction.current = {
          title: 'エラー',
          description: isErrorDTO(error)
            ? error.error.message
            : 'オファーのイベント送信に失敗しました',
        };
        linkButtonTappedSaveResult(
          new Failure(Error('オファーのイベント送信に失敗しました')),
        );
      });
  };

  const tutorialOfferOpenEventFired = () => {
    const fitStatsPointRepository: FitStatsPointRepository =
      new FitStatsPointRepositoryImpl();

    if (!locationState?.isTutorial) return;

    const eventType: PointEvent = PointEvent.TUTORIAL_OFFER;
    const _ = fitStatsPointRepository
      .createEvent(eventType)
      .then((dto) => {
        offerOpenEventFiredAction.current = {
          title: 'FitStatsポイント獲得！',
          description: `オファーを開封したので、FitStatsポイントを${dto.amount}pt獲得しました！`,
        };
        offerOpenEventFiredSaveResult(
          new Success('オファーの開封に成功しました'),
        );
      })
      .catch((error) => {
        if (isErrorDTO(error) && error.error.status === 'ALREADY_EXISTS') {
          offerOpenEventFiredAction.current = {
            title: 'FitStatsポイント獲得！',
            description: `既にオファーが開封されているため、FitStatsポイントが付与されています。`,
          };
          offerOpenEventFiredSaveResult(
            new Success('オファーの開封に成功しました'),
          );

          return;
        }

        offerOpenEventFiredAction.current = {
          title: 'エラー',
          description:
            'オファーを開封できませんでした。恐れ入りますが再度お試しください。',
          primaryButton: {
            text: 'OK',
            onClick: () => history.go(0), // reload
          },
          onClose: () => history.go(0),
        };
        offerOpenEventFiredSaveResult(
          new Failure(Error('オファーを開封できませんでした')),
        );
      });
  };

  const offerOpenEventFired = (
    offerDetailViewData: AnnouncementOfferDetailViewData,
    offerEventRepository: OfferEventRepository = new OfferEventRepositoryImpl(),
  ) => {
    // チュートリアルの場合、オファー開封イベント(開封ログ等含む)はリクエストせずポイント付与だけリクエストする。
    // チュートリアルのオファーは基盤に登録されたものでは無いため、ポイント付与だけ実行すれば良い。
    if (locationState?.isTutorial) {
      tutorialOfferOpenEventFired();

      return;
    }

    if (offerDetailViewData.isOfferOpened) return;

    const eventType: OfferEvent = OfferEvent.ANNOUNCEMENT_OFFER_OPEN_EVENT;
    const _ = offerEventRepository
      .createEvent(offerId, eventType)
      .then(() => {
        offerOpenEventFiredAction.current = {
          title: 'FitStatsポイント獲得！',
          description: `オファー開封ポイントを${offerDetailViewData.awardPoint}pt獲得しました。`,
          imageSrc: '/offer/offer_achive.svg',
        };
        offerOpenEventFiredSaveResult(
          new Success('オファーの開封に成功しました'),
        );
      })
      .catch((error) => {
        if (isErrorDTO(error) && error.error.status === 'ALREADY_EXISTS')
          return;

        offerOpenEventFiredAction.current = {
          title: 'エラー',
          description:
            'オファーを開封できませんでした。恐れ入りますが再度お試しください。',
          primaryButton: {
            text: 'オファ一覧に戻る',
            onClick: () => history.push('/offers'),
          },
          onClose: () => history.go(0), // reload
        };
        offerOpenEventFiredSaveResult(
          new Failure(Error('オファーを開封できませんでした')),
        );
      });
  };

  return {
    fetchResult,
    linkButtonTapped,
    linkButtonTappedAction: linkButtonTappedAction.current,
    offerOpenEventFired,
    offerOpenEventFiredAction: offerOpenEventFiredAction.current,
  };
};

export default useAnnouncementOfferDetail;
