import { Flex } from '@chakra-ui/react';
import { VFC } from 'react';
import styles from './ProgressBar.module.css';

export type Props = {
  max: number;
  value: number;
};

const ProgressBar: VFC<Props> = ({ max, value }) => (
  <>
    <div className={styles.progressBarContainer}>
      <Flex alignItems="center" justifyContent="flex-end">
        <div>完了まで</div>
        <div className={styles.itemStep}>step</div>
        <div className={styles.itemStepStatusWrapper}>
          <span className={styles.itemCurrentStep}>{value}</span>
          <span className={styles.itemMaxStep}>/{max}</span>
        </div>
      </Flex>
    </div>
    <progress className={styles.progressBar} max={max} value={value} />
  </>
);
export default ProgressBar;
