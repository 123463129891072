import { VFC } from 'react';
import TopPage from 'features/top/pages/TopPage';
import useUserLifelogRecords from 'features/top/hooks/use-user-lifelog-records';
import useTop from 'features/top/hooks/use-top';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ScrollToTopWhenMounted from 'global/components/ScrollToTopWhenMounted/ScrollToTopWhenMounted';
import { TopDailyScoresRepositoryImpl } from '../data/repositories/top-daily-scores-repository';
import { TopScoresRepositoryImpl } from '../data/repositories/top-scores-repository';

const TopContainer: VFC = () => {
  useUserLifelogRecords();
  useNavigationBarItemsSetting({ type: 'logo' });

  const {
    scoresFetchResult,
    dailyScoresFetchResult,
    dailyScoresNextButtonTapped,
    dailyScoresPrevButtonTapped,
    offersFetchResult,
  } = useTop(new TopScoresRepositoryImpl(), new TopDailyScoresRepositoryImpl());

  return (
    <ScrollToTopWhenMounted>
      <TopPage
        scoresFetchResult={scoresFetchResult}
        dailyScoresFetchResult={dailyScoresFetchResult}
        dailyScoresNextButtonTapped={dailyScoresNextButtonTapped}
        dailyScoresPrevButtonTapped={dailyScoresPrevButtonTapped}
        offersFetchResult={offersFetchResult}
      />
    </ScrollToTopWhenMounted>
  );
};

export default TopContainer;
