import { VFC } from 'react';
import NoticeDetailViewData from '../view-data/notice-detail-view-data';
import styles from './NoticeDetailPage.module.css';

type Props = {
  viewData: NoticeDetailViewData;
};

const NoticeTopPage: VFC<Props> = ({ viewData }) => (
  <div className={styles.container}>
    <div className={styles.registeredAt}>{viewData.registeredAt}</div>
    <div className={styles.title}>{viewData.title}</div>
    <div className={styles.description}>{viewData.description}</div>
  </div>
);

export default NoticeTopPage;
