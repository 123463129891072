import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import PointDetailDto from '../dto/point-detail-dto';

const createPointDetailRequestConfig = (page: number): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/fit_stats_point/reports',
  },
  method: 'get',
  parameters: { page },
});

interface PointDetailRepository {
  fetch: (page: number) => Promise<PointDetailDto>;
}

export class PointDetailRepositoryImpl implements PointDetailRepository {
  fetch = async (page: number): Promise<PointDetailDto> =>
    new ApiClient().connect(createPointDetailRequestConfig(page));
}

export default PointDetailRepository;
