import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import NoticeTopDto from '../dto/notice-top-dto';

const createNoticeTopRequestConfig = (
  cursor?: number,
  itemsPerPage?: number,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/notifications',
  },
  method: 'get',
  parameters: { cursor, items_per_page: itemsPerPage },
});

const createNoticeReadRequestConfig = (id: string): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/users/me/notifications/${id}/mark_as_read`,
  },
  method: 'post',
  parameters: {},
});

interface NoticeTopRepository {
  fetch: (cursor?: number, itemsPerPage?: number) => Promise<NoticeTopDto>;
  readNotice: (id: string) => Promise<void>;
}

export class NoticeTopRepositoryImpl implements NoticeTopRepository {
  fetch = async (
    cursor?: number,
    itemsPerPage?: number,
  ): Promise<NoticeTopDto> =>
    new ApiClient().connect(createNoticeTopRequestConfig(cursor, itemsPerPage));

  readNotice = async (id: string): Promise<void> =>
    new ApiClient().connect(createNoticeReadRequestConfig(id));
}

export default NoticeTopRepository;
