import { useCallback, VFC } from 'react';
import TopPage from 'features/top/pages/TopPage';
import useUserLifelogRecords from 'features/top/hooks/use-user-lifelog-records';
import useTutorialNavigation, {
  TutorialLocationState,
} from 'features/tutorial/hooks/use-tutorial-navigation';
import useDialogInSlideShow from 'global/components/dialog-in-slide-show/use-dialog-in-slide-show';
import DialogInSlideShow, {
  DialogUsedSlide,
} from 'global/components/dialog-in-slide-show/DialogInSlideShow';
import { useLocation } from 'react-router';
import useTop from 'features/top/hooks/use-top';
import useTopTutorial from 'features/top/hooks/use-top-tutorial';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import TutorialOfferRepository from 'features/offer/data/repositories/tutorial-offer-repository';
import { TopDailyScoresRepositoryImpl } from '../data/repositories/top-daily-scores-repository';
import { TopScoresRepositoryImpl } from '../data/repositories/top-scores-repository';

const TutorialTopContainer: VFC = () => {
  useNavigationBarItemsSetting(
    { type: 'logo' },
    {
      isCloseButtonVisible: false,
      isMenuButtonVisible: false,
      isNotificationButtonVisible: false,
      isBackButtonVisible: false,
    },
  );

  useUserLifelogRecords();

  const { navigate } = useTutorialNavigation();

  const { isOpen, requestShowing, onClose } = useDialog();

  const {
    isOpen: isSlideShowOpen,
    currentPage,
    button,
    requestShowing: requestSlideShowShowing,
    requestSlidePaging,
    requestButtonChanging,
  } = useDialogInSlideShow();

  const { pointEventFailedAction, pointEventSuccessedAction, startTutorial } =
    useTopTutorial();

  const openTutorialDialog = useCallback(() => {
    requestSlideShowShowing({
      text: '次へ',
      onClick: (page) => {
        requestSlidePaging(page + 1);
      },
    });
  }, [requestSlideShowShowing, requestSlidePaging]);

  const openErrorDialog = useCallback(() => {
    requestShowing();
  }, [requestShowing]);

  const didLoad = useCallback(() => {
    startTutorial(openTutorialDialog, openErrorDialog);
  }, [openTutorialDialog, openErrorDialog, startTutorial]);

  const {
    scoresFetchResult,
    dailyScoresFetchResult,
    dailyScoresNextButtonTapped,
    dailyScoresPrevButtonTapped,
    offersFetchResult,
  } = useTop(
    new TopScoresRepositoryImpl(),
    new TopDailyScoresRepositoryImpl(),
    new TutorialOfferRepository(),
  );

  const slide1: DialogUsedSlide = {
    kind: 'common',
    imageSrc: '/global/get_point.png',
    title: pointEventSuccessedAction?.title,
    description: pointEventSuccessedAction?.description,
  };

  const slide2: DialogUsedSlide = {
    kind: 'common',
    imageSrc: '/global/get_point.png',
    title: 'ポイントを貯めるには',
    description:
      '更に今後FitStatsポイントを貯めていくためには「オファー」を受け取る必要があります。',
  };

  const slide3: DialogUsedSlide = {
    kind: 'common',
    imageSrc: '/tutorial/tutorial_02@3x.png',
    title: '「オファー」とは',
    description:
      '「オファー」とは、企業から届く、あなたにマッチした多彩な情報などのメッセージです。',
  };

  const slide4: DialogUsedSlide = {
    kind: 'common',
    imageSrc: '/tutorial/tutorial_02@3x.png',
    title: '「オファー」とは',
    description:
      'オファーを受け取った後、その内容を確認したり、アンケートに回答すると、FitStatsポイントが貯まります。',
  };

  const slide5: DialogUsedSlide = {
    kind: 'common',
    imageSrc: '/tutorial/tutorial_03@3x.png',
    title: '「FitStatsポイント」とは',
    description: 'FitStatsポイントは、FitStatsで獲得できるポイントです。',
  };

  const slide6: DialogUsedSlide = {
    kind: 'common',
    imageSrc: '/tutorial/tutorial_03@3x.png',
    title: '「FitStatsポイント」とは',
    description:
      'FiNCモールほか、様々な提携先のポイントやクーポンへ交換することができます。',
  };

  const elements = [slide1, slide2, slide3, slide4, slide5, slide6];

  const { state } = useLocation<TutorialLocationState | undefined>();

  return (
    <>
      <DialogInSlideShow
        isOpen={isSlideShowOpen}
        slideElements={elements}
        selectedPage={currentPage}
        buttonText={button.text}
        onClose={() => {
          // チュートリアル中なので、閉じない
        }}
        onButtonClicked={() => button.onClick(currentPage)}
        onPageChanged={(page) => {
          if (page >= elements.length - 1) {
            requestButtonChanging({
              text: '早速、オファーを見てみる',
              onClick: () => {
                onClose();
                navigate('/offers');
              },
            });
          }
          if (page !== currentPage) {
            requestSlidePaging(page);
          }
        }}
      />
      <TopPage
        scoresFetchResult={scoresFetchResult}
        dailyScoresFetchResult={dailyScoresFetchResult}
        dailyScoresNextButtonTapped={dailyScoresNextButtonTapped}
        dailyScoresPrevButtonTapped={dailyScoresPrevButtonTapped}
        didLoad={didLoad}
        locationState={state}
        offersFetchResult={offersFetchResult}
      />
      {pointEventFailedAction && (
        <Dialog
          title={pointEventFailedAction.title}
          description={pointEventFailedAction.description}
          primaryButton={pointEventFailedAction.primaryButton}
          isOpen={isOpen}
          onClose={pointEventFailedAction.onClose}
        />
      )}
    </>
  );
};

export default TutorialTopContainer;
