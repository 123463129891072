import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import { useRef, VFC } from 'react';
import { Prompt, useHistory } from 'react-router';

type DialogProps = {
  description: string;
  primaryButtonTitle: string;
  destructiveButtonTitle: string;
};
type Props = {
  when: boolean;
  dialogProps: DialogProps;
};

const DialogPrompt: VFC<Props> = ({ when, dialogProps }) => {
  const { isOpen, requestShowing, onClose } = useDialog();

  const history = useHistory();
  const shouldDisplayPrompt = useRef(true);
  const targetLocationPath = useRef('/top');

  const leave = () => {
    shouldDisplayPrompt.current = false;
    history.push(targetLocationPath.current);
  };

  return (
    <>
      <Dialog
        description={dialogProps.description}
        primaryButton={{ text: dialogProps.primaryButtonTitle }}
        destructiveButton={{
          text: dialogProps.destructiveButtonTitle,
          onClick: leave,
        }}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Prompt
        when={when}
        message={(location) => {
          if (shouldDisplayPrompt.current === false) {
            return true;
          }

          targetLocationPath.current = location.pathname;

          requestShowing();

          return false;
        }}
      />
    </>
  );
};

export default DialogPrompt;
