import UserInputItem from 'features/profile/user-input-item';
import { DateTime } from 'luxon';
import YearMonthDay, {
  isYearMonthDay,
} from '../../../global/utilities/year-month-day';

class FunctionForProfileViewData {}

export const isNeedParentalConsent = (age: number): boolean => age < 16;

export const convertBirthdayToAge = (
  date: string | YearMonthDay | undefined,
): number | undefined => {
  if (date == null) {
    return undefined;
  }

  const birthDate = isYearMonthDay(date) ? date : YearMonthDay.fromString(date);
  if (birthDate) {
    const today = new YearMonthDay(DateTime.local());
    const age = birthDate.diffOfYears(today);

    // 生年月日の入力が未来だった場合0歳とする
    return age < 0 ? 0 : age;
  }

  return undefined;
};

// NOTE: 親権者同意チェックボックスのdefaultステータスについて
// 1.入力済の生年月日(年齢)が親権者同意の対象の場合：既に同意をして進んだはずなのでdefault値はオン
// 2.入力済の生年月日(年齢)が親権者同意の対象外の場合：まだ同意をしたことがないのでdefault値はオフ
// 3.まだ入力がない場合：default値はオフ
export const defaultParentalConsentStatus = (
  userInputItems: UserInputItem[] | undefined,
): boolean => {
  if (userInputItems) {
    const value = userInputItems.find((v) => v.name === 'birthday')?.value;

    const inputtingBirthday = Array.isArray(value) ? undefined : value;
    const inputtingAge = convertBirthdayToAge(inputtingBirthday);

    return (
      typeof inputtingAge === 'number' && isNeedParentalConsent(inputtingAge)
    );
  }

  return false;
};

export default FunctionForProfileViewData;
