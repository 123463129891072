import { VFC } from 'react';
import { useLocation } from 'react-router';
import { TutorialLocationState } from 'features/tutorial/hooks/use-tutorial-navigation';

type Props = {
  defaultContainer: React.ReactElement;
  tutorialContainer: React.ReactElement;
};

const ContainerResolver: VFC<Props> = ({
  defaultContainer,
  tutorialContainer,
}) => {
  const { state } = useLocation<TutorialLocationState | undefined>();
  if (state?.isTutorial === true) {
    return tutorialContainer;
  }

  return defaultContainer;
};

export default ContainerResolver;
