import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import PhoneNumberResettingsContainer from './resettings/containers/index';
import PhoneNumberAuthenticationContainer from './authentication/containers/index';

const PhoneNumberResettingsRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/reset`}>
      <PhoneNumberResettingsContainer />
    </Route>
    <Route exact path={`${match.path}/authentication`}>
      <PhoneNumberAuthenticationContainer />
    </Route>
  </Switch>
);

export default PhoneNumberResettingsRouter;
