import { VFC } from 'react';

export type Props = {
  marginTop?: MarginSize;
  marginBottom?: MarginSize;
  marginLeft?: MarginSize;
  marginRight?: MarginSize;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
};

export type MarginSize =
  | '3xs'
  | 'xxs'
  | 'xs'
  | 's'
  | 'm'
  | 'l'
  | 'xl'
  | 'xxl'
  | '3xl'
  | '4xl'
  | number;

const convertMarginSize = (value?: string | number): number => {
  if (typeof value === 'undefined') {
    return 0;
  }

  if (typeof value === 'string') {
    switch (value) {
      case '3xs':
        return 4;
      case 'xxs':
        return 8;
      case 'xs':
        return 12;
      case 's':
        return 16;
      case 'm':
        return 24;
      case 'l':
        return 32;
      case 'xl':
        return 40;
      case 'xxl':
        return 48;
      case '3xl':
        return 64;
      case '4xl':
        return 80;
      default:
        return 0;
    }
  }

  return value;
};

const Container: VFC<Props> = ({
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  children,
}) => (
  <div
    style={{
      marginTop: convertMarginSize(marginTop),
      marginBottom: convertMarginSize(marginBottom),
      marginLeft: convertMarginSize(marginLeft),
      marginRight: convertMarginSize(marginRight),
    }}
  >
    {children}
  </div>
);
export default Container;
