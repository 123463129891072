import { useQuery } from 'react-query';
import ClientCompanyDetailRepository, {
  ClientCompanyDetailRepositoryImpl,
} from 'features/my-data/client-company/data/repositories/client-company-detail-repository';
import { isErrorDTO } from 'data/dto/error-dto';
import Result from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import ClientCompanyDetailViewData from '../view-data/client-company-detail-view-data';
import ClientCompanyDetailDto from '../data/dto/client-company-detail-dto';

type ReturnType = {
  fetchResult: Result<ClientCompanyDetailViewData, Error>;
};

const convertDtoToViewData = (
  dto: ClientCompanyDetailDto,
): ClientCompanyDetailViewData => dto;

const useClientCompanyDetail = (
  clientId: string,
  repository: ClientCompanyDetailRepository = new ClientCompanyDetailRepositoryImpl(),
): ReturnType => {
  const queryResult = useQuery<ClientCompanyDetailViewData, Error>(
    ['/client-company-detail', clientId],
    async () => {
      const dto = await repository.fetch(clientId).catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertDtoToViewData(dto);
    },
  );

  const fetchResult = createResult(queryResult);

  return {
    fetchResult,
  };
};

export default useClientCompanyDetail;
