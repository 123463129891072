import EmailAuthenticationRepository, {
  EmailAuthenticationRepositoryImpl,
} from 'features/sign-up/email/authentication/data/repositories/email-authentication-repository';
import ErrorDTO, { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';

type ReturnType = {
  submitted: (emailAuthCode: number) => void;
};

const useEmailAuthentication = (
  repository: EmailAuthenticationRepository = new EmailAuthenticationRepositoryImpl(),
  authResult: (result: Result<undefined, ErrorDTO | undefined>) => void,
): ReturnType => {
  const submitted = async (emailAuthCode: number): Promise<void> => {
    try {
      await repository.auth(emailAuthCode);
      authResult(new Success(undefined));
    } catch (error) {
      const _ = isErrorDTO(error) ? authResult(new Failure(error)) : authResult(new Failure(undefined));
    }
  };

  return { submitted };
};

export default useEmailAuthentication;
