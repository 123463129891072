import { VFC } from 'react';
import Button from 'global/components/button/Button';
import { useHistory } from 'react-router';
import Container from 'global/components/Container/Container';
import { Divider } from '@chakra-ui/react';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import styles from './ProfileDescriptionPage.module.css';

const ProfileDescriptionPage: VFC = () => {
  const history = useHistory();

  const nextButtonTapAction = () => {
    history.push('/sign_up/profile/detail');
  };

  return (
    <>
      <div className={styles.descriptionPageWrapper}>
        <Container>
          <img
            className={styles.categoryImage}
            src="/profile/profile.png"
            alt="プロフィール入力"
          />
        </Container>
        <Container marginTop={50} marginBottom={24}>
          <div className={styles.descriptionPageTitle}>
            さらに、あなたについて
            <br />
            詳しく教えてください
          </div>
        </Container>
        <div className={styles.descriptionPageDescription}>
          もっと詳しいプロフィールを登録することで
          <br />
          あなたにマッチした多彩なオファーを
          <br />
          お届けできるようになります。
          <br />
          <br />
          登録されたプロフィールは、
          <br />
          無断で企業に公開されることはありません。
        </div>
      </div>
      <BottomContainer>
        <Divider orientation="horizontal" />
        <Container
          marginTop={50}
          marginBottom={50}
          marginLeft="s"
          marginRight="s"
        >
          <Button
            text="次へ"
            type="primary"
            size="large"
            onClick={nextButtonTapAction}
          />
        </Container>
      </BottomContainer>
    </>
  );
};

export default ProfileDescriptionPage;
