import ErrorDTO, { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import PasswordRepository, {
  PasswordRepositoryImpl,
} from '../data/repositories/password-repository';

type ReturnType = {
  submitted: (password: string) => void;
};

type DeviceId = string;

const usePassword = (
  repository: PasswordRepository = new PasswordRepositoryImpl(),
  saveResult: (result: Result<DeviceId, ErrorDTO | undefined>) => void,
): ReturnType => {
  const getVersion = () => {
    const termsOfUseVersion = localStorage.getItem('termsOfUseVersion');
    const privacyPolicyVersion = localStorage.getItem('privacyPolicyVersion');
    if (!termsOfUseVersion || !privacyPolicyVersion) {
      throw Error(
        'アカウント登録を完了するには利用規約および個人情報の取り扱いへの同意が必要です',
      );
    }

    return { termsOfUseVersion, privacyPolicyVersion };
  };

  const submitted = async (password: string): Promise<void> => {
    const { termsOfUseVersion, privacyPolicyVersion } = getVersion();

    try {
      const dto = await repository.save(
        password,
        termsOfUseVersion,
        privacyPolicyVersion,
      );
      saveResult(new Success(dto.deviceId));
    } catch (error) {
      const _ = isErrorDTO(error)
        ? saveResult(new Failure(error))
        : saveResult(new Failure(undefined));
    }
  };

  return { submitted };
};

export default usePassword;
