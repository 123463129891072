import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import PhoneNumberAuthenticationRepository, {
  PhoneNumberAuthenticationRepositoryImpl,
} from 'features/sign-in/phone-number-authentication/data/repositories/phone-number-authentication-repository';
import { useRef } from 'react';

type ReturnType = {
  submitErrorMessage?: string;
  submitted: (phoneNumberAuthCode: number) => void;
};

const usePhoneNumberAuthentication = (
  transactionId: string,
  repository: PhoneNumberAuthenticationRepository = new PhoneNumberAuthenticationRepositoryImpl(),
  saveResult: (result: Result<void, boolean>) => void,
): ReturnType => {
  const submitErrorMessage = useRef<string | undefined>(undefined);

  const submitted = async (phoneNumberAuthCode: number): Promise<void> => {
    try {
      const fincUserId = localStorage.getItem('fincUserId');
      if (fincUserId) {
        const dto = await repository.auth(
          phoneNumberAuthCode,
          transactionId,
          fincUserId,
        );
        localStorage.setItem('deviceId', dto.deviceId);
        saveResult(new Success(undefined));
      } else {
        submitErrorMessage.current = 'FiNCのユーザーIDが存在しません';
      }
    } catch (error) {
      submitErrorMessage.current = isErrorDTO(error)
        ? error.error.message
        : 'SMS認証に失敗しました';
      const isInvalidArgument =
        isErrorDTO(error) && error.error.status === 'INVALID_ARGUMENT';
      saveResult(new Failure(isInvalidArgument));
    }
  };

  return {
    submitErrorMessage: submitErrorMessage.current,
    submitted,
  };
};

export default usePhoneNumberAuthentication;
