import { VFC } from 'react';
import Loading from 'global/components/Loading/Loading';
import TermsOfUseAndPrivacyPolicyPage from 'global/pages/terms-of-use-and-privacy-policy/TermsOfUseAndPrivacyPolicyPage';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import usePrivacyPolicy from 'features/terms-and-privacy-policy/privacy-policy/hooks/use-privacy-policy';
import { PrivacyPolicyRepositoryImpl } from 'features/terms-and-privacy-policy/privacy-policy/data/repositories/privacy-policy-repository';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';

const PrivacyPolicyContainer: VFC = () => {
  useNavigationBarItemsSetting('個人情報の取り扱いについて');

  const { fetchResult } = usePrivacyPolicy(
    new PrivacyPolicyRepositoryImpl(),
    () => '',
  );

  if (fetchResult.isSuccess()) {
    return (
      <>
        <TermsOfUseAndPrivacyPolicyPage
          name="個人情報の取り扱い"
          content={fetchResult.value.content}
          submitted={undefined}
        />
      </>
    );
  }

  if (fetchResult.isFailure())
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;

  return <Loading />;
};

export default PrivacyPolicyContainer;
