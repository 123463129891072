import useDialogInSlideShow, {
  DialogInSlideShowButton,
} from '../components/dialog-in-slide-show/use-dialog-in-slide-show';

const TUTORIAL_DONE_VALUE = 'done';

type OneTimeDialogInSlideShowReturn = {
  isOpen: boolean;
  currentPage: number;
  buttonText: string;
  onButtonClicked: () => void;
  onSlidePageChanged: (page: number) => void;
  onClose: () => void;
};

const useOneTimeDialogInSlideShow = (
  tutorialKey: string,
  slideLength: number,
  completedButtonText?: string,
  incompleteButtonText?: string,
): OneTimeDialogInSlideShowReturn => {
  const {
    isOpen,
    currentPage,
    button,
    requestShowing,
    requestSlidePaging,
    requestButtonChanging,
    onClose,
  } = useDialogInSlideShow();

  const isDoneTutorial =
    localStorage.getItem(tutorialKey) === TUTORIAL_DONE_VALUE;

  const initialButton: DialogInSlideShowButton = {
    text: incompleteButtonText ?? '次へ',
    onClick: (page) => {
      requestSlidePaging(page + 1);
    },
  };

  if (!isOpen && !isDoneTutorial) {
    requestShowing(initialButton);
  }

  const onButtonClicked = () => {
    button.onClick(currentPage);
  };

  const onSlidePageChanged = (page: number) => {
    if (page >= slideLength - 1) {
      requestButtonChanging({
        text: completedButtonText ?? '閉じる',
        onClick: () => {
          localStorage.setItem(tutorialKey, TUTORIAL_DONE_VALUE);
          onClose();
        },
      });
    } else {
      requestButtonChanging(initialButton);
    }
    if (page !== currentPage) {
      requestSlidePaging(page);
    }
  };

  return {
    isOpen,
    currentPage,
    buttonText: button.text,
    onButtonClicked,
    onSlidePageChanged,
    onClose,
  };
};

export default useOneTimeDialogInSlideShow;
