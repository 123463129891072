import { FC } from 'react';
import styles from './TopContainer.module.css';

type Props = {
  background?: string;
};

const TopContainer: FC<Props> = ({ children, background }) => (
  <div
    style={{ background: background || '#fff' }}
    className={styles.topContainer}
  >
    {children}
  </div>
);

export default TopContainer;
