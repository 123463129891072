import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import PushNotificationSettingsDto, {
  PushNotificationChangeSettingsDto,
} from '../dto/push-notification-settings-dto';

const createPushNotificationSettingsFetchRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/push_notification_categories',
  },
  method: 'get',
  parameters: {},
});

const createPushNotificationSettingsChangeRequestConfig = (
  code: string,
  accepted: boolean,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/users/me/push_notification_categories/${code}`,
  },
  method: 'patch',
  parameters: { accepted },
});

interface PushNotificationSettingsRepository {
  fetch: () => Promise<PushNotificationSettingsDto>;
  changeSettings: (
    code: string,
    accepted: boolean,
  ) => Promise<PushNotificationChangeSettingsDto>;
}

export class PushNotificationSettingsRepositoryImpl
  implements PushNotificationSettingsRepository
{
  fetch = async (): Promise<PushNotificationSettingsDto> =>
    new ApiClient().connect(createPushNotificationSettingsFetchRequestConfig());

  changeSettings = async (
    code: string,
    accepted: boolean,
  ): Promise<PushNotificationChangeSettingsDto> =>
    new ApiClient().connect(
      createPushNotificationSettingsChangeRequestConfig(code, accepted),
    );
}

export default PushNotificationSettingsRepository;
