import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import FincCouponDto from '../dto/finc-coupon-dto';

const createFincCouponFetchRequestRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/fit_stats_point/balance',
  },
  method: 'get',
  parameters: {},
});

const createFincCouponExchangeRequestConfig = (
  exchangeQuantity: number,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/fit_stats_point/finc_coupon',
  },
  method: 'post',
  parameters: {
    exchange_quantity: exchangeQuantity,
  },
});

interface FincCouponRepository {
  fetch: () => Promise<FincCouponDto>;
  exchangeCoupon: (exchangeQuantity: number) => Promise<void>;
}

export class FincCouponRepositoryImpl implements FincCouponRepository {
  fetch = async (): Promise<FincCouponDto> =>
    new ApiClient().connect(createFincCouponFetchRequestRequestConfig());

  exchangeCoupon = async (exchangeQuantity: number): Promise<void> =>
    new ApiClient().connect(
      createFincCouponExchangeRequestConfig(exchangeQuantity),
    );
}

export default FincCouponRepository;
