import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import ClientCompanyDetailDto from '../dto/client-company-detail-dto';

const createClientCompanyDetailRequestConfig = (
  clientId: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/client_companies/${clientId}`,
  },
  method: 'get',
  parameters: {},
});

interface ClientCompanyDetailRepository {
  fetch: (clientId: string) => Promise<ClientCompanyDetailDto>;
}

export class ClientCompanyDetailRepositoryImpl
  implements ClientCompanyDetailRepository
{
  fetch = async (clientId: string): Promise<ClientCompanyDetailDto> =>
    new ApiClient().connect(createClientCompanyDetailRequestConfig(clientId));
}

export default ClientCompanyDetailRepository;
