import { FC } from 'react';
import styles from './QuestionSection.module.css';
import Button from '../button/Button';

type Props = {
  key?: string | number;
  imgSrc: string;
  title: string;
  answeredQuestionsCount: number;
  totalQuestionsCount: number;
  isAchieve?: boolean;
  buttonTappedAction: () => void;
  buttonLabel?: string;
};

const QuestionSection: FC<Props> = ({
  key,
  imgSrc,
  title,
  answeredQuestionsCount,
  totalQuestionsCount,
  isAchieve,
  buttonTappedAction,
  buttonLabel,
}) => (
  <div className={styles.questionContainer} key={key}>
    <div className={styles.questionIcon}>
      <img src={imgSrc} alt="icon" />
    </div>
    <div className={styles.questionDetail}>
      <div className={styles.questionName}>{title}</div>
      <div className={styles.questionProgress}>
        {answeredQuestionsCount}
        <div className={styles.questionTotal}>/ {totalQuestionsCount}</div>
        <div className={styles.questionTotalUnit}>問</div>
      </div>
    </div>
    <div className={styles.questionButton}>
      <div>
        {isAchieve ? (
          <div className={styles.questionDisableButton}>
            <Button
              text="回答済み"
              type="primary"
              size="small"
              disabled
              onClick={() => null}
            />
          </div>
        ) : (
          <div className={styles.questionEnableButton}>
            <Button
              text="回答する"
              type="default"
              size="small"
              onClick={buttonTappedAction}
            />
          </div>
        )}
      </div>
      {buttonLabel && (
        <div className={styles.questionButtonPointLabel}>{buttonLabel}</div>
      )}
    </div>
  </div>
);

export default QuestionSection;
