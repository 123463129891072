import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { TutorialLocationState } from 'features/tutorial/hooks/use-tutorial-navigation';

type ReturnType = {
  shouldDisplayBackButton: boolean;
  onClickBackButton: () => void;
};

// 以下の画面では戻るボタンを非表示にする
// - サービスページ(/)
// - 新規登録関連(/sign_up/*)
// - 利用規約、個人情報の取り扱い
// - トップ
// - チュートリアル
// - 退会完了
// - メンテナンスページ
const shouldHideBackButton = (
  pathname: string,
  isTutorial: boolean,
): boolean => {
  if (
    pathname === '/sign_up/agreement/terms_of_use' ||
    pathname === '/sign_up/agreement/privacy_policy'
  ) {
    return false;
  }

  return (
    isTutorial ||
    pathname === '/' ||
    pathname === '/top' ||
    pathname === '/maintenance' ||
    /^\/sign_up(\/.*)*/.test(pathname) ||
    /^\/terms_and_privacy_policy(\/.*)*/.test(pathname) ||
    /^\/withdrawal_settings\/completed(\/.*)*/.test(pathname)
  );
};

const useFloatingBackButton = (): ReturnType => {
  const [shouldDisplayBackButton, setShouldDisplayBackButton] = useState(false);
  const history = useHistory();
  const location = useLocation<TutorialLocationState | undefined>();

  useEffect(() => {
    setShouldDisplayBackButton(
      !shouldHideBackButton(
        location.pathname,
        location.state?.isTutorial === true,
      ),
    );
  }, [location.pathname, location.state?.isTutorial]);

  const onClickBackButton = () => {
    history.goBack();
  };

  return { shouldDisplayBackButton, onClickBackButton };
};

export default useFloatingBackButton;
