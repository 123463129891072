import { useQuery } from 'react-query';
import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import TermsOfUseAndPrivacyPolicyViewData from 'global/view-data/terms-of-use-and-privacy-policy-view-data';
import createResult from 'global/utilities/create-result-from-query-result';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import TermsOfUseRepository, {
  TermsOfUseRepositoryImpl,
} from 'features/sign-up/terms-of-use/data/repositories/terms-of-use-repository';
import convertDtoToTermsOfUseAndPrivacyPolicyViewData from 'features/terms-and-privacy-policy/utils/convert-dto-to-terms-of-use-and-privacy-policy-view-data';
import PrivacyPolicyRepository, {
  PrivacyPolicyRepositoryImpl,
} from 'features/sign-up/privacy-policy/data/repositories/privacy-policy-repository';
import queryClient from 'global/query-client';
import UserRepository, {
  UserRepositoryImpl,
} from 'features/sign-up/terms-of-use/data/repositories/user-repository';
import { useRef } from 'react';
import AuthorizationRepository from 'features/sign-up/privacy-policy/data/repositories/authorization-repository';

type ReturnType = {
  fetchTermsOfUseResult: Result<TermsOfUseAndPrivacyPolicyViewData, Error>;
  fetchPrivacyPolicyResult: Result<TermsOfUseAndPrivacyPolicyViewData, Error>;
  submitErrorMessage?: string;
  submitted: () => void;
};

const TERMS_OF_USE_QUERY_KEY = '/terms-of-use';
const PRIVACY_POLICY_QUERY_KEY = '/privacy-policy';

const cachedTermsOfUseViewData = ():
  | TermsOfUseAndPrivacyPolicyViewData
  | undefined => queryClient.getQueryData([TERMS_OF_USE_QUERY_KEY]);

const cachedPrivacyPolicyViewData = ():
  | TermsOfUseAndPrivacyPolicyViewData
  | undefined => queryClient.getQueryData([PRIVACY_POLICY_QUERY_KEY]);

const useAgreement = (
  termsOfUseRepository: TermsOfUseRepository = new TermsOfUseRepositoryImpl(),
  privacyPolicyRepository: PrivacyPolicyRepository = new PrivacyPolicyRepositoryImpl(),
  userRepository: UserRepository = new UserRepositoryImpl(),
  authorizationRepository = new AuthorizationRepository(),
  saveResult: (result: Result<string, void>) => void,
): ReturnType => {
  const submitErrorMessage = useRef<string | undefined>(undefined);

  const fetchTermsOfUseQueryResult = useQuery<
    TermsOfUseAndPrivacyPolicyViewData,
    Error
  >([TERMS_OF_USE_QUERY_KEY], async () => {
    const dto = await termsOfUseRepository.fetch().catch((error) => {
      if (isErrorDTO(error)) {
        throw Error(error.error.message);
      }
      throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
    });

    return convertDtoToTermsOfUseAndPrivacyPolicyViewData(dto);
  });

  const fetchTermsOfUseResult = createResult(fetchTermsOfUseQueryResult);

  const fetchPrivacyPolicyQueryResult = useQuery<
    TermsOfUseAndPrivacyPolicyViewData,
    Error
  >([PRIVACY_POLICY_QUERY_KEY], async () => {
    const dto = await privacyPolicyRepository.fetch().catch((error) => {
      if (isErrorDTO(error)) {
        throw Error(error.error.message);
      }
      throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
    });

    return convertDtoToTermsOfUseAndPrivacyPolicyViewData(dto);
  });

  const fetchPrivacyPolicyResult = createResult(fetchPrivacyPolicyQueryResult);

  const submitted = async () => {
    const termsOfUseDocVersion = cachedTermsOfUseViewData()?.docVersion;
    if (!termsOfUseDocVersion) {
      throw Error('利用規約の取得に失敗しました');
    }
    localStorage.setItem('termsOfUseVersion', termsOfUseDocVersion);

    const privacyPolicyDocVersion = cachedPrivacyPolicyViewData()?.docVersion;
    if (!privacyPolicyDocVersion) {
      throw Error('個人情報の取り扱いの取得に失敗しました');
    }
    localStorage.setItem('privacyPolicyVersion', privacyPolicyDocVersion);

    try {
      await userRepository.create();
    } catch (error) {
      submitErrorMessage.current = isErrorDTO(error)
        ? error.error.message
        : 'ユーザーの仮登録に失敗しました';
      saveResult(new Failure(undefined));

      return;
    }

    try {
      const authorizationResult = await authorizationRepository.authorize();
      saveResult(new Success(authorizationResult.redirectUrl));
    } catch (error) {
      submitErrorMessage.current = isErrorDTO(error)
        ? error.error.message
        : '認可画面へのリダイレクトに失敗しました';
      saveResult(new Failure(undefined));
    }
  };

  return {
    fetchTermsOfUseResult,
    fetchPrivacyPolicyResult,
    submitErrorMessage: submitErrorMessage.current,
    submitted,
  };
};

export default useAgreement;
