import { VFC } from 'react';
import Loading from 'global/components/Loading/Loading';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import SettingsRootPage from '../pages/SettingsRootPage';
import useSettingsRoot from '../hooks/use-settings-root';

const SettingsRootContainer: VFC = () => {
  const { isOpen, requestShowing, dialogProps, onClose } = useDialog();
  useNavigationBarItemsSetting('メニュー');
  const { fetchResult, onClickSettingMenu, onClickLogout } = useSettingsRoot(
    (onTapDecided) => {
      requestShowing({
        title: 'ログアウトします',
        description:
          '再度ログインするにはメールアドレスとパスワードの入力が必要になります。\n\nログアウトしますか？',
        primaryButton: { text: 'ログアウト', onClick: onTapDecided },
        destructiveButton: { text: '戻る' },
      });
    },
    (errorMessage) => {
      requestShowing({
        title: 'エラー',
        description: errorMessage,
        primaryButton: { text: '閉じる' },
      });
    },
  );

  if (fetchResult.isSuccess()) {
    return (
      <>
        <Dialog
          isOpen={isOpen}
          title={dialogProps?.title}
          description={dialogProps?.description}
          primaryButton={dialogProps?.primaryButton ?? { text: '閉じる' }}
          destructiveButton={dialogProps?.destructiveButton}
          onClose={onClose}
        />

        <SettingsRootPage
          viewData={fetchResult.value}
          onClickSettingsMenu={onClickSettingMenu}
          onClickLogout={onClickLogout}
        />
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default SettingsRootContainer;
