import { FC } from 'react';
import styles from './OpenExternalWeb.module.css';

type Props = {
  url: string;
};

// NOTE: FitStatsをブラウザ上で動かす場合は、WebCommandによる外部ブラウザ表示ではなく、
// ブラウザの別タブで任意のページを開く挙動になると思われる。
// その際はこのコンポーネント内でそのへんの処理を隠蔽する。
const OpenExternalWeb: FC<Props> = ({ url, children }) => (
  <a
    href={`fitstats-web-command://open_external_browser_without_auth?url=${url}`}
    className={styles.link}
  >
    {children}
  </a>
);

export default OpenExternalWeb;
