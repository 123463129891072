import { Divider } from '@chakra-ui/react';
import AttentionContainer from 'global/components/AttentionContainer/AttentionContainer';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import Button from 'global/components/button/Button';
import Container from 'global/components/Container/Container';
import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import InputTel from 'global/components/InputTel/InputTel';
import { useRef, VFC } from 'react';
import { useForm } from 'react-hook-form';
import styles from './PhoneNumberRegistrationPage.module.css';

type Props = {
  submitted: (phoneNumber: string) => void;
};

type FormData = {
  phoneNumber: string;
};

const formTitle = <FormTitle size="large" text="電話番号" />;

const PhoneNumberRegistrationPage: VFC<Props> = ({ submitted }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<FormData>({ mode: 'onBlur' });

  const processing = useRef(false);
  const onSubmit = (data: FormData) => {
    // TODO: 今後のことを考えると専用のコンポーネントにするのが望ましい
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;

    // 処理中フラグを上げる
    processing.current = true;

    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 3000);

    submitted(data.phoneNumber);
  };

  const name = 'phoneNumber';
  const options = {
    required: '電話番号は入力必須の項目です。',
    pattern: {
      value: /^0[0-9]{1}0[0-9]{8}$/,
      message: '電話番号が間違っています。',
    },
  };

  const registration = register(name, options);

  const inputTel = (
    <InputTel
      name={name}
      size="large"
      placeholder="携帯電話の電話番号"
      register={registration}
    />
  );

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <p className={styles.topMessage}>
          お持ちの携帯電話の電話番号を入力してください。
          <br />
          登録された電話番号はSMS認証に使用いたします。
        </p>
        <Container marginTop="s">
          <FormContainer>{[formTitle, inputTel]}</FormContainer>
          <p className={styles.errorMessage}>{errors?.phoneNumber?.message}</p>
        </Container>

        <Container marginTop="m">
          <AttentionContainer>
            ここで入力した電話番号に認証コードをお送りします。
            送信後しばらくして認証番号が届かない場合は再度この画面で電話番号を入力してください
          </AttentionContainer>
        </Container>
      </Container>

      <div className={styles.bottomContainer}>
        <BottomContainer>
          <Divider orientation="horizontal" />
          <Container
            marginTop="s"
            marginBottom="s"
            marginLeft="s"
            marginRight="s"
          >
            <Button
              text="認証コードを受け取る"
              type="primary"
              size="large"
              disabled={!isDirty || !isValid}
              onClick={handleSubmit(onSubmit)}
            />
          </Container>
        </BottomContainer>
      </div>
    </>
  );
};

export default PhoneNumberRegistrationPage;
