import { VFC } from 'react';
import { Divider } from '@chakra-ui/react';
import Container from 'global/components/Container/Container';
import {
  UserOfferViewData,
  UserOfferTypeViewData,
} from '../view-data/offer-top-view-data';
import AdditionalReadingList from './util/ AdditionalReadingList';
import styles from './OfferList.module.css';
import OfferListItem from './OfferListItem';

type Props = {
  offers: UserOfferViewData[];
  isNeedLoadMore: boolean;
  onLoadItem: (cursor: number) => void;
  onTapOffersItem: (id: string, type: UserOfferTypeViewData) => void;
};

const OfferList: VFC<Props> = ({
  offers,
  isNeedLoadMore,
  onLoadItem,
  onTapOffersItem,
}) => (
  <>
    <div className={styles.sectionPanel} id="tutorial-offer">
      <AdditionalReadingList
        isNeedLoadMore={isNeedLoadMore}
        onLoadItem={onLoadItem}
      >
        {offers.map((offer) => (
          <div>
            <Container
              marginTop="s"
              marginBottom="s"
              marginRight="s"
              marginLeft="s"
            >
              <OfferListItem offer={offer} onTapOffersItem={onTapOffersItem} />
            </Container>
            <Divider orientation="horizontal" />
          </div>
        ))}
      </AdditionalReadingList>
    </div>
  </>
);

export default OfferList;
