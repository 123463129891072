import { VFC } from 'react';
import { CommonImage } from '../../../global/utilities/common-image';
import ProgramId from '../data/common/program-id';
import styles from './ProgramListCell.module.css';

type Props = {
  id: ProgramId;
  title: string;
  bannerImage: CommonImage;
  termDescription: string;
  scoreDescription: string;
  isLastElement: boolean;
};

const bannerStyles = (isLastElement: boolean): React.CSSProperties => ({
  position: 'relative',
  marginRight: isLastElement ? '0px' : '16px',
});

const ProgramListCell: VFC<Props> = ({
  id,
  title,
  bannerImage,
  termDescription,
  scoreDescription,
  isLastElement,
}) => (
  <div key={id.value}>
    <div style={bannerStyles(isLastElement)}>
      <img
        className={styles.bannerImage}
        src={bannerImage.original.url}
        alt="banner"
      />
      <div className={styles.termDescriptionMask}>
        <div className={styles.termDescription}>{termDescription}</div>
      </div>
    </div>
    <div className={styles.title}>{title}</div>
    <div className={styles.scoreDescription}>{scoreDescription}</div>
  </div>
);

export default ProgramListCell;
