/* eslint-disable react/jsx-props-no-spreading */
import { VFC } from 'react';
import ActionId from '../data/common/action-id';
import MissionId from '../data/common/mission-id';
import ActionViewData from '../view-data/action-view-data';
import ActionListCell from './ActionListCell';
import styles from './MissionPanelListCell.module.css';

type Props = {
  id: MissionId;
  title: string;
  termDescription: string;
  actionList: ActionViewData[];
  actionButtonTapped: (id: ActionId) => void;
};

const MissionPanelListCell: VFC<Props> = ({
  id,
  title,
  termDescription,
  actionList,
  actionButtonTapped,
}) => (
  <div className={styles.container} key={id.value}>
    <div className={styles.panel}>
      <div className={styles.title}>{title}</div>
      <div className={styles.termDescription}>{termDescription}</div>
      <div>
        {actionList.map((e) => (
          <div key={e.id.value}>
            <ActionListCell {...e} actionButtonTapped={actionButtonTapped} />
            <div className={styles.hr} />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default MissionPanelListCell;
