import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';

const resetPasswordRequestConfig = (
  newPassword: string,
  transactionId: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/password/reset',
  },
  method: 'post',
  parameters: {
    password: newPassword,
    transaction_id: transactionId,
  },
});

interface NewPasswordRepository {
  send: (newPassword: string, transactionId: string) => Promise<void>;
}

export class NewPasswordRepositoryImpl implements NewPasswordRepository {
  send = async (newPassword: string, transactionId: string): Promise<void> =>
    new ApiClient().connect(
      resetPasswordRequestConfig(newPassword, transactionId),
    );
}

export default NewPasswordRepository;
