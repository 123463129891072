import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import IntroductionProgressDto from '../dto/introduction-progress-dto';

const createRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/introduction_progress',
  },
  method: 'get',
  parameters: {},
});

interface IntroductionProgressRepository {
  fetch: () => Promise<IntroductionProgressDto>;
}

export class IntroductionProgressRepositoryImpl
  implements IntroductionProgressRepository
{
  fetch = async (): Promise<IntroductionProgressDto> =>
    new ApiClient().connect(createRequestConfig());
}

export default IntroductionProgressRepository;
