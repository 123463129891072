import { VFC } from 'react';
import styles from './DetailItem.module.css';

type Props = {
  headline: string;
  bodyText: string;
};

const DetailItem: VFC<Props> = ({ headline, bodyText }) => (
  <>
    <div className={styles.offerDetailTitle}>{headline}</div>
    <div className={styles.offerDetailMessage}>{bodyText}</div>
  </>
);

export default DetailItem;
