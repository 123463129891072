import { VFC } from 'react';
import DialogInSlideShow, {
  DialogUsedSlide,
} from '../components/dialog-in-slide-show/DialogInSlideShow';
import useOneTimeDialogInSlideShow from './use-one-time-dialog-in-slide-show';

type Props = {
  tutorialKeyName: string;
  slideList: DialogUsedSlide[];
  completedButtonText?: string;
  incompleteButtonText?: string;
};

const OneTimeDialogInSlideShow: VFC<Props> = ({ tutorialKeyName, slideList, completedButtonText, incompleteButtonText}) => {
  const {
    isOpen,
    currentPage,
    buttonText,
    onButtonClicked,
    onSlidePageChanged,
    onClose,
  } = useOneTimeDialogInSlideShow(tutorialKeyName, slideList.length, completedButtonText, incompleteButtonText);

  return (
    <DialogInSlideShow
      isOpen={isOpen}
      slideElements={slideList}
      selectedPage={currentPage}
      buttonText={buttonText}
      onClose={onClose}
      onButtonClicked={onButtonClicked}
      onPageChanged={onSlidePageChanged}
    />
  );
};

export default OneTimeDialogInSlideShow;
