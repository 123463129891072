import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import QuestionnaireDetailDto from 'features/questionnaire/data/dto/questionnaire-detail-dto';
import PopupInfoDto from 'features/questionnaire/data/dto/popup-info-dto';

export type QuestionAnswer = {
  questionId: number;
  answerId: number | null;
};

const createQuestionnaireDetailRequestConfig = (
  categoryId: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/questionnaire/categories/${categoryId}`,
  },
  method: 'get',
  parameters: {},
});

const createQuestionnairePostRequestConfig = (
  answers: QuestionAnswer[],
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/questionnaire/user_answers`,
  },
  method: 'post',
  parameters: {
    answers: answers.map((answer) => ({
      question_id: answer.questionId,
      answer_id: answer.answerId,
    })),
  },
});

// テスト用Mockデータ
/*
const popupInfoDto: PopupInfoDto = {
  popupInfo: [
    {
      title: 'カテゴリ全問回答を達成!',
      point: 100,
      description: ['就業状況について'],
    },
    {
      title: 'カテゴリ全問回答を達成!',
      point: 300,
      description: ['就業状況について', '余暇の過ごし方について'],
    },
  ],
}; */

interface QuestionnaireRepository {
  fetch: (categoryId: string) => Promise<QuestionnaireDetailDto>;
  save: (answers: QuestionAnswer[]) => Promise<PopupInfoDto>;
}

export class QuestionnaireRepositoryImpl implements QuestionnaireRepository {
  fetch = async (categoryId: string): Promise<QuestionnaireDetailDto> =>
    new ApiClient().connect(createQuestionnaireDetailRequestConfig(categoryId));

  save = async (answers: QuestionAnswer[]): Promise<PopupInfoDto> =>
    new ApiClient().connect(createQuestionnairePostRequestConfig(answers));
  // new Promise((resolve, _) => setTimeout(() => resolve(popupInfoDto), 100));
}

export default QuestionnaireRepository;
