import { useState, VFC } from 'react';
import { ScoreCommentViewData } from '../view-data/top-scores-view-data';
import styles from './TopLifeScoreComment.module.css';

type Props = {
  scoreComments: ScoreCommentViewData[];
};
const commentClassName = (type: string) =>
  type === 'bonus'
    ? `${styles.scoreCommentDetail} ${styles.bonusComment}`
    : `${styles.scoreCommentDetail}`;

const TopLifeScoreComment: VFC<Props> = ({ scoreComments }) => {
  const [currentReadMoreTapped, setReadMoreTapped] = useState(false);

  const scoreCommentsData: ScoreCommentViewData[] = currentReadMoreTapped
    ? scoreComments
    : scoreComments.filter((s) => scoreComments.indexOf(s) === 0);

  return (
    <div className={styles.scoreCommentWrapper}>
      {scoreComments.length ? (
        <>
          {scoreCommentsData.map((scoreComment) => (
            <div className={styles.scoreCommentDetailWrapper}>
              <div className={commentClassName(scoreComment.type)}>
                {scoreComment.text}
              </div>
            </div>
          ))}
          {scoreComments.length >= 2 && (
            <div className={styles.readMoreButtonWrapper}>
              <button
                type="button"
                className={styles.readMoreButton}
                style={{
                  display: currentReadMoreTapped ? 'none' : 'inline-block',
                }}
                onClick={() => setReadMoreTapped(true)}
              >
                もっと見る
              </button>
            </div>
          )}
        </>
      ) : (
        <div className={styles.noScoreCommentDetail} />
      )}
    </div>
  );
};

export default TopLifeScoreComment;
