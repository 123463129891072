import { Divider, Flex } from '@chakra-ui/react';
import { useEffect, VFC } from 'react';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import Container from 'global/components/Container/Container';
import Button from 'global/components/button/Button';
import NoticeContainer from 'global/components/NoticeContainer/NoticeContainer';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import ToggleSwitch from 'global/components/toggle-switch/ToggleSwitch';
import { TutorialLocationState } from 'features/tutorial/hooks/use-tutorial-navigation';
import UndraggableLink from 'global/components/UndraggableLink/UndraggableLink';
import ClientCompanyViewData, {
  ConsentElement,
  StorageViewData,
} from '../view-data/client-company-view-data';
import styles from './ClientCompanyPage.module.css';

type Props = {
  clientConmapnyData: ClientCompanyViewData;
  pageLoaded: () => void;
  storageData: StorageViewData;
  closeButtonTapped: () => void;
  didConsentChanged: (element: ConsentElement) => void;
  saveButtonTapped: () => void;
  locationState?: TutorialLocationState | undefined;
};

const ClientCompanyPage: VFC<Props> = ({
  clientConmapnyData,
  pageLoaded,
  storageData,
  closeButtonTapped,
  didConsentChanged,
  saveButtonTapped,
  locationState,
}) => {
  // ページが描画されたら現在時刻を記録;
  useEffect(() => {
    pageLoaded();
  }, [pageLoaded]);

  const { isOpen, requestShowing, onClose } = useDialog();

  return (
    <>
      <div className={styles.clientCompanyContainer}>
        {!locationState?.isTutorial &&
          storageData.isCloseButtonTapped !== true && (
            <Container marginBottom="s" marginLeft="s" marginRight="s">
              <NoticeContainer closeButtonCustomAction={closeButtonTapped}>
                ここで「公開」を選択した企業へデータが公開されます。
                <br />
                公開するデータが多いほど、様々な企業からオファーを受けることができます。
              </NoticeContainer>
            </Container>
          )}

        {locationState?.isTutorial && (
          <div id="tutorial-mydata-client-company">
            <Container marginTop="s" marginBottom="s">
              <div
                className={[
                  styles.sectionWrapper,
                  styles.sectionWrapperHighlight,
                ].join(' ')}
              >
                <div
                  id="all-items"
                  className={[
                    styles.sectionTitle,
                    styles.sectionTitleHighlight,
                  ].join(' ')}
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    推奨：登録企業をまとめて公開選択する
                    <ToggleSwitch
                      onOffText={{ onText: '公開', offText: '非公開' }}
                      isOn={clientConmapnyData.sections.every((section) =>
                        section.clients.every((client) => client.consent),
                      )}
                      onClick={(isChecked) =>
                        didConsentChanged({
                          type: 'all',
                          name: 'all-items',
                          isChecked,
                        })
                      }
                    />
                  </Flex>
                </div>
              </div>
            </Container>
          </div>
        )}
        {clientConmapnyData.sections.map(
          (section) =>
            section.clients.length > 0 && (
              <>
                <div className={styles.sectionWrapper}>
                  <div className={styles.sectionTitle}>
                    <Flex alignItems="center" justifyContent="space-between">
                      {section.title}
                      <Flex alignItems="center" justifyContent="space-between">
                        <div className={styles.toggleSwitchText}>一括選択</div>
                        <ToggleSwitch
                          onOffText={{ onText: '公開', offText: '非公開' }}
                          isOn={section.clients.every(
                            (client) => client.consent,
                          )}
                          onClick={(isChecked) =>
                            didConsentChanged({
                              type: 'section',
                              name: section.title,
                              isChecked,
                            })
                          }
                        />
                      </Flex>
                    </Flex>
                  </div>
                </div>
                {section.clients.map((client) => (
                  <>
                    <div className={styles.clientWrapper}>
                      <Flex alignItems="center" justifyContent="space-between">
                        <UndraggableLink
                          to={{
                            pathname: `/mydata/client_companies/${client.clientId}`,
                            state: { isTutorial: locationState?.isTutorial },
                          }}
                          className={styles.clientInfoWrapper}
                        >
                          <div className={styles.clientCompanyName}>
                            {client.companyName}
                          </div>
                          <div className={styles.clientMajorBrands}>
                            {client.majorBrands}
                          </div>
                        </UndraggableLink>
                        <ToggleSwitch
                          onOffText={{ onText: '公開', offText: '非公開' }}
                          isOn={client.consent}
                          onClick={(isChecked) =>
                            didConsentChanged({
                              type: 'item',
                              name: client.clientId,
                              isChecked,
                            })
                          }
                        />
                      </Flex>
                    </div>
                    <div className={styles.dividerWrapper}>
                      <Divider orientation="horizontal" />
                    </div>
                  </>
                ))}
              </>
            ),
        )}
      </div>
      <BottomContainer>
        <Divider orientation="horizontal" />
        <Container
          marginTop="s"
          marginBottom="s"
          marginLeft="s"
          marginRight="s"
        >
          <Button
            text={
              locationState?.isTutorial ? '公開先企業を確定する' : '更新する'
            }
            type="primary"
            size="large"
            onClick={requestShowing}
          />
        </Container>
      </BottomContainer>
      <Dialog
        title="確認画面"
        description="この画面で公開選択した企業に、「公開するデータの選択」画面で「公開」を選択しているデータが公開されます。"
        primaryButton={{
          text: '更新する',
          onClick: saveButtonTapped,
        }}
        isOpen={isOpen}
        showsCloseButton={false}
        onClose={onClose}
      />
    </>
  );
};

export default ClientCompanyPage;
