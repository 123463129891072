import { useQuery } from 'react-query';
import { isErrorDTO } from 'data/dto/error-dto';
import Result from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { useState } from 'react';
import YearMonthDay from 'global/utilities/year-month-day';
import { DateTime } from 'luxon';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import CampaignSummaryRepository, {
  CampaignSummaryRepositoryImpl,
} from '../data/repositories/campaign-summary-repository';
import CampaignSummaryViewData from '../view-data/campaign-summary-view-data';
import CampaignSummaryDto from '../data/dto/campaign-summary-dto';

type ReturnType = {
  summaryBoardVisibleOnTop: boolean;
  hideSummaryBoadFromTop: () => void;
  checkSummaryBoardDisalbe: (data: CampaignSummaryViewData) => void;
  campaignSummaryResult: Result<CampaignSummaryViewData, Error>;
  topBannerVisible: boolean;
};

// viewで欲しいデータを作成
// - 取得できるトータルポイント
// - どのステップまで進んでいるか
// - 次のステップまで何問あるか
// - 次のステップで何ポイントもらえるか
// - プログレスバー進捗率
export const convertDtoToViewData = (
  dto: CampaignSummaryDto,
): CampaignSummaryViewData => {
  let achievedStep = 0;
  dto.steps.forEach((step) => {
    // nextTargetQuestionCountより現在の回答数が多ければ達成ステップを一つ上げる
    if (dto.answeredQuestionsCount >= step.achieveCount) {
      achievedStep += 1;
    }
  });

  let questionCountForNextStep =
    achievedStep === dto.steps.length
      ? 0
      : dto.steps[achievedStep].achieveCount - dto.answeredQuestionsCount;

  let pointForNextStep =
    achievedStep === dto.steps.length ? 0 : dto.steps[achievedStep].point;

  // プログレスバーは線形で進まないので、各ステップの間を計算する
  // 単位ステップの長さ
  const stepUnit = 100 / (dto.steps.length - 1);
  let progressLength = 0;
  if (achievedStep === dto.steps.length) {
    progressLength = 100;
  } else if (achievedStep !== 0) {
    // 達成済みのポイントから次のターゲット前の間の進捗
    const localProgressLength =
      (dto.answeredQuestionsCount - dto.steps[achievedStep - 1].achieveCount) /
      (dto.steps[achievedStep].achieveCount -
        dto.steps[achievedStep - 1].achieveCount);
    progressLength = Math.floor(
      stepUnit * (achievedStep - 1) + stepUnit * localProgressLength,
    );
  }

  // 達成済みだがユーザーが回答を削除したために進捗が落ちている場合の補正
  // 実際の回答数ではなくisAchieveを優先させる
  // 達成は覆らない仕様
  let displayAchievedStep = 0;
  dto.steps.forEach((step, index) => {
    if (step.isAchieve) {
      displayAchievedStep = index + 1;
    }
  });

  // displayAchievedStepがachievedStepを上回っている場合は補正値に直す
  if (displayAchievedStep > achievedStep) {
    progressLength = Math.floor((displayAchievedStep - 1) * stepUnit);
    pointForNextStep = dto.steps[displayAchievedStep].point;
    questionCountForNextStep =
      dto.steps[displayAchievedStep].achieveCount - dto.answeredQuestionsCount;
    achievedStep = displayAchievedStep;
  }

  const endAt = DateTime.fromMillis(Date.parse(dto.endAt)).setZone(
    'Asia/Tokyo',
  );
  // 時間部分は0埋めする
  const endAtLabel = `${endAt.year}/${endAt.month}/${
    endAt.day
  } ${`0${endAt.hour}`.slice(-2)}:${`0${endAt.minute}`.slice(
    -2,
  )}:${`0${endAt.second}`.slice(-2)}`;

  const isEnd = Date.parse(dto.endAt) < Date.now();

  const campaignSummaryViewData: CampaignSummaryViewData = {
    isAchieve: dto.isAchieve,
    totalPoint: dto.totalRecieptPoint,
    achievedStep,
    questionCountForNextStep,
    pointForNextStep,
    progress: progressLength,
    steps: dto.steps,
    endAt: endAtLabel,
    isEnd,
  };

  return campaignSummaryViewData;
};

const useCampaignSummary = (
  repository: CampaignSummaryRepository = new CampaignSummaryRepositoryImpl(),
): ReturnType => {
  const [summaryBoardVisibleOnTop, setSummaryBoardVisibleOnTop] =
    useState(true);

  const hideSummaryBoadFromTop = (): void => {
    setSummaryBoardVisibleOnTop(() => false);
  };

  let topBannerVisible = true;

  const checkSummaryBoardDisalbe = (data: CampaignSummaryViewData): void => {
    if (!summaryBoardVisibleOnTop) {
      return;
    }

    // トップから消す条件(上から優先順位高い)
    // 1. キャンペーンが終了している場合
    // 2. is_archieveがtrueの場合
    // 3. progressが100%の場合
    // 4. 今日表示しないを押している場合(localStorageに保存)

    if (data.isEnd || data.isAchieve || data.progress === 100) {
      hideSummaryBoadFromTop();
      topBannerVisible = false;

      return;
    }

    const prevHidedAt = localStorage.getItem('campaignTopBoardHidedAt');
    if (prevHidedAt) {
      const prevYearMonthDay = YearMonthDay.fromString(prevHidedAt);
      if (
        prevYearMonthDay &&
        prevYearMonthDay.diffOfDays(new YearMonthDay(DateTime.local())) <= 0
      ) {
        hideSummaryBoadFromTop();
      }
    }
  };

  const queryResult = useQuery<CampaignSummaryViewData, Error>(
    ['/campaign/register/progress'],
    async () => {
      const dto = await repository.fetch().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertDtoToViewData(dto);
    },
  );

  const campaignSummaryResult = createResult(queryResult);

  return {
    summaryBoardVisibleOnTop,
    hideSummaryBoadFromTop,
    checkSummaryBoardDisalbe,
    campaignSummaryResult,
    topBannerVisible,
  };
};

export default useCampaignSummary;
