import { VFC } from 'react';
import styles from './QuestionnaireCaution.module.css';

type Props = {
  text: string;
};

const QuestionnaireCaution: VFC<Props> = ({ text }) => (
  <>
    <div className={styles.offerTerms}>
      <div className={styles.termsTitle}>注意事項</div>
      <div className={styles.termsBody}>
        本キャンペーンをご利用の方（以下、「お客様」といいます）は、以下をよくお読みいただき、同意の上ご利用ください。同意頂けない場合はご利用をお控えください。なお、ご利用される場合は以下の内容に同意頂いたものとさせていただきます。<br />
        ・アンケートの回答は一人一回のみ有効です。<br />
        ・アンケートに回答することで、FitStatsポイントが謝礼として付与されます。<br />
        ・予定している回答数に達した場合は、締切期限より早くアンケートが終了する場合がございます。あらかじめご了承ください。<br />
        ・アンケート内容およびアンケートで知り得た情報については、第三者に口外しないよう（SNSやWebサイトへの書込みを含む）、ご協力お願いいたします。<br />
        ・不正等があった場合にはFitStatsポイントの没収やアカウントの停止措置を取らせていただく場合がございます。<br />
        ・アンケートは予告なく変更・中止になる場合がございます。あらかじめご了承ください。<br />
        {text && 
          <>
            <div className={styles.termsSubTitle}>アンケートを回答頂く際のお願い</div>
            {text}
          </>
        }
      </div>
    </div>
  </>
);

export default QuestionnaireCaution;
