import { VFC } from 'react';
import { useHistory } from 'react-router';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import PasswordResettingsPage from '../pages/PasswordResettingsPage';
import usePasswordResettings from '../hooks/use-password-resettings';

const PasswordResettingsContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, dialogProps, onClose } = useDialog();
  useNavigationBarItemsSetting('パスワードの再設定');
  const { submitted } = usePasswordResettings((saveResult) => {
    if (saveResult.isSuccess()) {
      requestShowing({
        description: 'パスワードの再設定が完了しました。',
        primaryButton: { text: 'OK', onClick: () => history.push('/settings') },
      });
    }
    if (saveResult.isFailure()) {
      requestShowing({
        title: 'エラー',
        description: saveResult.error,
        primaryButton: { text: 'OK' },
      });
    }
  });

  return (
    <>
      <Dialog
        title={dialogProps?.title}
        description={dialogProps?.description}
        primaryButton={dialogProps?.primaryButton ?? { text: '閉じる' }}
        isOpen={isOpen}
        onClose={onClose}
      />

      <PasswordResettingsPage submitted={submitted} />
    </>
  );
};

export default PasswordResettingsContainer;
