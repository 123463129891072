import { VFC } from 'react';
import { useHistory } from 'react-router';
import useCampaignSummary from '../hooks/use-campaign-summary';
import styles from './CampaignBanner.module.css';

const CampaignBanner: VFC = () => {
  const { campaignSummaryResult, topBannerVisible } = useCampaignSummary();
  const history = useHistory();

  const linkToCampaign = () => {
    history.push('/campaign');
  };

  return (
    <>
      {campaignSummaryResult.isSuccess() && topBannerVisible && (
        <div aria-hidden onClick={linkToCampaign}>
          <img
            src="/campaign/campaign-banner.png"
            alt=""
            className={styles.image}
          />
        </div>
      )}
    </>
  );
};
export default CampaignBanner;
