import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import InputCodeRepository, {
  InputCodeRepositoryImpl,
} from '../data/repositories/input-code-repository';

type ReturnType = {
  submitted: (email: string, transactionId: string) => void;
};

const useInputCode = (
  saveResult: (result: Result<string, string>) => void,
  repository: InputCodeRepository = new InputCodeRepositoryImpl(),
): ReturnType => {
  const submitted = (email: string, transactionId: string): void => {
    const _ = repository
      .send(email, transactionId)
      .then(() => {
        saveResult(new Success(transactionId));
      })
      .catch((e) => {
        saveResult(
          new Failure(
            isErrorDTO(e) ? e.error.message : '認証コードの送信に失敗しました',
          ),
        );
      });
  };

  return {
    submitted,
  };
};

export default useInputCode;
