import { useRef, VFC } from 'react';
import { useForm } from 'react-hook-form';
import Container from 'global/components/Container/Container';
import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import InputText from 'global/components/InputText/InputText';
import Button from 'global/components/button/Button';
import AttentionContainer from 'global/components/AttentionContainer/AttentionContainer';
import styles from './EmailResettingsPage.module.css';

type Props = {
  submitted: (currentEmail: string, newEmail: string) => void;
};

type FormData = {
  currentEmail: string;
  newEmail: string;
  newEmailConfirmation: string;
};

const EmailResettingsPage: VFC<Props> = ({ submitted }) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty, isValid },
  } = useForm<FormData>({ mode: 'onChange' });

  const processing = useRef(false);
  const onSubmit = (data: FormData) => {
    // TODO: 今後のことを考えると専用のコンポーネントにするのが望ましい
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;

    // 処理中フラグを上げる
    processing.current = true;

    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 3000);

    submitted(data.currentEmail, data.newEmail);
  };

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <div className={styles.body}>
          <Container marginTop="m">
            <FormContainer>
              {[
                <FormTitle size="large" text="現在のメールアドレス" />,
                <InputText
                  name="currentEmail"
                  placeholder="example@stats.com"
                  size="large"
                  register={register('currentEmail', {
                    required: '現在のメールアドレスは入力必須の項目です。',
                    pattern: {
                      value: /.+@.+/,
                      message: '正しいメールアドレスを入力してください。',
                    },
                    maxLength: {
                      value: 50,
                      message: '50文字以下で入力してください。',
                    },
                  })}
                />,
              ]}
            </FormContainer>
            <p className={styles.errorMessage}>
              {errors?.currentEmail?.message}
            </p>
          </Container>
          <Container marginTop="m">
            <FormContainer>
              {[
                <FormTitle size="large" text="新しいメールアドレス" />,
                <InputText
                  name="newEmail"
                  placeholder="new@stats.com"
                  size="large"
                  register={register('newEmail', {
                    required: '新しいメールアドレスは入力必須の項目です。',
                    pattern: {
                      value: /.+@.+/,
                      message: '正しいメールアドレスを入力してください。',
                    },
                    maxLength: {
                      value: 50,
                      message: '50文字以下で入力してください。',
                    },
                  })}
                />,
              ]}
            </FormContainer>
            <p className={styles.errorMessage}>{errors?.newEmail?.message}</p>
          </Container>
          <Container marginTop="m">
            <FormContainer>
              {[
                <FormTitle size="large" text="新しいメールアドレスの確認" />,
                <InputText
                  name="newEmailConfirmation"
                  placeholder="new@stats.com"
                  size="large"
                  register={register('newEmailConfirmation', {
                    validate: (value) =>
                      value === getValues('newEmail') ||
                      '新しいメールアドレスと一致しません。',
                  })}
                />,
              ]}
            </FormContainer>
            <p className={styles.errorMessage}>
              {errors?.newEmailConfirmation?.message}
            </p>
          </Container>
        </div>
      </Container>
      <Container marginTop="xl" marginLeft="s" marginRight="s">
        <AttentionContainer>
          ここで入力したメールアドレスに認証コードをお送りします。送信後しばらくして認証番号が届かない場合は再度この画面でメールアドレスを入力してください。
        </AttentionContainer>
      </Container>
      <Container marginTop="4xl" marginLeft="s" marginRight="s">
        <Button
          text="認証コードを受け取る"
          type="primary"
          size="large"
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(onSubmit)}
        />
      </Container>
    </>
  );
};

export default EmailResettingsPage;
