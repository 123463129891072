import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import PopupInfoDto from 'features/questionnaire/data/dto/popup-info-dto';
import { UserInputPostItem } from '../../user-input-item';
import ProfileDto from '../dto/profile-dto';

const createProfileRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/profile',
  },
  method: 'get',
  parameters: {},
});

const createProfilePostRequestConfig = (
  userInputItems: UserInputPostItem[],
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/profile',
  },
  method: 'put',
  parameters: { user_profile: userInputItems },
});

interface ProfileRepository {
  fetch: () => Promise<ProfileDto>;
  save: (userProfile: UserInputPostItem[]) => Promise<PopupInfoDto>;
}

export class ProfileRepositoryImpl implements ProfileRepository {
  fetch = async (): Promise<ProfileDto> =>
    new ApiClient().connect(createProfileRequestConfig());

  save = async (userProfile: UserInputPostItem[]): Promise<PopupInfoDto> =>
    new ApiClient().connect(createProfilePostRequestConfig(userProfile));
}

export default ProfileRepository;
