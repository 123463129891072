import { useHistory } from 'react-router';

declare global {
  interface Window {
    menuButtonTapped: (type: NavigationButtonType) => void;
  }
}

type NavigationButtonType = 'notification' | 'menu';

const useNavigationButtonHandler = (): void => {
  const history = useHistory();

  window.menuButtonTapped = (type: NavigationButtonType) => {
    switch (type) {
      case 'notification':
        history.push('/notice');
        break;
      case 'menu':
        history.push('/settings');
        break;
      default: {
        const _: never = type;
        break;
      }
    }
  };
};

export default useNavigationButtonHandler;
