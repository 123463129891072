import { VFC } from 'react';
import ActionId from '../data/common/action-id';
import {
  ActionButtonStateViewObject,
  ActionProgressTypeViewObject,
  ActionRequirementsViewState,
  ActionRewardViewObject,
  isEnableButton,
} from '../view-data/action-view-data';
import ProgressBar from './common/ProgressBar';
import styles from './ActionListCell.module.css';

type Props = {
  id: ActionId;
  title: string;
  reward: ActionRewardViewObject;
  progressType: ActionProgressTypeViewObject;
  requirementsViewState: ActionRequirementsViewState;
  actionButtonTapped: (id: ActionId) => void;
};

const actionButtonStyles = (
  state: ActionButtonStateViewObject,
): React.CSSProperties => {
  const buttonBackGroundColor = (): string => {
    switch (state.kind) {
      case 'progressing':
        return 'var(--color-light)';
      case 'notStartedYet':
      case 'rewardReceived':
      case 'todaysAchieved':
        return '#f4f5f7';
      case 'rewardCanBeReceived':
        return 'var(--color-primary-100)';
      default:
        return '#f4f5f7';
    }
  };

  const buttonTextColor = (): string => {
    switch (state.kind) {
      case 'progressing':
        return 'var(--color-primary-100)';
      case 'notStartedYet':
      case 'rewardReceived':
      case 'todaysAchieved':
        return '#424242';
      case 'rewardCanBeReceived':
        return 'var(--color-light)';
      default:
        return '#424242';
    }
  };

  const border = (): string => {
    switch (state.kind) {
      case 'progressing':
        return 'solid 1px var(--color-primary-100)';
      case 'notStartedYet':
      case 'rewardReceived':
      case 'todaysAchieved':
      case 'rewardCanBeReceived':
      default:
        return 'none';
    }
  };

  return {
    flexShrink: 0,
    width: '96px',
    height: '28px',
    padding: '4px 8px',
    marginLeft: '16px',
    marginTop: '8px',
    border: border(),
    borderRadius: '20px',
    fontFamily: 'HiraginoSans-W6',
    fontSize: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: buttonTextColor(),
    backgroundColor: buttonBackGroundColor(),
  };
};

const ActionListCell: VFC<Props> = ({
  id,
  title,
  reward,
  progressType,
  requirementsViewState,
  actionButtonTapped,
}) => (
  <div key={id.value} className={styles.container}>
    <div className={styles.leftArea}>
      <div className={styles.leftAreaChild}>
        <div className={styles.rewardIconArea}>
          <div className={styles.icon}>
            <img src={reward.iconPath} alt={`rewadIcon_${id.value}`} />
          </div>
          <div className={styles.value}>{reward.valueText}</div>
        </div>
      </div>
    </div>
    <div className={styles.summaryArea}>
      <div className={styles.title}>{title}</div>
      <div className={styles.underContentsArea}>
        {progressType.kind === 'rate' && (
          <div className={styles.progressRateArea}>
            <div className={styles.progressTextValue}>
              <div>
                <span className={styles.numerator}>
                  {progressType.numeratorText}
                </span>
                <span
                  className={styles.otherValue}
                >{`/ ${progressType.denominatorText} ${progressType.unitSuffixText}`}</span>
              </div>
            </div>
            <div className={styles.progressBar}>
              <ProgressBar currentDecimalRate={progressType.progressValue} />
            </div>
          </div>
        )}
        {requirementsViewState.kind === 'exist' && (
          <button
            style={actionButtonStyles(requirementsViewState.button.state)}
            type="button"
            disabled={!isEnableButton(requirementsViewState.button.state)}
            onClick={() => actionButtonTapped(id)}
          >
            {requirementsViewState.button.state.buttonText}
          </button>
        )}
      </div>
    </div>
  </div>
);

export default ActionListCell;
