import { VFC } from 'react';
import { useHistory } from 'react-router';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import PhoneNumberResettingsPage from '../pages/PhoneNumberResettingsPage';
import usePhoneNumberResettings from '../hooks/use-phone-number-resettings';

const PhoneNumberResettingsContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, onClose } = useDialog();
  useNavigationBarItemsSetting('電話番号の再設定');

  const { submitErrorMessage, submitted } = usePhoneNumberResettings(
    (saveResult) => {
      if (saveResult.isSuccess()) {
        history.push('/phone_number_settings/authentication', {
          phoneNumber: saveResult.value,
        });
      }
      if (saveResult.isFailure()) {
        requestShowing();
      }
    },
  );

  return (
    <>
      <Dialog
        title="エラー"
        description={submitErrorMessage}
        primaryButton={{ text: 'OK' }}
        isOpen={isOpen}
        onClose={onClose}
      />

      <PhoneNumberResettingsPage submitted={submitted} />
    </>
  );
};

export default PhoneNumberResettingsContainer;
