export const URL = {
  FIT_STATS: process.env.REACT_APP_FIT_STATS_BASE_URL,
};

export type EndPoint = {
  baseUrl: string;
  path: string;
};

export type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'delete';

// eslint-disable-next-line @typescript-eslint/ban-types
export type RequestParameters = object;

type RequestConfig = {
  endpoint: EndPoint;
  method: HttpMethod;
  parameters: RequestParameters;
};

export default RequestConfig;
