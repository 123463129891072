import { VFC } from 'react';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import AcountSettingsRootPage from '../pages/AcountSettingsRootPage';
import useAcountSettingsRoot from '../hooks/use-acount-settings-root';

const AcountSettingsRootContainer: VFC = () => {
  const { viewData, onMenuClick } = useAcountSettingsRoot();
  useNavigationBarItemsSetting('アカウント設定');

  return (
    <AcountSettingsRootPage viewData={viewData} onMenuClick={onMenuClick} />
  );
};

export default AcountSettingsRootContainer;
