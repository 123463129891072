import { VFC } from 'react';
import { Carousel } from 'react-responsive-carousel';
import styles from './DialogUsedCarousel.module.css';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

type Props = {
  children: JSX.Element[];
  selectedPage?: number;
  onPageChanged?: (page: number) => void;
};

// NOTE: 一旦react-responsive-carouselというプラグインを使用してます。
// ベキ論で言えばカルーセル表現は自作した方が良いですが時間短縮のため。
const DialogUsedCarousel: VFC<Props> = ({
  children,
  selectedPage,
  onPageChanged,
}) => (
  <Carousel
    showThumbs={false}
    showArrows={false}
    showStatus={false}
    selectedItem={selectedPage}
    onChange={(index, _) => onPageChanged?.call(this, index)}
    renderIndicator={(onClickHandler, isSelected, index, label) => (
      <button
        className={isSelected ? styles.activePageDot : styles.deactivePageDot}
        type="button"
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        tabIndex={0}
        aria-label={`${label} ${index + 1}`}
      />
    )}
  >
    {children}
  </Carousel>
);

export default DialogUsedCarousel;
