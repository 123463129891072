import PhoneNumberAuthenticationRepository, {
  PhoneNumberAuthenticationRepositoryImpl,
} from 'features/settings/phone-number/authentication/data/repositories/phone-number-authentication-repository';
import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import { useRef } from 'react';

type ReturnType = {
  submitErrorMessage?: string;
  submitted: (phoneNumberAuthCode: number) => void;
};

const usePhoneNumberAuthentication = (
  repository: PhoneNumberAuthenticationRepository = new PhoneNumberAuthenticationRepositoryImpl(),
  saveResult: (result: Result<void, void>) => void,
): ReturnType => {
  const submitErrorMessage = useRef<string | undefined>(undefined);

  const submitted = async (phoneNumberAuthCode: number): Promise<void> => {
    try {
      await repository.auth(phoneNumberAuthCode);
      saveResult(new Success(undefined));
    } catch (error) {
      submitErrorMessage.current = isErrorDTO(error)
        ? error.error.message
        : 'SMS認証に失敗しました';
      saveResult(new Failure(undefined));
    }
  };

  return {
    submitErrorMessage: submitErrorMessage.current,
    submitted,
  };
};

export default usePhoneNumberAuthentication;
