import { VFC } from 'react';
import styles from './Pagination.module.css';

type Props = {
  currentIndex: number;
  listIndex: number;
};

const Pagination: VFC<Props> = ({ currentIndex, listIndex }) => (
  <>
    <div
      className={currentIndex === listIndex ? styles.currentPage : styles.pager}
    />
  </>
);

export default Pagination;
