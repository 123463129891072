import ActionDomainObject, {
  ActionAchievedState,
  ActionProgressState,
  ActionRequirements,
  ActionRequirementsEnableState,
  ActionRequirementsDisableReason,
  ActionRequirementsLifelogMealActionType,
  ActionRequirementsLifelogMealInputType,
  ActionRequirementsType,
  ActionReward,
} from '../action-domain-object';
import ActionDto, {
  ActionRequirementsLifelogMealDetailDto,
  ActionStatsRewardDto,
} from '../../dto/action-dto';

const convertStatsReward = (dto: ActionStatsRewardDto): ActionReward => {
  switch (dto.type) {
    case 'meal':
      return {
        kind: 'mealStats',
        iconPath: '/global/meal_icon.svg',
        value: { score: dto.value.score },
      };
    case 'sleep':
      return {
        kind: 'sleepStats',
        iconPath: '/global/sleep_icon.svg',
        value: { score: dto.value.score },
      };
    case 'body':
      return {
        kind: 'bodyStats',
        iconPath: '/global/body_icon.svg',
        value: { score: dto.value.score },
      };
    case 'exercise':
      return {
        kind: 'exerciseStats',
        iconPath: '/global/exercise_icon.svg',
        value: { score: dto.value.score },
      };
    case 'mental':
      return {
        kind: 'mentalStats',
        iconPath: '/global/mental_icon.svg',
        value: { score: dto.value.score },
      };
    default:
      throw new Error(`Unexpected Object`);
  }
};

export const rewardTitle = (reward: ActionReward): string => {
  switch (reward.kind) {
    case 'mealStats':
      return '食事スタッツ';
    case 'sleepStats':
      return '睡眠スタッツ';
    case 'bodyStats':
      return 'カラダスタッツ';
    case 'exerciseStats':
      return '運動スタッツ';
    case 'mentalStats':
      return 'メンタルスタッツ';
    default:
      throw new Error(`Unexpected Object`);
  }
};

const convertMealInputType = (
  type: string,
): ActionRequirementsLifelogMealInputType => {
  switch (type) {
    case 'breakfast':
      return { kind: 'breakfast' };
    case 'lunch':
      return { kind: 'lunch' };
    case 'dinner':
      return { kind: 'dinner' };
    case 'snack':
      return { kind: 'snack' };
    default:
      throw new Error(`Unexpected Object`);
  }
};

export const actionRequirementsLifelogMealInputTypeWhen = <T>({
  type,
  breakfast,
  lunch,
  dinner,
  snack,
}: {
  type: ActionRequirementsLifelogMealInputType;
  breakfast: () => T;
  lunch: () => T;
  dinner: () => T;
  snack: () => T;
}): T => {
  switch (type.kind) {
    case 'breakfast':
      return breakfast();
    case 'lunch':
      return lunch();
    case 'dinner':
      return dinner();
    case 'snack':
      return snack();
    default:
      throw new Error(`Unexpected Object`);
  }
};

const convertInputType = (
  detail: ActionRequirementsLifelogMealDetailDto,
): ActionRequirementsLifelogMealActionType => {
  switch (detail.optionalActionType) {
    case 'no_need':
      return {
        kind: 'noNeed',
      };
    case 'input_specific':
      if (!detail.optionalActionInputMealType) {
        throw new Error(
          'アクション要件は具体的な時間帯の食事記録を要求していますが時間帯情報を取得できませんでした。',
        );
      }

      return {
        kind: 'inputSpecific',
        type: convertMealInputType(detail.optionalActionInputMealType),
      };
    default:
      return {
        kind: 'noNeed',
      };
  }
};

export const actionRequirementsLifelogMealActionTypeWhen = <T>({
  type,
  noNeed,
  inputSpecific,
}: {
  type: ActionRequirementsLifelogMealActionType;
  noNeed: () => T;
  inputSpecific: (inputType: ActionRequirementsLifelogMealInputType) => T;
}): T => {
  switch (type.kind) {
    case 'noNeed':
      return noNeed();
    case 'inputSpecific':
      return inputSpecific(type.type);
    default:
      throw new Error(`Unexpected Object`);
  }
};

const convertRequirementsDisableReason = (
  reason: string,
): ActionRequirementsDisableReason => {
  switch (reason) {
    case 'today_achieved':
      return { kind: 'todaysAchieved' };
    default:
      throw new Error(`Unexpected Object`);
  }
};

export const actionRequirementsDisableReasonWhen = <T>({
  reason,
  todaysAchieved,
}: {
  reason: ActionRequirementsDisableReason;
  todaysAchieved: () => T;
}): T => {
  switch (reason.kind) {
    case 'todaysAchieved':
      return todaysAchieved();
    default:
      throw new Error(`Unexpected Object`);
  }
};

export const actionRequirementsEnableStateWhen = <T>({
  state,
  enable,
  disable,
}: {
  state: ActionRequirementsEnableState;
  enable: () => T;
  disable: (reason: ActionRequirementsDisableReason) => T;
}): T => {
  switch (state.kind) {
    case 'enable':
      return enable();
    case 'disable':
      return disable(state.reason);
    default:
      throw new Error(`Unexpected Object`);
  }
};

const convertRequirementsType = (action: ActionDto): ActionRequirementsType => {
  switch (action.requirements.type) {
    case 'open_external_web_contents':
      if (!action.requirements.openExternalWebContentsDetail) {
        throw new Error(
          'アクション要件は外部ブラウザの展開を指定していますがurlなどの情報を取得できませんでした。',
        );
      }

      return {
        kind: 'openExternalWebContents',
        actionId: action.id,
        url: action.requirements.openExternalWebContentsDetail.url,
      };
    case 'self_reporting':
      return {
        kind: 'selfReporting',
      };
    case 'selfcheck':
      return {
        kind: 'selfCheck',
      };
    case 'lifelog_meal_record':
      if (!action.requirements.lifelogMealContentsDetail) {
        throw new Error(
          'アクション要件は食事記録を指定していますが記録したい時間帯などの詳細情報を取得できませんでした。',
        );
      }

      return {
        kind: 'lifelogMealRecord',
        actionType: convertInputType(
          action.requirements.lifelogMealContentsDetail,
        ),
      };
    case 'lifelog_step_record':
      return {
        kind: 'lifelogStepRecord',
      };
    case 'lifelog_body_weight_record':
      return {
        kind: 'lifelogBodyWeightRecord',
      };
    case 'lifelog_sleep_record':
      return {
        kind: 'lifelogSleepRecord',
      };
    default:
      throw new Error(`Unexpected Object`);
  }
};

export const actionRequirementsTypeWhen = <T>({
  type,
  openExternalWebContents,
  selfReporting,
  selfCheck,
  lifelogMealRecord,
  lifelogStepRecord,
  lifelogBodyWeightRecord,
  lifelogSleepRecord,
}: {
  type: ActionRequirementsType;
  openExternalWebContents: (url: string) => T;
  selfReporting: () => T;
  selfCheck: () => T;
  lifelogMealRecord: (actionType: ActionRequirementsLifelogMealActionType) => T;
  lifelogStepRecord: () => T;
  lifelogBodyWeightRecord: () => T;
  lifelogSleepRecord: () => T;
}): T => {
  switch (type.kind) {
    case 'openExternalWebContents':
      return openExternalWebContents(type.url);
    case 'selfReporting':
      return selfReporting();
    case 'selfCheck':
      return selfCheck();
    case 'lifelogMealRecord':
      return lifelogMealRecord(type.actionType);
    case 'lifelogStepRecord':
      return lifelogStepRecord();
    case 'lifelogBodyWeightRecord':
      return lifelogBodyWeightRecord();
    case 'lifelogSleepRecord':
      return lifelogSleepRecord();
    default:
      throw new Error(`Unexpected Object`);
  }
};

const convertRequirements = (action: ActionDto): ActionRequirements => ({
  title: action.requirements.title,
  enableState: action.requirements.isEnabled
    ? { kind: 'enable' }
    : {
        kind: 'disable',
        reason: convertRequirementsDisableReason(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          action.requirements.isEnabledFalseDetail!.reason,
        ),
      },
  type: convertRequirementsType(action),
});

const convertAchievedState = (action: ActionDto): ActionAchievedState => {
  switch (action.achievementState) {
    case 'not_achieved':
      return { kind: 'progressing' };
    case 'achieved':
      return { kind: 'done' };
    case 'rewarded':
      return { kind: 'rewardReceived' };
    default:
      throw new Error(`Unexpected Object`);
  }
};

export const actionAchieveStateWhen = <T>({
  state,
  progressing,
  done,
  rewardReceived,
}: {
  state: ActionAchievedState;
  notStartedYet: () => T;
  progressing: () => T;
  done: () => T;
  rewardReceived: () => T;
}): T => {
  switch (state.kind) {
    case 'progressing':
      return progressing();
    case 'done':
      return done();
    case 'rewardReceived':
      return rewardReceived();
    default:
      throw new Error(`Unexpected Object`);
  }
};

export const convertProgressState = (
  action: ActionDto,
): ActionProgressState => {
  switch (action.progressType) {
    case 'no_need':
      return {
        kind: 'noNeed',
      };
    case 'rate':
      if (!action.progressRateDetail) {
        throw new Error(`アクションの進捗情報が取得できませんでした。`);
      }

      return {
        kind: 'rate',
        allCountText: action.progressRateDetail.totalCount,
        achivedCountText: action.progressRateDetail.achievedCount,
        rateValue: action.progressRateDetail.rateValue,
        unitSuffix: action.progressRateDetail.unitValue,
      };
    default:
      throw new Error(`Unexpected Object`);
  }
};

export const actionProgressStateWhen = <T>({
  state,
  noNeed,
  rate,
}: {
  state: ActionProgressState;
  noNeed: () => T;
  rate: (
    allCountText: string,
    achivedCountText: string,
    rateValue: number,
    unitSuffix: string,
  ) => T;
}): T => {
  switch (state.kind) {
    case 'noNeed':
      return noNeed();
    case 'rate':
      return rate(
        state.allCountText,
        state.achivedCountText,
        state.rateValue,
        state.unitSuffix,
      );
    default:
      throw new Error(`Unexpected Object`);
  }
};

export const convertActionDtoToDomainObject = (
  dto: ActionDto,
): ActionDomainObject => ({
  id: dto.id,
  title: dto.title,
  reward: convertStatsReward(dto.statsReward),
  achievementState: convertAchievedState(dto),
  progressState: convertProgressState(dto),
  requirements: convertRequirements(dto),
});
