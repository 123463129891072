import { useEffect, useRef, useState, VFC } from 'react';
import Button from 'global/components/button/Button';
import Container from 'global/components/Container/Container';
import { Divider } from '@chakra-ui/react';

import TopContainer from 'global/components/TopContainer/TopContainer';
import ProgressBar from 'features/sign-up/components/ProgressBar/ProgressBar';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import SquareCheckboxWithLabel from 'global/components/square-checkbox-with-label/SquareCheckboxWithLabel';
import { Link } from 'react-router-dom';
import { useStatePersist } from 'use-state-persist';
import styles from './AgreementPage.module.css';

type Props = {
  nextButtonTapped: () => void;
};

const AgreementPage: VFC<Props> = ({ nextButtonTapped }) => {
  const [isEnable, setIsEnable] = useState(false);
  const [termsOfUseChecked, setTermsOfUseChecked] = useStatePersist(
    '@termsOfUseChecked',
    false,
  );
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useStatePersist(
    '@privacyPolicyChecked',
    false,
  );
  const [errorMessage, setErrorMessage] = useState('');
  const pageRef = useRef<HTMLDivElement>(null);
  const buttonEnability = () => {
    if (
      pageRef.current &&
      Math.ceil(window.scrollY) + window.innerHeight >= pageRef.current.clientHeight
    ) {
      setIsEnable(true);
    }
  };

  const handleClick = () => {
    if (termsOfUseChecked && privacyPolicyChecked) {
      nextButtonTapped();
      setTermsOfUseChecked(false);
      setPrivacyPolicyChecked(false);
      setErrorMessage('');
    } else {
      setErrorMessage('利用規約と個人情報の取り扱いについてに同意してください');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('scroll', buttonEnability);

    return () => window.removeEventListener('scroll', buttonEnability);
  }, []);

  return (
    <>
      <TopContainer>
        <ProgressBar max={8} value={1} />
      </TopContainer>
      <div className={styles.profilePageWrapper} ref={pageRef}>
        <Container marginTop="m" marginBottom={30}>
          <img src="/agreement/terms.jpg" alt="terms" />
        </Container>
      </div>
      <BottomContainer>
        <Divider orientation="horizontal" />
        <Container
          marginTop={10}
          marginBottom={10}
          marginLeft="s"
          marginRight="s"
        >
          <div className={styles.checkbox}>
            <div>
              <SquareCheckboxWithLabel
                name="checkbox"
                value=""
                label=""
                checked={termsOfUseChecked}
                onClick={() => setTermsOfUseChecked(!termsOfUseChecked)}
              />
            </div>
            <div>
              <Link to="/sign_up/agreement/terms_of_use">利用規約</Link>に同意する
            </div>
          </div>
        </Container>
        <Container
          marginTop={10}
          marginBottom={10}
          marginLeft="s"
          marginRight="s"
        >
          <div className={styles.checkbox}>
            <div>
              <SquareCheckboxWithLabel
                name="checkbox"
                value=""
                label=""
                checked={privacyPolicyChecked}
                onClick={() => setPrivacyPolicyChecked(!privacyPolicyChecked)}
              />
            </div>
            <div>
              <Link to="/sign_up/agreement/privacy_policy">個人情報の取り扱いについて</Link>に同意する
            </div>
          </div>
        </Container>
        <Container
          marginTop={0}
          marginBottom={0}
          marginLeft="s"
          marginRight="s"
        >
          {(!termsOfUseChecked || !privacyPolicyChecked) && (
            <p className={styles.errorMessage}>{errorMessage}</p>
          )}
        </Container>
        <Container
          marginTop={20}
          marginBottom={50}
          marginLeft="s"
          marginRight="s"
        >
          <Button
            text="同意して次へ"
            type="primary"
            size="large"
            disabled={!isEnable}
            onClick={handleClick}
          />
        </Container>
      </BottomContainer>
    </>
  );
};

export default AgreementPage;
