import { useCallback, useState } from 'react';

export type DialogInSlideShowButton = {
  text: string;
  onClick: (page: number) => void;
};

type UseDialogInSlideShowReturn = {
  isOpen: boolean;
  currentPage: number;
  button: DialogInSlideShowButton;
  requestShowing: (initialButton?: DialogInSlideShowButton) => void;
  requestSlidePaging: (page: number) => void;
  requestButtonChanging: (button: DialogInSlideShowButton) => void;
  onClose: () => void;
};

const useDialogInSlideShow = (): UseDialogInSlideShowReturn => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setPage] = useState(0);
  const [button, setButton] = useState({
    text: '閉じる',
    onClick: (_: number) => setIsOpen(false),
  });

  const requestShowing = useCallback(
    (initialButton?: DialogInSlideShowButton) => {
      if (initialButton) {
        setButton(initialButton);
      }
      setIsOpen(true);
    },
    [],
  );

  const requestSlidePaging = useCallback((page: number): void => {
    setPage(page);
  }, []);

  const requestButtonChanging = useCallback(
    (btn: DialogInSlideShowButton): void => {
      setButton(btn);
    },
    [],
  );

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    currentPage,
    button,
    requestSlidePaging,
    requestButtonChanging,
    requestShowing,
    onClose,
  };
};

export default useDialogInSlideShow;
