import { DataConsentItemDto } from '../../../data/dto/data-consent-dto';
import { ClientCompanyItemDto } from '../../data/dto/client-company-dto';
import { ClientCompanyItemViewData } from '../../view-data/client-company-view-data';

const convertDtoToClientCompanyItemViewData = (
  consentItems: DataConsentItemDto[],
  clientCompanyItems: ClientCompanyItemDto[],
): ClientCompanyItemViewData[] => {
  const result = clientCompanyItems.map((clientItem) => ({
    ...clientItem,
    consent:
      consentItems.find((v) => v.name === clientItem.clientId)?.consent ||
      false,
  }));

  const sortedResult = result.sort((a, b) => {
    // 第一優先: 同意ステータス（非公開を優先）
    // 第二優先: 企業登録日（新着を優先）
    if (a.consent < b.consent) return -1;
    if (a.consent > b.consent) return 1;
    if (a.registeredAt > b.registeredAt) return -1;
    if (a.registeredAt < b.registeredAt) return 1;

    return 0;
  });

  return sortedResult;
};

export default convertDtoToClientCompanyItemViewData;
