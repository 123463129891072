/* eslint-disable react/jsx-props-no-spreading */
import { useState, VFC } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'global/components/button/Button';
import SquareCheckboxWithLabel from 'global/components/square-checkbox-with-label/SquareCheckboxWithLabel';
import WithdrawalSettingsViewData, {
  WithdrawalReasonType,
} from '../view-data/withdrawal-settings-view-data';
import styles from './WithdrawalSettingsPage.module.css';

type Props = {
  viewData: WithdrawalSettingsViewData;
  onChangeReasonChecked: (
    type: WithdrawalReasonType,
    isApplicable: boolean,
  ) => void;
  submitted: (opinionAndRequest: string) => void;
};

type FormData = {
  opinionAndRequest: string;
};

const WithdrawalSettingsPage: VFC<Props> = ({
  viewData,
  onChangeReasonChecked,
  submitted,
}) => {
  const [hasAnsweredReasonError, setAnsweredReasonError] = useState(false);
  const [hasOpinionAndRequestError, setOpinionAndRequestError] =
    useState(false);

  const { register, handleSubmit } = useForm<FormData>({ mode: 'onBlur' });

  const onSubmit = (data: FormData) => {
    const answeredReason = viewData.checks.find((d) => d.isApplicable);

    if (!answeredReason || data.opinionAndRequest.length > 300) {
      if (!answeredReason && data.opinionAndRequest.length <= 300) {
        setAnsweredReasonError(true);
        setOpinionAndRequestError(false);
      }

      if (answeredReason && data.opinionAndRequest.length > 300) {
        setAnsweredReasonError(false);
        setOpinionAndRequestError(true);
      }

      if (!answeredReason && data.opinionAndRequest.length > 300) {
        setAnsweredReasonError(true);
        setOpinionAndRequestError(true);
      }

      return;
    }

    setAnsweredReasonError(false);
    setOpinionAndRequestError(false);
    submitted(data.opinionAndRequest);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{viewData.title}</div>
      <div className={styles.description}>{viewData.description}</div>
      <div className={styles.checksRoot}>
        {viewData.checks.map((c) => (
          <div key={c.reason.type} className={styles.check}>
            <SquareCheckboxWithLabel
              name="checkbox"
              value=""
              size="large"
              label={c.reason.text}
              checked={c.isApplicable}
              onClick={() =>
                onChangeReasonChecked(c.reason.type, !c.isApplicable)
              }
            />
          </div>
        ))}
      </div>
      {hasAnsweredReasonError && (
        <div className={styles.errorMessage}>
          退会理由の選択は入力必須です。
        </div>
      )}
      <div className={styles.opinionAndRequestTitle}>
        その他の理由・ご意見など
      </div>
      <textarea
        className={styles.opinionAndRequest}
        {...register('opinionAndRequest')}
        maxLength={500}
      />
      {hasOpinionAndRequestError && (
        <div className={styles.errorMessage}>
          300文字以下で入力してください。
        </div>
      )}
      <div className={styles.button}>
        <Button
          type="primary"
          size="large"
          text="退会する"
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
};

export default WithdrawalSettingsPage;
