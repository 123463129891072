import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import NoticeDetailDto from '../dto/notice-detail-dto';

const createNoticeDetailRequestConfig = (id: string): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/users/me/notifications/${id}`,
  },
  method: 'get',
  parameters: {},
});

interface NoticeDetailRepository {
  fetch: (id: string) => Promise<NoticeDetailDto>;
}

export class NoticeDetailRepositoryImpl implements NoticeDetailRepository {
  fetch = (id: string): Promise<NoticeDetailDto> =>
    new ApiClient().connect(createNoticeDetailRequestConfig(id));
}

export default NoticeDetailRepository;
