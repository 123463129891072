import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { VFC } from 'react';
import { useHistory } from 'react-router';
import PhoneNumberAuthenticationTipsPage from '../pages/PhoneNumberAuthenticationTipsPage';

const PhoneNumberAuthenticationTipsContainer: VFC = () => {
  const history = useHistory();
  const submitted = () => {
    history.push('/sign_up/phone_number/registration');
  };
  useNavigationBarItemsSetting('電話番号の認証・登録', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  return <PhoneNumberAuthenticationTipsPage submitted={submitted} />;
};

export default PhoneNumberAuthenticationTipsContainer;
