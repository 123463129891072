import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import QuestionsListDto from '../dto/questions-list-dto';

const createQuestionsListRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/campaigns/register',
  },
  method: 'get',
  parameters: {},
});

const questionsListDto: QuestionsListDto = [
  {
    name: '本人情報',
    questions: [
      {
        id: 1,
        name: '就業状況について',
        iconName: 'employment_status',
        totalQuestionsCount: 25,
        answeredQuestionsCount: 24,
        point: 200,
        isAchieve: true,
        path: {
          type: 'personal_info',
          ref: '就業状況について',
        },
      },
      {
        id: 2,
        name: '余暇の過ごし方について',
        iconName: 'leisure',
        totalQuestionsCount: 25,
        answeredQuestionsCount: 9,
        point: 100,
        isAchieve: false,
        path: {
          type: 'personal_info',
          ref: '余暇の過ごし方について',
        },
      },
      {
        id: 3,
        name: '将来について',
        iconName: 'future',
        totalQuestionsCount: 25,
        answeredQuestionsCount: 25,
        point: 200,
        isAchieve: true,
        path: {
          type: 'personal_info',
          ref: '将来について',
        },
      },
    ],
  },
  {
    name: 'セルフチェック',
    questions: [
      {
        id: 6,
        name: '食事について',
        iconName: 'meal',
        totalQuestionsCount: 12,
        answeredQuestionsCount: 25,
        point: 200,
        isAchieve: false,
        path: {
          type: 'questionnaire',
          ref: '1',
        },
      },
      {
        id: 7,
        name: '運動について',
        iconName: 'sport',
        totalQuestionsCount: 5,
        answeredQuestionsCount: 25,
        point: 100,
        isAchieve: false,
        path: {
          type: 'questionnaire',
          ref: '2',
        },
      },
      {
        id: 8,
        name: '睡眠について',
        iconName: 'sleep',
        totalQuestionsCount: 5,
        answeredQuestionsCount: 25,
        point: 200,
        isAchieve: false,
        path: {
          type: 'questionnaire',
          ref: '3',
        },
      },
      {
        id: 9,
        name: 'カラダについて',
        iconName: 'physical',
        totalQuestionsCount: 5,
        answeredQuestionsCount: 25,
        point: 200,
        isAchieve: false,
        path: {
          type: 'questionnaire',
          ref: '4',
        },
      },
      {
        id: 10,
        name: 'メンタルについて',
        iconName: 'mental',
        totalQuestionsCount: 5,
        answeredQuestionsCount: 25,
        point: 200,
        isAchieve: false,
        path: {
          type: 'questionnaire',
          ref: '5',
        },
      },
    ],
  },
];

interface QuestionsListRepository {
  fetch: () => Promise<QuestionsListDto>;
}

export class QuestionsListRepositoryImpl implements QuestionsListRepository {
  fetch = async (): Promise<QuestionsListDto> =>
    new ApiClient().connect(createQuestionsListRequestConfig());
}

export class MockQuestionsListRepositoryImpl
  implements QuestionsListRepository
{
  fetch = async (): Promise<QuestionsListDto> =>
    new Promise((resolve, _) =>
      setTimeout(() => resolve(questionsListDto), 1500),
    );
}

export default QuestionsListRepository;
