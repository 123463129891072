import { VFC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Divider } from '@chakra-ui/react';
import PointIcon from 'global/components/PointIcon/PointIcon';
import InputNum from 'global/components/InputNum/InputNum';
import Button from 'global/components/button/Button';
import NoticeContainer from 'global/components/NoticeContainer/NoticeContainer';
import HontoCouponScreenViewData from '../view-data/honto-coupon-view-data';
import styles from './HontoCouponPage.module.css';

type Props = {
  viewData: HontoCouponScreenViewData;
  onClosedNotice: () => void;
  isClosedNotice: boolean;
  formRegister: UseFormRegisterReturn;
  expectedExchangeCouponCountText: string;
  exchangeNeedCostText: string;
  exchangedResultRemainPointText: string;
  isInsufficientPoints: boolean;
  isExchangeDisable: boolean;
  onExchangeValueChanged: (value: number) => void;
  onSubmit: () => void;
};

const HontoCouponPage: VFC<Props> = ({
  viewData,
  onClosedNotice,
  isClosedNotice,
  formRegister,
  expectedExchangeCouponCountText,
  exchangeNeedCostText,
  exchangedResultRemainPointText,
  isInsufficientPoints,
  isExchangeDisable,
  onExchangeValueChanged,
  onSubmit,
}) => (
  <>
    <div className={styles.container}>
      {!isClosedNotice && (
        <div className={styles.noticeArea}>
          <NoticeContainer closeButtonCustomAction={onClosedNotice}>
            現在お持ちのFitStatsポイントを電子書籍と紙の書籍のハイブリッド型総合書店「honto」で利用できる値引きクーポンに交換できます。
          </NoticeContainer>
        </div>
      )}
      <div className={styles.contentsArea}>
        <div className={styles.ownedPointTitle}>保有ポイント</div>
        <div className={styles.ownedPointRoot}>
          <PointIcon />
          <div className={styles.point}>
            {viewData.availablePoint.toLocaleString()}
          </div>
          <div className={styles.suffix}>
            <div className={styles.commonValueSuffix}>pt</div>
          </div>
        </div>
        <div className={styles.exchangeGuide}>
          交換するクーポンの枚数を入力してください
        </div>
        <div className={styles.couponArea}>
          <div className={styles.sentenceDetailRoot}>
            <div className={styles.couponTitle}>
              {viewData.targetCoupon.name}
            </div>
            <div className={styles.valueAndQuantityPair}>
              <div className={styles.exchangeCouponValue}>
                <InputNum
                  name="exchangeQuantity"
                  size="small"
                  placeholder="0"
                  maxLength={2}
                  register={formRegister}
                  onChange={(e) => {
                    onExchangeValueChanged(Number(e.target.value));
                  }}
                />
              </div>
              <div className={styles.commonValueSuffix}>枚</div>
            </div>
          </div>
        </div>
        <div className={styles.dividerWrapper}>
          <Divider orientation="horizontal" />
        </div>
        <div className={styles.resultGuideTitle}>交換するクーポン</div>
        <div className={styles.resultDetailRoot}>
          <div className={styles.sentenceDetailRoot}>
            <div className={styles.resultDetailTitle}>
              {viewData.targetCoupon.name}
            </div>
            <div className={styles.valueAndQuantityPair}>
              <div className={styles.resultNumValue}>
                {expectedExchangeCouponCountText}
              </div>
              <div className={styles.commonValueSuffix}>枚</div>
            </div>
          </div>
          <div className={styles.sentenceDetailRoot}>
            <div className={styles.resultDetailTitle}>交換に必要なポイント</div>
            <div className={styles.valueAndQuantityPair}>
              <div className={styles.resultNumValue}>
                {exchangeNeedCostText}
              </div>
              <div className={styles.commonValueSuffix}>pt</div>
            </div>
          </div>
          <div className={styles.sentenceDetailRoot}>
            <div className={styles.resultDetailTitle}>交換後の残りポイント</div>
            <div className={styles.valueAndQuantityPair}>
              <div className={styles.resultNumValue}>
                {exchangedResultRemainPointText}
              </div>
              <div className={styles.commonValueSuffix}>pt</div>
            </div>
          </div>
        </div>
        {isInsufficientPoints && (
          <div className={styles.insufficientPoints}>
            交換に必要なポイントが不足しています。
          </div>
        )}
        <div
          className={styles.email}
        >{`クーポン交換後、クーポンコードを以下のメールアドレスに送信します。\n\n${viewData.email}`}</div>
        <div className={styles.exchangeButton}>
          <Button
            text="交換する"
            type="primary"
            size="large"
            disabled={isExchangeDisable}
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  </>
);

export default HontoCouponPage;
