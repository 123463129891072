import { VFC } from 'react';
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import Container from '../Container/Container';
import Button from '../button/Button';
import DialogUsedCarousel from './DialogUsedCarousel';
import styles from './DialogInSlideShow.module.css';

type CommonDialogSlide = {
  kind: 'common';
  title?: string;
  description?: string;
  imageSrc?: string;
};

type AmorphousDialogSlide = {
  kind: 'amorphous';
  element: JSX.Element;
};

export type DialogUsedSlide = CommonDialogSlide | AmorphousDialogSlide;

type Props = {
  isOpen: boolean;
  selectedPage?: number;
  buttonText: string;
  onButtonClicked?: () => void;
  onPageChanged?: (page: number) => void;
  slideElements: DialogUsedSlide[];
  onClose: () => void;
};

const DialogInSlideShow: VFC<Props> = ({
  isOpen,
  selectedPage,
  buttonText,
  onButtonClicked,
  onPageChanged,
  slideElements,
  onClose,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    isCentered
    closeOnOverlayClick={false}
  >
    <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.5)" />
    <ModalContent width="90%" borderRadius="8px">
      <DialogUsedCarousel
        selectedPage={selectedPage}
        onPageChanged={onPageChanged}
      >
        {slideElements.map((e) => (
          <>
            {e.kind === 'common' && (
              <div key={e.title} className={styles.slide}>
                <div className={styles.dialogContentsRoot}>
                  {e.imageSrc && (
                    <div className={styles.imageContainer}>
                      <img src={e.imageSrc} alt="" />
                    </div>
                  )}
                  {e.title && <div className={styles.title}>{e.title}</div>}
                  {e.description && (
                    <Container marginTop="m">
                      <div className={styles.description}>{e.description}</div>
                    </Container>
                  )}
                </div>
              </div>
            )}
            {e.kind === 'amorphous' && (
              <div key={e.element.key} className={styles.slide}>
                {e.element}
              </div>
            )}
          </>
        ))}
      </DialogUsedCarousel>
      <div className={styles.primaryButton}>
        <Button
          text={buttonText}
          type="primary"
          size="large"
          onClick={onButtonClicked ?? onClose}
        />
      </div>
    </ModalContent>
  </Modal>
);

export default DialogInSlideShow;
