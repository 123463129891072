import { VFC, useEffect } from 'react';
import Result from 'global/utilities/result';
import Container from 'global/components/Container/Container';
import { useHistory } from 'react-router';
import OfferTopViewData from 'features/offer/view-data/offer-top-view-data';
import { TutorialLocationState } from 'features/tutorial/hooks/use-tutorial-navigation';
import styles from './TopPage.module.css';
import TopScoresViewData from '../view-data/top-scores-view-data';
import TopLifeScore from '../components/TopLifeScore';
import TopDailyScores from '../components/TopDailyScores';
import TopDailyScoresViewData from '../view-data/top-daily-scores-view-data';
import TopDisplayOrderModals from '../components/TopDisplayOrderModals';
import TopOffers from '../components/TopOffers';
import CampaignSummaryBoard from '../../campaign/components/SummaryBoard';
import CampaignBanner from '../../campaign/components/CampaignBanner';

type Props = {
  scoresFetchResult: Result<TopScoresViewData, Error>;
  dailyScoresFetchResult: Result<TopDailyScoresViewData, Error>;
  dailyScoresPrevButtonTapped: () => void;
  dailyScoresNextButtonTapped: () => void;
  didLoad?: () => void;
  locationState?: TutorialLocationState | undefined;
  offersFetchResult: Result<OfferTopViewData, Error>;
};

const TopPage: VFC<Props> = ({
  scoresFetchResult,
  dailyScoresFetchResult,
  dailyScoresPrevButtonTapped,
  dailyScoresNextButtonTapped,
  didLoad,
  locationState,
  offersFetchResult,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    if (didLoad != null) {
      didLoad();
    }
  }, [didLoad]);

  const history = useHistory();

  return (
    <>
      {/* チュートリアルではランクアップボーナスをリクエストしない（ダイアログの案内が重複するため） */}
      {locationState?.isTutorial ? <></> : <TopDisplayOrderModals />}
      <div className={styles.containerWithoutPadding}>
        <CampaignSummaryBoard onTop />
      </div>
      <div className={styles.container}>
        <div className={styles.sectionWrapper}>
          <Container marginBottom="s">
            <TopLifeScore scoresFetchResult={scoresFetchResult} />
          </Container>
          <Container marginTop="s" marginBottom="s">
            <TopOffers
              offersFetchResult={offersFetchResult}
              onTapOffersItem={(id, type) => {
                if (type.kind === 'announcement') {
                  history.push(`/offers/announcement_offers/${id}`);
                }
                if (type.kind === 'questionnaire') {
                  history.push(`/offers/questionnaire_offers/${id}`);
                }
              }}
            />
          </Container>
        </div>
        <Container marginTop="s" marginBottom="s">
          <CampaignBanner />
        </Container>
        <div className={styles.sectionWrapper}>
          <TopDailyScores
            dailyScoresFetchResult={dailyScoresFetchResult}
            prevButtonTapped={dailyScoresPrevButtonTapped}
            nextButtonTapped={dailyScoresNextButtonTapped}
          />
        </div>
      </div>
    </>
  );
};

export default TopPage;
