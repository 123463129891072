import { useRef, VFC } from 'react';
import { useHistory } from 'react-router';
import usePhoneNumberAuthentication from 'features/sign-up/phone-number/authentication/hooks/use-phone-number-authentication';
import { PhoneNumberAuthenticationRepositoryImpl } from 'features/sign-up/phone-number/authentication/data/repositories/phone-number-authentication-repository';
import PhoneNumberAuthenticationPage from 'features/sign-up/phone-number/authentication/pages/PhoneNumberAuthenticationPage';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import TopContainer from 'global/components/TopContainer/TopContainer';
import ProgressBar from 'features/sign-up/components/ProgressBar/ProgressBar';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorDTO from 'data/dto/error-dto';

type DialogInfo = {
  description: string;
  primaryButtonAction: () => void;
};

const PhoneNumberAuthenticationContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, onClose } = useDialog();
  const dialogInfoRef = useRef<DialogInfo | undefined>();
  const primaryButtonActionRef = useRef<() => void>(() => onClose());

  useNavigationBarItemsSetting('認証コード入力', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const isPermissionDeniedError = (error: ErrorDTO | undefined) =>
    error && error.error.status === 'PERMISSION_DENIED';

  const { submitted } = usePhoneNumberAuthentication(
    new PhoneNumberAuthenticationRepositoryImpl(),
    (authResult) => {
      if (authResult.isSuccess()) {
        history.push('/sign_up/password', {
          phoneNumber: authResult.value,
        });
      }

      if (authResult.isFailure()) {
        const description = authResult.error
          ? authResult.error.error.message
          : 'SMS認証に失敗しました';

        if (isPermissionDeniedError(authResult.error)) {
          primaryButtonActionRef.current = () => {
            onClose();
            history.push('/');
          };
        }

        dialogInfoRef.current = {
          description,
          primaryButtonAction: primaryButtonActionRef.current,
        };

        requestShowing();
      }
    },
  );

  return (
    <>
      {dialogInfoRef.current && (
        <Dialog
          title="エラー"
          description={dialogInfoRef.current.description}
          primaryButton={{
            text: 'OK',
            onClick: dialogInfoRef.current.primaryButtonAction,
          }}
          isOpen={isOpen}
          onClose={() => undefined}
          showsCloseButton={false}
        />
      )}

      {/* PhoneNumberAuthenticationPageは初回登録以外でも呼ばれる可能性があるためここで初回登録用のプログレスバーを設置 */}
      <TopContainer>
        <ProgressBar max={8} value={6} />
      </TopContainer>

      {/* これ以上style長くなりそうならcssファイルの切り出し検討する */}
      <div style={{ paddingTop: 60, paddingBottom: 150 }}>
        <PhoneNumberAuthenticationPage
          phoneNumber={
            (history.location.state as { phoneNumber: string }).phoneNumber
          }
          submitted={submitted}
        />
      </div>
    </>
  );
};

export default PhoneNumberAuthenticationContainer;
