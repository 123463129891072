import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { VFC } from 'react';
import WithdrawalCompletedPage from '../pages/WithdrawalCompletedPage';

const WithdrawalCompletedContainer: VFC = () => {
  useNavigationBarItemsSetting('退会', {
    isCloseButtonVisible: true,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  return <WithdrawalCompletedPage />;
};

export default WithdrawalCompletedContainer;
