import { VFC } from 'react';
import Loading from 'global/components/Loading/Loading';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import { PrivacyPolicyRepositoryImpl } from 'features/sign-up/privacy-policy/data/repositories/privacy-policy-repository';
import { TermsOfUseRepositoryImpl } from 'features/sign-up/terms-of-use/data/repositories/terms-of-use-repository';
import { UserRepositoryImpl } from 'features/sign-up/terms-of-use/data/repositories/user-repository';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import AuthorizationRepository from 'features/sign-up/privacy-policy/data/repositories/authorization-repository';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import useAgreement from '../hooks/use-agreement';
import AgreementPage from '../pages/AgreementPage';

const AgreementContainer: VFC = () => {
  useNavigationBarItemsSetting('はじめに', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });
  const { isOpen, requestShowing, onClose } = useDialog();
  const {
    fetchTermsOfUseResult,
    fetchPrivacyPolicyResult,
    submitErrorMessage,
    submitted,
  } = useAgreement(
    new TermsOfUseRepositoryImpl(),
    new PrivacyPolicyRepositoryImpl(),
    new UserRepositoryImpl(),
    new AuthorizationRepository(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        window.location.href = saveResult.value;
      }
      if (saveResult.isFailure()) {
        requestShowing();
      }
    },
  );

  if (fetchTermsOfUseResult.isSuccess() && fetchPrivacyPolicyResult.isSuccess) {
    return (
      <>
        <Dialog
          title="エラー"
          description={submitErrorMessage}
          primaryButton={{ text: 'OK' }}
          isOpen={isOpen}
          onClose={onClose}
        />
        <AgreementPage nextButtonTapped={submitted} />
      </>
    );
  }

  if (fetchTermsOfUseResult.isFailure()) {
    return (
      <ErrorContainer>{fetchTermsOfUseResult.error.message}</ErrorContainer>
    );
  }

  return <Loading />;
};

export default AgreementContainer;
