import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import EmailRegistrationDto from '../dto/email-registration-dto';

const createEmailRegistrationRequestConfig = (
  email: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/email',
  },
  method: 'put',
  parameters: { email },
});

interface EmailRegistrationRepository {
  save: (email: string) => Promise<EmailRegistrationDto>;
}

export class EmailRegistrationRepositoryImpl
  implements EmailRegistrationRepository
{
  save = async (email: string): Promise<EmailRegistrationDto> =>
    new ApiClient().connect(createEmailRegistrationRequestConfig(email));
}

export default EmailRegistrationRepository;
