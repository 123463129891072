import { VFC } from 'react';
import styles from './PointNotice.module.css';

type Props = {
  pointReason: string;
  pointDetail: string;
};

const PointNotice: VFC<Props> = ({ pointReason, pointDetail }) => (
  <>
    <div className={styles.pointSummary}>
      <div className={styles.pointReason}>{pointReason}</div>
      <div className={styles.pointDetail}>{pointDetail}</div>
    </div>
  </>
);

export default PointNotice;
