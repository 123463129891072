import { useRef, VFC } from 'react';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

type Props = {
  onClickItem: (index: number) => void;
  children: React.ReactChild[];
};

const ProgramCarousel: VFC<Props> = ({ onClickItem, children }) => {
  const prevTouch = useRef<React.Touch | undefined>(undefined);

  return (
    <>
      <Carousel
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        centerMode
        centerSlidePercentage={95}
        onClickItem={(index, _) => onClickItem(index)}
        onSwipeStart={(e) => {
          prevTouch.current = e.targetTouches.item(0);
        }}
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={50}
      >
        {children}
      </Carousel>
    </>
  );
};

export default ProgramCarousel;
