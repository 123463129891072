import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import HontoCouponContainer from './containers/index';

const HontoCouponRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`}>
      <HontoCouponContainer />
    </Route>
  </Switch>
);

export default HontoCouponRouter;
