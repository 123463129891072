import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import ProfileBasicContainer from 'features/sign-up/profile/containers/basic';
import ProfileDetailContainer from './containers/detail';
import ProfileDescriptionContainer from './containers/desccription';
import ProfileConfirmContainer from './containers/confirm';

const ProfileRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/basic`}>
      <ProfileBasicContainer />
    </Route>
    <Route path={`${match.path}/description`}>
      <ProfileDescriptionContainer />
    </Route>
    <Route path={`${match.path}/detail`}>
      <ProfileDetailContainer />
    </Route>
    <Route path={`${match.path}/confirm`}>
      <ProfileConfirmContainer />
    </Route>
  </Switch>
);

export default ProfileRouter;
