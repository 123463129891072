import { VFC } from 'react';
import styles from './AnnouncementCaution.module.css';

type Props = {
  text: string;
};

const AnnouncementCaution: VFC<Props> = ({ text }) => (
  <>
    <div className={styles.offerTerms}>
      <div className={styles.termsTitle}>注意事項</div>
      <div className={styles.termsBody}>
        本キャンペーンをご利用の方（以下、｢お客様｣といいます）は、以下をよくお読みいただき、同意の上ご利用ください。同意いただけない場合はご利用をお控えください。なお、ご利用される場合は以下の内容に同意いただいたものとさせていただきます。
        <div className={styles.termsSubTitle}>本利用規約</div>
        {text}
      </div>
    </div>
  </>
);

export default AnnouncementCaution;
