import { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

type Props = {
  children: React.ReactChild[];
  isNeedLoadMore: boolean;
  onLoadItem: (cursor: number) => void;
};

const AdditionalReadingList: FC<Props> = ({
  isNeedLoadMore,
  onLoadItem,
  children,
}) => (
  <>
    <InfiniteScroll
      hasMore={isNeedLoadMore}
      loadMore={(page) => {
        window.ontouchstart = () => {
          ('');
        };

        onLoadItem(page);
      }}
    >
      {children}
    </InfiniteScroll>
  </>
);

export default AdditionalReadingList;
