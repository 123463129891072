import { useQuery } from 'react-query';
import { isErrorDTO } from 'data/dto/error-dto';
import Result from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import QuestionsListRepository, {
  QuestionsListRepositoryImpl,
} from '../data/repositories/questions-list-repository';
import QuestionsListViewData from '../view-data/questions-list-view-data';
import QuestionsListDto from '../data/dto/questions-list-dto';

type ReturnType = {
  questionsListResult: Result<QuestionsListViewData, Error>;
};

export const convertDtoToViewData = (
  dto: QuestionsListDto,
): QuestionsListViewData => dto;

const useQuestionsList = (
  repository: QuestionsListRepository = new QuestionsListRepositoryImpl(),
): ReturnType => {
  const queryResult = useQuery<QuestionsListViewData, Error>(
    ['/campaign/register'],
    async () => {
      const dto = await repository.fetch().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertDtoToViewData(dto);
    },
  );

  const questionsListResult = createResult(queryResult);

  return {
    questionsListResult,
  };
};

export default useQuestionsList;
