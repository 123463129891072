import { VFC } from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'global/components/Container/Container';
import QuestionSection from 'global/components/QuestionSection/QuestionSection';
import { QuestionnaireCategoryViewData } from '../view-data/questionnaire-top-view-data';
import styles from './QuestionnaireTopPage.module.css';

type Props = {
  categories: QuestionnaireCategoryViewData[];
};

const QuestionnaireTopPage: VFC<Props> = ({ categories }) => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      {categories.map((category) => (
        <Container marginTop={12} marginBottom={12}>
          <QuestionSection
            key={category.id}
            imgSrc={`/questionnaire/questionnaire_category_${category.id}.svg`}
            title={`${category.name}について`}
            answeredQuestionsCount={category.answeredQuestionsCount}
            totalQuestionsCount={category.totalQuestionsCount}
            buttonTappedAction={() =>
              history.push(`/questionnaires/categories/${category.id}`)
            }
          />
        </Container>
      ))}
    </div>
  );
};

export default QuestionnaireTopPage;
