import { useQuery } from 'react-query';
import { DateTime } from 'luxon';
import { isErrorDTO } from 'data/dto/error-dto';
import requestWebCommand, {
  OpenExternalWeb,
} from 'global/utilities/web-command';
import IntegrationCodeRepository, {
  IntegrationCodeRepositoryImpl,
  IntegrationCodeSeed,
} from 'features/integration-code/data/repositories/integration-code-repository';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import ClientCompanyRepository, {
  ClientCompanyRepositoryImpl,
} from '../client-company/data/repositories/client-company-repository';
import ClientCompanyDto from '../client-company/data/dto/client-company-dto';
import lastPageLoadAt from '../client-company/hooks/utils/last-page-load-at';

type ReturnType = {
  hasNewClientCompany: boolean;
  elneLinkButtonTapped: () => void;
};

const convertDtoToViewData = (
  dto: ClientCompanyDto,
  lastClientCompanyPageLoadAt: DateTime | undefined,
): boolean => {
  if (lastClientCompanyPageLoadAt) {
    const newClientCompany = dto.clients.find(
      (v) => lastClientCompanyPageLoadAt <= v.registeredAt,
    );

    return !!newClientCompany;
  }

  return true;
};

const useMyDataTop = (
  repository: ClientCompanyRepository = new ClientCompanyRepositoryImpl(),
): ReturnType => {
  const queryResult = useQuery<boolean, Error>(['/mydata_top'], async () => {
    const dto = await repository.fetch().catch((error) => {
      if (isErrorDTO(error)) {
        throw Error(error.error.message);
      }
      throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
    });

    return convertDtoToViewData(dto, lastPageLoadAt());
  });

  const hasNewClientCompany = queryResult.isSuccess ? queryResult.data : false;

  const elneLinkButtonTapped = (
    integrationCodeRepository: IntegrationCodeRepository = new IntegrationCodeRepositoryImpl(),
  ): void => {
    const seed: IntegrationCodeSeed = {
      dt: DateTime.now().toMillis(),
    };

    const _ = integrationCodeRepository
      .generateIntegrationCode(seed)
      .then((value) => {
        requestWebCommand(
          new OpenExternalWeb(
            `${process.env.REACT_APP_DNP_ELNE_POINT_SITE_URL}/deposit/elne?exchange_id=${value.code}`,
          ),
        );
      });
  };

  return {
    hasNewClientCompany,
    elneLinkButtonTapped,
  };
};

export default useMyDataTop;
