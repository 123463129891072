import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { VFC } from 'react';
import PointDetailPage from '../pages/PointDetailPage';

const PointDetailContainer: VFC = () => {
  useNavigationBarItemsSetting('ポイント明細');

  return <PointDetailPage />;
};
export default PointDetailContainer;
