import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import PrivacyPolicyDto from '../dto/privacy-policy-dto';

const fetchPrivacyPolicyRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/privacy_policy',
  },
  method: 'get',
  parameters: {},
});

const createPrivacyPolicyRequestConfig = (
  docVersion: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/privacy_policy',
  },
  method: 'post',
  parameters: { doc_version: docVersion },
});

interface PrivacyPolicyRepository {
  fetch: () => Promise<PrivacyPolicyDto>;
  save: (docVersion: string) => Promise<void>;
}

export class PrivacyPolicyRepositoryImpl implements PrivacyPolicyRepository {
  fetch = async (): Promise<PrivacyPolicyDto> =>
    new ApiClient().connect(fetchPrivacyPolicyRequestConfig());

  save = async (docVersion: string): Promise<void> =>
    new ApiClient().connect(createPrivacyPolicyRequestConfig(docVersion));
}

export default PrivacyPolicyRepository;
