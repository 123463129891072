import { DateTime } from 'luxon';
import DataConsentDto from '../../../data/dto/data-consent-dto';
import ClientCompanyDto from '../../data/dto/client-company-dto';
import ClientCompanyViewData, {
  ClientCompanyItemViewData,
  ClientCompanySectionViewData,
} from '../../view-data/client-company-view-data';
import convertDtoToClientCompanyItemViewData from './convert-dto-to-client-company-item-view-data';

// eslint-disable-next-line no-shadow
export enum ClientCompanySection {
  FIRST_LOOK = '新しく追加された企業',
  ALREADY_LOOKED = 'これまでに追加された企業',
}

const isMatchedSectionType = (
  clientCompanySection: ClientCompanySection,
  clientCompanyItem: ClientCompanyItemViewData,
  lastPageLoadAt: DateTime | undefined,
): boolean => {
  const isFirstLookClientCompany = lastPageLoadAt
    ? lastPageLoadAt <= clientCompanyItem.registeredAt
    : true;

  switch (clientCompanySection) {
    case ClientCompanySection.FIRST_LOOK:
      return isFirstLookClientCompany;
    case ClientCompanySection.ALREADY_LOOKED:
      return !isFirstLookClientCompany;
    default:
      return false;
  }
};

const convertDtoToClientCompanyViewData = (
  clientCompanyDto: ClientCompanyDto,
  consentDto: DataConsentDto,
  lastPageLoadAt: DateTime | undefined,
): ClientCompanyViewData => {
  const sectionTypes: ClientCompanySection[] = Object.entries(
    ClientCompanySection,
  ).map(([_, key]) => key);

  const sections: ClientCompanySectionViewData[] = [];

  sectionTypes.forEach((sectionType) => {
    const clientCompanyItems = convertDtoToClientCompanyItemViewData(
      consentDto.consentItems,
      clientCompanyDto.clients,
    );

    sections.push({
      title: sectionType,
      clients: clientCompanyItems.filter((item) =>
        isMatchedSectionType(sectionType, item, lastPageLoadAt),
      ),
    });
  });

  return {
    sections,
  };
};

export default convertDtoToClientCompanyViewData;
