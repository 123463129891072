/* eslint-disable react/jsx-props-no-spreading */
import { VFC } from 'react';
import Container from 'global/components/Container/Container';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import { Divider } from '@chakra-ui/react';
import Button from 'global/components/button/Button';
import { FormProvider, useForm } from 'react-hook-form';
import AuthCodeFormContainer from 'global/components/AuthCodeFormContainer/AuthCodeFormContainer';
import UndraggableLink from 'global/components/UndraggableLink/UndraggableLink';
import styles from './PhoneNumberAuthenticationPage.module.css';

type Props = {
  phoneNumber: string;
  submitted: (phoneNumberAuthCode: number) => void;
};

type FormData = {
  phoneNumberAuthCode: number;
};

const PhoneNumberAuthenticationPage: VFC<Props> = ({
  phoneNumber,
  submitted,
}) => {
  const methods = useForm<FormData>({ mode: 'onBlur' });

  const onSubmit = (data: FormData) => {
    submitted(data.phoneNumberAuthCode);
  };

  const phoneNumberWithHyphen = [
    phoneNumber.substr(0, 3),
    phoneNumber.substr(3, 4),
    phoneNumber.substr(7, 4),
  ].join('-');

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <div className={styles.body}>
          <Container marginTop="l">
            <div className={styles.textLarge}>
              登録いただいた電話番号(
              <span className={styles.highlightTextLarge}>
                {phoneNumberWithHyphen}
              </span>
              )に認証コードを送信しました。
              <br />
              送付した6桁の認証コードを入力してください。
            </div>
          </Container>
          <FormProvider {...methods}>
            <Container marginTop="xxl">
              <AuthCodeFormContainer name="phoneNumberAuthCode" />
              <p className={styles.errorMessage}>
                {methods.formState.errors.phoneNumberAuthCode?.message}
              </p>
            </Container>
          </FormProvider>
          <Container marginTop="xxl">
            <p className={styles.textSmall}>
              認証コードが届かない場合や
              <br />
              認証コードの有効期限が切れた場合は、
              <br />「
              <UndraggableLink
                className={styles.highlightText}
                to="/sign_up/phone_number/registration"
              >
                電話番号の登録
              </UndraggableLink>
              」 にて再送してください。
            </p>
          </Container>
          <Container marginTop="s">
            <p className={styles.textSmall}>
              SMS設定でSMSを受信しない、海外事業者からの送信などを拒否設定している方は、拒否条件を外して、SMSを受信する設定に変更頂けますと認証コードが届く場合が御座います。
            </p>
          </Container>
        </div>
      </Container>
      <BottomContainer>
        <Divider orientation="horizontal" />
        <Container
          marginTop="xxl"
          marginBottom="m"
          marginLeft="s"
          marginRight="s"
        >
          <Button
            text="次へ"
            type="primary"
            size="large"
            disabled={!methods.formState.isDirty || !methods.formState.isValid}
            onClick={methods.handleSubmit(onSubmit)}
          />
        </Container>
      </BottomContainer>
    </>
  );
};

export default PhoneNumberAuthenticationPage;
