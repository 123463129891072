/* eslint-disable react/jsx-props-no-spreading */
import { useState, VFC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from './InputPassword.module.css';

type InputPasswordSize = 'large';

export type Props = {
  name: string;
  value?: string;
  size?: InputPasswordSize;
  placeholder?: string;
  register?: UseFormRegisterReturn;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
};

const classNameForInputPassword = (size?: InputPasswordSize): string => {
  switch (size) {
    case 'large':
      return styles.inputPasswordLarge;
    default:
      return styles.inputPasswordLarge;
  }
};

const InputPassword: VFC<Props> = ({
  name,
  value,
  size,
  placeholder,
  register,
  readOnly,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <>
      <input
        name={name}
        type={passwordVisible ? 'text' : 'password'}
        value={value}
        placeholder={placeholder || '12文字以上のpassword'}
        className={[classNameForInputPassword(size), styles.input].join(' ')}
        {...register}
        readOnly={readOnly}
        maxLength={64}
      />
      <button
        type="button"
        onClick={() => setPasswordVisible(!passwordVisible)}
        className={styles.visibleButton}
        tabIndex={0}
      >
        {passwordVisible ? '非表示' : '表示'}
      </button>
    </>
  );
};

export default InputPassword;
