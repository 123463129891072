import TopDailyScoresDto from 'features/top/data/dto/top-daily-scores-dto';
import TopDailyScoresViewData, {
  DailyScoreViewData,
} from 'features/top/view-data/top-daily-scores-view-data';
import YearMonthDay from 'global/utilities/year-month-day';
import { DateTime } from 'luxon';

const targetDaysList = (
  startDate: YearMonthDay,
  endDate: YearMonthDay,
): YearMonthDay[] => {
  const list = [];

  const diff = Math.abs(endDate.diffOfDays(startDate));

  for (let i = 0; i <= diff; i += 1) {
    list.push(startDate.addDays(i));
  }

  return list;
};

const convertDateToGraphlabel = (date: YearMonthDay): string => {
  const today = new YearMonthDay(DateTime.local());

  return date.separatedByHyphen() === today.separatedByHyphen()
    ? '今日'
    : `${date.month}/${date.day}`;
};

const convertDailyScoresDtoToViewData = (
  dto: TopDailyScoresDto,
  startDate: YearMonthDay,
  endDate: YearMonthDay,
): DailyScoreViewData[] => {
  const result = targetDaysList(startDate, endDate).map((targetDate) => {
    const targetScore = dto.scores.find(
      (score) =>
        score.date.separatedByHyphen() === targetDate.separatedByHyphen(),
    )?.score;

    return {
      date: targetDate.separatedBySlash(),
      score: typeof targetScore === 'number' ? targetScore : null,
      graphLabel: convertDateToGraphlabel(targetDate),
    };
  });

  return result;
};

const convertTopDailyScoresDtoToViewData = (
  dto: TopDailyScoresDto,
  startDate: YearMonthDay,
  endDate: YearMonthDay,
): TopDailyScoresViewData => ({
  ...dto,
  scores: convertDailyScoresDtoToViewData(dto, startDate, endDate),
  startDate: startDate.separatedBySlash(),
  endDate: endDate.separatedBySlash(),
});

export default convertTopDailyScoresDtoToViewData;
