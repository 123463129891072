import Result, { Failure, Success } from 'global/utilities/result';
import queryClient from 'global/query-client';
import { useHistory } from 'react-router-dom';
import ProfileViewData, {
  ProfileSinglePageViewData,
} from '../../../profile/view-data/profile-view-data';
import { UserInputRawData } from '../../../profile/user-input-item';
import getProfileViewData from '../../../profile/utils/get-profile-view-data';
import convertRecordToUserInputItems from '../../../profile/utils/convert-record-to-user-input-items';
import { PROFILE_CACHE_KEY, USER_PROFILE_DETAIL_CACHE_KEY } from './constants';

type ReturnType = {
  fetchResult: Result<ProfileSinglePageViewData, Error>;
  nextButtonTapped: (userInput: UserInputRawData) => void;
};

const getProfileDetailViewData = (): ProfileViewData | undefined => {
  const cachedProfile: ProfileViewData | undefined =
    queryClient.getQueryData(PROFILE_CACHE_KEY);

  if (cachedProfile == null) {
    return undefined;
  }

  return cachedProfile;
};

const useProfileDetail = (): ReturnType => {
  const profileDetail = getProfileDetailViewData();
  let result: Result<ProfileViewData, Error>;
  if (profileDetail) {
    result = new Success(profileDetail);
  } else {
    result = new Failure(new Error('データの取得に失敗しました'));
  }

  const fetchResult = getProfileViewData(result, 'profile_detail');

  const history = useHistory();
  const nextButtonTapped = (userInput: UserInputRawData) => {
    const userInputItems = convertRecordToUserInputItems(userInput);

    queryClient.setQueryDefaults(USER_PROFILE_DETAIL_CACHE_KEY, {
      cacheTime: Infinity, // プロフィール入力中に長時間放置した場合にキャッシュが失われないように、勝手にはキャッシュが破棄されないようにする
    });
    queryClient.setQueryData(USER_PROFILE_DETAIL_CACHE_KEY, userInputItems);
    history.push('/sign_up/profile/confirm');
  };

  return {
    fetchResult,
    nextButtonTapped,
  };
};

export default useProfileDetail;
