import { VFC } from 'react';
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import styles from './Dialog.module.css';
import Container from '../Container/Container';
import Button from '../button/Button';

export type DialogButton = {
  text: string;
  onClick?: () => void;
};

export type ImageSize = 'large' | 'small';

export type Props = {
  title?: string;
  description?: string;
  imageSrc?: string;
  imageSize?: ImageSize;
  primaryButton: DialogButton;
  destructiveButton?: DialogButton;
  isOpen: boolean;
  showsCloseButton?: boolean;
  onClose: () => void;
  alignDescriptionLeft?: boolean;
};

const classNameForImageSize = (imageSize: ImageSize): string => {
  switch (imageSize) {
    case 'large':
      return styles.imageLarge;
    case 'small':
      return styles.imageSmall;
    default:
      return '';
  }
};

const Dialog: VFC<Props> = ({
  title,
  description,
  imageSrc,
  imageSize = 'small',
  isOpen,
  primaryButton,
  destructiveButton,
  showsCloseButton = true,
  onClose,
  alignDescriptionLeft,
}) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.5)" />
    <ModalContent width="90%" borderRadius="8px">
      <Container marginTop={56} marginRight="m" marginBottom="m" marginLeft="m">
        {showsCloseButton && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <button
            className={styles.closeButton}
            type="button"
            onClick={onClose}
          />
        )}
        {imageSrc && (
          <div className={styles.imageContainer}>
            <img
              src={imageSrc}
              alt=""
              className={classNameForImageSize(imageSize)}
            />
          </div>
        )}

        {title && <div className={styles.title}>{title}</div>}
        {description && (
          <Container marginTop="m">
            <div className={styles.description}>
              {alignDescriptionLeft ? (
                <div className={styles.left}>{description}</div>
              ) : (
                description
              )}
            </div>
          </Container>
        )}

        <Container marginTop="xl">
          {destructiveButton && (
            <Container marginBottom="s">
              <Button
                text={destructiveButton.text}
                type="destructive"
                size="large"
                onClick={() => {
                  if (destructiveButton.onClick) {
                    destructiveButton.onClick();
                  }
                  onClose();
                }}
              />
            </Container>
          )}
          <Button
            text={primaryButton.text}
            type="primary"
            size="large"
            onClick={() => {
              if (primaryButton.onClick) {
                primaryButton.onClick();
              }
              onClose();
            }}
          />
        </Container>
      </Container>
    </ModalContent>
  </Modal>
);

export default Dialog;
