import { useQuery } from 'react-query';
import QuestionnaireTopRepository, {
  QuestionnaireTopRepositoryImpl,
} from 'features/questionnaire/data/repositories/questionnaire-top-repository';
import { isErrorDTO } from 'data/dto/error-dto';
import Result from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import QuestionnaireTopViewData from '../view-data/questionnaire-top-view-data';
import QuestionnaireTopDto from '../data/dto/questionnaire-top-dto';

type ReturnType = {
  fetchResult: Result<QuestionnaireTopViewData, Error>;
};

const useQuestionnaireTop = (
  repository: QuestionnaireTopRepository = new QuestionnaireTopRepositoryImpl(),
): ReturnType => {
  const convertQuestionnaireTopDtoToViewData = (
    dto: QuestionnaireTopDto,
  ): QuestionnaireTopViewData => ({
    categories: dto.categories.map((category) => ({
      ...category,
      answeredQuestionsCount:
        category.totalQuestionsCount - category.unansweredQuestionsCount,
    })),
  });

  const queryResult = useQuery<QuestionnaireTopViewData, Error>(
    ['/questionnaires'],
    async () => {
      const dto = await repository.fetch().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertQuestionnaireTopDtoToViewData(dto);
    },
  );

  const fetchResult = createResult(queryResult);

  return {
    fetchResult,
  };
};

export default useQuestionnaireTop;
