import { VFC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Loading from 'global/components/Loading/Loading';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { TutorialLocationState } from 'features/tutorial/hooks/use-tutorial-navigation';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import ClientCompanyDetailPage from '../pages/ClientCompanyDetailPage';
import useClientCompanyDetail from '../hooks/use-client-company-detail';
import { ClientCompanyDetailRepositoryImpl } from '../data/repositories/client-company-detail-repository';

const ClientCompanyDetailContainer: VFC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const { state } = useLocation<TutorialLocationState | undefined>();

  useNavigationBarItemsSetting('企業情報', {
    isCloseButtonVisible: !state?.isTutorial,
    isMenuButtonVisible: !state?.isTutorial,
    isNotificationButtonVisible: !state?.isTutorial,
    isBackButtonVisible: true,
  });

  const { fetchResult } = useClientCompanyDetail(
    clientId,
    new ClientCompanyDetailRepositoryImpl(),
  );

  if (fetchResult.isSuccess()) {
    return (
      <ClientCompanyDetailPage
        clientCompany={fetchResult.value}
        locationState={state}
      />
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default ClientCompanyDetailContainer;
