import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from 'react-query';
import Result from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import NoticeTopRepository, {
  NoticeTopRepositoryImpl,
} from '../data/repositories/notice-top-repository';
import { isErrorDTO } from '../../../data/dto/error-dto';
import NoticeTopViewData, {
  NoticeListViewData,
  NoticeType,
} from '../view-data/notice-top-view-data';
import NoticeTopDto, { NoticeTopListDto } from '../data/dto/notice-top-dto';

type ReturnType = {
  fetchResult: Result<NoticeTopViewData, Error>;
  shownNotices: NoticeListViewData[];
  isNeedAddLoadListMore: boolean;
  onAddLoadShownListItem: (cursor: number) => void;
  onTapNotice: (id: string, type: NoticeType, isRead: boolean) => void;
};

const convertNoticeType = (dto: NoticeTopListDto): NoticeType => {
  switch (dto.transitionType) {
    case 'information':
      return { kind: 'detail' };
    case 'program': {
      if (dto.transitionProgramDetail !== null) {
        if (
          dto.transitionProgramDetail.destination === 'detail' &&
          dto.transitionProgramDetail.detailId === null
        ) {
          throw new Error('プログラム詳細の遷移先IDが不正です。');
        }
      }

      return {
        kind: 'program',
        location:
          dto.transitionProgramDetail?.destination === 'detail'
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            { kind: 'detail', id: dto.transitionProgramDetail.detailId! }
            : { kind: 'top' },
      };
    }
    case 'offer':
      return { kind: 'offer' };
    case 'mydata':
      return {
        kind: 'mydata',
        location:
          dto.transitionMydataDetail?.destination === 'offers_company_select'
            ? 'offersCompanySelect'
            : 'top',
      };
    case 'point_detail':
      return { kind: 'point_detail' };
    case 'selfcheck':
      return { kind: 'selfcheck' };
    default:
      throw Error('お知らせタイプが不正です。');
  }
};

const convertDtoToViewData = (dto: NoticeTopDto): NoticeTopViewData => ({
  ...dto,
  notices: dto.notifications.map((e) => ({
    ...e,
    registeredAt: e.registeredAt
      .setZone('Asia/Tokyo')
      .toFormat('yyyy/M/d HH:mm'),
    type: convertNoticeType(e),
  })),
});

const useNoticeTop = (
  repository: NoticeTopRepository = new NoticeTopRepositoryImpl(),
): ReturnType => {
  const history = useHistory();
  const currentListViewCursor = useRef<number | null>(null);
  const [shownNotices, setShownNotices] = useState<NoticeListViewData[]>([]);
  const [isNeedAddLoadListMore, setIsNeedAddLoadListMore] = useState(true);

  const queryResult = useQuery<NoticeTopViewData, Error>(
    ['/notice-top'],
    async () => {
      const dto = await repository.fetch().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertDtoToViewData(dto);
    },
    {
      onSuccess: (data: NoticeTopViewData) => {
        currentListViewCursor.current = data.cursor;
        setIsNeedAddLoadListMore(currentListViewCursor.current != null);
        setShownNotices(data.notices);
      },
    },
  );

  const fetchResult = createResult(queryResult);

  const onAddLoadShownListItem = (): void => {
    if (!isNeedAddLoadListMore) {
      return;
    }
    if (currentListViewCursor.current == null) {
      return;
    }

    const _ = repository.fetch(currentListViewCursor.current).then((dto) => {
      currentListViewCursor.current = dto.cursor;
      const viewData = convertDtoToViewData(dto);
      setShownNotices([...shownNotices, ...viewData.notices]);
      setIsNeedAddLoadListMore(currentListViewCursor.current != null);
    });
  };

  const onTapNotice = (id: string, type: NoticeType, isRead: boolean): void => {
    if (!isRead) {
      // NOTE: 既読にするためのAPIだが失敗しても既読にならないだけなので放置
      const _ = repository.readNotice(id);
    }

    switch (type.kind) {
      case 'detail':
        history.push(`/notice/detail/${id}`);
        break;
      case 'program':
        if (type.location.kind === 'detail') {
          history.push(`/program/${type.location.id}`);
        } else {
          history.push('/program');
        }
        break;
      case 'offer':
        history.push('/offers');
        break;
      case 'mydata':
        if (type.location === 'offersCompanySelect') {
          history.push('/mydata/client_company');
        } else {
          history.push('/mydata');
        }
        break;
      case 'point_detail':
        history.push('/point_detail');
        break;
      case 'selfcheck':
        history.push('/questionnaires');
        break;
      default:
        break;
    }
  };

  return {
    fetchResult,
    shownNotices,
    isNeedAddLoadListMore,
    onAddLoadShownListItem,
    onTapNotice,
  };
};

export default useNoticeTop;
