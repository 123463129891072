import ActionId from '../data/common/action-id';

export type ActionRewardViewObject = {
  titleText: string;
  iconPath: string;
  valueText: string;
};

type ActionProgressTypeViewObjectNoNeed = {
  kind: 'noNeed';
};
type ActionProgressTypeViewObjectRate = {
  kind: 'rate';
  numeratorText: string;
  denominatorText: string;
  progressValue: number;
  unitSuffixText: string;
};
export type ActionProgressTypeViewObject =
  | ActionProgressTypeViewObjectNoNeed
  | ActionProgressTypeViewObjectRate;

type ActionButtonStateViewObjectNotStartedYet = {
  kind: 'notStartedYet';
  buttonText: string;
};
type ActionButtonStateViewObjectProgressing = {
  kind: 'progressing';
  buttonText: string;
};
type ActionButtonStateViewObjectRewardCanBeReceived = {
  kind: 'rewardCanBeReceived';
  buttonText: string;
};
type ActionButtonStateViewObjectRewardReceived = {
  kind: 'rewardReceived';
  buttonText: string;
};
type ActionButtonStateViewObjectTodaysAchieved = {
  kind: 'todaysAchieved';
  buttonText: string;
};
export type ActionButtonStateViewObject =
  | ActionButtonStateViewObjectNotStartedYet
  | ActionButtonStateViewObjectProgressing
  | ActionButtonStateViewObjectRewardCanBeReceived
  | ActionButtonStateViewObjectRewardReceived
  | ActionButtonStateViewObjectTodaysAchieved;

export const isEnableButton = (state: ActionButtonStateViewObject): boolean => {
  switch (state.kind) {
    case 'notStartedYet':
      return false;
    case 'progressing':
      return true;
    case 'rewardCanBeReceived':
      return true;
    case 'rewardReceived':
      return false;
    case 'todaysAchieved':
      return false;
    default:
      throw new Error(`Unexpected Object`);
  }
};

type ActionRequirementsMealInputViewTypeTop = {
  kind: 'top';
};
type ActionRequirementsMealInputViewTypeBreakfast = {
  kind: 'breakfast';
};
type ActionRequirementsMealInputViewTypeLunch = {
  kind: 'lunch';
};
type ActionRequirementsMealInputViewTypeDinner = {
  kind: 'dinner';
};
type ActionRequirementsMealInputViewTypeSnack = {
  kind: 'snack';
};
export type ActionRequirementsMealInputViewType =
  | ActionRequirementsMealInputViewTypeTop
  | ActionRequirementsMealInputViewTypeBreakfast
  | ActionRequirementsMealInputViewTypeLunch
  | ActionRequirementsMealInputViewTypeDinner
  | ActionRequirementsMealInputViewTypeSnack;

export type ActionButtonViewObject = {
  state: ActionButtonStateViewObject;
};

type ActionRequirementsViewStateEmpty = {
  kind: 'empty';
};
type ActionRequirementsViewStateExist = {
  kind: 'exist';
  button: ActionButtonViewObject;
};
export type ActionRequirementsViewState =
  | ActionRequirementsViewStateEmpty
  | ActionRequirementsViewStateExist;

type ActionViewData = {
  id: ActionId;
  title: string;
  reward: ActionRewardViewObject;
  progressType: ActionProgressTypeViewObject;
  requirementsViewState: ActionRequirementsViewState;
};

export default ActionViewData;
