import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import NotifyEmailRepository, {
  NotifyEmailRepositoryImpl,
} from '../data/repositories/notify-email-repository';

type ReturnType = {
  submitted: (phoneNumber: string, dateOfBirth: string) => void;
};

const useNotifyEmail = (
  saveResult: (result: Result<void, string>) => void,
  repository: NotifyEmailRepository = new NotifyEmailRepositoryImpl(),
): ReturnType => {
  const submitted = (phoneNumber: string, dateOfBirth: string): void => {
    const _ = repository
      .notify(phoneNumber, dateOfBirth)
      .then(() => {
        saveResult(new Success(undefined));
      })
      .catch((e) => {
        saveResult(
          new Failure(
            isErrorDTO(e)
              ? e.error.message
              : 'メールアドレスの通知に失敗しました',
          ),
        );
      });
  };

  return {
    submitted,
  };
};

export default useNotifyEmail;
