import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';

const createUserRequestConfig = (): RequestConfig => ({
  endpoint: { baseUrl: URL.FIT_STATS, path: '/users' },
  method: 'post',
  parameters: {},
});

interface UserRepository {
  create: () => Promise<void>;
}

export class UserRepositoryImpl {
  create = async (): Promise<void> =>
    new ApiClient().connect(createUserRequestConfig());
}

export default UserRepository;
