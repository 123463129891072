import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import TransactionIdDto from '../dto/transaction-id-dto';

const sendAuthCodeRequestConfig = (email: string): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/password/reset_request',
  },
  method: 'post',
  parameters: {
    email,
  },
});

interface InputEmailRepository {
  send: (email: string) => Promise<TransactionIdDto>;
}

export class InputEmailRepositoryImpl implements InputEmailRepository {
  send = async (email: string): Promise<TransactionIdDto> =>
    new ApiClient().connect(sendAuthCodeRequestConfig(email));
}

export default InputEmailRepository;
