import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import { useRef } from 'react';
import PhoneNumberResettingsRepository, {
  PhoneNumberResettingsRepositoryImpl,
} from '../data/repositories/phone-number-resettings-repository';

type ReturnType = {
  submitErrorMessage?: string;
  submitted: (phoneNumber: string) => void;
};

const usePhoneNumberResettings = (
  saveResult: (result: Result<string, string>) => void,
  repository: PhoneNumberResettingsRepository = new PhoneNumberResettingsRepositoryImpl(),
): ReturnType => {
  const submitErrorMessage = useRef<string | undefined>(undefined);

  const submitted = async (phoneNumber: string): Promise<void> => {
    try {
      await repository.resetting(phoneNumber);
      saveResult(new Success(phoneNumber));
    } catch (error) {
      submitErrorMessage.current = isErrorDTO(error)
        ? error.error.message
        : '電話番号の再設定に失敗しました';
      saveResult(new Failure('undefined'));
    }
  };

  return {
    submitErrorMessage: submitErrorMessage.current,
    submitted,
  };
};

export default usePhoneNumberResettings;
