import { useCallback, useState } from 'react';
import ClientCompanyRepository, {
  ClientCompanyRepositoryImpl,
} from 'features/my-data/client-company/data/repositories/client-company-repository';
import clientCompaniesPageLastLoadedAt from 'features/my-data/client-company/hooks/utils/last-page-load-at';

type ReturnType = {
  fetchNumberOfNewClientCompanies: () => void;
  numberOfNewCompanies: number;
};

const useNumberOfNewClientCompanies = (
  repository: ClientCompanyRepository = new ClientCompanyRepositoryImpl(),
): ReturnType => {
  const [numberOfNewCompanies, setNumberOfNewCompanies] = useState(0);

  const fetchNumberOfNewClientCompanies = useCallback(() => {
    void repository.fetch().then((dto) => {
      const lastLoadedAt = clientCompaniesPageLastLoadedAt();
      if (lastLoadedAt == null) {
        setNumberOfNewCompanies(dto.clients.length);
      } else {
        const newClientCompanies = dto.clients.filter(
          (c) => c.registeredAt >= lastLoadedAt,
        );
        setNumberOfNewCompanies(newClientCompanies.length);
      }
    });
  }, [repository]);

  return { fetchNumberOfNewClientCompanies, numberOfNewCompanies };
};

export default useNumberOfNewClientCompanies;
