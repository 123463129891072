import { FC } from 'react';
import { Link } from 'react-router-dom';

type stateType = {
  isTutorial?: boolean;
};

type toType = {
  pathname: string;
  state?: stateType;
  search?: string;
};

type Props = {
  to: toType | string;
  className?: string;
};

const UndraggableLink: FC<Props> = ({ children, to, className }) => (
  <Link to={to} className={className} draggable={false}>
    {children}
  </Link>
);

export default UndraggableLink;
