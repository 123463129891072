import MissionDto from '../../dto/mission-dto';
import MissionDomainObject, {
  MissionTermState,
} from '../mission-domain-object';
import { convertActionDtoToDomainObject } from './action-dto-convert';

const convertTermState = (mission: MissionDto): MissionTermState => {
  switch (mission.termState) {
    case 'before_term':
      return {
        kind: 'before',
        description: mission.termDescription,
      };
    case 'in_term':
      return {
        kind: 'withIn',
        description: mission.termDescription,
      };
    case 'after_term':
      return {
        kind: 'end',
        description: mission.termDescription,
      };
    default:
      throw new Error(`Unexpected Object`);
  }
};

export const missionTermStateWhen = <T>({
  state,
  before,
  withIn,
  end,
}: {
  state: MissionTermState;
  before: () => T;
  withIn: () => T;
  end: () => T;
}): T => {
  switch (state.kind) {
    case 'before':
      return before();
    case 'withIn':
      return withIn();
    case 'end':
      return end();
    default:
      throw new Error(`Unexpected Object`);
  }
};

export const convertMissionDtoToDomainObject = (
  dto: MissionDto,
): MissionDomainObject => ({
  id: dto.id,
  title: dto.title,
  termState: convertTermState(dto),
  actionList: dto.actions.map((e) => convertActionDtoToDomainObject(e)),
});
