import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';

const createPasswordResettingsRequestConfig = (
  currentPassword: string,
  postPassword: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/password',
  },
  method: 'put',
  parameters: {
    current_password: currentPassword,
    post_password: postPassword,
  },
});

interface PasswordResettingsRepository {
  resetting: (currentPassword: string, postPassword: string) => Promise<void>;
}

export class PasswordResettingsRepositoryImpl
  implements PasswordResettingsRepository
{
  resetting = async (
    currentPassword: string,
    postPassword: string,
  ): Promise<void> =>
    new ApiClient().connect(
      createPasswordResettingsRequestConfig(currentPassword, postPassword),
    );
}

export default PasswordResettingsRepository;
