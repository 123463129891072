import { DateTime } from 'luxon';
import TopScoresViewData, {
  CategoryScoreViewData,
  GraphSeriesViewData,
  ScoreRankViewData,
} from '../../view-data/top-scores-view-data';
import TopScoresDto, {
  ScoreRankDto,
  TotalScoreDto,
} from '../../data/dto/top-scores-dto';

const colorCodeForRank = (rank?: ScoreRankDto): string => {
  switch (rank?.name) {
    case 'A':
      return '#4a8be7';
    case 'B':
      return '#12c28d';
    case 'C':
      return '#e4b70b';
    case 'D':
      return '#ff87a6';
    case 'E':
      return '#f2554f';
    default:
      return '#000';
  }
};

const convertTotalScoreToGraphSeries = (
  totalScore: TotalScoreDto,
): GraphSeriesViewData[] => {
  if (totalScore.score && totalScore.maxScore) {
    const graphSeries = totalScore.achievedScoreRanks.map(
      (achievedScoreRank) => ({
        colorCode: colorCodeForRank(achievedScoreRank.rank),
        value: achievedScoreRank.value,
      }),
    );
    // スコアの満点に満たない部分を描画できるようにGraphSeriesを追加
    graphSeries.push({
      colorCode: '#edf3f3',
      value: totalScore.maxScore - totalScore.score,
    });

    return graphSeries;
  }

  // totalScoreがない場合は単一色の円グラフを描画
  return [{ colorCode: '#edf3f3', value: 1 }];
};

const convertScoreRankToViewData = (
  rank?: ScoreRankDto,
): ScoreRankViewData => ({
  name: rank?.name || '-',
  colorCode: colorCodeForRank(rank),
});

const convertLastUpdatedAtToViewData = (lastUpdatedAt?: DateTime): string =>
  lastUpdatedAt
    ? lastUpdatedAt.setZone('Asia/Tokyo').toFormat('M/d HH:mm')
    : '';

const convertTopScoresDtoToViewData = (
  dto: TopScoresDto,
): TopScoresViewData => ({
  totalScore: {
    score: dto.totalScore.score,
    maxScore: dto.totalScore.maxScore,
    differenceFromPrevious: dto.totalScore.differenceFromPrevious,
    rank: convertScoreRankToViewData(dto.totalScore.rank),
    lastUpdatedAt: convertLastUpdatedAtToViewData(dto.totalScore.lastUpdatedAt),
    graphSeries: convertTotalScoreToGraphSeries(dto.totalScore),
  },
  scoreComments: dto.scoreComments,
  categoryScores: dto.categoryScores.map(
    (score): CategoryScoreViewData => ({
      ...score,
      rank: convertScoreRankToViewData(score.rank),
    }),
  ),
});

export default convertTopScoresDtoToViewData;
