import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import OfferListDto from '../dto/offer-list-dto';
import AnnouncementOfferDto from '../dto/announcement-offer-dto';
import QuestionnaireOfferDto from '../dto/questionnaire-offer-dto';

const createOfferTopRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/offers',
  },
  method: 'get',
  parameters: {},
});

const createAnnouncementOfferDetailRequestConfig = (
  offerId: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/users/me/offers/announcement_offers/${offerId}`,
  },
  method: 'get',
  parameters: {},
});

const createQuestionnaireOfferDetailRequestConfig = (
  offerId: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/users/me/offers/questionnaire_offers/${offerId}`,
  },
  method: 'get',
  parameters: {},
});

interface OfferRepository {
  fetchList: () => Promise<OfferListDto>;
  fetchAnnouncementOffer: (offerId: string) => Promise<AnnouncementOfferDto>;
  fetchQuestionnaireOffer: (offerId: string) => Promise<QuestionnaireOfferDto>;
}

export class OfferRepositoryImpl implements OfferRepository {
  fetchList = async (): Promise<OfferListDto> =>
    new ApiClient().connect(createOfferTopRequestConfig());

  fetchAnnouncementOffer = async (
    offerId: string,
  ): Promise<AnnouncementOfferDto> =>
    new ApiClient().connect(
      createAnnouncementOfferDetailRequestConfig(offerId),
    );

  fetchQuestionnaireOffer = async (
    offerId: string,
  ): Promise<QuestionnaireOfferDto> =>
    new ApiClient().connect(
      createQuestionnaireOfferDetailRequestConfig(offerId),
    );
}

export default OfferRepository;
