import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { VFC } from 'react';
import { useHistory } from 'react-router';
import ConfirmPhoneNumberPage from '../pages/ConfirmPhoneNumberPage';

const ConfirmPhoneNumberContainer: VFC = () => {
  const history = useHistory();
  useNavigationBarItemsSetting('メールアドレスの確認');
  const submitted = (phoneNumber: string) => {
    history.push('/forget_email/confirm_birthday', {
      phoneNumber,
    });
  };

  return <ConfirmPhoneNumberPage submitted={submitted} />;
};
export default ConfirmPhoneNumberContainer;
