import { useRef, VFC } from 'react';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

type Props = {
  selectedIndex: number;
  children: React.ReactChild[];
};

const indicatorStyles = (
  isSelected: boolean,
  childrenLength: number,
): React.CSSProperties => ({
  display: 'inline-block',
  padding: 'var(--spacing-3xs)',
  margin: childrenLength <= 4 ? '0 8px' : '0 4px',
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  border: 'solid 0',
  backgroundColor: isSelected ? 'var(--color-primary-100)' : '#d8d8d8',
});

const MissionCarousel: VFC<Props> = ({ selectedIndex, children }) => {
  const prevTouch = useRef<React.Touch | undefined>(undefined);

  return (
    <>
      <Carousel
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        selectedItem={selectedIndex}
        emulateTouch={false}
        onSwipeStart={(e) => {
          prevTouch.current = e.targetTouches.item(0);
        }}
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={50}
        showIndicators
        renderIndicator={(onClickHandler, isSelected, index, label) => (
          <button
            style={indicatorStyles(isSelected, children.length)}
            type="button"
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            tabIndex={0}
            aria-label={`${label} ${index + 1}`}
          />
        )}
      >
        {children}
      </Carousel>
    </>
  );
};

export default MissionCarousel;
