import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import InputEmailContainer from './input-email/containers/index';
import InputCodeContainer from './input-code/containers/index';
import NewPasswordContainer from './new-password/containers/index';

const ForgetPasswordRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/input_email`}>
      <InputEmailContainer />
    </Route>
    <Route exact path={`${match.path}/input_code`}>
      <InputCodeContainer />
    </Route>
    <Route exact path={`${match.path}/new_password`}>
      <NewPasswordContainer />
    </Route>
  </Switch>
);

export default ForgetPasswordRouter;
