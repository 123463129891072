import { VFC } from 'react';
import { useHistory } from 'react-router';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import InputEmailPage from '../pages/InputEmailPage';
import useInputEmail from '../hooks/use-input-email';

const InputEmailContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, dialogProps, onClose } = useDialog();
  useNavigationBarItemsSetting('パスワードの再設定');
  const { submitted } = useInputEmail((saveResult) => {
    if (saveResult.isSuccess()) {
      history.push('/forget_password/input_code', {
        email: saveResult.value.email,
        transactionId: saveResult.value.transactionId,
      });
    }
    if (saveResult.isFailure()) {
      requestShowing({
        title: 'エラー',
        description: saveResult.error,
        primaryButton: { text: 'OK' },
      });
    }
  });

  return (
    <>
      <Dialog
        title={dialogProps?.title}
        description={dialogProps?.description}
        primaryButton={dialogProps?.primaryButton ?? { text: '閉じる' }}
        isOpen={isOpen}
        onClose={onClose}
      />

      <InputEmailPage submitted={submitted} />
    </>
  );
};

export default InputEmailContainer;
