import { VFC } from 'react';
import styles from './ProgressBar.module.css';

type Props = {
  currentDecimalRate: number;
};

const progressValueStyles = (
  currentDecimalRate: number,
): React.CSSProperties => ({
  position: 'relative',
  height: '8px',
  width: `${currentDecimalRate * 100}%`,
  borderRadius: '16px',
  backgroundColor: 'var(--color-primary-100)',
});

const ProgressBar: VFC<Props> = ({ currentDecimalRate }) => (
  <div className={styles.background}>
    <div style={progressValueStyles(currentDecimalRate)} />
  </div>
);

export default ProgressBar;
