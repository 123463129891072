import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import PhoneNumberAuthenticationDto from 'features/sign-up/phone-number/authentication/data/dto/phone-number-authentication-dto';

const createAuthenticationRequestConfig = (
  phoneNumberAuthCode: number,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/phone_number/auth',
  },
  method: 'post',
  parameters: {
    phone_number_auth_code: phoneNumberAuthCode,
  },
});

interface PhoneNumberAuthenticationRepository {
  auth: (phoneNumberAuthCode: number) => Promise<PhoneNumberAuthenticationDto>;
}

export class PhoneNumberAuthenticationRepositoryImpl
  implements PhoneNumberAuthenticationRepository
{
  auth = async (
    phoneNumberAuthCode: number,
  ): Promise<PhoneNumberAuthenticationDto> =>
    new ApiClient().connect(
      createAuthenticationRequestConfig(phoneNumberAuthCode),
    );
}

export default PhoneNumberAuthenticationRepository;
