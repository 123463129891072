import UserRankBonusesDto, {
  UserRankUpBonusDto,
  UserRankKeepBonusDto,
} from 'features/top/data/dto/user-rank-bonuses-dto';
import UserRankBonusesViewData, {
  UserRankUpBonusViewData,
  UserRankKeepBonusViewData,
} from 'features/top/view-data/user-rank-bonuses-view-data';

const convertUserRankUpBonus = (
  userRankUpBonuses?: UserRankUpBonusDto[],
): UserRankUpBonusViewData | undefined => {
  if (!userRankUpBonuses) {
    return undefined;
  }

  const totalPoint = userRankUpBonuses.reduce(
    (total, rankUpBonus) => total + rankUpBonus.point,
    0,
  );

  const details = userRankUpBonuses
    .sort((a, b) => (a.rank < b.rank ? -1 : 1)) // ランクの高い順にソート
    .map(
      (rankUpBonus) =>
        `${rankUpBonus.rank}にランクアップ +${rankUpBonus.point}pt`,
    );

  return {
    totalPoint,
    details,
  };
};

const convertUserRankKeepBonus = (
  userRankKeepBonus?: UserRankKeepBonusDto,
): UserRankKeepBonusViewData | undefined => {
  if (!userRankKeepBonus) {
    return undefined;
  }

  return {
    totalPoint: userRankKeepBonus.point,
    days: userRankKeepBonus.days,
  };
};

const convertUserRankBonusesDtoToViewData = (
  dto: UserRankBonusesDto,
): UserRankBonusesViewData => ({
  userRankUpBonus: convertUserRankUpBonus(dto.rankUpBonuses),
  userRankKeepBonus: convertUserRankKeepBonus(dto.rankKeepBonus),
});

export default convertUserRankBonusesDtoToViewData;
