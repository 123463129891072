import TopContainer from 'features/top/containers';
import Authentication from 'features/authentication/containers';
import NotFound from 'global/pages/NotFound';
import QuestionnaireRouter from 'features/questionnaire/questionnaire-router';
import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import OfferRouter from 'features/offer/offer-router';
import MyDataRouter from 'features/my-data/my-data-router';
import PointDetailRouter from 'features/point-detail/point-detail-router';
import SignInRouter from 'features/sign-in/sign-in-router';
import TermsAgreementRouter from 'features/terms-and-privacy-policy/terms-and-privacy-policy-router';
import ServiceDescriptionRouter from 'features/service-description/service-description-router';
import SignUpRouter from 'features/sign-up/sign-up-router';
import ProgramRouter from 'features/program/program-router';
import CampaignRouter from 'features/campaign/campaign-router';
import HontoCouponRouter from 'features/point/exchange/honto-coupon/honto-coupon-router';
import FincCouponRouter from 'features/point/exchange/finc-coupon/finc-coupon-router';
import NoticeRouter from 'features/notice/notice-router';
import TutorialTopContainer from 'features/top/containers/tutorial-index';
import ContainerResolver from 'features/tutorial/ContainerResolver';
import SettingsRouter from 'features/settings/root/settings-root/settings-root-router';
import AcountSettingsRouter from 'features/settings/root/acount-settings-root/acount-settings-root-router';
import PhoneNumberSettingsRouter from 'features/settings/phone-number/phone-number-router';
import PushNotificationSettingsRouter from 'features/settings/push_notification/push-notification-settings/push-notification-settings-router';
import ForgetPasswordRouter from 'features/forget-password/forget-password-router';
import ForgetEmailRouter from 'features/forget-email/forget-email-router';
import WithdrawalSettingsRouter from 'features/settings/withdrawal/withdrawal-settings/withdrawal-settings-router';
import PasswordResettingsRouter from 'features/settings/password/password-resettings/password-resettings-router';
import EmailResettingsRouter from 'features/settings/email/email-resettings/email-resettings-router';
import DebugPage from 'features/debug/DebugPage';
import AttemptLogoutContainer from 'features/attempt-logout/containers/AttemptLogoutContainer';
import { useTracking } from 'global/hooks/use-tracking';
import MaintainancePage from 'features/maintenance/MaintenancePage';

const Router: VFC = () => {
  useTracking();

  return (
    <Switch>
      <Route exact path="/" component={ServiceDescriptionRouter} />
      <Route exact path="/top">
        <ContainerResolver
          defaultContainer={<TopContainer />}
          tutorialContainer={<TutorialTopContainer />}
        />
      </Route>
      <Route exact path="/oauth/token" component={Authentication} />
      <Route path="/questionnaires" component={QuestionnaireRouter} />
      <Route
        path="/terms_and_privacy_policy"
        component={TermsAgreementRouter}
      />
      <Route path="/sign_up" component={SignUpRouter} />
      <Route path="/sign_in" component={SignInRouter} />
      <Route path="/point_detail" component={PointDetailRouter} />
      <Route path="/offers" component={OfferRouter} />
      <Route path="/mydata" component={MyDataRouter} />
      <Route path="/program" component={ProgramRouter} />
      <Route path="/campaign" component={CampaignRouter} />
      <Route path="/honto_coupon" component={HontoCouponRouter} />
      <Route path="/finc_coupon" component={FincCouponRouter} />
      <Route path="/notice" component={NoticeRouter} />
      <Route path="/settings" component={SettingsRouter} />
      <Route path="/acount_settings" component={AcountSettingsRouter} />
      <Route
        path="/phone_number_settings"
        component={PhoneNumberSettingsRouter}
      />
      <Route
        path="/push_notification_settings"
        component={PushNotificationSettingsRouter}
      />
      <Route path="/forget_password" component={ForgetPasswordRouter} />
      <Route path="/forget_email" component={ForgetEmailRouter} />
      <Route path="/withdrawal_settings" component={WithdrawalSettingsRouter} />
      <Route path="/password_resettings" component={PasswordResettingsRouter} />
      <Route path="/email_resettings" component={EmailResettingsRouter} />
      {/* TODO: /attempt_logoutのルーティング及びAttemptLogoutContainerはリリースまでに削除する */}
      {/* 検証用の古いアプリでは引き続き/attempt_logoutが呼ばるため互換性を保つために検証期間中は残しておく */}
      <Route path="/attempt_logout" component={AttemptLogoutContainer} />
      {process.env.NODE_ENV === 'development' && (
        <Route path="/debug" component={DebugPage} />
      )}
      <Route path="/maintenance" component={MaintainancePage} />
      <Route component={NotFound} />
    </Switch>
  );
};
export default Router;
