import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import FitStatsPointEventDto from '../dto/fit-stats-point-event-dto';

export const PointEvent = {
  REGISTRATION_COMPLETED: 'registration_completed',
  TUTORIAL_OFFER: 'tutorial_offer',
} as const;

export type PointEvent = typeof PointEvent[keyof typeof PointEvent];

const createEventConfig = (event: PointEvent): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/users/me/fit_stats_point/events`,
  },
  method: 'post',
  parameters: { event },
});

interface FitStatsPointRepository {
  createEvent: (event: PointEvent) => Promise<FitStatsPointEventDto>;
}

export class FitStatsPointRepositoryImpl implements FitStatsPointRepository {
  createEvent = async (event: PointEvent): Promise<FitStatsPointEventDto> =>
    new ApiClient().connect(createEventConfig(event));
}

export default FitStatsPointRepository;
