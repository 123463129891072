import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';

const createNotifyEmailRequestConfig = (
  phoneNumber: string,
  dateOfBirth: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/email/recover',
  },
  method: 'post',
  parameters: {
    phone_number: phoneNumber,
    date_of_birth: dateOfBirth,
  },
});

interface NotifyEmailRepository {
  notify: (phoneNumber: string, dateOfBirth: string) => Promise<void>;
}

export class NotifyEmailRepositoryImpl implements NotifyEmailRepository {
  notify = async (phoneNumber: string, dateOfBirth: string): Promise<void> =>
    new ApiClient().connect(
      createNotifyEmailRequestConfig(phoneNumber, dateOfBirth),
    );
}

export default NotifyEmailRepository;
