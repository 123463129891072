import { VFC } from 'react';
import styles from './PointIcon.module.css';

const PointIcon: VFC = () => (
  <div className={styles.parent}>
    <div className={styles.child}>
      <div className={styles.string}>P</div>
    </div>
  </div>
);

export default PointIcon;
