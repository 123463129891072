import { UseQueryResult } from 'react-query';
import Result, { Success, Failure, InProgress } from 'global/utilities/result';

const createResult = <T, E>(
  queryResult: UseQueryResult<T, E>,
): Result<T, E> => {
  if (queryResult.isError) {
    return new Failure(queryResult.error);
  }
  if (queryResult.isSuccess) {
    return new Success(queryResult.data);
  }

  return new InProgress();
};

export default createResult;
