import { useState } from 'react';
import { useQuery } from 'react-query';
import { useForm, UseFormRegisterReturn } from 'react-hook-form';
import queryClient from 'global/query-client';
import Result, { Failure, Success } from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import HontoCouponRepository, {
  HontoCouponRepositoryImpl,
} from '../data/repositories/honto-coupon-repository';
import { isErrorDTO } from '../../../../../data/dto/error-dto';
import HontoCouponScreenViewData from '../view-data/honto-coupon-view-data';
import {
  HontoCouponFetchEmailResponseDto,
  HontoCouponFetchPointResponseDto,
} from '../data/dto/honto-coupon-dto';

const IS_NOTICE_CLOSED_DATA_KEY = '/honto-coupon-notice-closed';
const HONTO_COUPON_QUERY_KEY = '/honto-coupon';

const cachedViewData = (): HontoCouponScreenViewData | undefined =>
  queryClient.getQueryData([HONTO_COUPON_QUERY_KEY]);

type ReturnType = {
  fetchResult: Result<HontoCouponScreenViewData, Error>;
  onClosedNotice: () => void;
  isClosedNotice: boolean;
  formRegister: UseFormRegisterReturn;
  expectedExchangeCouponCountText: string;
  exchangeNeedCostText: string;
  exchangedResultRemainPointText: string;
  isInsufficientPoints: boolean;
  isExchangeDisable: boolean;
  onExchangeValueChanged: (value: number) => void;
  handleExchangeSubmit: () => void;
};

type FormData = {
  exchangeQuantity: number;
};

type ExpectedResultInfo = {
  couponCount: number;
  totalCouponCost: number;
  remainPoint: number;
};

const convertDtoToViewData = (
  pointDto: HontoCouponFetchPointResponseDto,
  emailDto: HontoCouponFetchEmailResponseDto,
): HontoCouponScreenViewData => ({
  availablePoint: pointDto.ptsAvailable,
  targetCoupon: { name: '500円引きクーポン', cost: 500 }, // NOTE(smile-yoshryo): サーバーにクーポン情報の概念がないとのことで決め打ち。View情報としては必要なので型だけ用意しておく。
  email: emailDto.email,
});

const useHontoCoupon = (
  exchangeConfirm: (
    confirmMessage: string,
    onTapDecided: () => Promise<void>,
  ) => void,
  exchangedCoupon: (result: Result<{ successMessage: string }, Error>) => void,
  repository: HontoCouponRepository = new HontoCouponRepositoryImpl(),
): ReturnType => {
  const [expectedResult, setExpectedResult] = useState<ExpectedResultInfo>({
    couponCount: 0,
    totalCouponCost: 0,
    remainPoint: 0,
  });
  const queryResult = useQuery<HontoCouponScreenViewData, Error>(
    [HONTO_COUPON_QUERY_KEY],
    async () => {
      const pointDto = await repository.fetchPoint().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });
      const usersInfoDto = await repository.fetchEmail().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertDtoToViewData(pointDto, usersInfoDto);
    },
    {
      onSuccess: (data) => {
        setExpectedResult({
          couponCount: 0,
          totalCouponCost: 0,
          remainPoint: data.availablePoint,
        });
      },
    },
  );
  const fetchResult = createResult(queryResult);

  const onClosedNotice = (): void => {
    const formattedValue = JSON.stringify(true);

    localStorage.setItem(IS_NOTICE_CLOSED_DATA_KEY, formattedValue);
  };

  const isClosedNotice = (): boolean => {
    const data: string | null = localStorage.getItem(IS_NOTICE_CLOSED_DATA_KEY);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedData = data === null ? undefined : JSON.parse(data);

    return typeof parsedData === 'boolean' ? parsedData : false;
  };

  const { getValues, register, handleSubmit, reset } = useForm<FormData>({
    defaultValues: { exchangeQuantity: 0 },
  });

  const onExchangeValueChanged = (quantity: number): void => {
    const cached = cachedViewData();
    if (cached) {
      const expectedExchangeCount = !Number.isNaN(quantity) ? quantity : 0;
      const totalCouponCost = cached.targetCoupon.cost * expectedExchangeCount;
      setExpectedResult({
        couponCount: expectedExchangeCount,
        totalCouponCost,
        remainPoint: cached.availablePoint - totalCouponCost,
      });
    }
  };

  const onSubmit = (data: FormData) => {
    const _ = repository
      .exchangeCoupon(data.exchangeQuantity)
      .then(() => {
        queryClient.setQueryData([HONTO_COUPON_QUERY_KEY], {
          ...cachedViewData(),
          availablePoint: expectedResult.remainPoint,
        });
        reset({ exchangeQuantity: 0 });
        exchangedCoupon(
          new Success({
            successMessage:
              '登録しているメールアドレスにクーポンコードが送られましたので、ご確認ください。\n送られたメールが見つからない場合は、迷惑メールフォルダをご確認ください。',
          }),
        );
      })
      .catch(() => {
        exchangedCoupon(new Failure(Error('クーポンの交換に失敗しました。')));
      });
  };

  const handleExchangeSubmit = (): void => {
    const cached = cachedViewData();
    const quantity = getValues().exchangeQuantity;
    if (cached !== undefined && !Number.isNaN(quantity)) {
      exchangeConfirm(
        `「確定する」ボタンを押すと交換が確定します。\n\n${cached.targetCoupon.name} ${quantity}枚`,
        handleSubmit(onSubmit),
      );
    }
  };

  return {
    fetchResult,
    onClosedNotice,
    isClosedNotice: isClosedNotice(),
    formRegister: register('exchangeQuantity'),
    expectedExchangeCouponCountText:
      expectedResult.couponCount.toLocaleString(),
    exchangeNeedCostText: expectedResult.totalCouponCost.toLocaleString(),
    exchangedResultRemainPointText: expectedResult.remainPoint.toLocaleString(),
    isInsufficientPoints: expectedResult.remainPoint < 0,
    isExchangeDisable:
      expectedResult.remainPoint < 0 || expectedResult.totalCouponCost <= 0,
    onExchangeValueChanged,
    handleExchangeSubmit,
  };
};

export default useHontoCoupon;
