import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import EmailResettingsContainer from './containers/index';
import EmailResettingsAuthenticationContainer from './containers/show';

const EmailResettingsRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`}>
      <EmailResettingsContainer />
    </Route>
    <Route path={`${match.path}/authentication`}>
      <EmailResettingsAuthenticationContainer />
    </Route>
  </Switch>
);

export default EmailResettingsRouter;
