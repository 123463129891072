import { useEffect } from 'react';
import requestWebCommand, {
  SetNavigationBarItems,
  NavigationBarTitle,
  NavigationBarButtonSettings,
} from 'global/utilities/web-command';

const useNavigationBarItemsSetting = (
  title: NavigationBarTitle,
  barButtonSettings: NavigationBarButtonSettings | undefined = {
    isCloseButtonVisible: true,
    isMenuButtonVisible: true,
    isNotificationButtonVisible: true,
    isBackButtonVisible: true,
  },
): void => {
  useEffect(() => {
    requestWebCommand(new SetNavigationBarItems(title, barButtonSettings));
  }, [barButtonSettings, title]);
};

export default useNavigationBarItemsSetting;
