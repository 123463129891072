import { VFC } from 'react';
import { useForm } from 'react-hook-form';
import Container from 'global/components/Container/Container';
import FormContainer from 'global/components/FormContainer/FormContainer';
import InputNum from 'global/components/InputNum/InputNum';
import Button from 'global/components/button/Button';
import UndraggableLink from 'global/components/UndraggableLink/UndraggableLink';
import styles from './InputCodePage.module.css';

type Props = {
  submitted: (email: string, transactionId: string) => void;
  transactionId: string;
  email: string;
};

type FormData = {
  authCode: string;
};

const InputCodePage: VFC<Props> = ({ submitted, transactionId, email }) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<FormData>({ mode: 'onBlur' });

  const onSubmit = (data: FormData) => {
    submitted(data.authCode, transactionId);
  };

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <div className={styles.body}>
          <p className={styles.message}>
            登録いただいたメールアドレス({email})に認証コードを送信しました。
            <br />
            メールにて送付した9桁の認証コードを入力してください。
          </p>
          <Container marginTop="m">
            <FormContainer>
              {[
                <InputNum
                  name="authCode"
                  size="small"
                  placeholder=""
                  maxLength={9}
                  register={register('authCode', {
                    required: true,
                    maxLength: 9,
                  })}
                />,
              ]}
            </FormContainer>
          </Container>
          <p className={styles.message}>
            認証メールが届かない場合には
            <UndraggableLink to="/forget_password/input_email">
              メールアドレスの入力
            </UndraggableLink>
            にて再送してください。
          </p>
        </div>
      </Container>
      <Container marginTop="4xl" marginLeft="s" marginRight="s">
        <Button
          text="次へ"
          type="primary"
          size="large"
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(onSubmit)}
        />
      </Container>
    </>
  );
};

export default InputCodePage;
