import { VFC } from 'react';
import PieChart from 'highcharts-react-official';
import Container from 'global/components/Container/Container';
import { TotalScoreViewData } from '../view-data/top-scores-view-data';
import styles from './TopLifeScoreChart.module.css';
import ScoreTrend from './ScoreTrend';

type Props = {
  chartData: TotalScoreViewData;
};

const TopLifeScoreChart: VFC<Props> = ({ chartData }) => {
  const pieChartData = chartData.graphSeries.map((entry) => ({
    y: entry.value,
    color: entry.colorCode,
  }));

  const options = {
    chart: {
      type: 'pie',
      height: '100%',
      window: '100%',
      margin: 0,
    },
    title: { text: undefined },
    credits: { enabled: false },
    plotOptions: {
      pie: {
        slicedOffset: 0,
        borderWidth: 0,
        innerSize: '88%',
        enableMouseTracking: false,
      },
    },
    series: [
      {
        data: pieChartData,
        dataLabels: {
          enabled: false,
        },
      },
    ],
  };

  return (
    <>
      <div className={styles.pieChartWrapper}>
        <PieChart options={options} />
        <div className={styles.pieChartDetail}>
          <div className={styles.pieChartTiele}>総合スタッツ</div>
          <div className={styles.scoreValueWrapper}>
            <div className={styles.scoreValue}>
              {typeof chartData.score === 'number' ? chartData.score : '---'}
            </div>
            <div>/ {chartData.maxScore || '---'}</div>
          </div>
          <div
            className={styles.scoreRankWrapper}
            style={{ color: chartData.rank.colorCode }}
          >
            {chartData.rank.name}
            <div className={styles.scoreTrendWrapper}>
              <ScoreTrend
                differenceFromPrevious={chartData.differenceFromPrevious}
              />
            </div>
          </div>
        </div>
        <Container marginTop={6}>
          <div className={styles.lastUpdatedAt}>
            {chartData.lastUpdatedAt ? `${chartData.lastUpdatedAt}更新` : ''}
          </div>
        </Container>
      </div>
    </>
  );
};

export default TopLifeScoreChart;
