import { useQuery } from 'react-query';
import ProfileRepository, {
  ProfileRepositoryImpl,
} from 'features/profile/data/repositories/profile-repository';
import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import { useRef } from 'react';
import ProfileViewData, {
  ProfileSinglePageViewData,
} from '../../../profile/view-data/profile-view-data';
import ProfileDto from '../../../profile/data/dto/profile-dto';
import UserInputItem, {
  UserInputRawData,
} from '../../../profile/user-input-item';
import getProfileViewData from '../../../profile/utils/get-profile-view-data';
import convertRecordToUserInputItems from '../../../profile/utils/convert-record-to-user-input-items';
import { PROFILE_CACHE_KEY } from './constants';

type ReturnType = {
  fetchResult: Result<ProfileSinglePageViewData, Error>;
  submitErrorMessage?: string;
  submitted: (userInput: UserInputRawData) => void;
};

const convertDtoToViewData = (dto: ProfileDto): ProfileViewData => dto;
type SaveSuccessMessage = string;

const useProfileBasic = (
  saveResult: (result: Result<SaveSuccessMessage, Error>) => void,
): ReturnType => {
  const repository: ProfileRepository = new ProfileRepositoryImpl();
  const submitErrorMessage = useRef<string | undefined>(undefined);

  const queryResult = useQuery<ProfileViewData, Error>(
    [PROFILE_CACHE_KEY],
    async () => {
      const dto = await repository.fetch().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertDtoToViewData(dto);
    },
    { cacheTime: Infinity }, // プロフィール入力中に長時間放置した場合にキャッシュが失われないように、勝手にはキャッシュが破棄されないようにする
  );

  const fetchResult = getProfileViewData(
    createResult(queryResult),
    'profile_basic_info',
  );

  const removeUnansweredItem = (
    userInputItems: UserInputItem[],
  ): UserInputItem[] => {
    const filteredResult = userInputItems
      .filter((item) => item.value != null && item.value.length > 0)
      .filter((item) => item.name !== 'year')
      .filter((item) => item.name !== 'month')
      .filter((item) => item.name !== 'day');

    return filteredResult;
  };

  const submitted = (userInput: UserInputRawData) => {
    let userInputItems = convertRecordToUserInputItems(userInput);

    const yearValue = userInputItems.find((item) => item.name === 'year');
    const monthValue = userInputItems.find((item) => item.name === 'month');
    const dayValue = userInputItems.find((item) => item.name === 'day');

    userInputItems = removeUnansweredItem(userInputItems);
    if (yearValue && monthValue && dayValue) {
      const birthday = `${String(yearValue.value)}-${String(
        monthValue.value,
      )}-${String(dayValue.value)}`;
      userInputItems.push({ name: 'birthday', value: birthday });
    }

    const _ = repository
      .save(userInputItems)
      .then(() => {
        saveResult(new Success('プロフィールの登録に成功しました'));
      })
      .catch((error) => {
        submitErrorMessage.current = isErrorDTO(error)
          ? error.error.message
          : 'プロフィールの登録に失敗しました';
        saveResult(new Failure(Error('プロフィールの登録に失敗しました')));
      });
  };

  return {
    fetchResult,
    submitErrorMessage: submitErrorMessage.current,
    submitted,
  };
};

export default useProfileBasic;
