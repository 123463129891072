import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import ServiceDescriptionContainer from 'features/service-description/containers/index';

const ServiceDescriptionRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`}>
      <ServiceDescriptionContainer />
    </Route>
  </Switch>
);

export default ServiceDescriptionRouter;
