import { isErrorDTO } from 'data/dto/error-dto';
import { IntroductionProgressRepositoryImpl } from 'features/introduction-progress/data/repositories/introduction-progress-repository';
import { SignOutRepositoryImpl } from 'features/logout/data/sign-out-repository';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import useClearCaches from 'global/hooks/use-clear-caches';
import { useHistory } from 'react-router';

type ReturnType = {
  redirectToSignInIfUserSwitched: () => void;
};
const useUserSwitchCheck = (): ReturnType => {
  const history = useHistory();
  const { clearCaches } = useClearCaches();
  const repository = new IntroductionProgressRepositoryImpl();
  const redirectToSignInIfUserSwitched = async () => {
    // ここで行っているのは、
    // 「FiNCとの連携の結果、過去に登録済みであったFitStatsユーザーと連携された場合に、
    // パスワードなしでログインできた事になるのが良くないので、ログイン画面に遷移する」という事である。
    // これが実現できれば確認方法は何でも良いが、
    // ファーストリリース時点では便宜上チュートリアルの進捗状況確認がこの役割を果たす。
    const switched: boolean = await repository
      .fetch()
      .then((dto) => dto.state !== 'no_progress')
      .catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

    if (switched) {
      const signOutRepository = new SignOutRepositoryImpl();
      signOutRepository
        .delete()
        .then(() => {
          localStorage.removeItem('deviceId');
          clearCaches();
          history.push('/sign_in');
        })
        .catch((error) => {
          if (isErrorDTO(error)) {
            throw Error(error.error.message);
          }
          throw Error(
            'ログアウトに失敗しました。一度アプリを終了させて再度お試しください',
          );
        });
    }
  };

  return { redirectToSignInIfUserSwitched };
};
export default useUserSwitchCheck;
