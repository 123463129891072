import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import PointDetailContainer from 'features/point-detail/containers/index';

const PointDetailRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`}>
      <PointDetailContainer />
    </Route>
  </Switch>
);

export default PointDetailRouter;
