import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { VFC } from 'react';
import { useHistory } from 'react-router';
import ConfirmBirthdayPage from '../pages/ConfirmBirthdayPage';

const ConfirmBirthdayContainer: VFC = () => {
  const history = useHistory();
  useNavigationBarItemsSetting('メールアドレスの確認');
  const { phoneNumber } =
    (history.location.state as {
      phoneNumber: string;
    }) || '';

  // 直接このページには飛べないようにする
  // 必ず、電話番号の確認を終えてから
  if (!phoneNumber) {
    history.push('/top');
  }

  const submitted = (birthday: string) => {
    history.push('/forget_email/notify_email', {
      phoneNumber,
      birthday,
    });
  };

  return <ConfirmBirthdayPage submitted={submitted} />;
};

export default ConfirmBirthdayContainer;
