import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import EmailResettingsRepository, {
  EmailResettingsRepositoryImpl,
} from '../data/repositories/email-resettings-repository';

type ReturnType = {
  submitted: (currentEmail: string, newEmail: string) => void;
};

const useEmailResettings = (
  saveResult: (result: Result<string, string>) => void,
  repository: EmailResettingsRepository = new EmailResettingsRepositoryImpl(),
): ReturnType => {
  const submitted = (currentEmail: string, newEmail: string): void => {
    const _ = repository
      .resetting(currentEmail, newEmail)
      .then(() => {
        saveResult(new Success(newEmail));
      })
      .catch((e) => {
        saveResult(
          new Failure(
            isErrorDTO(e)
              ? e.error.message
              : 'メールアドレスの登録に失敗しました',
          ),
        );
      });
  };

  return {
    submitted,
  };
};

export default useEmailResettings;
