import { DateTime } from 'luxon';
import YearMonthDay from 'global/utilities/year-month-day';
import OfferListDto from '../dto/offer-list-dto';
import AnnouncementOfferDto from '../dto/announcement-offer-dto';
import QuestionnaireOfferDto from '../dto/questionnaire-offer-dto';
import OfferRepository from './offer-repository';

const tommorow = new YearMonthDay(DateTime.local()).addDays(1);

export const tutorialAnnouncementOffer: AnnouncementOfferDto = {
  isLinkUrl1Clicked: false,
  isLinkUrl2Clicked: false,
  isOfferOpened: false,
  isOfferAchieved: false,
  offerId: 'tutorial',
  pointId: 0,
  offerTypeSym: 'announcement_offer',
  thumbnailImageAccessKey: `${process.env.REACT_APP_FIT_STATS_CLIENT_BASE_URL}/tutorial/tutorial-offer-thumbnail.jpg`,
  companyName: 'FitStats食品',
  title: '朝専用１００％果実・野菜ミックスジュース\nモニター募集のお知らせ',
  scheduledDeliveryEndDatetime: DateTime.local(
    tommorow.year,
    tommorow.month,
    tommorow.day,
    23,
    59, // 翌日の23:59
  ),
  awardPoint: 1,
  announcementLinkUrl1: '',
  announcementLinkUrl2: '',
  mainImageAccessKey: `${process.env.REACT_APP_FIT_STATS_CLIENT_BASE_URL}/tutorial/tutorial-offer-main.jpg`,
  headline1: 'カラダとココロが整う朝専用ミックスジュース',
  bodyText1: `こんにちは、FitStats食品です。FitStats食品は“朝専用”の果実・野菜２０種類１００％のミックスジュースを開発しました。カラダとココロが目覚める朝に、１日の栄養を手軽にチャージして健康に導く、うれしいごほうびです。まずは７日間、朝専用ミックスジュースで毎朝のメンテナンスを始めてみませんか。１日の始まりに必要な、朝専用の果実・野菜２０種類１００％のミックスジュースのモニター募集は以下よりご参加ください。募集は${tommorow.month}月${tommorow.day}日までです。\n※本オファーはイメージです。`,
  textAnnouncementLinkUrl1: '',
  subImageAccessKey: '',
  headline2: '',
  bodyText2: '',
  textAnnouncementLinkUrl2: '',
  deliveryTargetConditions: '',
  caution: '',
};

class TutorialOfferRepository implements OfferRepository {
  fetchList = (): Promise<OfferListDto> =>
    new Promise((resolve) => {
      const dto: OfferListDto = {
        offers: [
          {
            offerId: tutorialAnnouncementOffer.offerId,
            offerTypeSym: tutorialAnnouncementOffer.offerTypeSym,
            isOfferAchieved: tutorialAnnouncementOffer.isOfferAchieved,
            isOfferOpened: tutorialAnnouncementOffer.isOfferOpened,
            scheduledDeliveryEndDatetime:
              tutorialAnnouncementOffer.scheduledDeliveryEndDatetime,
            awardPoint: tutorialAnnouncementOffer.awardPoint,
            companyName: tutorialAnnouncementOffer.companyName,
            title: tutorialAnnouncementOffer.title,
            thumbnailImageAccessKey:
              tutorialAnnouncementOffer.thumbnailImageAccessKey,
          },
        ],
      };
      resolve(dto);
    });

  fetchAnnouncementOffer = (): Promise<AnnouncementOfferDto> =>
    new Promise((resolve) => {
      const dto: AnnouncementOfferDto = {
        isLinkUrl1Clicked: tutorialAnnouncementOffer.isLinkUrl1Clicked,
        isLinkUrl2Clicked: tutorialAnnouncementOffer.isLinkUrl2Clicked,
        isOfferOpened: tutorialAnnouncementOffer.isOfferOpened,
        isOfferAchieved: tutorialAnnouncementOffer.isOfferAchieved,
        offerId: tutorialAnnouncementOffer.offerId,
        pointId: tutorialAnnouncementOffer.pointId,
        offerTypeSym: tutorialAnnouncementOffer.offerTypeSym,
        thumbnailImageAccessKey:
          tutorialAnnouncementOffer.thumbnailImageAccessKey,
        companyName: tutorialAnnouncementOffer.companyName,
        title: tutorialAnnouncementOffer.title,
        scheduledDeliveryEndDatetime:
          tutorialAnnouncementOffer.scheduledDeliveryEndDatetime,
        awardPoint: tutorialAnnouncementOffer.awardPoint,
        announcementLinkUrl1: tutorialAnnouncementOffer.announcementLinkUrl1,
        announcementLinkUrl2: tutorialAnnouncementOffer.announcementLinkUrl2,
        mainImageAccessKey: tutorialAnnouncementOffer.mainImageAccessKey,
        headline1: tutorialAnnouncementOffer.headline1,
        bodyText1: tutorialAnnouncementOffer.bodyText1,
        textAnnouncementLinkUrl1:
          tutorialAnnouncementOffer.textAnnouncementLinkUrl1,
        subImageAccessKey: tutorialAnnouncementOffer.subImageAccessKey,
        headline2: tutorialAnnouncementOffer.headline2,
        bodyText2: tutorialAnnouncementOffer.bodyText2,
        textAnnouncementLinkUrl2:
          tutorialAnnouncementOffer.textAnnouncementLinkUrl2,
        deliveryTargetConditions:
          tutorialAnnouncementOffer.deliveryTargetConditions,
        caution: tutorialAnnouncementOffer.caution,
      };
      resolve(dto);
    });

  // チュートリアルにアンケートオファーを使用することがあれば下記の原稿を修正する
  fetchQuestionnaireOffer = (): Promise<QuestionnaireOfferDto> =>
    new Promise((resolve) => {
      const dto: QuestionnaireOfferDto = {
        isOfferOpened: false,
        isOfferAchieved: false,
        offerId: 'tutorial',
        pointId: 0,
        offerTypeSym: 'questionnaire_offer',
        thumbnailImageAccessKey: 'https://www.thumbnailImage.com',
        companyName: '掲載企業名',
        title: 'タイトル',
        scheduledDeliveryEndDatetime: DateTime.fromISO(
          '2200-12-10T00:00:00.000+09:00',
        ),
        awardPoint: 100,
        mainImageAccessKey: 'https://www.mainImage.com',
        headline1: 'ヘッドライン１',
        bodyText1: '本文１',
        answeringQuestionnaireUrl: 'https://www.answer.com',
        deliveryTargetConditions: '氏名、年齢、生年月日、住所、学歴、職歴',
        caution: '注意事項',
      };
      resolve(dto);
    });
}

export default TutorialOfferRepository;
