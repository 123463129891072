import { VFC } from 'react';
import { useHistory } from 'react-router';
import Loading from 'global/components/Loading/Loading';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import ScrollToTopWhenMounted from 'global/components/ScrollToTopWhenMounted/ScrollToTopWhenMounted';
import OfferTopPage from '../pages/OfferTopPage';
import useOfferTop from '../hooks/use-offer-top';

const OfferTopContainer: VFC = () => {
  const {
    fetchResult,
    isNeedAddLoadShownListMore,
    onAddLoadShownListItem,
    topShownOffers,
  } = useOfferTop();
  const history = useHistory();
  useNavigationBarItemsSetting('オファー');

  if (fetchResult.isSuccess()) {
    return (
      <ScrollToTopWhenMounted>
        <OfferTopPage
          offers={topShownOffers}
          isNeedLoadMore={isNeedAddLoadShownListMore}
          onLoadItem={onAddLoadShownListItem}
          onTapOffersItem={(id, type) => {
            if (type.kind === 'announcement') {
              history.push(`/offers/announcement_offers/${id}`);
            }
            if (type.kind === 'questionnaire') {
              history.push(`/offers/questionnaire_offers/${id}`);
            }
          }}
        />
      </ScrollToTopWhenMounted>
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default OfferTopContainer;
