import { useRef, VFC } from 'react';
import { useHistory } from 'react-router';
import { EmailAuthenticationRepositoryImpl } from 'features/sign-up/email/authentication/data/repositories/email-authentication-repository';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import TopContainer from 'global/components/TopContainer/TopContainer';
import ProgressBar from 'features/sign-up/components/ProgressBar/ProgressBar';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorDTO from 'data/dto/error-dto';
import EmailAuthenticationPage from '../pages/EmailAuthenticationPage';
import useEmailAuthentication from '../hooks/use-email-authentication';

type DialogInfo = {
  description: string;
  primaryButtonAction: () => void;
};

const EmailAuthenticationContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, onClose } = useDialog();
  const dialogInfoRef = useRef<DialogInfo | undefined>();
  const primaryButtonActionRef = useRef<() => void>(() => onClose());

  useNavigationBarItemsSetting('認証コード入力', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const isPermissionDeniedError = (error: ErrorDTO | undefined) =>
    error && error.error.status === 'PERMISSION_DENIED';

  const { submitted } = useEmailAuthentication(
    new EmailAuthenticationRepositoryImpl(),
    (authResult) => {
      if (authResult.isSuccess()) {
        history.push('/sign_up/phone_number/authentication_tips');
      }

      if (authResult.isFailure()) {
        const description = authResult.error
          ? authResult.error.error.message
          : 'メールアドレスの認証に失敗しました。';

        if (isPermissionDeniedError(authResult.error)) {
          primaryButtonActionRef.current = () => {
            onClose();
            history.push('/');
          };
        }

        dialogInfoRef.current = {
          description,
          primaryButtonAction: primaryButtonActionRef.current,
        };

        requestShowing();
      }
    },
  );

  return (
    <>
      {dialogInfoRef.current && (
        <Dialog
          title="エラー"
          description={dialogInfoRef.current.description}
          primaryButton={{
            text: 'OK',
            onClick: dialogInfoRef.current.primaryButtonAction,
          }}
          isOpen={isOpen}
          onClose={() => undefined}
          showsCloseButton={false}
        />
      )}

      {/* EmailAuthenticationPageは初回登録以外でも呼ばれる可能性があるためここで初回登録用のプログレスバーを設置 */}
      <TopContainer>
        <ProgressBar max={8} value={4} />
      </TopContainer>
      {/* これ以上style長くなりそうならcssファイルの切り出し検討する */}
      <div style={{ paddingTop: 60, paddingBottom: 150 }}>
        <EmailAuthenticationPage
          submitted={submitted}
          email={(history.location.state as { email: string }).email}
        />
      </div>
    </>
  );
};

export default EmailAuthenticationContainer;
