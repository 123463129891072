/* eslint-disable react/jsx-props-no-spreading */
import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import InputBirthDate from 'global/components/InputBirthDate/InputBirthDate';
import InputText from 'global/components/InputText/InputText';
import Select from 'global/components/Select/Select';
import { VFC } from 'react';
import {
  FieldValues,
  UseFormRegister,
  RegisterOptions,
  Validate,
  UseFormWatch,
} from 'react-hook-form';
import Container from 'global/components/Container/Container';
import YearMonthDay, { isYearMonthDay } from 'global/utilities/year-month-day';
import { DateTime } from 'luxon';
import { ProfileFormItemViewData } from 'features/profile/view-data/profile-view-data';
import buttonStyles from 'global/components/button/Button.module.css';
import styles from './FormItem.module.css';

type Props = {
  formItem: ProfileFormItemViewData;
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  ageChanged?: (age: number) => void;
  addressSearchButtonTapped: () => void;
  normalizeAddress: () => void;
  replaceHanToZenInBuildingName: () => void;
  validateBirthday: () => void;
};

const FormItem: VFC<Props> = ({
  formItem,
  register,
  watch,
  ageChanged,
  addressSearchButtonTapped,
  normalizeAddress,
  replaceHanToZenInBuildingName,
  validateBirthday,
}) => {
  const options: RegisterOptions = {};

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const watchYear = watch('year') as string;
  const watchMonth = watch('month') as string;
  const watchDay = watch('day') as string;
  const watchBirthday = `${watchYear}-${watchMonth}-${watchDay}`;

  formItem.validationRules.forEach((rule) => {
    switch (rule.type) {
      case 'required':
        // eslint-disable-next-line no-case-declarations
        const elm = formItem.inputElement;

        // 複数選択の場合、プレースホルダー選択（実質未回答）だとvalueは['']が返る。
        // この場合requiredバリテーションを通過してしまうため、下記で独自にチェックしている。
        if (elm.type === 'select_box' && elm.options.multiple && rule.value) {
          const validation: Record<string, Validate<string>> = {};
          validation.multiSelect = (value: string | string[]) => {
            const filteredValue = Array.isArray(value)
              ? value.filter((v) => v.length > 0)
              : value;

            return (
              filteredValue.length > 0 ||
              `${formItem.title}は入力必須の項目です。`
            );
          };
          options.validate = validation;
          break;
        }

        if (elm.type === 'birthday') {
          return;
        }

        options.required = {
          value: rule.value,
          message: `${formItem.title}は入力必須の項目です。`,
        };
        break;
      case 'text_length':
        options.maxLength = {
          value: rule.value.maximum,
          message: `${rule.value.maximum}文字以下で入力してください。`,
        };
        options.minLength = {
          value: rule.value.minimum,
          message: `${rule.value.minimum}文字以上で入力してください。`,
        };
        break;
      case 'allowed_characters': {
        const validation: Record<string, Validate<string>> = {};
        rule.value.forEach((v) => {
          switch (v.type) {
            case 'hiragana':
              validation.hiragana = (value: string) =>
                !value || /^[ぁ-んー]*$/.test(value) || 'ひらがなで入力してください。';
              break;
            case 'half_width_number':
              validation.halfWidthNumber = (value: string) =>
                !value || /^[0-9]*$/.test(value) || '数字で入力してください。';
              break;
            default:
              break;
          }
        });
        options.validate = validation;
        break;
      }
      case 'forbidden_characters': {
        const validation: Record<string, Validate<string>> = {};
        rule.value.forEach((v) => {
          switch (v.type) {
            case 'emoji':
              validation.emoji = (value: string) =>
                /^(?!.*\p{Extended_Pictographic}).*$/u.test(value) ||
                '絵文字は使用できません。';
              break;
            default:
              break;
          }
        });
        options.validate = validation;
        break;
      }
      default:
        break;
    }
  });

  switch (formItem.inputElement.name) {
    case 'address_number':
      options.onBlur = normalizeAddress;
      break;
    case 'building_name':
      options.onBlur = replaceHanToZenInBuildingName;
      break;
    default:
      // do nothing
      break;
  }
  const registration = register(formItem.inputElement.name, options);

  const convertBirthdayToAge = (date: string): number => {
    const birthDate = isYearMonthDay(date)
      ? date
      : YearMonthDay.fromString(date);

    if (birthDate) {
      const today = new YearMonthDay(DateTime.local());
      const age = birthDate.diffOfYears(today);

      // 生年月日の入力が未来だった場合0歳とする
      return age < 0 ? 0 : age;
    }

    return 0;
  };

  const convertAgeToAgeStr = (age?: number): string =>
    typeof age === 'number' ? `${age}歳` : '-歳';

  const convertFormTitle = (item: ProfileFormItemViewData): string => {
    if (item.inputElement.name === 'zip_code') {
      return `${item.title}（ハイフンなし）`;
    }

    return item.title;
  };

  const getInpuTextMaxLength = (item: ProfileFormItemViewData): number | undefined => {
    if (item.inputElement.name === 'zip_code') {
      return 7;
    }

    return undefined;
  }

  return (
    <>
      {formItem.inputElement.type === 'text' && (
        <FormContainer>
          <FormTitle text={convertFormTitle(formItem)} />
          <InputText
            name={formItem.inputElement.name}
            placeholder={formItem.inputElement.options?.placeholder}
            register={registration}
            maxLength={getInpuTextMaxLength(formItem)}
          />
          {formItem.inputElement.name === 'zip_code' && (
            <>
              <button
                type="button"
                className={[
                  buttonStyles.button,
                  buttonStyles.buttonSmall,
                  buttonStyles.buttonPrimary,
                  styles.addressSearchButton,
                ].join(' ')}
                onClick={() => {
                  addressSearchButtonTapped();
                }}
              >
                住所検索
              </button>
            </>
          )}
        </FormContainer>
      )}
      {formItem.inputElement.type === 'select_box' && (
        <FormContainer>
          <FormTitle text={formItem.title} />
          <Select
            name={formItem.inputElement.name}
            multiple={formItem.inputElement.options.multiple ?? false}
            register={registration}
            options={formItem.inputElement.options?.values}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value={watch(formItem.inputElement.name)}
          />
        </FormContainer>
      )}
      {formItem.inputElement.type === 'birthday' && (
        <>
          <FormContainer>
            <FormTitle text={formItem.title} />
            <InputBirthDate
              registerYear={register('year', {
                required: '年は入力必須の項目です',
                onBlur: () => {
                  validateBirthday();
                },
              })}
              registerMonth={register('month', {
                required: '月は入力必須の項目です',
                onBlur: () => {
                  validateBirthday();
                },
              })}
              registerDay={register('day', {
                required: '日は入力必須の項目です',
                onBlur: () => {
                  validateBirthday();
                },
              })}
              watchYear={watchYear}
              watchMonth={watchMonth}
            />
          </FormContainer>
          {watchYear && watchMonth && watchDay && (
            <>
              {ageChanged && ageChanged(convertBirthdayToAge(watchBirthday))}
              <Container marginTop={10} marginBottom={6}>
                <FormContainer>
                  <FormTitle text="年齢" />
                  <InputText
                    name="age"
                    value={convertAgeToAgeStr(
                      convertBirthdayToAge(watchBirthday),
                    )}
                    readOnly
                  />
                </FormContainer>
              </Container>
            </>
          )}
        </>
      )}
    </>
  );
};

export default FormItem;
