import { useQuery } from 'react-query';
import Result, { Failure, Success } from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { isErrorDTO } from 'data/dto/error-dto';
import DataConsentRepository, {
  ConsentCategory,
  ConsentItem,
  DataConsentRepositoryImpl,
} from 'features/my-data/data/repositories/data-consent-repository';
import { DateTime } from 'luxon';
import queryClient from 'global/query-client';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { DialogButton } from 'global/components/dialog/Dialog';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import ClientCompanyRepository, {
  ClientCompanyRepositoryImpl,
} from '../data/repositories/client-company-repository';
import ClientCompanyViewData, {
  ConsentElement,
  StorageViewData,
} from '../view-data/client-company-view-data';
import convertDtoToClientCompanyViewData from './utils/convert-dto-to-client-company-view-data';
import updateConsentState from './utils/update-consent-state';
import buildConsentItems from './utils/build-consent-items';
import lastPageLoadAt, {
  LAST_PAGE_LOAD_AT_DATA_KEY,
} from './utils/last-page-load-at';

const IS_NOTICE_CLOSED_DATA_KEY = '/client_company_notice_closed';
const CLIENT_COMPANY_CACHE_KEY = '/client_company';

export type ReturnType = {
  fetchResult: Result<ClientCompanyViewData, Error>;
  pageLoaded: () => void;
  storageData: StorageViewData;
  closeButtonTapped: () => void;
  didConsentChanged: (item: ConsentElement) => void;
  saveButtonTapped: () => void;
  dialogProps?: DialogProps;
  isConsentStateChanged: boolean;
};

type DialogProps = {
  title?: string;
  description: string;
  primaryButton: DialogButton;
};

const cachedClientCompanyData = (): ClientCompanyViewData | undefined =>
  queryClient.getQueryData(CLIENT_COMPANY_CACHE_KEY);

const useClientCompany = (
  clientCompanyRepository: ClientCompanyRepository = new ClientCompanyRepositoryImpl(),
  consentRepository: DataConsentRepository = new DataConsentRepositoryImpl(),
  saveResult: (result: Result<void, Error>) => void,
): ReturnType => {
  const dialogProps = useRef<DialogProps | undefined>();
  const history = useHistory();
  const savedConsentItems = useRef<ConsentItem[]>();

  useEffect(() => {
    const clearQuery = () => {
      queryClient.removeQueries({
        queryKey: CLIENT_COMPANY_CACHE_KEY,
      });
    };

    return clearQuery;
  }, []);

  const queryResult = useQuery<ClientCompanyViewData, Error>(
    CLIENT_COMPANY_CACHE_KEY,
    async () => {
      try {
        const clientCompanyDto = await clientCompanyRepository.fetch();
        const consentDto = await consentRepository.fetch(
          ConsentCategory.Company,
        );

        const clientCompanyViewData = convertDtoToClientCompanyViewData(
          clientCompanyDto,
          consentDto,
          lastPageLoadAt(),
        );

        savedConsentItems.current = buildConsentItems(clientCompanyViewData);

        return clientCompanyViewData;
      } catch (error) {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      }
    },
  );

  const fetchResult = createResult(queryResult);

  const pageLoaded = () => {
    const now = DateTime.local();
    localStorage.setItem(LAST_PAGE_LOAD_AT_DATA_KEY, JSON.stringify(now));
  };

  const closeButtonTapped = () => {
    const formattedValue = JSON.stringify(true);

    localStorage.setItem(IS_NOTICE_CLOSED_DATA_KEY, formattedValue);
  };

  const isCloseButtonTapped = (): boolean => {
    const data: string | null = localStorage.getItem(IS_NOTICE_CLOSED_DATA_KEY);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedData = data === null ? undefined : JSON.parse(data);

    return typeof parsedData === 'boolean' ? parsedData : false;
  };

  const didConsentChanged = (consentElement: ConsentElement): void => {
    const cachedData = cachedClientCompanyData();
    if (cachedData == null) {
      return;
    }

    const newClientCompanyData = updateConsentState(cachedData, consentElement);
    queryClient.setQueryData(CLIENT_COMPANY_CACHE_KEY, newClientCompanyData);
  };

  const saveButtonTapped = () => {
    const cachedData = cachedClientCompanyData();
    if (cachedData == null) {
      return;
    }

    const consentItems = buildConsentItems(cachedData);
    consentRepository
      .save(ConsentCategory.Company, consentItems)
      .then(() => {
        savedConsentItems.current = consentItems;
        dialogProps.current = {
          title: '内容が更新されました。',
          description:
            'データを公開した企業から、新しいオファーが届く可能性があります。',
          primaryButton: {
            text: 'マイデータTOPへ',
            onClick: () => history.push('/mydata'),
          },
        };
        saveResult(new Success(undefined));
      })
      .catch((error) => {
        const errorMessage = isErrorDTO(error)
          ? error.error.message
          : 'データの公開先の更新に失敗しました。';
        dialogProps.current = {
          title: 'エラー',
          description: errorMessage,
          primaryButton: { text: 'OK' },
        };
        saveResult(new Failure(Error(errorMessage)));
      });
  };

  const isConsentStateChanged =
    fetchResult.isSuccess() && savedConsentItems.current != null
      ? JSON.stringify(savedConsentItems.current) !==
        JSON.stringify(buildConsentItems(fetchResult.value))
      : false;

  return {
    fetchResult,
    pageLoaded,
    storageData: {
      isCloseButtonTapped: isCloseButtonTapped(),
    },
    closeButtonTapped,
    didConsentChanged,
    saveButtonTapped,
    dialogProps: dialogProps.current,
    isConsentStateChanged,
  };
};

export default useClientCompany;
