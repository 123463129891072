import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import PhoneNumberAuthenticationDto from '../dto/phone-number-authentication-dto';

const createPhoneNumberAuthenticationRequestConfig = (
  phoneNumberAuthCode: number,
  transactionId: string,
  fincUserId: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/sign_in/phone_number/auth',
  },
  method: 'post',
  parameters: {
    phone_number_auth_code: phoneNumberAuthCode,
    transaction_id: transactionId,
    finc_user_id: fincUserId,
  },
});

interface PhoneNumberAuthenticationRepository {
  auth: (
    phoneNumberAuthCode: number,
    transactionId: string,
    fincUserId: string,
  ) => Promise<PhoneNumberAuthenticationDto>;
}

export class PhoneNumberAuthenticationRepositoryImpl
  implements PhoneNumberAuthenticationRepository
{
  auth = async (
    phoneNumberAuthCode: number,
    transactionId: string,
    fincUserId: string,
  ): Promise<PhoneNumberAuthenticationDto> =>
    new ApiClient().connect(
      createPhoneNumberAuthenticationRequestConfig(
        phoneNumberAuthCode,
        transactionId,
        fincUserId,
      ),
    );
}

export default PhoneNumberAuthenticationRepository;
