import { VFC, useRef, useCallback, useEffect } from 'react';
import Container from 'global/components/Container/Container';
import Button from 'global/components/button/Button';
import AnnouncementOfferDetailViewData from '../view-data/announcement-offer-detail-view-data';
import styles from './AnnouncementOfferDetailPage.module.css';
import AnnouncementCaution from '../components/AnnouncementCaution';
// import Reference from '../components/Reference';
import DetailItem from '../components/DetailItem';
import PointNotice from '../components/PointNotice';
import DetailIntro from '../components/DetailIntro';

type Props = {
  offerDetailViewData: AnnouncementOfferDetailViewData;
  linkButtonTapped: (
    offerDetailViewData: AnnouncementOfferDetailViewData,
    buttonNumber: number,
  ) => void;
  offerOpenEventFired: (
    offerDetailViewData: AnnouncementOfferDetailViewData,
  ) => void;
};

const AnnouncementOfferDetailPage: VFC<Props> = ({
  offerDetailViewData,
  linkButtonTapped,
  offerOpenEventFired,
}) => {
  const pageTopRef = useRef<HTMLDivElement>(null);
  const scrollToTop = useCallback(() => {
    pageTopRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'start',
    });
  }, []);
  useEffect(() => {
    scrollToTop();
    offerOpenEventFired(offerDetailViewData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToTop]);
  const linkButton1TappedAction = () => {
    linkButtonTapped(offerDetailViewData, 1);
  };
  const linkButton2TappedAction = () => {
    linkButtonTapped(offerDetailViewData, 2);
  };

  return (
    <>
      <Container>
        <div className={styles.container} ref={pageTopRef}>
          <img
            src={offerDetailViewData.mainImageAccessKey}
            alt="main"
            onError={(e) => {
              e.currentTarget.style.display = 'none';
            }}
          />
        </div>
      </Container>
      <Container marginTop="m" marginBottom="s" marginLeft="s" marginRight="s">
        <DetailIntro
          offerTitle={offerDetailViewData.title}
          companyName={offerDetailViewData.companyName}
          termDescription={offerDetailViewData.termDescription}
        />
      </Container>
      <PointNotice
        pointReason="オファーポイント獲得済み"
        // NOTE: システム基盤側に登録された獲得pt数は実際の獲得数と合致する、かつ変更の可能性がない前提で表示pt数にawardPointを使用
        pointDetail={`+${offerDetailViewData.awardPoint}pt 獲得！`}
      />
      <Container marginTop="s" marginBottom="s" marginLeft="s" marginRight="s">
        <DetailItem
          headline={offerDetailViewData.headline1}
          bodyText={offerDetailViewData.bodyText1}
        />
      </Container>
      {offerDetailViewData.textAnnouncementLinkUrl1 && (
        <Container
          marginTop="l"
          marginBottom="xxl"
          marginLeft="s"
          marginRight="s"
        >
          <Button
            text={offerDetailViewData.textAnnouncementLinkUrl1}
            type="primary"
            size="large"
            onClick={linkButton1TappedAction}
            iconType="external"
          />
        </Container>
      )}

      {offerDetailViewData.subImageAccessKey && (
        <Container marginLeft="s" marginRight="s">
          <img
            src={offerDetailViewData.subImageAccessKey}
            alt="sub"
            onError={(e) => {
              e.currentTarget.style.display = 'none';
            }}
          />
        </Container>
      )}
      {offerDetailViewData.headline2 && offerDetailViewData.bodyText2 && (
        <Container
          marginTop="s"
          marginBottom="s"
          marginLeft="s"
          marginRight="s"
        >
          <DetailItem
            headline={offerDetailViewData.headline2}
            bodyText={offerDetailViewData.bodyText2}
          />
        </Container>
      )}
      {offerDetailViewData.textAnnouncementLinkUrl2 && (
        <Container
          marginTop="l"
          marginBottom="xxl"
          marginLeft="s"
          marginRight="s"
        >
          <Button
            text={offerDetailViewData.textAnnouncementLinkUrl2}
            type="primary"
            size="large"
            onClick={linkButton2TappedAction}
            iconType="external"
          />
        </Container>
      )}
      {offerDetailViewData.caution && (
        <AnnouncementCaution text={offerDetailViewData.caution} />
      )}
      {/* 4/6ローンチ時点ではこの部分を出したくないとのことでいったん非表示にする<Reference
        companyName={offerDetailViewData.companyName}
        deliveryTargetConditions={offerDetailViewData.deliveryTargetConditions}
      /> */}
    </>
  );
};
export default AnnouncementOfferDetailPage;
