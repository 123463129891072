import {
  VFC,
  useRef,
  useEffect,
  useCallback,
  RefObject,
  createRef,
} from 'react';
import Checkbox from 'global/components/checkbox/Checkbox';
import { QuestionnairePageViewData } from 'features/questionnaire/view-data/questionnaire-detail-view-data';
import styles from './QuestionnaireQuestionList.module.css';

type Props = {
  questionnaire: QuestionnairePageViewData;
  checkboxChecked: (
    questionId: number,
    answerId: number,
    turnOn: boolean,
  ) => void;
};

type QuestionDivRef = {
  questionId: number;
  ref: RefObject<HTMLDivElement>;
};

const QuestionnaireQuestionList: VFC<Props> = ({
  questionnaire,
  checkboxChecked,
}) => {
  const firstUnansweredQuestionRef = useRef<HTMLDivElement>(null);

  const scrollToQuestion = useCallback((element: HTMLDivElement | null) => {
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, []);

  useEffect(() => {
    scrollToQuestion(firstUnansweredQuestionRef.current);
  }, [scrollToQuestion]);

  const questionDivRefs = questionnaire.section.questions.reduce(
    (result: QuestionDivRef[], question) => {
      result.push({
        questionId: question.id,
        ref: createRef<HTMLDivElement>(),
      });

      return result;
    },
    [],
  );

  const checkboxCheckAction = (
    questionid: number,
    optionId: number,
    questionIndex: number,
    turnOn: boolean,
  ) => {
    if (turnOn) {
      // チェックされた設問に次の設問が存在する場合はその設問までスクロールする
      const nextQuestionIndex = questionIndex + 1;
      if (nextQuestionIndex < questionnaire.section.questions.length) {
        const nextRef = questionDivRefs[nextQuestionIndex];
        scrollToQuestion(nextRef.ref.current);
      }
    }

    checkboxChecked(questionid, optionId, turnOn);
  };

  return (
    <>
      <div key={questionnaire.section.id}>
        <h2 className={styles.sectionTitle}>
          {questionnaire.section.sentence}
        </h2>
        <div className={styles.categoryImageWrapper}>
          <img
            className={styles.categoryImage}
            src={`/questionnaire/questionnaire_category_${questionnaire.categoryId}.svg`}
            alt="category"
          />
        </div>
        {questionnaire.section.questions.map((question, index) => (
          <div
            className={styles.questionWrapper}
            key={question.id}
            ref={
              questionnaire.firstUnansweredQuestionId === question.id
                ? firstUnansweredQuestionRef
                : null
            }
          >
            <div
              className={styles.questionTitleWrapper}
              ref={
                questionDivRefs.find((ref) => ref.questionId === question.id)
                  ?.ref ?? null
              }
            >
              <h3 className={styles.questionNumber}>Q{question.number}</h3>
              <p className={styles.questionTitle}>{question.sentence}</p>
              {question.supplement && (
                <p className={styles.questionSupplement}>
                  {question.supplement}
                </p>
              )}
            </div>
            <div
              className={[
                styles.optionWrapper,
                question.displayType === 'row' ? styles.row : styles.column,
              ].join(' ')}
            >
              {question.options.map((option) => (
                <div className={styles.checkboxWrapper} key={option.id}>
                  <Checkbox
                    name={`${question.id}`}
                    value={`${option.id}`}
                    checked={question.userAnsweredOptionId === option.id}
                    onClick={() =>
                      checkboxCheckAction(
                        question.id,
                        option.id,
                        index,
                        question.userAnsweredOptionId !== option.id,
                      )
                    }
                  />
                  <div className={styles.optionLabelWrapper}>
                    <div className={styles.optionLabel}>{option.sentence}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default QuestionnaireQuestionList;
