import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import PhoneNumberRegistrationContainer from './registration/containers/index';
import PhoneNumberAuthenticationContainer from './authentication/containers/index';
import PhoneNumberAuthenticationTipsContainer from './authentication-tips/containers';

const PhoneNumberRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/registration`}>
      <PhoneNumberRegistrationContainer />
    </Route>
    <Route exact path={`${match.path}/authentication`}>
      <PhoneNumberAuthenticationContainer />
    </Route>
    <Route exact path={`${match.path}/authentication_tips`}>
      <PhoneNumberAuthenticationTipsContainer />
    </Route>
  </Switch>
);

export default PhoneNumberRouter;
