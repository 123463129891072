import { useQuery } from 'react-query';
import QuestionnaireRepository, {
  QuestionnaireRepositoryImpl,
  QuestionAnswer,
} from 'features/questionnaire/data/repositories/questionnaire-repository';
import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import QuestionnaireDetailViewData, {
  QuestionnairePageViewData,
  QuestionnaireSection,
} from 'features/questionnaire/view-data/questionnaire-detail-view-data';
import queryClient from 'global/query-client';
import { useEffect, useState, useRef } from 'react';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import convertQuestionnaireDetailDtoToViewData from './utils/convert-questionnaire-detail-dto';
import buildQuestionAnswers from './utils/build-question-answers';
import PopupInfoViewData from '../view-data/popup-info-view-data';
import PopupInfoViewDto from '../data/dto/popup-info-dto';

const QUESTIONNAIRE_DETAIL_QUERY_KEY = '/questionnaire';

const cachedQuestionnaireDetailViewData = (
  categoryId: string,
): QuestionnaireDetailViewData | undefined =>
  queryClient.getQueryData([QUESTIONNAIRE_DETAIL_QUERY_KEY, categoryId]);

const questionnaireAnswers = (categoryId: string): QuestionAnswer[] => {
  const cached = cachedQuestionnaireDetailViewData(categoryId);
  if (cached === undefined) {
    return [];
  }

  const answers = buildQuestionAnswers(cached);

  return answers;
};

// カテゴリIDに一致するカテゴリ名を取得
// サーバから取得するのが望ましいが、
// カテゴリ情報はマスタで管理しており、IDとカテゴリの紐付けは
// まず変わらないため、クライアント側で対応しても問題ないと判断
const categoryName = (categoryId: string): string | undefined => {
  switch (categoryId) {
    case '1':
      return '食事';
    case '2':
      return '運動';
    case '3':
      return '睡眠';
    case '4':
      return 'カラダ';
    case '5':
      return 'メンタル';
    default:
      return undefined;
  }
};

type ReturnType = {
  fetchResult: Result<QuestionnaireDetailViewData, Error>;
  currentPage: number;
  answers: QuestionAnswer[];
  checkboxChecked: (
    questionId: number,
    answerId: number | null,
    turnOn: boolean,
  ) => void;
  nextButtonTapped: () => void;
  prevButtonTapped: () => void;
  submitted: () => void;
  isAnswerChanged: boolean;
  categoryName?: string;
};

const useQuestionnaireDetail = (
  categoryId: string,
  repository: QuestionnaireRepository = new QuestionnaireRepositoryImpl(),
  saveResult: (result: Result<PopupInfoViewData, Error>) => void,
): ReturnType => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const savedQuestionAnswers = useRef<QuestionAnswer[]>();

  useEffect(() => {
    const clearQuery = () => {
      queryClient.removeQueries({
        queryKey: [QUESTIONNAIRE_DETAIL_QUERY_KEY, categoryId],
      });
    };

    return clearQuery;
  }, [categoryId]);

  const queryResult = useQuery<QuestionnaireDetailViewData, Error>(
    [QUESTIONNAIRE_DETAIL_QUERY_KEY, categoryId],
    async () => {
      const dto = await repository.fetch(categoryId).catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      const questionnaireDetailViewData =
        convertQuestionnaireDetailDtoToViewData(dto, categoryId);

      savedQuestionAnswers.current = buildQuestionAnswers(
        questionnaireDetailViewData,
      );

      return questionnaireDetailViewData;
    },
    { refetchOnReconnect: false }, // 通信が再接続されたタイミングで再fetchを行わない
  );

  const fetchResult = createResult(queryResult);

  const checkboxChecked = (
    questionId: number,
    answerId: number | null,
    turnOn: boolean,
  ) => {
    const data = cachedQuestionnaireDetailViewData(categoryId);
    if (data === undefined) {
      return;
    }

    const newPages = data.pages.map((page): QuestionnairePageViewData => {
      const questions = page.section.questions.map((question) => {
        if (question.id === questionId) {
          const newQuestion = question;
          newQuestion.userAnsweredOptionId = turnOn ? answerId : null;

          return newQuestion;
        }

        return question;
      });

      const newSection: QuestionnaireSection = {
        id: page.section.id,
        sentence: page.section.sentence,
        questions,
      };

      return {
        name: page.name,
        categoryId: page.categoryId,
        numberOfQuestions: page.numberOfQuestions,
        section: newSection,
        firstUnansweredQuestionId: page.firstUnansweredQuestionId,
      };
    });

    const newData: QuestionnaireDetailViewData = { pages: newPages };

    queryClient.setQueryData(
      [QUESTIONNAIRE_DETAIL_QUERY_KEY, categoryId],
      newData,
    );
  };

  const nextButtonTapped = () => {
    setCurrentPage((old) => old + 1);
  };

  const prevButtonTapped = () => {
    setCurrentPage((old) => old - 1);
  };

  const convertDtoToViewData = (dto: PopupInfoViewDto): PopupInfoViewData =>
    dto;

  const submitted = () => {
    const answers = questionnaireAnswers(categoryId);
    const _ = repository
      .save(answers)
      .then((popupInfo) => {
        savedQuestionAnswers.current = answers;
        saveResult(new Success(convertDtoToViewData(popupInfo)));
      })
      .catch(() => {
        const errorMessage = 'セルフチェックの回答に失敗しました';
        saveResult(new Failure(Error(errorMessage)));
      });
  };

  const isAnswerChanged =
    fetchResult.isSuccess() && savedQuestionAnswers.current != null
      ? JSON.stringify(savedQuestionAnswers.current) !==
        JSON.stringify(buildQuestionAnswers(fetchResult.value))
      : false;

  return {
    fetchResult,
    currentPage,
    answers: questionnaireAnswers(categoryId),
    checkboxChecked,
    nextButtonTapped,
    prevButtonTapped,
    submitted,
    isAnswerChanged,
    categoryName: categoryName(categoryId),
  };
};

export default useQuestionnaireDetail;
