class ProgramId {
  value: number;

  constructor(value: number) {
    this.value = value;
  }

  // NOTE: hooksでパースしようとした場合必ず一意の値を返さないといけない都合上try,catchでエラーハンドリングできない。解決策が見つからないのでここのエラーは看過する。
  static fromString(value: string): ProgramId {
    const id = Number(value);

    return new ProgramId(id);
  }
}

export default ProgramId;
