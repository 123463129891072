import { VFC } from 'react';
import {
  NoticeListViewData,
  NoticeType,
} from '../view-data/notice-top-view-data';
import NoticeListCell from './NoticeListCell';
import AdditionalReadingList from './util/AdditionalReadingList';

type Props = {
  notices: NoticeListViewData[];
  isNeedLoadMore: boolean;
  onLoadItem: (cursor: number) => void;
  onTapNotice: (id: string, type: NoticeType, isRead: boolean) => void;
};

const NoticeList: VFC<Props> = ({
  notices,
  isNeedLoadMore,
  onLoadItem,
  onTapNotice,
}) => (
  <AdditionalReadingList
    isNeedLoadMore={isNeedLoadMore}
    onLoadItem={onLoadItem}
  >
    {notices.map((e) => (
      <div key={e.id}>
        <NoticeListCell viewData={e} onTapNotice={onTapNotice} />
      </div>
    ))}
  </AdditionalReadingList>
);

export default NoticeList;
