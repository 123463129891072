import Button from 'global/components/button/Button';
import { VFC } from 'react';
import SettingsContentsListCell from '../component/SettingsContentsListCell';
import SettingsRootViewData, {
  SettingsCategory,
} from '../view-data/settings-root-view-data';
import styles from './SettingsRootPage.module.css';

type Props = {
  viewData: SettingsRootViewData;
  onClickSettingsMenu: (category: SettingsCategory) => void;
  onClickLogout: () => void;
};

const SettingsRootPage: VFC<Props> = ({
  viewData,
  onClickSettingsMenu,
  onClickLogout,
}) => (
  <>
    {viewData.settings.map((e) => (
      <div key={e.category}>
        <SettingsContentsListCell viewData={e} onClick={onClickSettingsMenu} />
      </div>
    ))}
    <div className={styles.noticeArea}>
      <div className={styles.notice}>
        最終ログイン時刻
        <div className={styles.child}>{viewData.lastLoggedInAt}</div>
      </div>

      <div className={styles.notice}>
        これまでのログイン失敗回数
        <div
          className={styles.child}
        >{`${viewData.failedLoggedInCount}回`}</div>
      </div>
    </div>
    <div className={styles.logoutButtonWrapper}>
      <Button
        text="ログアウト"
        type="primary"
        size="large"
        onClick={onClickLogout}
      />
    </div>
  </>
);

export default SettingsRootPage;
