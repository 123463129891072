import { VFC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styles from './DailyPoint.module.css';
import DailyPointDetail from './DailyPointDetail';
import usePointDetail from '../hooks/use-point-detail';

const DailyPoint: VFC = () => {
  const {
    pointDetailResult,
    pointDetailResultValue,
    updatePointDetailResultValue,
    didScrollToBottom,
    hasMore,
  } = usePointDetail();

  return (
    <div className={styles.pointList} id="scrollableDiv">
      <InfiniteScroll
        dataLength={pointDetailResultValue.length}
        next={didScrollToBottom}
        hasMore={hasMore}
        loader={
          <div className={styles.monthSectionListItem}>読み込み中...</div>
        }
        scrollableTarget="scrollableDiv"
        scrollThreshold={1.0}
      >
        {pointDetailResult.isSuccess() &&
          pointDetailResultValue !== pointDetailResult.value &&
          updatePointDetailResultValue(pointDetailResult.value)}
        {pointDetailResultValue.map((data) => (
          <div className={styles.pointListItem} key={data.month}>
            <div className={styles.monthSectionList}>
              <div className={styles.monthSectionListItem}>{data.month}</div>
              <div className={styles.monthSectionListItem}>
                <DailyPointDetail dailyPointViewDataList={data.days} />
              </div>
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default DailyPoint;
