import { VFC, useRef, useCallback } from 'react';
import { QuestionnairePageViewData } from 'features/questionnaire/view-data/questionnaire-detail-view-data';
import Button from 'global/components/button/Button';
import QuestionnaireQuestionList from 'features/questionnaire/components/QuestionnaireQuestionList';
import smoothscroll from 'smoothscroll-polyfill';
import styles from './QuestionnaireDetailPage.module.css';

smoothscroll.polyfill();

type Props = {
  questionnaire: QuestionnairePageViewData;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  checkboxChecked: (
    questionId: number,
    answerId: number,
    turnOn: boolean,
  ) => void;
  nextButtonTapped: () => void;
  prevButtonTapped: () => void;
  submitted: () => void;
};

const QuestionnaireDetailPage: VFC<Props> = ({
  questionnaire,
  hasNextPage,
  hasPrevPage,
  checkboxChecked,
  nextButtonTapped,
  prevButtonTapped,
  submitted,
}) => {
  const pageTopRef = useRef<HTMLDivElement>(null);

  const scrollToTop = useCallback(() => {
    pageTopRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'start',
    });
  }, []);

  const nextButtonTapAction = () => {
    scrollToTop();
    nextButtonTapped();
  };

  const prevButtonTapAction = () => {
    scrollToTop();
    prevButtonTapped();
  };

  return (
    <>
      <div className={styles.container} ref={pageTopRef}>
        <QuestionnaireQuestionList
          questionnaire={questionnaire}
          checkboxChecked={checkboxChecked}
        />
        <div className={styles.buttonArea}>
          {hasPrevPage && (
            <Button
              text="前へ"
              type="default"
              size="large"
              onClick={prevButtonTapAction}
            />
          )}
          {hasNextPage && (
            <Button
              text="次へ"
              type="default"
              size="large"
              onClick={nextButtonTapAction}
            />
          )}
          {!hasNextPage && (
            <Button
              text="回答を完了する"
              type="primary"
              size="large"
              onClick={submitted}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default QuestionnaireDetailPage;
