import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import PasswordDto from '../dto/password-dto';

const createPasswordRequestConfig = (
  password: string,
  termsOfUseVersion: string,
  privacyPolicyVersion: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/final_registration',
  },
  method: 'post',
  parameters: {
    password,
    terms_of_use_version: termsOfUseVersion,
    privacy_policy_version: privacyPolicyVersion,
  },
});

interface PasswordRepository {
  save: (
    password: string,
    termsOfUseVersion: string,
    privacyPolicyVersion: string,
  ) => Promise<PasswordDto>;
}

export class PasswordRepositoryImpl implements PasswordRepository {
  save = async (
    password: string,
    termsOfUseVersion: string,
    privacyPolicyVersion: string,
  ): Promise<PasswordDto> =>
    new ApiClient().connect(
      createPasswordRequestConfig(
        password,
        termsOfUseVersion,
        privacyPolicyVersion,
      ),
    );
}

export default PasswordRepository;
