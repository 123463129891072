/* eslint-disable react/jsx-props-no-spreading */
import React, { VFC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from 'global/components/InputNum/InputNum.module.css';

type InputNumSize = 'large' | 'medium' | 'small';

export type Props = {
  name: string;
  value?: string;
  size?: InputNumSize;
  maxLength?: number;
  placeholder?: string;
  register?: UseFormRegisterReturn;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
};

const classNameForInputNum = (size?: InputNumSize): string => {
  switch (size) {
    case 'large':
      return styles.inputNumLarge;
    case 'small':
      return styles.inputNumSmall;
    default:
      return styles.inputNumLarge;
  }
};

const InputNum: VFC<Props> = ({
  name,
  value,
  placeholder,
  size,
  maxLength,
  register,
  onChange,
  readOnly,
}) => (
  <input
    name={name}
    value={value}
    placeholder={placeholder || '入力してください'}
    type="tel"
    maxLength={maxLength || 6}
    className={[classNameForInputNum(size), styles.input].join(' ')}
    {...register}
    onChange={onChange}
    readOnly={readOnly}
  />
);

export default InputNum;
