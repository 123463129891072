import { VFC } from 'react';
import Container from 'global/components/Container/Container';
import styles from './PointDetailPage.module.css';
import DailyPoint from '../components/DailyPoint';
import FitstatsPoint from '../../my-data/components/fitstats-point/FitstatsPoint';

const PointDetailPage: VFC = () => (
  <div className={styles.container}>
    <div className={styles.pointSection}>
      <FitstatsPoint showHistoryLink={false} />
    </div>
    <Container marginTop="s">
      <div className={styles.pointSectionWrapper}>
        <DailyPoint />
      </div>
    </Container>
  </div>
);

export default PointDetailPage;
