import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import MyDataTopContainer from 'features/my-data/containers/index';
import ContainerResolver from 'features/tutorial/ContainerResolver';
import PersonalDataContainer from './personal-data/containers';
import PersonalDataEditContainer from './personal-data/containers/edit';
import ClientCompanyContainer from './client-company/containers';
import ClientCompanyDetailContainer from './client-company/containers/show';
import TutorialPersonalDataContainer from './personal-data/containers/tutorial-index';
import TutorialClientCompanyContainer from './client-company/containers/tutorial-index';

const MyDataRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`}>
      <MyDataTopContainer />
    </Route>
    <Route exact path={`${match.path}/personal`}>
      <ContainerResolver
        defaultContainer={<PersonalDataContainer />}
        tutorialContainer={<TutorialPersonalDataContainer />}
      />
    </Route>
    <Route exact path={`${match.path}/personal/edit`}>
      <PersonalDataEditContainer />
    </Route>
    <Route exact path={`${match.path}/client_company`}>
      <ContainerResolver
        defaultContainer={<ClientCompanyContainer />}
        tutorialContainer={<TutorialClientCompanyContainer />}
      />
    </Route>
    <Route exact path={`${match.path}/client_companies/:clientId`}>
      <ClientCompanyDetailContainer />
    </Route>
  </Switch>
);

export default MyDataRouter;
