import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import ProgramTopContainer from 'features/program/containers/index';
import ProgramDetailContainer from './containers/show';

const ProgramRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`}>
      <ProgramTopContainer />
    </Route>
    <Route path={`${match.path}/:programId`}>
      <ProgramDetailContainer />
    </Route>
  </Switch>
);

export default ProgramRouter;
