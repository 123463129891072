import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';

const createEmailResettingsRequestConfig = (
  currentEmail: string,
  changedEmail: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/email/change_request',
  },
  method: 'post',
  parameters: {
    current_email: currentEmail,
    post_email: changedEmail,
  },
});

interface EmailResettingsRepository {
  resetting: (currentEmail: string, changedEmail: string) => Promise<void>;
}

export class EmailResettingsRepositoryImpl
  implements EmailResettingsRepository
{
  resetting = async (
    currentEmail: string,
    changedEmail: string,
  ): Promise<void> =>
    new ApiClient().connect(
      createEmailResettingsRequestConfig(currentEmail, changedEmail),
    );
}

export default EmailResettingsRepository;
