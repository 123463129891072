import TermsOfUseAndPrivacyPolicyViewData from 'global/view-data/terms-of-use-and-privacy-policy-view-data';
import PrivacyPolicyDto from '../privacy-policy/data/dto/privacy-policy-dto';
import TermsOfUseDto from '../terms-of-use/data/dto/terms-of-use-dto';

const convertDtoToTermsOfUseAndPrivacyPolicyViewData = (
  dto: TermsOfUseDto | PrivacyPolicyDto,
): TermsOfUseAndPrivacyPolicyViewData => {
  const UrlConvertedContent = dto.content.replace(
    /href="http/g,
    'href="fitstats-web-command://open_external_browser_without_auth?url=http',
  );

  return {
    ...dto,
    content: UrlConvertedContent,
  };
};

export default convertDtoToTermsOfUseAndPrivacyPolicyViewData;
