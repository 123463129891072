import { VFC } from 'react';
import Highcharts from 'highcharts-react-official';
import TopDailyScoresViewData from '../view-data/top-daily-scores-view-data';

type Props = {
  chartData: TopDailyScoresViewData;
};

const TopDailyScoresChart: VFC<Props> = ({ chartData }) => {
  const Y_AXIS_PADDING_RATE = 0.04;

  const xAxisLabels = chartData.scores.map((score) => score.graphLabel);
  const yAxisData = chartData.scores.map((score) => score.score);
  const dailyScores = yAxisData.flatMap((v) => (v === null ? [] : [v]));
  const hasDailyScores = dailyScores.length;

  const options = {
    chart: {
      type: 'line',
      height: 185,
      window: '100%',
      marginLeft: 45,
      marginRight: 15,
      spacingBottom: 10,
    },
    title: { text: undefined },
    credits: { enabled: false },
    plotOptions: {
      line: {
        enableMouseTracking: false,
        color: 'rgb(0 157 133 / 40%)',
        lineWidth: 2,
        marker: {
          fillColor: 'rgb(0 157 133 / 100%)',
          height: 6,
          radius: 3,
        },
      },
    },
    xAxis: {
      categories: xAxisLabels,
      tickInterval: 7,
      title: { enabled: false },
      labels: {
        style: {
          color: '#738c89',
          fontSize: 10,
          fontFamily: 'HiraginoSans-W3',
        },
      },
    },
    yAxis: {
      title: { text: undefined },
      gridLineColor: '#f1eff5',
      tickAmount: 5, // 目盛りの数
      allowDecimals: false,
      labels: {
        style: {
          color: '#738c89',
          fontSize: 12,
          fontFamily: 'Josefin Sans',
        },
      },
      floor: 0,
      // NOTE:
      // minPadding・maxPaddingの結果は、指定割合の算出結果と完全に一致しない（超過する）ことがある。
      // 目盛りを指定数(tickAmount)だけ均等に設置するため、端数が生じた時などライブラリ側でよしなに調整されるため。
      // また、minPadding・maxPaddingとmin・maxは共存できない（min・max設定が優先される）ので注意。
      maxPadding: hasDailyScores ? Y_AXIS_PADDING_RATE : null, // データ最大値から指定割合を乗算した目盛り最大値
      minPadding: hasDailyScores ? Y_AXIS_PADDING_RATE : null, // データ最小値から指定割合を乗算した目盛り最小値
      max: hasDailyScores ? null : 4, // 目盛り最大値
      min: hasDailyScores ? null : 0, // 目盛り最小値
    },
    series: [
      {
        data: yAxisData,
        connectNulls: true,
      },
    ],
    legend: {
      enabled: false,
    },
  };

  return (
    <>
      <Highcharts options={options} />
    </>
  );
};

export default TopDailyScoresChart;
