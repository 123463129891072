import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import UserAnnouncementModalsDto from '../dto/user-announcement-modal-dto';

const createUserAnnouncementModalDtoRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/announcement_modal/',
  },
  method: 'post',
  parameters: {},
});

interface UserAnnouncementModalRepository {
  fetch: () => Promise<UserAnnouncementModalsDto>;
}

export class UserAnnouncementModalRepositoryImpl
  implements UserAnnouncementModalRepository {
  fetch = async (): Promise<UserAnnouncementModalsDto> =>
    new ApiClient().connect(createUserAnnouncementModalDtoRequestConfig());
}

export default UserAnnouncementModalRepository;
