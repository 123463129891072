import { useCallback, useEffect, useRef, useState, VFC } from 'react';
import Button from 'global/components/button/Button';
import Container from 'global/components/Container/Container';
import { useForm } from 'react-hook-form';
import AttentionContainer from 'global/components/AttentionContainer/AttentionContainer';
import SquareCheckboxWithLabel from 'global/components/square-checkbox-with-label/SquareCheckboxWithLabel';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import { Divider } from '@chakra-ui/react';
import FormItemList from 'features/profile/components/FormItemList';

import {
  defaultParentalConsentStatus,
  isNeedParentalConsent,
} from 'features/profile/utils/function-for-profile-view-data';
import TopContainer from 'global/components/TopContainer/TopContainer';
import ProgressBar from 'features/sign-up/components/ProgressBar/ProgressBar';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import { ProfileSinglePageViewData } from '../../../profile/view-data/profile-view-data';
import styles from './ProfilePage.module.css';
import { UserInputRawData } from '../../../profile/user-input-item';

type Props = {
  result: ProfileSinglePageViewData;
  submitted: (userInput: UserInputRawData) => void;
};

const ProfileBasicPage: VFC<Props> = ({
  result,
  submitted,
}) => {
  const { isOpen, requestShowing, onClose } = useDialog();
  const pageTopRef = useRef<HTMLDivElement>(null);
  const scrollToTop = useCallback(() => {
    pageTopRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'start',
    });
  }, []);

  // ページが描画されたら先頭までスクロール
  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    setError,
    clearErrors,
  } = useForm<UserInputRawData>({
    mode: 'onBlur',
    defaultValues: result.userInputItems?.reduce(
      (obj, item) => ({ ...obj, [item.name]: item.value }),
      {},
    ),
  });

  const [hasParentalConsent, setParentalConsent] = useState(
    defaultParentalConsentStatus(result.userInputItems),
  );

  const [currentAge, setAge] = useState(0);

  const ageChanged = (age: number): void => {
    setAge(age);
  };

  const isBirthdayValid = (): boolean => {
    if (watch('year') && watch('month') && watch('day') && !errors.birthday) {
      return true;
    }

    return false;
  };

  return (
    <>
      <TopContainer>
        <ProgressBar max={8} value={8} />
      </TopContainer>
      <div className={styles.profilePageWrapper} ref={pageTopRef}>
        <form>
          <Container marginTop="m" marginBottom="m">
            <div className={styles.profilePageDescription}>
              あなたについて教えてください。
              <br />
              登録されたプロフィールは無断で
              <br />
              企業に公開されることはありません。
              <br />
              <span className={styles.profilePageDescriptionWarning}>
                このページは全て必須項目です。
              </span>
            </div>
          </Container>
          {result.profilePageViewData.sections.map((section) => (
            <FormItemList
              formItems={section.formItems}
              register={register}
              errors={errors}
              watch={watch}
              ageChanged={ageChanged}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
            />
          ))}
          {isBirthdayValid() && isNeedParentalConsent(currentAge) && (
            <Container marginTop="m" marginBottom="m">
              <AttentionContainer>
                <Container marginTop={10} marginBottom={10}>
                  <Container marginLeft={32}>
                    <div className={styles.profilePageAttentionDescription}>
                      FitStatsでは、あなたの個人情報を取り扱うため、
                      <br />
                      16歳未満の方は保護者の同意が必要になります
                    </div>
                  </Container>
                  <Container marginTop={6}>
                    <SquareCheckboxWithLabel
                      name="parental_consent"
                      value="consented"
                      label="保護者の同意を得ている"
                      checked={hasParentalConsent}
                      onClick={() => setParentalConsent(!hasParentalConsent)}
                    />
                  </Container>
                </Container>
              </AttentionContainer>
            </Container>
          )}
        </form>
      </div>
      <BottomContainer>
        <Divider orientation="horizontal" />
        <Container
          marginTop={50}
          marginBottom={50}
          marginLeft="s"
          marginRight="s"
        >
          <Button
            text="登録する"
            type="primary"
            size="large"
            disabled={
              !isValid ||
              (isNeedParentalConsent(currentAge)
                ? !hasParentalConsent
                : false)
            }
            onClick={requestShowing}
          />
        </Container>
      </BottomContainer>
      <Dialog
        title="確認画面"
        description={"入力された内容を、あなたのマイデータとして登録します。\n\n​登録内容は、後から編集することができます。"}
        primaryButton={{
          text: '登録する',
          onClick: handleSubmit(submitted),
        }}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default ProfileBasicPage;
