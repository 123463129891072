import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';

export const OfferEvent = {
  ANNOUNCEMENT_OFFER_OPEN_EVENT: 'ANNOUNCEMENT_OFFER_OPEN_EVENT',
  QUESTIONNAIRE_OFFER_OPEN_EVENT: 'QUESTIONNAIRE_OFFER_OPEN_EVENT',
  LINK_URL_1_CLICK_EVENT: 'LINK_URL_1_CLICK_EVENT',
  LINK_URL_2_CLICK_EVENT: 'LINK_URL_2_CLICK_EVENT',
} as const;

export type OfferEvent = typeof OfferEvent[keyof typeof OfferEvent];

const createOfferEventConfig = (
  offerId: string,
  eventType: OfferEvent,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/users/me/offers/${offerId}/events`,
  },
  method: 'post',
  parameters: { event_type: eventType },
});

interface OfferEventRepository {
  createEvent: (offerId: string, eventType: OfferEvent) => Promise<void>;
}

export class OfferEventRepositoryImpl implements OfferEventRepository {
  createEvent = async (offerId: string, eventType: OfferEvent): Promise<void> =>
    new ApiClient().connect(createOfferEventConfig(offerId, eventType));
}

export default OfferEventRepository;
