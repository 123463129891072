/* eslint-disable react/jsx-props-no-spreading */
import { VFC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from './InputBirthDate.module.css';

export type Props = {
  registerYear?: UseFormRegisterReturn;
  registerMonth?: UseFormRegisterReturn;
  registerDay?: UseFormRegisterReturn;
  watchYear: string;
  watchMonth: string;
};

const InputBirthDate: VFC<Props> = ({
  registerYear,
  registerMonth,
  registerDay,
  watchYear,
  watchMonth,
}) => {
  const createYear = () => {
    const list = [];
    list.push(
      <option key="year_0" value="">
        年
      </option>,
    );
    for (let i = new Date().getFullYear(); i >= 1900; i -= 1) {
      list.push(
        <option key={`year_${i}`} value={i}>
          {i}
        </option>,
      );
    }

    return list;
  };

  const createMonth = () => {
    const list = [];
    list.push(
      <option key="month_0" value="">
        月
      </option>,
    );
    for (let i = 1; i <= 12; i += 1) {
      list.push(
        <option key={`month_${i}`} value={`00${i}`.slice(-2)}>
          {i}
        </option>,
      );
    }

    return list;
  };
  const createDay = () => {
    const list = [];
    list.push(
      <option key="day_0" value="">
        日
      </option>,
    );
    const lastday = new Date(
      Number(watchYear),
      Number(watchMonth),
      0,
    ).getDate();
    for (let i = 1; i <= lastday; i += 1) {
      list.push(
        <option key={`day_${i}`} value={`00${i}`.slice(-2)}>
          {i}
        </option>,
      );
    }

    return list;
  };

  return (
    <>
      <select name="year" {...registerYear} className={styles.selectBirthDate}>
        {createYear()}
      </select>
      <select
        name="month"
        {...registerMonth}
        className={styles.selectBirthDate}
      >
        {createMonth()}
      </select>
      <select name="date" {...registerDay} className={styles.selectBirthDate}>
        {createDay()}
      </select>
    </>
  );
};
export default InputBirthDate;
