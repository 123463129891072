import { VFC } from 'react';
import styles from './ToggleSwitch.module.css';

type OnOffText = {
  onText: string;
  offText: string;
};

type Props = {
  onOffText: OnOffText;
  isOn: boolean;
  onClick: (isOn: boolean) => void;
};

const ToggleSwitch: VFC<Props> = ({ onOffText, isOn, onClick }) => {
  const click = () => {
    onClick(!isOn);
  };

  const className = isOn
    ? `${styles.switch} ${styles.on}`
    : `${styles.switch} ${styles.off}`;

  return (
    <button type="button" className={className} onClick={click}>
      <div className={styles.labelText}>
        {isOn ? onOffText.onText : onOffText.offText}
      </div>
    </button>
  );
};

export default ToggleSwitch;
