import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import SettingsRootDto from '../dto/settings-root-dto';

const createSettingsUsersLoggedInRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/evidences/sign_in_histories',
  },
  method: 'get',
  parameters: {},
});

interface SettingsRootRepository {
  fetch: () => Promise<SettingsRootDto>;
}

export class SettingsRootRepositoryImpl implements SettingsRootRepository {
  fetch = async (): Promise<SettingsRootDto> =>
    new ApiClient().connect(createSettingsUsersLoggedInRequestConfig());
}

export default SettingsRootRepository;
