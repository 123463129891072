import { VFC } from 'react';
import Loading from 'global/components/Loading/Loading';

import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import NoticeTopPage from '../pages/NoticeTopPage';
import useNoticeTop from '../hooks/use-notice-top';

const NoticeTopContainer: VFC = () => {
  useNavigationBarItemsSetting('お知らせ');
  const {
    fetchResult,
    shownNotices,
    isNeedAddLoadListMore,
    onAddLoadShownListItem,
    onTapNotice,
  } = useNoticeTop();

  if (fetchResult.isSuccess()) {
    return (
      <NoticeTopPage
        notices={shownNotices}
        isNeedLoadMore={isNeedAddLoadListMore}
        onLoadItem={onAddLoadShownListItem}
        onTapNotice={onTapNotice}
      />
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default NoticeTopContainer;
