import { DataConsentRepositoryImpl } from 'features/my-data/data/repositories/data-consent-repository';
import DialogPrompt from 'global/components/dialog-prompt/DialogPrompt';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import Loading from 'global/components/Loading/Loading';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { VFC } from 'react';
import { ClientCompanyRepositoryImpl } from '../data/repositories/client-company-repository';
import useClientCompany from '../hooks/use-client-company';
import ClientCompanyPage from '../pages/ClientCompanyPage';

const ClientCompanyContainer: VFC = () => {
  const { isOpen, requestShowing, onClose } = useDialog();
  useNavigationBarItemsSetting('データ公開する企業の選択');

  const {
    fetchResult,
    pageLoaded,
    storageData,
    closeButtonTapped,
    didConsentChanged,
    saveButtonTapped,
    dialogProps,
    isConsentStateChanged,
  } = useClientCompany(
    new ClientCompanyRepositoryImpl(),
    new DataConsentRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        requestShowing();
      }
      if (saveResult.isFailure()) {
        requestShowing();
      }
    },
  );

  if (fetchResult.isSuccess()) {
    return (
      <>
        <DialogPrompt
          when={isConsentStateChanged}
          dialogProps={{
            description:
              'データを編集中です。編集したデータを更新せず終了してよろしいですか？',
            primaryButtonTitle: '編集に戻る',
            destructiveButtonTitle: 'はい',
          }}
        />
        <Dialog
          title={dialogProps?.title}
          description={dialogProps?.description}
          primaryButton={dialogProps?.primaryButton ?? { text: 'OK' }}
          isOpen={isOpen}
          onClose={onClose}
        />
        <ClientCompanyPage
          clientConmapnyData={fetchResult.value}
          pageLoaded={pageLoaded}
          storageData={storageData}
          closeButtonTapped={closeButtonTapped}
          didConsentChanged={didConsentChanged}
          saveButtonTapped={saveButtonTapped}
        />
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default ClientCompanyContainer;
