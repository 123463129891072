import Button from 'global/components/button/Button';
import Container from 'global/components/Container/Container';
import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import InputPassword from 'global/components/InputPassword/InputPassword';
import InputText from 'global/components/InputText/InputText';
import { useRef, VFC } from 'react';
import { useForm } from 'react-hook-form';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import { Divider } from '@chakra-ui/react';
import styles from 'features/sign-in/email-password/pages/SignInPage.module.css';
import UndraggableLink from 'global/components/UndraggableLink/UndraggableLink';

type Props = {
  submitted: (email: string, password: string) => void;
};

type FormData = {
  email: string;
  password: string;
};

const SignInPage: VFC<Props> = ({ submitted }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<FormData>({ mode: 'onChange' });

  const processing = useRef(false);
  const onSubmit = (data: FormData) => {
    // TODO: 今後のことを考えると専用のコンポーネントにするのが望ましい
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;

    // 処理中フラグを上げる
    processing.current = true;

    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 3000);

    submitted(data.email, data.password);
  };

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <div className={styles.body}>
          <Container marginTop="m">
            <FormContainer>
              {[
                <FormTitle size="large" text="メールアドレス" />,
                <InputText
                  name="email"
                  placeholder="example@fitstats.com"
                  size="large"
                  register={register('email', {
                    required: 'メールアドレスは入力必須の項目です。',
                    maxLength: {
                      value: 50,
                      message: '50文字以下で入力してください。',
                    },
                  })}
                />,
              ]}
            </FormContainer>
            <p className={styles.errorMessage}>{errors?.email?.message}</p>
          </Container>

          <Container marginTop="m">
            <FormContainer>
              {[
                <FormTitle size="large" text="パスワード" />,
                <InputPassword
                  name="password"
                  size="large"
                  register={register('password', {
                    required: 'パスワードは入力必須の項目です。',
                    maxLength: {
                      value: 64,
                      message: '64文字以下で入力してください。',
                    },
                  })}
                />,
              ]}
            </FormContainer>
            <p className={styles.errorMessage}>{errors?.password?.message}</p>
          </Container>

          <Container marginTop="xxl">
            <p className={styles.text}>
              メールアドレスを忘れた場合は
              <UndraggableLink
                className={styles.highlightText}
                to="/forget_email/confirm_phone_number"
              >
                こちら
              </UndraggableLink>
            </p>
          </Container>
          <Container marginTop="s">
            <p className={styles.text}>
              パスワードを忘れた場合は
              <UndraggableLink
                className={styles.highlightText}
                to="/forget_password/input_email"
              >
                こちら
              </UndraggableLink>
            </p>
          </Container>
        </div>
      </Container>
      <div className={styles.bottomContainer}>
        <BottomContainer>
          <Divider orientation="horizontal" />
          <Container
            marginTop="s"
            marginBottom="s"
            marginLeft="s"
            marginRight="s"
          >
            <Button
              text="ログイン"
              type="primary"
              size="large"
              disabled={!isDirty || !isValid}
              onClick={handleSubmit(onSubmit)}
            />
          </Container>
        </BottomContainer>
      </div>
    </>
  );
};
export default SignInPage;
