import { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

type Props = {
  isNeedLoadMore: boolean;
  onLoadItem: (cursor: number) => void;
};

// NOTE: ここではプラグインのreact-infinite-scrollerを使っておく
// https://www.npmjs.com/package/react-infinite-scroll-component
const AdditionalReadingList: FC<Props> = ({
  isNeedLoadMore,
  onLoadItem,
  children,
}) => (
  <>
    <InfiniteScroll
      hasMore={isNeedLoadMore}
      loadMore={(page) => {
        // NOTE: react-infinite-scroller内部でのcss都合か、
        // iOSで追加読み込みされた要素にhoverがかかっている様子で2回タップしないとbuttonイベントが発火しない問題の対応。
        // 空のイベントを入れておく。
        window.ontouchstart = () => {
          ('');
        };

        onLoadItem(page);
      }}
    >
      {children}
    </InfiniteScroll>
  </>
);

export default AdditionalReadingList;
