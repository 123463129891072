import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import TermsOfUseDto from '../dto/terms-of-use-dto';

const fetchTermsOfUseRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/terms_of_use',
  },
  method: 'get',
  parameters: {},
});

const createTermsOfUseRequestConfig = (docVersion: string): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/terms_of_use',
  },
  method: 'post',
  parameters: { doc_version: docVersion },
});

interface TermsOfUseRepository {
  fetch: () => Promise<TermsOfUseDto>;
  save: (docVersion: string) => Promise<void>;
}

export class TermsOfUseRepositoryImpl implements TermsOfUseRepository {
  fetch = async (): Promise<TermsOfUseDto> =>
    new ApiClient().connect(fetchTermsOfUseRequestConfig());

  save = async (docVersion: string): Promise<void> =>
    new ApiClient().connect(createTermsOfUseRequestConfig(docVersion));
}

export default TermsOfUseRepository;
